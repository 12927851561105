import React, { useState, useEffect } from "react";
import BaseModal from "./BaseModal";
import ModalContainer from "./Modal/ModalContainer";
import MainCard from "./CardBox/MainCard";
import ModalBtn from "./Button/ModalBtn";
import { RandomIdGenerator } from "../utils/calculations";
import usflag from "../assets/usflag.svg";
import auflag from "../assets/auflag.svg";
import SymbolSearch from "./SymbolSearch";
import { companyIcons } from "../utils/sidebarContent";
import { createPortfolio } from "../ApiControler";
import { getPortfolio } from "../ApiControler";
import { useNavigate } from "react-router-dom";
import { addSymbol } from "../ApiControler";
function AddPortfolio({
  handleSelect,
  hnadeLoader,
  holdingBtnPressed,
  setAllPortfolios = [],
  PortfolioData,
  handelLoading,
  handelTicker,
  portFolioHandler,
  openModal,
  is_dashboard,
}) {
  const [portfolioList, setPortfolioList] = useState([
    {
      portfolioName: "All Portfolios",
      currency: "USD",
      ticker: [
        { symbol: "Data 1", data: [0, 0, 0, 0, 0, 0, 0], color: "#1C64F2" },
      ],
      cardHolding: [],
    },
  ]);

  useEffect(() => {
    setAllPortfolios(portfolioList);
  }, [portfolioList]);

  const [portfolioName, setPortfolioName] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const emptySpacesRegex = /^(?!\s*$).+/;
  // const [currency,setCurrency] = useState('USD')
  const [currency, setCurrency] = useState({ name: "USD", flag: usflag });
  const [openCurrency, setOpenCurrency] = useState(false);
  const [numberOfShares, setNumberOfShares] = useState(null);
  const [costPerShare, setCostPerShare] = useState(null);
  const [toggleSearch, setToggleSearch] = useState(false);
  const [openAssingPortfolio, setOpenAssingPortfolio] = useState(false);
  const navigate = useNavigate();
  const [errMsg, setErr] = useState("");
  const [isLoader, setLoader] = useState(false);
  // const currencyList = ['USD','AUD']
  const currencyList = [
    { name: "USD", flag: usflag },
    { name: "AUD", flag: auflag },
  ];
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const brokerageAccount = [
    { id: 1, image: "fidelity.png", name: "fidelity" },
    { id: 2, image: "schwab.png", name: "schwab" },
    { id: 3, image: "mfinance.png", name: "mfinance" },
    { id: 4, image: "vanguard.png", name: "vanguard" },
    { id: 5, image: "robinhood.png", name: "robinhood" },
    { id: 6, image: "etrade.png", name: "etrade" },
  ];
  const [stockTicker, setStockTicker] = useState("");
  const [selectedPortfolio, setSelectedPortfolio] = useState({
    id: 1,
    portfolioName: "All Portfolios",
    currency: "USD",
    ticker: [
      { symbol: "Data 1", data: [0, 0, 0, 0, 0, 0, 0], color: "#1C64F2" },
    ],
    cardHolding: [],
  });
  const [holdingModalItem, setHoldingModalItem] = useState(null);
  const [openAddPortfolio, setOpenAddPortfolio] = useState(false);
  const [selectedBrokerage, setSelectedBrokerage] = useState("");
  const [step, setStep] = useState(1);
  const [modalHeading, setModalHeading] = useState(
    "Select the type of Portfolio"
  );
  const portfolioType = [
    { name: "Automatic Portfolio", image: "automaticportfolio.png" },
    { name: "Manual Portfolio", image: "manualportfolio.png" },
  ];
  const [selectedPortfolioType, setSelectedPortfolioType] = useState(
    "Automatic Portfolio"
  );

  const handleAddPortoflio = () => {
    setOpenAddPortfolio(true);
  };
useEffect(() => {
  // setIsModalOpen(openModal);
  // setToggleSearch(openModal);
  setOpenAddPortfolio(openModal);
},[openModal])
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  useEffect(() => {
    if (holdingBtnPressed) {
      setToggleSearch(true);
    }
  }, [holdingBtnPressed]);

  const handleCloseModal = () => {
    setOpenAddPortfolio(false);
    setPortfolioName("");
    setSelectedBrokerage("");
    setCurrency("USD");
    setModalHeading("Select the type of Portfolio");
    setCostPerShare("");
    setNumberOfShares("");
    setStockTicker("");
    setStep(1);
  };

  const create_portfolio = async () => {
    const token = localStorage.getItem("token");
    if (!errMsg) {
      setLoader(true);
      try {
        const response = await createPortfolio(token, portfolioName);
        console.log("portFolio added  ", response?.message);
        if(response?.message === "New portfolio added successfully"){
          portFolioHandler(true);
        }

      } catch (e) {
        setLoader(false);
        setErr(e?.response?.data?.message);
        console.log("add portfolio error", e);
        // throw e;
      }
    } else {
      navigate("/");
    }
  };
  const get_portfolio = async () => {
    const token = localStorage.getItem("token");
    console.log("port---->", PortfolioData)
    if (!errMsg) {
      // setLoader(true);
      try {
        const response = await getPortfolio(token);
        
        setPortfolioList((prevList) => {
          // Extract new data
          const newPortfolios = PortfolioData || [];
          // Filter out duplicates
          const filteredNewPortfolios = newPortfolios.filter(
            (newPortfolio) =>
              !prevList.some(
                (existingPortfolio) =>
                  existingPortfolio.portfolioName === newPortfolio.portfolioName
              )
          );
          // Return the updated list
         
          
            // hnadeLoader(false);
            return [...prevList, ...filteredNewPortfolios];
          
        });
      } catch (e) {
        console.log("get portfolio error", e.message);
      }
    } else {
      navigate("/");
    }
  };

  // get portfolio api calling
  useEffect(() => {
    get_portfolio();
  }, []);

  // const get_portfolio = async () => {
  //   const token = localStorage.getItem("token");
  //   if (!errMsg) {
  //     setLoader(true);
  //     try {
  //       const response = await getPortfolio(token);
  //       console.log("Get Porfolio list : ", response?.data)
  //       setPortfolioList((prevList) => [...prevList, ...response?.data]);
  //       // setPortfolioList([...portfolioList,response?.data])

  //     } catch (e) {
  //       console.log("get portfolio error", e);
  //     }
  //   } else {
  //     navigate("/");
  //   }
  // };
  // // get portfolio api calling
  // useEffect(() => {
  //   get_portfolio()
  // },[]);

  const handleContinue = () => {
    if (selectedPortfolioType == "Automatic Portfolio") {
      setModalHeading("Link a Brokerage Account");
    } else {
      setModalHeading("Manual Portfolio");
    }
    setStep(2);
  };

  function getRandomInt(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min;
  }

  function getRandomColor() {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  function getRandomName() {
    return `Portfolio-${RandomIdGenerator(3)}`;
  }

  function getRandomData(length) {
    const data = [];
    for (let i = 0; i < length; i++) {
      data.push(getRandomInt(1000, 200000));
    }
    return data;
  }

  function generateRandomSeries(numSeries, dataLength) {
    const series = [];
    for (let i = 0; i < numSeries; i++) {
      series.push({
        name: getRandomName(),
        data: getRandomData(dataLength),
        color: getRandomColor(),
      });
    }
    return series;
  }

  const handleAddHolding = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await addSymbol(
        token,
        holdingModalItem?._id,
        stockTicker,
        numberOfShares,
        costPerShare
      );
if(response?.data){
  handelTicker(true)
   console.log(selectedPortfolio)
}
 

    } catch (e) {
      console.log("get portfolio error", e);
    }

    let holdingId = holdingModalItem?._id;
    
    let newItem = {
      img: companyIcons[stockTicker],
      ticker: stockTicker,
      shares: numberOfShares,
    };
    let totalAsset =
      (costPerShare ? costPerShare : 0) * (numberOfShares ? numberOfShares : 0);
    
    const updatedArr = portfolioList?.map((item) => {
      if (item._id === holdingId) {
        return {
          ...item,
          cardHolding: [...item?.ticker, newItem],
          totalAsset: totalAsset,
        };
      }
      return item;
    });
    setPortfolioList(updatedArr);
    var updatedSelectedObj = portfolioList?.find(
      (item) => item._id === holdingId
    );
    updatedSelectedObj = {
      ...updatedSelectedObj,
      cardHolding: [...updatedSelectedObj?.ticker, newItem],
      totalAsset: totalAsset,
    };
    
    handleSelect(updatedSelectedObj);
    setSelectedPortfolio(updatedSelectedObj);
    handleCloseModal();
  };





  console.log("spicficAddPortfolio", selectedPortfolio)



  const Modal = ({ onClose }) => (
    <div className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50">
      <div className="bg-white p-6 rounded shadow-lg relative">
        <button
          onClick={onClose}
          className="absolute top-2 right-2 text-gray-500 hover:text-gray-800"
        >
          ×
        </button>
        <h2 className="text-xl font-bold mb-4">Limit Reached</h2>
        <p>You can't create more than 10 portfolios.</p>
        <button
          onClick={onClose}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded"
        >
          Close
        </button>
      </div>
    </div>
  );

  const handleCreatePortfolio = () => {
    if (portfolioList.length > 10) {
      setIsModalOpen(true);
    } else {
      create_portfolio();
      // awaget_portfolio();
    }

    if (emptySpacesRegex.test(portfolioName)) {
      // handleSelect(newItem);
      // setHoldingModalItem(newItem);
      handleCloseModal();
    } else {
      alert("Empty fields are not allowed");
      // setErrormessage('Empty fields are not allowed')
      // setTimeout(() => {
      //   setErrormessage('')
      // }, 3000);
    }
  };

  const handleSymbolAdd = (sym) => {
    setStockTicker(sym);
    setStep(3);
    setOpenAddPortfolio(true);
    setModalHeading("Add Holding");
  };

  const handleClose = () => {
    setToggleSearch(false);
  };

  const handleHoldingBack = () => {
    setOpenAddPortfolio(false);
    setToggleSearch(true);
  };

  return (
    <>
      <div>
        {isModalOpen && <Modal onClose={() => setIsModalOpen(false)} />}
        {toggleSearch && (
          <BaseModal>
            <SymbolSearch
              onClose={handleClose}
              page="portfolio"
              symbolClick={handleSymbolAdd}
            />
          </BaseModal>
        )}
        {openAddPortfolio && (
          <BaseModal>
            <div className="w-[100%] h-[80vh] flex items-center justify-center">
              <ModalContainer title={modalHeading} onClose={handleCloseModal}>
                {step == 1 && (
                  <div className="px-5 pt-5">
                    <div className="w-full h-full flex items-center gap-6">
                      {portfolioType?.map((item, i) => (
                        <div
                          onClick={() => setSelectedPortfolioType(item?.name)}
                          className="w-[16rem] portfolioTypeCard cursor-pointer h-[8rem]"
                        >
                          <MainCard
                            key={i}
                            customClass={`dark:hover:opacity-80 hover:bg-[#E2E8F080] shadow-lg !p-3 ${
                              item?.name == selectedPortfolioType
                                ? "!border-[#4378AC]"
                                : "border-none"
                            }`}
                          >
                            <div className="w-fit h-fit flex items-center justify-center relative">
                              <svg
                                className={`w-5 h-5 ${
                                  item?.name == selectedPortfolioType
                                    ? "text-[#4378AC]"
                                    : "text-[#94A3B8]"
                                } `}
                                viewBox="0 0 24 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <rect
                                  x="3"
                                  y="3"
                                  width="18"
                                  height="18"
                                  rx="9"
                                  stroke="currentColor"
                                  stroke-width="2"
                                />
                              </svg>
                              {item?.name == selectedPortfolioType && (
                                <span className="w-2 h-2 rounded-full bg-[#4378AC] absolute" />
                              )}
                            </div>
                            <div className="flex items-center justify-center h-[60%] gap-3">
                              <span>
                                <img
                                  src={require(`../assets/images/${item.image}`)}
                                />
                              </span>
                              <span className="dark:text-[#F8FAFC]  text-[15px] font-normal">
                                {item?.name}
                              </span>
                            </div>
                          </MainCard>
                        </div>
                      ))}
                    </div>
                    <div className="w-full flex items-center justify-end gap-4 my-[2rem]">
                      <ModalBtn title="Cancel" onPress={handleCloseModal} />
                      <ModalBtn
                        title="Continue"
                        onPress={handleContinue}
                        customClass="!bg-[#FFB34B] !text-[#020617] !font-bold"
                      />
                    </div>
                  </div>
                )}

                {step == 2 &&
                  selectedPortfolioType == "Automatic Portfolio" && (
                    <div className="px-5 pt-5 w-[35rem] linkBrokerageContainer">
                      <div className="flex flex-wrap w-full gap-4">
                        {brokerageAccount?.map((item, i) => (
                          <div
                            onClick={() => setSelectedBrokerage(item?.name)}
                            className="w-[31%] h-[10rem] brokerageItem cursor-pointer"
                          >
                            <MainCard
                              customClass={`w-full h-full  flex items-center justify-center dark:hover:opacity-80 hover:bg-[#E2E8F080] shadow-lg !p-3 ${
                                item?.name == selectedBrokerage
                                  ? "!border-[#4378AC]"
                                  : "border-none"
                              }`}
                            >
                              <img
                                src={require(`../assets/images/${item.image}`)}
                              />
                            </MainCard>
                          </div>
                        ))}
                      </div>
                      <div className="w-full flex items-center justify-end  my-[1.5rem]">
                        <ModalBtn title="Go Back" onPress={() => setStep(1)} />
                      </div>
                    </div>
                  )}

                {step == 2 && selectedPortfolioType == "Manual Portfolio" && (
                  <div className="pt-5 w-[35rem]  h-[14rem] manualPortfolioContainer">
                    <div className="w-full border-b  dark:border-[#131a26]  px-5 py-4 flex items-center justify-between">
                      <div
                        className={`${
                          screenWidth < 610 ? "w-9/12 " : "w-[85%]"
                        } text-[14px] dark:text-[#94A3B8] font-normal`}
                      >
                        Portfolio Name
                      </div>
                      <div
                        className={`${
                          screenWidth < 610
                            ? "w-3/12 "
                            : "w-[15%] flex justify-end"
                        }}`}
                      >
                        <input
                          type="text"
                          value={portfolioName}
                          onChange={(e) => setPortfolioName(e.target.value)}
                          placeholder="Type here..."
                          className={`w-full placeholder:text-[#475569] text-[14px] dark:textF1F5F9] border-none outline-none text-left bg-transparent`}
                        />
                      </div>
                    </div>

                    <div className="w-full border-b  dark:border-[#131a26] px-5 py-4 flex items-center justify-between">
                      <div className="w-[70%] text-[14px] dark:text-[#94A3B8] font-normal">
                        Select a currency
                      </div>
                      <div className="w-[30%] relative flex items-center justify-end">
                        <span className="">
                          <img src={currency.flag} />
                        </span>
                        <span className="dark:text-[#F1F5F9] text-[14px] font-normal pl-2 pr-1">
                          {currency?.name}
                        </span>
                        <span
                          onClick={() => setOpenCurrency(!openCurrency)}
                          className="cursor-pointer hover:bg-[#d7dde5] dark:hover:bg-[#1b2233] p-2 rounded-full"
                        >
                          <svg
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                            className="dark:text-[#F1F5F9]"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L7 7L13 1"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        {openCurrency && (
                          <div className="absolute z-50 py-2 bg-[#d7dde5] w-[10rem] rounded-[4px] max-h-[15rem] dark:bg-[#1b2233] bg-[#e1e5ee] overflow-y-auto no-scrollbar top-[2rem] right-1">
                            {currencyList?.map((item, i) => (
                              <div
                                onClick={() => {
                                  setCurrency(item);
                                  setOpenCurrency(false);
                                }}
                                key={i}
                                className="py-2 px-3 text-[15px] z-50 hover:bg-[#c5ccd6] dark:hover:bg-[#161c2b] cursor-pointer"
                              >
                                {item?.name}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="w-full px-3 flex items-center justify-end  my-[1.5rem] gap-2">
                      <ModalBtn title="Go Back" onPress={() => setStep(1)} />
                      <ModalBtn
                        title="Create Portfolio"
                        onPress={handleCreatePortfolio}
                        customClass={`!bg-[#FFB34B] !text-[#020617] !font-bold`}
                      />
                    </div>
                  </div>
                )}

                {step == 3 && (
                  <div className="pt-5 w-[35rem] h-[21rem] manualPortfolioContainer">
                    <div className="w-full border-b  dark:border-[#131a26]  px-5 py-4 flex items-center justify-between">
                      <div
                        className={`${
                          screenWidth < 610 ? "w-9/12 " : "w-[85%]"
                        } text-[14px] dark:text-[#94A3B8] font-normal`}
                      >
                        Stock ticker
                      </div>
                      <div
                        className={`${
                          screenWidth < 610 ? "w-3/12 " : "w-[15%]"
                        } flex items-center justify-end space-x-2`}
                      >
                        <span>
                          <img
                            src={require(`../assets/images/${
                              companyIcons[stockTicker]
                                ? companyIcons[stockTicker]
                                : "tesla.png"
                            }`)}
                            width={20}
                            height={20}
                          />
                        </span>
                        <span className="dark:text-[#F1F5F9] text-[14px] font-medium">
                          {stockTicker}
                        </span>
                      </div>
                    </div>

                    <div className="w-full border-b  dark:border-[#131a26]  px-5 py-4 flex items-center justify-between">
                      <div
                        className={`${
                          screenWidth < 610 ? "w-9/12 " : "w-[85%]"
                        } text-[14px] dark:text-[#94A3B8] font-normal`}
                      >
                        Number of shares
                      </div>
                      <div
                        className={`${
                          screenWidth < 610 ? "w-3/12 " : "w-[15%]"
                        } flex justify-end`}
                      >
                        <input
                          type="number"
                          value={numberOfShares}
                          onChange={(e) => setNumberOfShares(e.target.value)}
                          placeholder="Type here..."
                          className={`w-full text-right pl-1 placeholder:text-[#475569] text-[14px] dark:textF1F5F9] border-none outline-none text-left bg-transparent`}
                        />
                      </div>
                    </div>

                    <div className="w-full border-b  dark:border-[#131a26] px-5 py-4 flex items-center justify-between">
                      <div
                        className={`${
                          screenWidth < 610 ? "w-6/12 " : "w-[70%]"
                        } text-[14px] dark:text-[#94A3B8] font-normal`}
                      >
                        Cost per share
                      </div>
                      <div
                        className={`${
                          screenWidth < 610 ? "w-6/12 " : "w-[30%]"
                        } relative flex items-center justify-end`}
                      >
                        <span className="">
                          <img src={usflag} />
                        </span>
                        <span className="dark:text-[#F1F5F9] text-[14px] font-medium pl-2">
                          USD
                        </span>
                        <span className="w-[50%]">
                          <input
                            type="number"
                            value={costPerShare}
                            onChange={(e) => setCostPerShare(e.target.value)}
                            placeholder="Type here..."
                            className={` w-[100%] text-right placeholder:text-[#475569] pl-2 text-[14px] dark:textF1F5F9] border-none outline-none bg-transparent`}
                          />
                        </span>
                      </div>
                    </div>

                    <div className="w-full border-b  dark:border-[#131a26] px-5 py-4 flex items-center justify-between">
                      <div className="w-[70%] text-[14px] dark:text-[#94A3B8] font-normal">
                        Assign a portfolio
                      </div>
                      <div className="w-[30%] relative flex items-center justify-end">
                        <span className="text-[#F88F00] text-[14px] whitespace-nowrap font-medium pl-2 pr-1">
                          {holdingModalItem?.portfolioName}
                        </span>
                        <span
                          onClick={() =>
                            setOpenAssingPortfolio(!openAssingPortfolio)
                          }
                          className="cursor-pointer hover:bg-[#d7dde5] dark:hover:bg-[#1b2233] p-2 rounded-full"
                        >
                          <svg
                            width="14"
                            height="8"
                            viewBox="0 0 14 8"
                            className="text-[#F88F00]"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1 1L7 7L13 1"
                              stroke="currentColor"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </span>
                        {openAssingPortfolio && (
                          <div className="absolute z-50 py-2 bg-[#d7dde5] w-[10rem] rounded-[4px] h-[10rem] dark:bg-[#1b2233] bg-[#e1e5ee] overflow-y-auto no-scrollbar top-[2rem] right-1">
                            {portfolioList?.map((item, i) => (
                              <div
                                onClick={() => {
                                 
                                  setHoldingModalItem(item);
                                  setOpenAssingPortfolio(false);
                                }}
                                key={i}
                                className={`py-2 px-3 text-[15px] hover:bg-[#c5ccd6] dark:hover:bg-[#161c2b] cursor-pointer ${
                                  i == 0 ? "pointer-events-none opacity-50" : ""
                                }`}
                              >
                                {item?.portfolioName}
                              </div>
                            ))}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="w-full px-3 flex items-center justify-end  my-[1.5rem] gap-2">
                      <ModalBtn title="Go Back" onPress={handleHoldingBack} />
                      <ModalBtn
                        title="Continue"
                        onPress={handleAddHolding}
                        customClass={`!bg-[#FFB34B] !text-[#020617] !font-bold`}
                      />
                    </div>
                  </div>
                )}
              </ModalContainer>
            </div>
          </BaseModal>
        )}
      </div>
      <div className="w-full h-fit py-4 flex flex-wrap gap-2 items-center">
        {/* {portfolioList?.map((item, i) => (
          <div
            key={i}
            onClick={() => {
              setSelectedPortfolio(item);
              handleSelect(item);
              setHoldingModalItem(item);
            }}
            className={`rounded-[8px] px-[0.8rem]  cursor-pointer   pt-[0.25rem] pb-[0.3rem] text-[14px]   ${
              selectedPortfolio?.portfolioName == item?.portfolioName
                ? "dark:bg-[#431407] font-[500] text-[#020617] bg-[#f88f00] dark:text-[#F88F00]"
                : "dark:text-[#475569] dark:hover:bg-[#182238] hover:bg-[#d0d8e2] dark:hover:text-[#E2E8F0] bg-[#E2E8F0] font-[400] dark:bg-transparent"
            }`}
          >
            {item?.portfolioName + "BB"}
          </div>
        ))} */}
        {is_dashboard ? (
          <div
            key={0}
            onClick={() => {
              setSelectedPortfolio(portfolioList[0]);
              handleSelect(portfolioList[0]);
              setHoldingModalItem(portfolioList[0]);
            }}
            // className={`rounded-[8px] px-[0.8rem] cursor-pointer pt-[0.25rem] pb-[0.3rem] text-[14px] ${
            //   selectedPortfolio?.portfolioName ===
            //   portfolioList[0]?.portfolioName
            //     ? "dark:bg-[#431407] font-[500] text-[#020617] bg-[#f88f00] dark:text-[#F88F00]"
            //     : "dark:text-[#475569] dark:hover:bg-[#182238] hover:bg-[#d0d8e2] dark:hover:text-[#E2E8F0] bg-[#E2E8F0] font-[400] dark:bg-transparent"
            // }`}
          >
            {/* {portfolioList[0]?.portfolioName} */}
          </div>
        ) : (
          portfolioList?.map((item, i) => (
            <div
              key={i}
              onClick={() => {
                setSelectedPortfolio(item);
                handleSelect(item);
                setHoldingModalItem(item);
              }}
              className={`rounded-[8px] px-[0.8rem] cursor-pointer pt-[0.25rem] pb-[0.3rem] text-[14px] ${
                selectedPortfolio?.portfolioName === item?.portfolioName
                  ? "dark:bg-[#431407] font-[500] text-[#020617] bg-[#f88f00] dark:text-[#F88F00]"
                  : "dark:text-[#475569] dark:hover:bg-[#182238] hover:bg-[#d0d8e2] dark:hover:text-[#E2E8F0] bg-[#E2E8F0] font-[400] dark:bg-transparent"
              }`}
            >
              {item?.portfolioName}
            </div>
          ))
        )}

        {!is_dashboard ? (
          <div
            onClick={handleAddPortoflio}
            className="rounded-[8px] flex items-center space-x-2 px-[0.8rem] pt-[0.25rem] pb-[0.3rem] font-[400] cursor-pointer text-[#020617] dark:bg-transparent dark:hover:bg-[#182238] bg-[#E2E8F0] hover:bg-[#d0d8e2]  dark:text-[#E2E8F0] text-[14px]"
          >
            <span>
              <svg
                className="text-[#475569] dark:text-white w-3 h-3"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M8 1V15"
                  stroke="currentColor"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M1 8H15"
                  stroke="currentColor"
                  stroke-width="1.6"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </span>
            <span>Add New Portfolio</span>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}

export default AddPortfolio;
