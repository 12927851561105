import { style } from 'd3';
import React, { useState,useEffect } from 'react'
import Chart from 'react-apexcharts';
import { useSelector } from "react-redux";

function PieChart({series= [44, 55, 13, 33]}) {
  const theme = useSelector((state) => state.theme.darkMode);
  const [options,setOptions] = useState({
    chart: {
      width: 380,
      type: 'donut',
    },
    dataLabels: {
      enabled: false,
    },
    legend: {
      position: 'right',
      offsetY: 0,
      height: 230,
      show: false 
    },
    stroke: {
      show: true,
      width: 1, // Remove the spacing between segments
    },
  })
  const [showChart,setShowChart] = useState(true)

  useEffect(() => {
    setOptions({
     ...options,
     stroke:{
      ...options.stroke,
      colors:'transparent'
     }
    })
 }, [])

      
  return (
    <div id="chart" className="w-full h-full">
      <style jsx>{`
        .apexcharts-tooltip span {
          color: ${theme ? "#fff" : "#000"};
      }
      `}</style>
       {showChart &&  <Chart options={options} series={series} type="donut"  width="100%" height="100%" />}
    </div>
  )
}


export default PieChart