import React,{useEffect, useState} from "react"
import Chart from 'react-apexcharts';
import { useSelector } from "react-redux";

function SingleAreaChart() {
    const theme = useSelector((state) => state.theme.darkMode);
    const [showChart,setShowChart] = useState(true)
    const [series, setSeries] = useState([
        {
          name: 'Data 1',
          data: [400, 250, 200, 370, 460, 380, 300],
          color: '#F88F00'
          
        },
      ]);

      const [options, setOptions] = useState({
        chart: {
          type: 'area',
          toolbar: { show: false },
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          curve: 'smooth'
        },
        xaxis: {
          type: 'month',
          categories: [
            "JAN",
            "FEB",
            "MAR",
            "APR",
            "MAY",
            "JUN",
            "JUL"
          ],
          labels: {
            style: {
              colors: '#94A3B8'
            }
          },
          axisBorder: {
            show: true,
            color: '#94A3B8',
            height: 1,
          }
          
        },
        yaxis: {
            min: 0,
            max: 500,
            tickAmount: 5,
            labels: {
              formatter: function (value) {
                if (value >= 1000) {
                  return value;
                }
                return value;
              },
              style: {
                colors: '#94A3B8'
              }
            },
            opposite: true ,

           
          },
        fill: {
            type: 'gradient',
            gradient: {
              type: 'vertical', // can be 'vertical' or 'horizontal'
              gradientToColors: ['#F88F00'], // Light yellow color at the end
              opacityFrom: 0.3,
              inverseColors: true,
              opacityTo: 0,
              stops: [0, 100]
            }
          },
          grid: {
            borderColor: theme ? '#1E293B' : '#E2E8F0'
          },
        tooltip: {
            style: {
                fontSize: '12px',
              },
          x: {
            format: 'dd MMM',
          },
       
        
        },
        legend: {
            show: false // Hide the legend
          }
      });

      useEffect(() => {
        setOptions({
          ...options,
          grid: {
            borderColor: theme ? '#1E293B' : '#E2E8F0'
          },
          xaxis: {
            ...options.xaxis,
            axisBorder: {
                color: theme ? '#1E293B' : '#E2E8F0'
              }
          }
        })
     }, [theme])
 


  return (
    <div id="chart" className="w-full h-full">
     {showChart && <Chart options={options} series={series} type="area"  width="100%" height="100%" />}
  </div>
  )
}

export default SingleAreaChart