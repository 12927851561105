import React,{useEffect, useState} from "react"
import Chart from 'react-apexcharts';
import { useSelector } from "react-redux";

function MultiLineChart() {
    const theme = useSelector((state) => state.theme.darkMode);
    const [showChart,setShowChart] = useState(true)
    const [series, setSeries] = useState([
        {
            name: 'Line 1',
            data: [5, 41, 55, 51, 49, 62, 69, 91, 100],
            color:"#E82127"
          },
          {
            name: 'Line 2',
            data: [23, 42, 84, 52, 28, 65, 70, 90, 80],
            color:"#0E61FE"
          },
          {
            name: 'Line 3',
            data: [30, 50, 20, 60, 10, 70, 80, 100, 50],
            color:"#475569"
          }
      ]);

      const [options, setOptions] = useState({
        chart: {
            type: 'line',
            toolbar: { show: false },

          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            width: [3, 3, 3],
            curve: 'straight',
            dashArray: [0, 0, 0]
          },
          xaxis: {
            type: 'category',
            categories: [
              "01 Apr",
              "02 Apr",
              "03 Apr",
              "04 Apr",
              "05 Apr",
              "06 Apr",
              "07 Apr",
              "08 Apr",
              "09 Apr"
            ],
            labels: {
              style: {
                colors: '#94A3B8'
              }
            },
            axisBorder: {
              show: true,
              color: '#94A3B8',
              height: 1, // Adjust the height to make the border thinner
            }
          },
          yaxis: {
            min: 0,
            max: 100,
            tickAmount: 5,
            labels: {
              formatter: function (value) {
                return value + '%';
              },
              style: {
                colors: '#94A3B8'
              }
            },
            opposite: true ,
            axisBorder: {
                show: true,
                color: '#94A3B8'
              }
          },
          grid: {
            show: false // Hide grid lines
          },
          tooltip: {
            enabled:false
          },
          legend: {
            show: false // Show the legend
          }
      });

      useEffect(() => {
        setOptions({
          ...options,
          grid: {
            borderColor: theme ? '#1E293B' : '#E2E8F0'
          },
          xaxis: {
            ...options.xaxis,
            axisBorder: {
                color: theme ? '#1E293B' : '#E2E8F0'
              }
          },
          yaxis: {
            ...options.yaxis,
            axisBorder: {
                show: true,
                color: theme ? '#1E293B' : '#E2E8F0'
              }
          }
        })
     }, [theme])
 


  return (
    <div id="chart" className="w-full h-full">
     {showChart && <Chart options={options} series={series} type="line"  width="100%" height="100%" />}
  </div>
  )
}

export default MultiLineChart