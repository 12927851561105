import React,{useState,useEffect} from 'react'
import ModalContainer from './ModalContainer'
import ModalBtn from '../Button/ModalBtn'
import {toggleSettingModal,toggleVerificationModal,toggleTwoFaModal} from '../../store/slices/themeSlice'
import { useDispatch } from 'react-redux'

function VerificationModal() {
    const [code,setCode] = useState("")
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);
    const dispatch = useDispatch()
    const handleModalClose = () => {
        localStorage.setItem("currentSetting","Security")
        dispatch(toggleVerificationModal())
        dispatch(toggleSettingModal())
    }
    const handleVerify = ()=>{
        dispatch(toggleVerificationModal())
        dispatch(toggleTwoFaModal())
    }

  return (
    <div className={`${screenWidth<440?'w-full':'w-[25rem]'} h-[24rem]`}>
        <ModalContainer customClass='w-full h-full relative' onClose={handleModalClose} title='Verification'>
       <div className='p-4 space-y-2'>
        <div className='dark:text-[#F1F5F9] text-[18px] font-semibold'>Confirm your identity</div>           
        <div className='label'>
            We just sent a 6-digit code to <br /> mbdpramodya@gmail.com, enter it below
        </div>
       </div>

       <div className='w-full flex items-center px-4 dark:border-[#1E293B] pb-4 pt-[1.5rem] border-[#cecece70] border-b justify-between'>
            <span className='label'>
              Code<span className='text-[#F88F00]'>*</span>
            </span>
            <div className='flex items-center space-x-2'>
                <input type="text" maxLength={6} placeholder='Enter code' value={code} onChange={(e)=>setCode(e.target.value)} className='border-none text-[14px] dark:placeholder:text-[#475569] text-right dark:text-[#F1F5F9] focus:outline-none bg-transparent' />
            </div>
            
        </div>

        <div className='text-center py-6'>
            <span className='label hover:underline cursor-pointer font-semibold'>Resend Code</span>
        </div>
        <div className='absolute bottom-6 flex items-center justify-end space-x-3 right-4'>
           <div>
              <ModalBtn onPress={handleModalClose} title="Go Back" customClass={`pointer-events-auto cursor-pointer !font-medium dark:bg-[#080e20] bg-[#d8d8d8] text-[#383838] dark:text-[#F1F5F9]`} />
            </div>               
           <div>
              <ModalBtn title="Verify Email" onPress={handleVerify} customClass={`${code?.length==6 ? 'pointer-events-auto cursor-pointer':'pointer-events-none opacity-60'} !text-white'} !font-semibold !bg-[#FFac39] text-black`} />
            </div>               
            </div>
        </ModalContainer>
    </div>
  )
}

export default VerificationModal