import React, { useState,useEffect } from "react";
import lightLogo from "../assets/lightLogo.svg";
import darkLogo from "../assets/darkLogo.svg";
import userIcon from "../assets/user.svg";
import { useSelector, useDispatch } from "react-redux";
import SidebarItems from "./SidebarItems";
import { sidebarContent } from "../utils/sidebarContent";
import { miniAreaContent } from "../utils/sidebarContent";
import { useLocation, useNavigate } from "react-router-dom";
import { SET_SIDE_BAR, setSelectedRoute, toggleSettingModal } from "../store/slices/themeSlice";
import { deleteSession } from "../ApiControler";
import BaseModal from '../components/BaseModal'
import ModalContainer from '../components/Modal/ModalContainer'
import ModalBtn from '../components/Button/ModalBtn'

function Sidebar({setOpenNotification}) {
  const darkMode = useSelector((state) => state.theme.darkMode);
  const currentRoute = useSelector((state) => state.theme.selectedRoute);
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleRoute = (item) => {
    if (item.name != "Log out") {
      if(item?.name == 'Settings'){
        
        dispatch(SET_SIDE_BAR(false));
        dispatch(toggleSettingModal());
      }else if (item?.route && location.pathname !== item?.route) {
        dispatch(SET_SIDE_BAR(false));
        dispatch(setSelectedRoute(item));
        localStorage.setItem("selectedRoute", JSON.stringify(item));
        navigate(item?.route);
      }
    } else {
      signOut()
    }
  };




  const signOut = async() =>{
    const token = localStorage.getItem('token')
    const deviceID = localStorage.getItem('sessionID')

    try{
      const response = await deleteSession(token, deviceID)
      if(response?.success){
        
        navigate("/")
        localStorage.clear()
        window.location.reload();
      }
    }catch(e){
      // throw e
      localStorage.clear()
      navigate("/")
      window.location.reload();
    }
    
  }



  return (
    <div className="w-full h-full py-5 px-2 overflow-hidden">
      <div className="flex items-start w-full px-4 h-[10%] justify-between">
        <div>
          <img src={darkMode ? darkLogo : lightLogo} className="w-[5rem]" />
          
        </div>
        <div onClick={()=>setOpenNotification(true)} className="w-10 h-10 cursor-pointer dark:hover:bg-[#182238] hover:bg-[#ced8e5] rounded-full dark:bg-[#1F2A37] flex items-center justify-center bg-[#E2E8F0]">
          <svg
            className="w-5 h-5 text-[#94A3B8]"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15 6.66675C15 5.34067 14.4732 4.0689 13.5355 3.13121C12.5979 2.19353 11.3261 1.66675 10 1.66675C8.67392 1.66675 7.40215 2.19353 6.46447 3.13121C5.52678 4.0689 5 5.34067 5 6.66675C5 12.5001 2.5 14.1667 2.5 14.1667H17.5C17.5 14.1667 15 12.5001 15 6.66675Z"
              stroke="currentColor"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M11.4417 17.5C11.2952 17.7526 11.0849 17.9622 10.8319 18.1079C10.5788 18.2537 10.292 18.3304 10 18.3304C9.70802 18.3304 9.42116 18.2537 9.16815 18.1079C8.91513 17.9622 8.70484 17.7526 8.55833 17.5"
              stroke="currentColor"
              strokeWidth="1.4"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>
      </div>
      <div className="w-full h-[80%] px-3 py-4 overflow-y-auto">
        <ul className="space-y-2">
          {sidebarContent?.map((item, i) => (
            <SidebarItems item={item} key={i} />
          ))}
        </ul>
        <div className="h-fit mt-[2rem] rounded-[8px] dark:bg-[#020617] bg-[#e1e6ea] py-2 space-y-2">
          {miniAreaContent?.map((item, i) => (
            <div
              key={i}
              onClick={() => handleRoute(item)}
              className="flex justify-center flex-col"
            >
              {i == 2 && (
                <div className="h-[1px] w-full bg-[#c4c4c4] dark:bg-[#1F2A37] mb-3" />
              )}
              <div
                className={`flex items-center font-[400] space-x-3 cursor-pointer hover:bg-[#c3c6c9] dark:hover:bg-[#0F172A] mx-2 py-2 px-2 rounded-[8px] ${
                  currentRoute?.id == item?.id
                    ? "bg-[#c3c6c9] dark:bg-[#0F172A]"
                    : ""
                }`}
              >
                <span>
                  <img
                    src={require(`../assets/${item?.icon}`)}
                    className="w-5 h-5"
                  />
                </span>
                <span
                  className={`dark:text-[#F1F5F9] text-[14px] ${
                    miniAreaContent?.length - 1 === i
                      ? "dark:text-[#EF4444]"
                      : ""
                  }`}
                >
                  {item.name}
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="w-full h-[4.5rem]  flex items-center justify-center">
        <div className="w-full h-[90%] px-4 flex items-center justify-between rounded-[8px] shadow bg-[#F1F5F9] dark:bg-[#141d31]">
          <div>
            <img src={userIcon} className="w-[3rem] h-[3rem]" />
          </div>
          <div className="space-y-1">
            <div className="dark:text-[#FFFFFF] text-center text-[14px] font-normal">
              John Smith
            </div>
            <div className="investorGradient shadow rounded-[8px] text-[10px] font-semibold px-2 pt-[2px] pb-[3px] text-white">
              Smart Invester
            </div>
          </div>
          <div className="items-center flex">
          <div className="w-10 h-10 cursor-pointer  flex items-center justify-center dark:hover:bg-[#172033] hover:bg-[#d5dae0] rounded-full bg-[#E2E8F0] dark:bg-[#10192c]">
            <svg
              className="w-5 h-5 dark:text-[#F8FAFC] text-[#020617]"
              viewBox="0 0 4 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.22658 8.83335C2.68682 8.83335 3.05992 8.46026 3.05992 8.00002C3.05992 7.53978 2.68682 7.16669 2.22658 7.16669C1.76635 7.16669 1.39325 7.53978 1.39325 8.00002C1.39325 8.46026 1.76635 8.83335 2.22658 8.83335Z"
                stroke="currentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.22658 2.99998C2.68682 2.99998 3.05992 2.62688 3.05992 2.16665C3.05992 1.70641 2.68682 1.33331 2.22658 1.33331C1.76635 1.33331 1.39325 1.70641 1.39325 2.16665C1.39325 2.62688 1.76635 2.99998 2.22658 2.99998Z"
                stroke="currentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M2.22658 14.6667C2.68682 14.6667 3.05992 14.2936 3.05992 13.8333C3.05992 13.3731 2.68682 13 2.22658 13C1.76635 13 1.39325 13.3731 1.39325 13.8333C1.39325 14.2936 1.76635 14.6667 2.22658 14.6667Z"
                stroke="currentColor"
                strokeWidth="1.66667"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
