import React, { useState, useEffect } from "react";
import Chart from "react-apexcharts";
import "tailwindcss/tailwind.css";
import { useSelector } from "react-redux";

const DonutChart = ({ centerText, setActive, setGoal, _isOn, item }) => {
  const darkMode = useSelector((state) => state.theme.darkMode);
  const [isOn, setIsOn] = useState(_isOn);
  const [series, setSeries] = useState([])
  const serieses = [centerText*.30,centerText*2];

useEffect(()=>{
  if(!isOn){
    setSeries(serieses)
  }
},[])
  const toggleSwitch = () => {
    // setIsOn(!isOn);
    
    setActive(isOn);
    if(isOn){
      setSeries([100])
      setIsOn(!isOn);
      // setGoal(100000)
    } else {
      setSeries(serieses)
      setIsOn(!isOn);
      // setGoal(0)
      
    }
  };

  const options = {
    chart: {
      type: "donut",
      stroke: {
        colors: ['transparent'],
        width: 0
      },
    },

    states: {
      hover: {
          filter: {
              type: !isOn ? "none" : "lighten",
          }
      },
  },
    
    plotOptions: {
      pie: {
        donut: {
          size: "65%",
        },
      },
    },
    legend: {
      show: false, // Disable legend
    },
    stroke: {
      show: true,
      width: 1, // Remove the spacing between segments
      colors: 'transparent',

    },
    dataLabels: {
      enabled: false, // Disable data labels
    },
    tooltip: {
      enabled: false, // Disable tooltip hover effect
    },
    fill: {
      colors: isOn ? [ item.name === "Monthly" ? "#4ADE80": item.name === "Diversification" ? "#818CF8" : "#14B8A6", darkMode ? "#1E293B" :"#c3cad6"] : darkMode ? ["#1E293B"] : ["#c3cad6"],
    },
   
    // series: [44, 55, 41, 17, 15],
    labels: ["A", "B", "C", "D", "E"],
  };


  return (
    <div className={`relative ${!isOn ? "opacity-50":""}`}>
      <Chart
       showText={false} 
       options={options} 
       series={series} 
       type="donut" 
       height="350" 
       />
      <div className={`flex justify-center items-center text-center absolute w-12 h-20 flex-col left-32 top-28`}>
        <p className="text-[28px] font-bold h-9">{item.name === "Diversification" ? parseFloat(centerText) + "%" : "$"+parseFloat(centerText)?.toLocaleString("en-US")}</p>
        <p className="flex text-[14px] text-[#94A3B8]">{item.name === "Diversification" ? "Diversification Goal" : item.name === "Yearly" ? "Yearly Goal (USD)" : "Monthly Goal (USD)"}</p>
        <label className="flex items-center cursor-pointer ">
          <span className="relative">
            <input
              type="checkbox"
              checked={isOn}
              onChange={toggleSwitch}
              className="sr-only"
            />
            <div className={`block w-10 h-6 rounded-full ${isOn ? "bg-[#F88F00]" : "bg-slate-300 dark:bg-[#1E293B]"}`}></div>
            <div
              className={`dot absolute left-1 top-1  w-4 h-4 rounded-full  ${
                isOn ? "transform translate-x-full bg-white" : "transform translate-x-0 dark:bg-[#475569] bg-slate-500"
              }`}
            />
          </span>
        </label>
      </div>
    </div>
  );
};

export default DonutChart;
