import React, { useState, useEffect } from "react";

function FutureTable({ columns, data, condition }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [screenWidth, setScreenWidth] = useState(window.innerHeight);

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight);
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);
  const startIndex = () => {
    return (currentPage - 1) * itemsPerPage + 1;
  };
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const endIndex = () => {
    return currentPage * itemsPerPage;
  };

  const firstPage = () => {
    setCurrentPage(1);
    onPageChange(1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  };

  const lastPage = () => {
    setCurrentPage(totalPages);
    onPageChange(totalPages);
  };

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage + 1;
    const endIndex = startIndex * itemsPerPage;
    return data?.slice(startIndex, endIndex);
  };

  return (
    <div
      className={`border w-full ${
        screenWidth < 640 ? "h-[65vh]" : "h-[60vh]"
      }  dark:bg-[#020617] overflow-hidden  dark:border-[#1E293B] rounded-[8px]`}
    >
      <div className="h-[47vh] overflow-x-auto overflow-y-auto">
        <table className="w-full text-[15px] bg-white">
          <thead className="z-50 sticky top-0 bg-[#F1F5F9] dark:bg-[#0F172A]">
            <tr>
              <th className="py-3 whitespace-nowrap border-none px-1 px-[1rem] text-[12px] font-[500] w-6 text-center">
                #
              </th>
              {columns?.map((item, index) => (
                <th
                  key={index}
                  className="px-6 w-20 text-left py-3 whitespace-nowrap border-b text-black dark:text-[#FFFFFF] text-[14px] border-none font-medium"
                >
                  {item?.value}
                </th>
              ))}
            </tr>
          </thead>

          <tbody className="w-full">
            {getPaginatedData()?.map((item, i) => (
              <tr
                key={i}
                className="text-black dark:hover:bg-[#151d26] dark:text-white hover:bg-[#f9f9f9] border-b-[0.1vh] dark:bg-[#020617]  dark:border-[#1E293B] "
              >
                <td className="py-3 whitespace-nowrap px-1 px-[1rem] text-black  dark:text-[#CBD5E1] dark:border-[#1E293B] border-b text-[14px] font-[600] w-6 text-center">
                  {i + 1}
                </td>
                {columns?.map((col, index) => (
                  <td
                    key={index}
                    className="px-6 w-20 text-left py-5 whitespace-nowrap -border-b text-black dark:text-[#CBD5E1] text-[14px] border-none font-semibold"
                  >
                    {col?.key === "company" ? (
                    <div className="flex items-center">
                        <img src={require(`../../assets/images/${item?.img}`)} alt={item.company} className="w-8 h-8 mr-2" /> {/* Image on the left */}
                        <span>{item.company}</span> {/* Text on the right */}
                    </div>
                    ) : 
                    col?.key === "accountValue" ? (
                      <span>${item.accountValue.toLocaleString('en-US')}</span>
                    ) : col?.key === "annualDiv" ? (
                      <span>${item.annualDiv.toLocaleString('en-US')}</span>
                    ) : col?.key === "divYield"? (
                      <span>{item.divYield}%</span>
                    ) : (
                        <span>{item[col?.key]}</span>
                    )
                    }
                    
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="w-full paginatedContainer border-t dark:border-[#292929a1] px-4 sm:flex-1 mb-2 sm:flex sm:items-center sm:justify-between">
       {!condition &&
        <div>
        <p className="text-[14px] font-[500] leading-5 font-[500] dark:text-[#64748B] text-[#6D6D6D]">
          Showing:
          <span> {startIndex()} </span>
          to
          <span> {endIndex()} </span>
          of
          <span> {data?.length} </span>
          results
        </p>
      </div>
       }
        <div className={`${condition ? 'text-end w-full' : ""}`} >
          {
            condition ? 
            condition
            :
           (
            data?.length > 0 && (
              <nav className="paginationArea">
                <ul className="flex items-center border dark:border-[#1F2A37] rounded-[8px] h-10 text-base bg-[#f1f5f9] dark:bg-[#0f172a]">
                  {/* <li>
          <button
            onClick={firstPage}
            disabled={currentPage === 1}
            className={`${
              currentPage === 1
                ? 'cursor-not-allowed text-gray-400 dark:text-[#475569]'
                : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
            } text-[14px] border-r dark:border-[#1F2A37] h-10 flex items-center justify-center px-4 ms-0 leading-tight`}
          >
            First
          </button>
        </li> */}
                  <li>
                    <button
                      onClick={prevPage}
                      disabled={currentPage === 1}
                      className={`${
                        currentPage === 1
                          ? "cursor-not-allowed  text-gray-400 dark:text-[#475569]"
                          : "cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]"
                      } text-[14px] h-10 flex items-center justify-center px-4 ms-0 leading-tight`}
                    >
                      Previous
                    </button>
                  </li>
                  {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                    (pageNumber) => (
                      <li key={pageNumber}>
                        <button
                          onClick={() => changePage(pageNumber)}
                          className={`${
                            currentPage === pageNumber
                              ? "bg-[#E2E8F0]  dark:bg-[#F8FAFC] text-[#020617]"
                              : "cursor-pointer hover:bg-[#e6ecf4] dark:text-[#94A3B8] dark:hover:bg-[#1F2A37]"
                          } text-[14px]  h-10 flex items-center border-l borde-r dark:border-[#1F2A37] justify-center px-4 ms-0 leading-tight `}
                        >
                          {pageNumber}
                        </button>
                      </li>
                    )
                  )}
                  <li>
                    <button
                      onClick={nextPage}
                      disabled={currentPage === totalPages}
                      className={`${
                        currentPage === totalPages
                          ? "cursor-not-allowed  text-gray-400 dark:text-[#475569]"
                          : "cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]"
                      } text-[14px]  border-l border-r dark:border-[#1F2A37] h-10 flex items-center justify-center px-4 ms-0 leading-tight `}
                    >
                      Next
                    </button>
                  </li>
                  {/* <li>
          <button
            onClick={lastPage}
            disabled={currentPage === totalPages}
            className={`${
              currentPage === totalPages
                ? 'cursor-not-allowed text-gray-400 dark:text-[#475569]'
                : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
            } text-[14px]  flex h-10 items-center justify-center px-4 ms-0 leading-tight `}
          >
            Last
          </button>
        </li> */}
                </ul>
              </nav>
            )
           )
          }
        </div>
      </div>
    </div>
  );
}

export default FutureTable;
