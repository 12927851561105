export const billingDetail = [
    {id:1,title:"Investor",price:"Free",priceDetail:'free forever',buttonText:'My Current Plan',
    planList:[
      {isAvailable:true,detail:'1 Manual Entry Portfolio'},
      {isAvailable:true,detail:'Unlimited Stocks in Portfolio'},
      {isAvailable:true,detail:'Unlimited Watchlist Items'},
      {isAvailable:true,detail:'Limited Portfolio Diversification Analysis'},
      {isAvailable:true,detail:'Payout Calendar (Restricted)'},
      {isAvailable:true,detail:'US, Canada, and UK Stock Data'},
      {isAvailable:false,detail:'Auto-Import Holdings from Brokerage'},
      {isAvailable:false,detail:'Import CSV from Brokerage'},
      {isAvailable:false,detail:'Dividend Grades'},
      {isAvailable:false,detail:'Locked Stock Metrics'},
      {isAvailable:false,detail:'Future Value Calculator'},
      {isAvailable:false,detail:'Income Goals Calculator'},
      {isAvailable:false,detail:'Dividend Stock Ideas'},
      {isAvailable:false,detail:'Stock Analysis'},
      {isAvailable:false,detail:'No Advertisements'},
      {isAvailable:false,detail:'Premium Support'},
    ]},
    {id:2,title:"Smart Investor",price:"$19.99",priceDetail:'$187.44/year',buttonText:'Try free for 30 days',planList:[
      {isAvailable:true,detail:'1 Manual Entry Portfolio'},
      {isAvailable:true,detail:'Unlimited Stocks in Portfolio'},
      {isAvailable:true,detail:'Unlimited Watchlist Items'},
      {isAvailable:true,detail:'Limited Portfolio Diversification Analysis'},
      {isAvailable:true,detail:'Payout Calendar (Restricted)'},
      {isAvailable:true,detail:'US, Canada, and UK Stock Data'},
      {isAvailable:true,detail:'Auto-Import Holdings from Brokerage'},
      {isAvailable:true,detail:'Import CSV from Brokerage'},
      {isAvailable:true,detail:'Dividend Grades'},
      {isAvailable:true,detail:'Locked Stock Metrics'},
      {isAvailable:true,detail:'Future Value Calculator'},
      {isAvailable:true,detail:'Income Goals Calculator'},
      {isAvailable:true,detail:'Dividend Stock Ideas'},
      {isAvailable:true,detail:'Stock Analysis'},
      {isAvailable:true,detail:'No Advertisements'},
      {isAvailable:true,detail:'Premium Support'},
    ]},
  ]