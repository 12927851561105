import React, { useState,useEffect } from 'react'
import infoIcon from '../../assets/info.svg'
import usflag from '../../assets/usflag.svg'
import auflag from '../../assets/auflag.svg'
import ModalBtn from '../Button/ModalBtn'

function Display() {
  const [currency,setCurrency] = useState({name:'USD',flag:usflag})
  const [openCurrency,setOpenCurrency] = useState(false)
  const currencyList = [{name:'USD',flag:usflag},{name:'AUD',flag:auflag}]
  const regionalLocaleList = ["United States","Australia"]
  const [locale,setLocale] = useState("United States")
  const [openLocale,setOpenLocale] = useState(false)
  const [showCode,setShowCode] = useState(false)
  const [isChange,setIsChange] = useState(false)
  const [isReset,setIsReset] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const toggleSwitch = () => {
    setShowCode(!showCode);
    setIsChange(true)
    setIsReset(true)
  };
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const handleReset = ()=>{
    setCurrency({name:'USD',flag:usflag})
    setLocale("United States")
    setShowCode(false)
    setIsChange(false)
  }

  return (
    <div className='w-full h-full py-4 relative'>
      <div className='w-full flex items-center px-4 dark:border-[#1E293B] py-4 border-[#cecece70] border-b justify-between'>
        <div className='flex items-center space-x-2'>
          <span className='dark:text-[#94A3B8] font-normal text-[14px]'>Default Currency</span>
          <span className='pt-1'>
            <img src={infoIcon} />
          </span>
        </div>
        <div className='relative flex items-center justify-end'>
                    <span className=''>
                      <img src={currency.flag} />
                    </span>
                    <span className='dark:text-[#F1F5F9] text-[14px] font-normal pl-2 pr-1'>
                      {currency?.name}
                    </span>
                    <span onClick={()=>setOpenCurrency(!openCurrency)} className='cursor-pointer hover:bg-[#d7dde5] dark:hover:bg-[#1b2233] p-2 rounded-full'>
                    <svg width="14" height="8" viewBox="0 0 14 8" className='dark:text-[#F1F5F9]' fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    {openCurrency &&
                    <div className='absolute z-30 py-2 bg-[#d7dde5] w-[10rem] rounded-[4px] max-h-[15rem] dark:bg-[#1b2233] bg-[#e1e5ee] overflow-y-auto no-scrollbar top-[2rem] right-1'>
                        {currencyList?.map((item,i)=>(
                            <div onClick={()=>{setCurrency(item);setOpenCurrency(false);setIsChange(true);setIsReset(true)}} key={i} className='py-2 px-3 text-[15px] hover:bg-[#c5ccd6] dark:hover:bg-[#161c2b] cursor-pointer'>
                                {item.name}
                            </div>
                        ))}
                    </div>}
        </div>
      </div>

      <div className='w-full flex items-center px-4 dark:border-[#1E293B] pb-4 pt-[1.5rem] border-[#cecece70] border-b justify-between'>
        <div className='flex items-center space-x-2'>
          <span className='dark:text-[#94A3B8] font-normal text-[14px]'>Regional Locale</span>
          <span className='pt-1'>
            <img src={infoIcon} />
          </span>
        </div>

        <div className='relative flex items-center justify-end'>
                   
                    <span className='dark:text-[#F1F5F9] text-[14px] font-normal pl-2 pr-1'>
                      {locale}
                    </span>
                    <span onClick={()=>setOpenLocale(!openLocale)} className='cursor-pointer hover:bg-[#d7dde5] dark:hover:bg-[#1b2233] p-2 rounded-full'>
                    <svg width="14" height="8" viewBox="0 0 14 8" className='dark:text-[#F1F5F9]' fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </span>
                    {openLocale &&
                    <div className='absolute z-30 py-2 bg-[#d7dde5] w-[10rem] rounded-[4px] max-h-[15rem] dark:bg-[#1b2233] bg-[#e1e5ee] overflow-y-auto no-scrollbar top-[2rem] right-1'>
                        {regionalLocaleList?.map((item,i)=>(
                            <div onClick={()=>{setLocale(item);setOpenLocale(false);setIsChange(true);setIsReset(true)}} key={i} className='py-2 px-3 text-[15px] hover:bg-[#c5ccd6] dark:hover:bg-[#161c2b] cursor-pointer'>
                                {item}
                            </div>
                        ))}
                    </div>}
        </div>
      
      </div>

       <div className='w-full flex items-center px-4 dark:border-[#1E293B] pb-4 pt-[1.5rem] border-[#cecece70] border-b justify-between'>
        <div className='flex items-center space-x-2'>
          <span className='dark:text-[#94A3B8] font-normal text-[14px]'>Show Country Code</span>
          <span className='pt-1'>
            <img src={infoIcon} />
          </span>
        </div>

        <div>
        <button
                      onClick={toggleSwitch}
                      className={`relative w-11 h-6 flex items-center ${showCode ? 'bg-[#F88F00]':'bg-[#d1d1d1] dark:bg-[#1E293B]'} rounded-full cursor-pointer`}
                    >
                      <div
                          className={`w-6 h-6 rounded-full shadow-md transform transition-transform ${
                            showCode ? 'translate-x-5 bg-white' : 'translate-x-0 bg-[#b2b2b2] dark:bg-[#475569]'
                          }`}
                        />
                  </button>
        </div>

       
      
      </div>

      <div className='absolute bottom-6 flex items-center justify-end space-x-3 right-4'>
           <div>
              <ModalBtn onPress={handleReset} title={screenWidth < 400 ? 'Reset':"Reset Changes"} customClass={` ${isReset ? 'pointer-events-auto cursor-pointer' : 'opacity-50 pointer-events-none cursor-default'} !font-medium dark:bg-[#080e20] bg-[#d8d8d8] text-[#383838] dark:text-[#797d88]`} />
            </div>               
           <div>
              <ModalBtn title={screenWidth < 400 ?'Save':"Save Changes"} customClass={`${isChange ? 'pointer-events-auto cursor-pointer':'opacity-50 pointer-events-none cursor-default'} !font-semibold !bg-[#FFac39] text-black`} />
            </div>               
      </div>


    </div>
  )
}

export default Display