import React from "react";

function TextInput({
  label,
  type,
  placeholder,
  className,
  image,
  _onChange,
  _value,
  mainClass,
  inputClass,
  _error
}) {
  return (
    <div className={`flex flex-col space-y-1 ${mainClass}`}>
      <label className="text-[12px] text-[#1E293B]">{label}</label>
      <div className="flex flex-col justify-center">
      <div
        className={`flex outline-none h-[44px] rounded-md justify-between text-[13px] border ${inputClass}`}
      >
        <input
          value={_value}
          onChange={_onChange}
          type={type}
          placeholder={placeholder}
          className={`${className} w-full outline-none border-0 m-3`}
        />
        <span className="mr-3 items-center justify-center flex">{image}</span>
      </div>
      <span className={`${_error && 'text-[12px] text-red-400'}`}>{_error}</span>
      </div>
    </div>
  );
}

export default TextInput;
