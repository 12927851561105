import React, { useEffect, useState } from "react";
import Summary from "./Summary";
import TabulatedView from "./TabulatedView";
import Positions from "./Positions";

export default function CalculatorChart2({ showChart, data, setOData }) {
  const [tabName, setTab] = useState("Summary");
  const [initData, setInitData] = useState(null)

  useEffect(() => {
    if(data) {
        setInitData(data)
   
    }
  },[])

  return (
    <div className="flex flex-col h-full w-full px-2">
      <div className="flex space-x-10 items-center">
        <span className="text-[20px]">Fund Overlap - Calculated Results</span>
        <div
          className="items-center flex space-x-2 hover:opacity-80"
          onClick={() => {
            showChart(false)
            setOData([])
          }}
        >
          <svg
            width="12"
            height="12"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 2.99219L7.5 1.99219L8.5 1.49219L7.5 0.992188L7 -0.0078125L6.5 0.992188L5.5 1.49219L6.5 1.99219L7 2.99219ZM2.5 4.99219L3.33313 3.32563L5 2.49219L3.33313 1.65875L2.5 -0.0078125L1.66688 1.65875L0 2.49219L1.66688 3.32563L2.5 4.99219ZM13.5 8.99219L12.6669 10.6587L11 11.4922L12.6669 12.3256L13.5 13.9922L14.3331 12.3256L16 11.4922L14.3331 10.6587L13.5 8.99219ZM15.7069 2.93688L13.0553 0.285313C12.8603 0.0896875 12.6044 -0.0078125 12.3484 -0.0078125C12.0925 -0.0078125 11.8366 0.0896875 11.6412 0.285313L0.293125 11.6334C-0.0975 12.0241 -0.0975 12.6572 0.293125 13.0475L2.94469 15.6991C3.14 15.8944 3.39594 15.9919 3.65156 15.9919C3.9075 15.9919 4.16344 15.8944 4.35875 15.6991L15.7069 4.35063C16.0975 3.96063 16.0975 3.32719 15.7069 2.93688ZM11.2328 6.35031L9.64188 4.75938L12.3481 2.05312L13.9391 3.64406L11.2328 6.35031Z"
              fill="#F88F00"
            />
          </svg>
          <span className="text-[#F88F00] text-[12px] cursor-pointer">
            Start New Calculation
          </span>
        </div>
      </div>
      <div className="flex items-center space-x-4 py-6">
        <button
          onClick={() => setTab("Summary")}
          className={`h-10 w-20 rounded-full text-[13px] ${
            tabName === "Summary"
              ? "dark:bg-white bg-slate-300 text-black font-semibold"
              : "dark:bg-slate-800 bg-slate-100 text-slate-500"
          } `}
        >
          Summary
        </button>
        <button
          onClick={() => setTab("Tabulated View")}
          className={`h-10 w-28 rounded-full text-[13px] ${
            tabName === "Tabulated View"
              ? "dark:bg-white bg-slate-300 text-black font-semibold"
              : "dark:bg-slate-800 bg-slate-100 text-slate-500"
          } `}
        >
          Tabulated View
        </button>
        <button
          onClick={() => setTab("Positions")}
          className={`h-10 w-20 rounded-full text-[13px] ${
            tabName === "Positions"
              ? "dark:bg-white bg-slate-300 text-black font-semibold"
              : "dark:bg-slate-800 bg-slate-100 text-slate-500"
          } `}
        >
          Positions
        </button>
      </div>
      <div className="flex h-full w-full rounded-lg py-2 px-2 overflow-y-auto">
        {tabName === 'Summary' && <Summary />}
        {tabName === 'Tabulated View' && <TabulatedView data={initData} />}
        {tabName === 'Positions' && <Positions data={initData} />}
      </div>
    </div>
  );
}
