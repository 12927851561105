import React from 'react';
import ReactApexChart from 'react-apexcharts';

const BarChart2 = () => {
  const series = [
    {
      name: 'Sector Drift',
      data: [50, 35, 20, 18, 14, 10, -10, -14, -20, -35, -50],
    },
  ];

  const options = {
    chart: {
      type: 'bar',
      toolbar: {
        show: false, // Remove chart toolbar
      },
      
    },
    plotOptions: {
      bar: {
        horizontal: true,
        borderRadius: 0, // Remove border radius
        barHeight: '77%', // Adjust bar height if needed
        colors : {
          ranges : [{
            from: -Infinity,
            to: Infinity,
            color: '#60A5FA',
          }],
        },
      },
    },
    dataLabels: {
      enabled: false, // Hide data labels
    },
    xaxis: {
      categories: [
        'Technology',
        'Communication Services',
        'Consumer Cyclicals',
        'Consumer Defensive',
        'Utilities',
        'Real State',
        'Basic Materials',
        'Energy',
        'Industrials',
        'Healthcare',
        'Financial Services',
      ],
      labels: {
        show: false, // Hide x-axis labels
      },
      axisBorder: {
        show: false, // Hide x-axis border
      },
      axisTicks: {
        show: false, // Hide x-axis ticks
      },
    },
    yaxis: {
      labels: {
        show: true, // Show y-axis labels (sectors)
        style: {
          colors: '#94A3B8',
        },
      },
      axisBorder: {
        show: false, // Hide y-axis border
      },
      axisTicks: {
        show: false, // Hide y-axis ticks
      },
    },
    grid: {
      show: false, // Remove grid lines
    },
    tooltip: {
      enabled: true, // Enable tooltips
    },
  };

  return <ReactApexChart options={options} series={series} type="bar" height={420} width={370} border="none"/>;
};

export default BarChart2;
