// src/CustomCarousel.js
import React, { useState, useEffect } from 'react';
import {carousel_items} from '../utils/carouselContent';

function Carousel ()  {
    const [currentIndex, setCurrentIndex] = useState(0);

    const goToSlide = (index) => {
        setCurrentIndex(index);
    };

    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex((prevIndex) => (prevIndex + 1) % carousel_items.length);
        }, 3000); // Change slide every 3 seconds

        // Clear the interval when the component unmounts
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="relative items-center justify-center flex-col flex w-full font-inter py-20">
            <div className='h-full w-2/4 '>
                <div className=" relative space-y-10 transition-opacity duration-1000 ease-in-out">
                    {/* {carousel_items.map((item, index) => (
                        <div
                            key={index}
                            className={`absolute top-0 left-0 w-full h-full opacity-${currentIndex === index ? '100' : '0'} transition-opacity duration-1000 ease-in-out`}
                        > */}
                            <img
                                src={carousel_items[currentIndex].image}
                                // src={item.image}
                                alt={carousel_items[currentIndex].alt}
                                // alt={item.alt}
                                className="w-full"
                            />
                            <div className="absolute text-center p-3 rounded">
                                <h1 className="text-[20px] font-bold text-[#1E293B] mb-2">
                                    {carousel_items[currentIndex].title}
                                    {/* {item.title} */}
                                </h1>
                                <p className="text-[15px] text-[#475569]">
                                    {carousel_items[currentIndex].description}
                                    {/* {item.description} */}
                                </p>
                            </div>
                        {/* </div> */}
                    {/* ))} */}
                </div>
            </div>
            <div className="flex justify-center space-x-2">
                {carousel_items.map((_, index) => (
                    <button
                        key={index}
                        onClick={() => goToSlide(index)}
                        className={`w-2 h-2 rounded-full ${
                            currentIndex === index ? 'bg-[#0F172A]' : 'bg-[#CBD5E1]'
                        }`}
                    ></button>
                ))}
            </div>
        </div>
    );
};

export default Carousel;
