import React from "react";
import { Link } from "react-router-dom";

function SmallCard({ item, index }) {
  return (
  
    <Link 
    to={`/quote?symbol=${item?.symbol}`} 
    key={index} 
    className="no-underline flex items-center justify-between w-[24rem] smallCardContainer p-3 rounded-[8px] border-[#E2E8F0] dark:border-[#1F2A37] bg-[#f8fafc] dark:bg-[#0F172A]"
    style={{ textDecoration: 'none', color: 'inherit' }}
  >
    <div className='flex items-center space-x-3'>
      <span>
        <img style={{ height: "50px", width: "50px" }} src={item?.logo} alt={`${item?.symbol} logo`} />
      </span>
      <div className='flex flex-col'>
        <span className='text-[#020617] dark:text-[#F8FAFC] font-semibold text-[1.1rem]'>{item?.symbol}</span>
        <span className='text-[#020617] dark:text-[#F8FAFC] font-normal text-[0.9rem]'>{item?.share} Shares</span>
      </div>
    </div>
    <div className='flex flex-col'>
      <span className='text-[#020617] dark:text-[#F8FAFC] font-medium text-[0.8rem]'>DIV YIELD</span>
      <span className='text-[#020617] dark:text-[#F8FAFC] font-bold text-[1.2rem]'>{item?.dividendYield} %</span>
    </div>
  </Link>
    
  );
}

export default SmallCard;
