import React from "react";
import SignUp from "../pages/SignUp";
import Carousel from "../components/Carousel";

function UserStory() {
    return (
        <div className="flex w-full h-full bg-[#F1F5F9]">
            <div className="flex w-full h-full justify-center items-center ">
                {/*   */}
                <div className="flex h-[40rem] w-4/6 rounded-xl">
                
                <SignUp />
                </div>
            </div>
            <div className="w-full h-full sm:flex hidden ">
                <Carousel />
            </div>
        </div>
        
    );
};

export default UserStory;