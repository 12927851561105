import React from 'react'
import IdeasTable from '../components/table/IdeasTable'

function MostPopularStocks() {
  const columns = [{key:'ticker',value:'Stock/Ticker Name'},{key:'price',value:'Price'},{key:'divYield',value:'Div Yield'},{key:'divAmount',value:'Div Amount'},{key:'payout',value:'Payout Ratio'},{key:'grade',value:'Grade'}]
  const data = [
    {ticker:'IBM',image:'ibm.png',price:172.70,divYield:0.56,divAmount:0.56,payout:14.91,grade:89},
    {ticker:'TSLA',image:'tesla.png',price:172.70,divYield:0.56,divAmount:0.56,payout:14.91,grade:39},
    {ticker:'AAPL',image:'apple.png',price:172.70,divYield:0.56,divAmount:0.56,payout:14.91,grade:89},
    {ticker:'NVDA',image:'nvda.png',price:172.70,divYield:0.56,divAmount:0.56,payout:14.91,grade:50},
    {ticker:'META',image:'meta.png',price:172.70,divYield:0.56,divAmount:0.56,payout:14.91,grade:10},
    {ticker:'AMZN',image:'amazon.png',price:172.70,divYield:0.56,divAmount:0.56,payout:14.91,grade:59},
]
  const detail = 'This list compiles the 50 most prevalent stock tickers in the portfolios of all The IVY Dividends users. It offers a snapshot of the most commonly held stocks within the community.'

  return (
    <div className='w-full h-full pt-[1rem]'>
      <IdeasTable title='Most Popular Stocks' columns={columns} data={data} detail={detail} />
    </div>
  )
}

export default MostPopularStocks