// import React,{useEffect, useState} from "react"
// import Chart from 'react-apexcharts';
// import { useSelector } from "react-redux";

// function MultiAreaChart({series=[]}) {
//     const theme = useSelector((state) => state.theme.darkMode);
//     const [showChart,setShowChart] = useState(true)

//       const [options, setOptions] = useState({
//         chart: {
//           type: 'area',
//           toolbar: { show: false },
//         },
//         dataLabels: {
//           enabled: false
//         },
//         stroke: {
//           curve: 'smooth'
//         },
//         xaxis: {
//           type: 'datetime',
//           categories: [
//             "01 Apr",
//             "02 Apr",
//             "03 Apr",
//             "04 Apr",
//             "05 Apr",
//             "06 Apr",
//             "07 Apr"
//           ],
//           labels: {
//             style: {
//               colors: '#94A3B8'
//             }
//           },
//           axisBorder: {
//             show: true,
//             color: '#94A3B8',
//             height: 1,
//           }

//         },
//         yaxis: {
//             min: 0,
//             max: 200000,
//             tickAmount: 5,
//             labels: {
//               formatter: function (value) {
//                 if (value >= 1000) {
//                   return (value / 1000).toFixed(0) + ' k';
//                 }
//                 return value;
//               },
//               style: {
//                 colors: '#94A3B8'
//               }
//             },

//           },
//         fill: {
//             type: 'gradient',
//             gradient: {
//               opacityFrom: 0,
//               opacityTo: 0,
//             }
//           },
//           grid: {
//             borderColor: theme ? '#1E293B' : '#E2E8F0'
//           },
//         tooltip: {
//             style: {
//                 fontSize: '12px',
//               },
//           x: {
//             format: 'dd MMM',
//           },

//         },
//         legend: {
//             show: false // Hide the legend
//           }
//       });

//       console.log("lets chart:")
//       useEffect(() => {
//         setOptions({
//           ...options,
//           grid: {
//             borderColor: theme ? '#1E293B' : '#E2E8F0'
//           },
//           xaxis: {
//             ...options.xaxis,
//             axisBorder: {
//                 color: theme ? '#1E293B' : '#E2E8F0'
//               }
//           }
//         })
//      }, [theme])

//   return (
//     <div id="chart" className="w-full h-full">
//      {showChart && <Chart options={options} series={series} type="area"  width="100%" height="100%" />}
//   </div>
//   )
// }

// export default MultiAreaChart

import React, { useEffect, useState } from "react";
import Chart from "react-apexcharts";
import { useSelector } from "react-redux";
import { getDashBoardChart } from "../../ApiControler";
function MultiAreaChart({ data }) {
  const theme = useSelector((state) => state.theme.darkMode);
  const [showChart, setShowChart] = useState(true);
  const [chartData, setChartData] = useState([]);

  
  useEffect(() => {
    setChartData(data);
  }, [data]);

  const options = {
    chart: {
      type: "area",
      toolbar: { show: false },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
    },
    xaxis: {
      type: "datetime",
      labels: {
        style: {
          colors: "#94A3B8",
        },
      },
      axisBorder: {
        show: true,
        color: "#94A3B8",
        height: 1,
      },
    },
    yaxis: {
      labels: {
        formatter: function (value) {
          return value.toLocaleString(); // Format y-axis labels as needed
        },
        style: {
          colors: "#94A3B8",
        },
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        opacityFrom: 0,
        opacityTo: 0,
      },
    },
    grid: {
      borderColor: theme ? "#1E293B" : "#E2E8F0",
    },
    tooltip: {
      style: {
        fontSize: "12px",
      },
      x: {
        format: "dd MMM yyyy", // Adjust date format as needed
      },
    },
    legend: {
      show: false, // Hide the legend
    },
  };

  // useEffect(() => {
  //   // Simulating API response
  //   const apiResponse = {
  //     success: true,
  //     data: [
  //       { prices: 11, date: "2024-06-11" },
  //       { prices: 31711300, date: "2024-06-12" },
  //       // Add more data here...
  //       { prices: 32995800, date: "2024-07-04" },
  //     ],
  //   };
  // }, []);
  return (
    <div id="chart" className="w-full h-full">
      {showChart && (
        <Chart
          options={options}
          series={chartData}
          type="area"
          width="100%"
          height="100%"
        />
      )}
    </div>
  );
}

export default MultiAreaChart;
