import React, { useState,useEffect } from 'react'
import ModalContainer from '../Modal/ModalContainer'
import ModalBtn from '../Button/ModalBtn'
import {toggleSettingModal,toggleChangePasswordModal} from '../../store/slices/themeSlice'
import {useDispatch} from 'react-redux'
import openEye from '../../assets/openeye.svg'
import closeEye from '../../assets/closeeye.svg'
 
function ChangePasswordModal() {
    const dispatch = useDispatch()
    const [currentPassword,setCurrentPassword] = useState("hello123")
    const [newPassword,setNewPassword] = useState("")
    const [showPassword,setShowPassword] = useState(false)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const [confirmPassword,setConfirmPassword] = useState("")
    const [criteria, setCriteria] = useState({
        minLength: false,
        hasNumber: false,
        hasSymbol: false,
      });

      useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
    
        window.addEventListener('resize', handleResize);
        
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      const handlePasswordChange = (e) => {
        const { value } = e.target;
        setNewPassword(value);
        setCriteria({
          minLength: value.length >= 8,
          hasNumber: /\d/.test(value),
          hasSymbol: /[!@#$%^&*(),.?":{}|<>]/.test(value),
        });
      };

    const handleModalClose = ()=>{
        
        localStorage.setItem("currentSetting","Security")
        dispatch(toggleChangePasswordModal())
        dispatch(toggleSettingModal())
    } 

    const handleSubmit = ()=>{
        if(newPassword == confirmPassword){
           
            handleModalClose()
        }else{
            alert("password does not match")
        }
    }

  return (
    <div className={`w-[35rem] ${screenWidth < 390 ? 'h-[33rem]':'h-[30rem]'} mainSettingContainer`}>
        <ModalContainer customClass='w-full h-full relative' onClose={handleModalClose} title='Change Password'>
        
        <div className='w-full flex items-center px-4 dark:border-[#1E293B] pb-4 pt-[1.5rem] border-[#cecece70] border-b justify-between'>
            <span className='label'>
                Current Password
            </span>
            <div className='flex items-center space-x-2'>
                <input disabled type={showPassword ? 'text' : 'password'} value={currentPassword} className='border-none text-[14px] text-right dark:text-[#F1F5F9] focus:outline-none bg-transparent' />
                <span className='h-5 w-[2px] bg-gray-300 dark:bg-[#1E293B]' />
                <span onClick={()=>setShowPassword(!showPassword)} className='cursor-pointer w-[1.5rem]'>
                    <img src={showPassword ? openEye : closeEye} />
                </span>
            </div>
            
        </div>

        <div className='w-full flex items-center px-4 dark:border-[#1E293B] pb-4 pt-[1.5rem] border-[#cecece70] border-b justify-between'>
            <span className='label'>
                New Password
            </span>
            <div className='flex items-center space-x-2'>
                <input type={showPassword ? 'text' : 'password'} placeholder='Enter password' onChange={handlePasswordChange} value={newPassword} className='border-none text-[14px] dark:placeholder:text-[#475569] text-right dark:text-[#F1F5F9] focus:outline-none bg-transparent' />
            </div>
            
        </div>

        <div className='w-full flex items-center px-4 dark:border-[#1E293B] pb-4 pt-[1.5rem] border-[#cecece70] border-b justify-between'>
        <ul>
          <li className="flex items-center text-[14px] font-normal dark:text-[#94A3B8]">
            <span
              className={`w-4 h-4 rounded-full mr-2 ${
                criteria.minLength ? "bg-[#FFB34B]" : "bg-[#b0b1b2] dark:bg-[#0F172A]"
              }`}
            ></span>
            Minimum 8 characters
          </li>
          <li className="flex items-center text-[14px] font-normal dark:text-[#94A3B8] my-3">
            <span
              className={`w-4 h-4 rounded-full mr-2 ${
                criteria.hasNumber ? "bg-[#FFB34B]" : "bg-[#b0b1b2] dark:bg-[#0F172A]"
              }`}
            ></span>
            At least one number
          </li>
          <li className="flex items-center text-[14px] font-normal dark:text-[#94A3B8]">
            <span
              className={`w-4 h-4 rounded-full mr-2 ${
                criteria.hasSymbol ? "bg-[#FFB34B]" : "bg-[#b0b1b2] dark:bg-[#0F172A]"
              }`}
            ></span>
            At least one symbol
          </li>
        </ul>
        </div>

        <div className='w-full flex items-center px-4 dark:border-[#1E293B] pb-4 pt-[1.5rem] border-[#cecece70] border-b justify-between'>
            <span className='label'>
              Confirm New Password
            </span>
            <div className='flex items-center space-x-2'>
                <input type={showPassword ? 'text' : 'password'} placeholder='Enter password' onChange={(e)=>setConfirmPassword(e.target.value)} value={confirmPassword} className='border-none text-[14px] dark:placeholder:text-[#475569] text-right dark:text-[#F1F5F9] focus:outline-none bg-transparent' />
            </div>
            
        </div>






        <div className='absolute bottom-6 flex items-center justify-end space-x-3 right-4'>
           <div>
              <ModalBtn onPress={handleModalClose} title="Go Back" customClass={`pointer-events-auto cursor-pointer !font-medium dark:bg-[#080e20] bg-[#d8d8d8] text-[#383838] dark:text-[#F1F5F9]`} />
            </div>               
           <div>
              <ModalBtn title={screenWidth<340?'Save':"Save Changes"} onPress={handleSubmit} customClass={`pointer-events-auto cursor-pointer !font-semibold !bg-[#FFac39] text-black`} />
            </div>               
            </div>
        </ModalContainer>
    </div>
  )
}

export default ChangePasswordModal