import React, { useState,useEffect }  from 'react'
import IconCircle from '../components/iconCircle';
import {useNavigate } from 'react-router-dom';
import { setSelectedRoute } from "../store/slices/themeSlice";
import { useDispatch } from "react-redux";
import trustedUs from '../assets/images/trusted.png'
import MainCard from '../components/CardBox/MainCard';
import {billingDetail} from '../utils/content'
import SimpleTable from '../components/table/SimpleTable';

function Billing() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const navigate = useNavigate()
  const dispatch = useDispatch();
  const [isMonthly, setIsMonthly] = useState(false);
  const [showInvoice,setShowInvoice] = useState(false)
  const [invoiceData,setInvoiceData] = useState([
    {reference:'PID123245',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
    {reference:'PID123246',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
    {reference:'PID123247',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
    {reference:'PID123248',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
    {reference:'PID123249',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
    {reference:'PID123250',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
    {reference:'PID123251',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
    {reference:'PID123252',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
    {reference:'PID123253',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
    {reference:'PID123254',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
    {reference:'PID123255',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
    {reference:'PID123256',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
    {reference:'PID123257',purchasedDate:'2024-03-12',paymentMethod:'Visa/Debit Card',nextBillingDate:'2025-03-12',amount:'$19.99',status:'Paid'},
  ])
  const columns = [
    {key:'reference',value:'Reference'},
    {key:'purchasedDate',value:'Purchased Date'},
    {key:'paymentMethod',value:'Payment Method'},
    {key:'nextBillingDate',value:'Next Billing Date'},
    {key:'amount',value:'Amount'},
    {key:'status',value:'Status'},
    {key:'pdf',value:'PDF'},
  ]

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

 
  const handleBack = ()=>{
    dispatch(setSelectedRoute({name:"Dashboard",id:1}))
    navigate('/')
}

const toggleSwitch = () => {
  setIsMonthly(!isMonthly);
};

  return (
    <>
    {showInvoice ?
    <div>

        <div className='w-full flex items-center justify-between'>
          <span className='dark:text-[#F8FAFC] text-[32px]'>Invoices ({invoiceData?.length})</span>
          <span>
            <IconCircle handleClick={() => setShowInvoice(false)}>
              <svg width="14" height="14" className='dark:text-[#F8FAFC]' viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13 1L1 13" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M1 1L13 13" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </IconCircle>
          </span>
        </div>
        <div className='mt-[1rem]'>
          <SimpleTable data={invoiceData} columns={columns} />
        </div>
    </div>
    :
    
    <div className='w-full h-fit background-image'>
        <div className='w-full flex items-center  justify-between'>
           <div className='flex items-center space-x-3'>
            <IconCircle handleClick={handleBack}>
            <svg width="17" height="17" viewBox="0 0 17 17" className='text-[#020617] dark:text-[#94A3B8]' fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.7349 8.43384H1.73494" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.73494 15.4338L1.73494 8.43384L8.73494 1.43384" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </IconCircle>
            <span onClick={handleBack} className='dark:text-[#94A3B8] cursor-pointer  hover:underline text-[15px] font-normal'>Back to Dashboard</span>
          </div>
          <div className='flex items-center space-x-3'>
            <span>
              <svg width="18" height="18" viewBox="0 0 18 18" className='dark:text-[#F8FAFC]' fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M10.5 1.5H4.5C4.10218 1.5 3.72064 1.65804 3.43934 1.93934C3.15804 2.22064 3 2.60218 3 3V15C3 15.3978 3.15804 15.7794 3.43934 16.0607C3.72064 16.342 4.10218 16.5 4.5 16.5H13.5C13.8978 16.5 14.2794 16.342 14.5607 16.0607C14.842 15.7794 15 15.3978 15 15V6L10.5 1.5Z" stroke="currentColor" stroke-width="1.26" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M10.5 1.5V6H15" stroke="currentColor" stroke-width="1.26" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 9.75H6" stroke="currentColor" stroke-width="1.26" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M12 12.75H6" stroke="currentColor" stroke-width="1.26" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M7.5 6.75H6.75H6" stroke="currentColor" stroke-width="1.26" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>
            </span>
            <span onClick={()=>setShowInvoice(true)} className='cursor-pointer  hover:underline text-[14px] dark:text-[#F8FAFC] font-normal'>
              Invoices
            </span>
          </div>
        </div>
        <div className='w-full flex mt-[2rem] flex-col items-center'>
              {/* <div class="join-us-container">
                            <div class="user-profile">
                                <img src="https://images.unsplash.com/flagged/photo-1570612861542-284f4c12e75f?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 1" />
                            </div>
                            <div class="user-profile">
                                <img src="https://images.unsplash.com/photo-1556157382-97eda2d62296?auto=format&fit=crop&q=80&w=2070&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 2" />
                            </div>
                            <div class="user-profile">
                                <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=80&w=1974&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 3" />
                            </div>
                            <div class="user-profile">
                                <img src="https://images.unsplash.com/photo-1573496359142-b8d87734a5a2?auto=format&fit=crop&q=80&w=1976&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 4" />
                            </div>
                            <div class="user-profile">
                                <img src="https://images.unsplash.com/photo-1494790108377-be9c29b29330?auto=format&fit=crop&q=80&w=1974&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D" alt="User 3" />
                            </div>
            </div> */}
            <div>
              <img src={trustedUs} />
            </div>
            <div>
            <span className="text-[18px] font-semibold">
                  <span className="bg-gradient-to-r from-[#FD9F1E] to-[#27EE2F] bg-clip-text text-transparent">
                    +100 Trusted Us
                  </span>
              </span>
            </div>
            <div className='text-center'>
              <span className={`dark:text-[#FFFFFF] font-bold ${screenWidth < 541 ? 'text-[1.5rem]' : screenWidth < 900 ? 'text-[2.5rem]':'text-[3rem]'}`}>Clear pricing tiers for easy <br /> <span>decision-making.</span></span>
            </div>
            <div className='my-[2rem] flex space-x-4 items-center text-[14px] font-normal dark:text-[#FFFFFF]'>
                <div className="flex items-center justify-center space-x-2">
                  <span>Monthly</span>
                  <button
                      onClick={toggleSwitch}
                      className={`relative w-12 h-7 flex items-center bg-[#F88F00] rounded-full cursor-pointer`}
                    >
                      <div
                          className={`w-6 h-6 bg-white rounded-full shadow-md transform transition-transform ${
                            isMonthly ? 'translate-x-0' : 'translate-x-6'
                          }`}
                        />
                  </button>
                  <span>Annually</span>

                </div>
                <span>16% off</span>
            </div>
            <div className={`w-full flex items-center justify-center ${screenWidth < 650 ? 'flex-col':'flex-row'}  gap-6`}>
                {billingDetail?.map((item,index)=>(
                    <div key={index} className={`${screenWidth < 650 ? 'w-11/12' :  screenWidth < 1050 ? 'w-6/12' : screenWidth < 1500 ? 'w-5/12':'w-4/12'}`}>
                    <MainCard customClass='w-full h-full shadow-lg'>
                    <div className='flex-col flex items-center'>
                            <span className='text-[24px] font-bold bg-gradient-to-r from-[#4B93FF] to-[#FFAD3F] bg-clip-text text-transparent'>{item.title}</span>
                            <span className='flex mt-[1.2rem]  items-center'>
                            <span className='text-[40px] dark:text-[#FFFFFF] font-bold'>
                              {item.price}
                            </span>
                            {item.id == 2 && <span className='text-[15px] font-normal text-[#94A3B8] pt-3'>/month</span>}
                            </span>
                            <span className='text-[14px] font-normal text-[#94A3B8]'>
                              {item.priceDetail}
                            </span>
                           {item.id == 2 && 
                            <div className='flex space-x-2 items-center my-3 dark:text-[#F88F00] text-[#ffffff] bg-[#F88F00] dark:bg-[#431407] py-1 px-3 rounded-[8px]'>
                              <span>
                                  You save $24.00
                              </span>
                              <span>
                              <svg width="17" height="16" className='dark:text-[#F88F00]' viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.49992 14.6666C12.1818 14.6666 15.1666 11.6819 15.1666 7.99998C15.1666 4.31808 12.1818 1.33331 8.49992 1.33331C4.81802 1.33331 1.83325 4.31808 1.83325 7.99998C1.83325 11.6819 4.81802 14.6666 8.49992 14.6666Z" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8.5 5.33331V7.99998" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M8.5 10.6667H8.50667" stroke="currentColor" stroke-width="1.33333" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>

                              </span>
                            </div>
                            }
                            <div className={`py-4 ${item.id == 1 ? 'mt-[5rem] bg-[#1E293B] text-[#FFFFFF]':'mt-[1.7rem] cardBtnGradient text-[#020617]'} w-full text-center rounded-[10px]  text-[16px] font-semibold`}>
                              {item.buttonText}
                            </div>
                           {item.id == 2 && 
                            <div className='text-center dark:text-[#d4d9df] pt-2 text-[14px] font-normal'>
                              or skip trail and <span className='text-[#225ad3] cursor-pointer hover:underline'>pay now</span>
                            </div>}

                            <div className={`${item.id == 1?'mt-[2.5rem]':'mt-[0.5rem]'} w-full`}>
                                {item.planList?.map((val,i)=>(
                                  <div key={i} className='w-full space-x-3 py-2 flex items-center'>
                                    {val.isAvailable ? 
                                    <span>
                                    <svg width="16" height="12" viewBox="0 0 16 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M14.6666 1L5.49992 10.1667L1.33325 6" stroke="#4ADE80" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>
                                    </span>
                                    :
                                    <span>
                                       <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M11 1L1 11" stroke="#64748B" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                        <path d="M1 1L11 11" stroke="#64748B" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>

                                    </span>
                                    
                                    }
                                    <span className='dark:text-[#CBD5E1] text-[14px] font-normal'>{val.detail}</span>
                                  </div>
                                ))}
                            </div>
                        </div>
                    </MainCard>   
                    </div>
                ))}
               
            </div>
         
        </div>
    </div>
    }
    </>
  )
}

export default Billing