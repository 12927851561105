import React, { useEffect, useState } from "react";
import { ReactComponent as Floppy } from "../../assets/Floppy.svg";
import { ReactComponent as Exclamation } from "../../assets/ExclamationCircle.svg";
import Slider from "rc-slider";
import { useSelector } from "react-redux";
import DonutCharts from "./Check";
import DummyChart from "./DummyChart";

export default function GoalChart({ item, header, _isActive, _setActive }) {
  console.log("Goal Values",header)
  const darkMode = useSelector((state) => state.theme.darkMode);
  const [goalValue, setGoal] = useState(0);
  const [isActive, setActive] = useState(false);

  // useEffect(() => {
  //   console.log('isActive', isActive);
  //   _setActive(isActive);
  //   OnChangeEventTriggerd(goalValue);

  //   // Save isActive state to localStorage whenever it changes
  //   localStorage.setItem('isActive', JSON.stringify(isActive));
  // }, [isActive]);

  const OnChangeEventTriggerd = (newValue) => {
    if (!isActive) {
      console.log("Values of goal", newValue)
      setGoal(newValue);
     
    } else {
      setGoal(0);
    }
  };

  return (
    <div className="flex flex-col w-[30rem] h-[30rem] justify-between items-center rounded-md py-3 px-5 dark:bg-slate-900 bg-slate-100 ">
      {/* header start */}
      <div className="flex w-full justify-between">
        <div className="flex items-center space-x-2">
          <div className="flex-col">
            <span className="text-[12px]">{`${header} Income Goal`}</span>
            <div className={`border-b-2 h-1 w-6 ${!isActive ? "border-b-[#F88F00]" : "dark:border-b-slate-700 border-b-slate-400"}`} />
          </div>
          <Exclamation className={`w-2.5 h-2.5 fill-none stroke-slate-500`} />
        </div>
        <Floppy className={`w-4 h-4 fill-none ${isActive ? "stroke-slate-700" : " stroke-[#F88F00] cursor-pointer hover:opacity-80"}`} />
      </div>
      {/* header END */}

      {/* chart start */}
      <DonutCharts centerText={goalValue} setActive={setActive} setGoal={setGoal} _isOn={isActive} item={item} />
      {/* chart end */}

      {/* slider start */}
      <div className="flex flex-col items-center w-full space-y-2 py-5">
        <div className="flex justify-between items-center w-full text-[10px] dark:text-slate-400 ">
          <span>{`${header === "Diversification" ? "0%" : "$0.00"}`}</span>
          <span>
            {header === "Diversification" ? "100%" : header === "Monthly" ? "$" + parseFloat("10000")
                ?.toLocaleString("en-US")
                .toLocaleString("en-US") : "$" +
              parseFloat("100000")
                ?.toLocaleString("en-US")
                .toLocaleString("en-US")}
          </span>
        </div>
        <Slider
          value={!isActive ? goalValue : 0}
          onChange={(val) => OnChangeEventTriggerd(val)}
          min={0}
          max={isActive===true  ? 0 :item.name === "Diversification" ? 100 : item.name === "Monthly" ? 10000 : 100000}
          trackStyle={{ backgroundColor: isActive===false ? "#F88F00": darkMode ? "#475569" : "#c3cad6", height: 6 }}
          railStyle={{
            backgroundColor:  darkMode ? "#1E293B" : "#eaf1fc",
            height: 6,
          }}
          handleStyle={{
            borderColor: isActive===false ? "#F88F00" : darkMode ? "#475569" : '#c3cad6',
            height: 15,
            width: 15,
            backgroundColor: darkMode ? "#0F172A" : "white",
          }}
        />
      </div>
      {/* slider end */}
    </div>
  );
}
