import React, { useState } from "react";
import TextInput from "../TextInput";
import PrimaryButton from "../Button";
import {ReactComponent as Eye} from "../../assets/Eye.svg"
import {ReactComponent as EyeOff} from "../../assets/eye-off.svg"

export default function PasswordComp({
  password,
  setPassword,
  rePassword,
  setRePassword,
  _click
}) {
  const [isSecure, setSecure] = useState(true)
  const specialCharRegex = /\W/;
  const checkNumber = /\d/;
  return (
    <div className="w-full h-full items-center justify-evenly flex flex-col px-14">
      <span className="text-[18px] font-semibold">Create your passwrod</span>
      <span className="text-[12px] text-slate-400 text-center">
        Your password must be at least 8 characters long,<br></br> and include 1
        symbol and 1 number.
      </span>
      <TextInput
        _value={password}
        _onChange={(text) => setPassword(text.target.value)}
        label={"Password"}
        type={isSecure ? "password" : "text"}
        placeholder={"New password"}
        mainClass={" xl:w-[360px] sm:w-[220px] w-[280px] "}
        image={<div onClick={()=>setSecure(!isSecure)}>{isSecure ? <EyeOff className="h-3 stroke-slate-400 fill-none" />:<Eye className="stroke-slate-400 fill-none h-2.5"/>}</div>}
      />
      <TextInput
        _value={rePassword}
        _onChange={(text) => setRePassword(text.target.value)}
        label={"Password"}
        type={isSecure ? "password" : "text"}
        placeholder={"Retype above password"}
        mainClass={" xl:w-[360px] sm:w-[220px] w-[280px] "}
      />
      <div className="text-[10px] flex flex-col w-full px-10 space-y-2">
        <div className="flex space-x-1 items-center">
          <svg
            className={`h-3.5 w-3.5 ${
              password?.length > 7 ? "fill-green-400" : "fill-gray-200"
            }`}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9" cy="9" r="9" />
            <path
              d="M13 6L7.5 11.5L5 9"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>Minimum 8 characters</span>
        </div>
        <div className="flex space-x-2 items-center">
          <svg
            className={`h-3.5 w-3.5 ${
              checkNumber.test(password) ? "fill-green-400" : "fill-gray-200"
            }`}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9" cy="9" r="9" />
            <path
              d="M13 6L7.5 11.5L5 9"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>At least one number</span>
        </div>
        <div className="flex space-x-2 items-center">
          <svg
            className={`h-3.5 w-3.5 ${
              specialCharRegex.test(password)
                ? "fill-green-400"
                : "fill-gray-200"
            }`}
            viewBox="0 0 18 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="9" cy="9" r="9" />
            <path
              d="M13 6L7.5 11.5L5 9"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span>At least one symbol</span>
        </div>
      </div>
      <PrimaryButton
        isDisable={
          password?.length > 7 &&
          specialCharRegex.test(password) &&
          checkNumber.test(password) &&
          password === rePassword
            ? true
            : false
        }
        title={"Create password"}
        _click={_click}
        
        mainClass={` xl:w-[360px] sm:w-[220px] w-[280px]  ${
          password?.length > 7 &&
          specialCharRegex.test(password) &&
          checkNumber.test(password) && password === rePassword
            ? "bg-[#FFAA36] cursor-pointer hover:opacity-50"
            : "bg-[#fbd8a4]"
        }`}
      />
    </div>
  );
}
