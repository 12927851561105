import React,{useState} from 'react'
import {toggleSettingModal,toggleChangePasswordModal,toggleVerificationModal} from '../../store/slices/themeSlice'
import { useDispatch } from 'react-redux'

function Security() {
  const dispatch = useDispatch()

  const handleChangePassword = () => {
      dispatch(toggleSettingModal())
      dispatch(toggleChangePasswordModal())
  }

  const handleTwoFactor = ()=>{
    dispatch(toggleSettingModal())
    dispatch(toggleVerificationModal())
  }

  return (
    <div className='w-full h-full'>
      <div className='w-full flex items-center px-4 dark:border-[#1E293B] pb-4 pt-[1.5rem] border-[#cecece70] border-b justify-between'>
        <span className='dark:text-[#F1F5F9] text-[16px] font-normal'>
            Change Password
        </span>
        <span onClick={handleChangePassword} className='w-6 h-6 flex items-center justify-center rounded-full cursor-pointer hover:dark:bg-[#1E293B] hover:bg-[#E2E8F0]'>
        <svg width="8" height="14" className='dark:text-[#F1F5F9]' viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13L7 7L1 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </span>
      </div>

      <div className='w-full flex items-center px-4 dark:border-[#1E293B] pb-4 pt-[1.5rem] border-[#cecece70] border-b justify-between'>
        <span className='dark:text-[#F1F5F9] text-[16px] font-normal'>
          Two-factor Authentication 
        </span>
        <span onClick={handleTwoFactor} className='w-6 h-6 flex items-center justify-center rounded-full cursor-pointer hover:dark:bg-[#1E293B] hover:bg-[#E2E8F0]'>
        <svg width="8" height="14" className='dark:text-[#F1F5F9]' viewBox="0 0 8 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 13L7 7L1 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </span>
      </div>
    </div>
  )
}

export default Security