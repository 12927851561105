import React, { useEffect, useState } from "react";
import MainCard from "../CardBox/MainCard";
import { getAbout } from "../../ApiControler";
import { useNavigate, useLocation } from "react-router-dom";
function About() {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const symbol = queryParams.get("symbol");

  // const generalInfo = [
  //   {name:"Name",value:"Apple Inc"},
  //   {name:"Website",value:"https://www.apple.com"},
  //   {name:"Physical Address",value:"One Apple Park Way Cupertino CA"},
  //   {name:"Phone",value:"4089961010"},
  //   {name:"Primary Exchange",value:"NASDAQ"},
  //   {name:"Currency",value:"USD"},
  //   {name:"Region/Country",value:"USA"},
  //   {name:"Inception Date",value:"1980-12-12"},
  //   {name:"Total Employees",value:"161,000"},
  //   {name:"Company Industry",value:"Consumer Electronics"},
  //   {name:"Company Sector",value:"Technology"},
  //   {name:"Stock Type",value:"Common Stock"},
  // ]

  const generalInfo = [
    { name: "Name", value: data?.name ?? "N/A" },
    { name: "Website", value: data?.weburl ?? "N/A" },
    { name: "Physical Address", value: data?.address ?? "N/A" },
    { name: "Phone", value: data?.phone ?? "N/A" },
    { name: "Primary Exchange", value: data?.exchange ?? "N/A" },
    { name: "Currency", value: data?.currency ?? "N/A" },
    { name: "Region/Country", value: data?.country ?? "N/A" },
    { name: "Inception Date", value: data?.ipo ?? "N/A" },
    { name: "Total Employees", value: data?.employeeTotal ?? "N/A" },
    { name: "Company Industry", value: data?.finnhubIndustry ?? "N/A" },
    { name: "Company Sector", value: data?.gsector ?? "N/A" },
    { name: "Stock Type", value: "Common Stock" },
  ];
  const officersLevel = [
    { name: "CEO & Director (Age: 63)", value: "Mr. Timothy D. Cook" },
    { name: "CFO & Senior VP (Age: 61)", value: "Mr. Luca Maestri" },
    {
      name: "Chief Operating Officer (Age: 60)",
      value: "Mr. Jeffrey E. Williams",
    },
    {
      name: "Senior VP, General Counsel & Secretary (Age: 60)",
      value: "Ms. Katherine L. Adams",
    },
    {
      name: "Senior Vice President of Retail (Age: 57)",
      value: "Ms. Deirdre O'Brien",
    },
    {
      name: "Senior Director of Corporate Accounting",
      value: "Mr. Chris Kondo",
    },
    { name: "Chief Technology Officer", value: "Mr. James Wilson" },
    { name: "Director of Investor Relations", value: "Suhasini Chandramouli" },
    {
      name: "Senior Vice President of Worldwide Marketing",
      value: "Mr. Greg Joswiak",
    },
    {
      name: "Head of Corporate Development (Age: 50)",
      value: "Mr. Adrian Perica",
    },
  ];

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      
      const data = await getAbout(token, symbol);

      
      setData(data?.data?.companyProfile);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  //calling the about api
  useEffect(() => {
    fetchData();
  }, [symbol]);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <div className="w-full h-fit">
      <div
        className={`w-full flex  items-center gap-4 ${
          screenWidth < 1000 ? "cardMainContainer" : ""
        }`}
      >
        <div
          className={`w-6/12 h-[70vh] ${
            screenWidth < 1000 ? "cardSubContainer" : ""
          }`}
        >
          <MainCard customClass="border-none ">
            <div className="mb-[1rem]">General Info</div>
            {generalInfo.map((item, index) => (
              <div
                key={index}
                className="flex py-3 space-y-2 flex-col items-start border-b border-dashed border-b-[#E2E8F0] dark:border-b-[#1E293B] justify-between"
              >
                <span className="dark:text-[#64748B] text-[12px] font-normal">
                  {item.name}
                </span>
                <span className="dark:text-[#E2E8F0] text-[#94A3B8] text-[16px] font-medium">
                  {item.value}
                </span>
              </div>
            ))}
          </MainCard>
        </div>
        <div
          className={`w-6/12 h-[70vh] ${
            screenWidth < 1000 ? "cardSubContainer" : ""
          }`}
        >
          <MainCard customClass="border-none  ">
            <div className="mb-[1rem]">Officers/C-Level</div>
            {officersLevel.map((item, index) => (
              <div
                key={index}
                className="flex py-3 space-y-2 flex-col items-start border-b border-dashed border-b-[#E2E8F0] dark:border-b-[#1E293B] justify-between"
              >
                <span className="dark:text-[#64748B] text-[12px] font-normal">
                  {item.name}
                </span>
                <span className="dark:text-[#E2E8F0] text-[#94A3B8] text-[16px] font-medium">
                  {item.value}
                </span>
              </div>
            ))}
          </MainCard>
        </div>
      </div>
    </div>
  );
}

export default About;
