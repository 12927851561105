import React, { useEffect, useState } from "react";
import Layout from "../layout/Layout";
import UserStory from "../userstory/UserStory";
import Login from "../pages/Login";
import ForgetPassword from "../pages/ForgetPassword";
import { Routes, Route, Redirect, useNavigate } from "react-router-dom";
import ResetPassword from "../pages/ResetPassword";
import { sessionValidate } from "../ApiControler";

const AuthRoute = () => {
  const userToken = localStorage.getItem("token");
  const [isLogin, setLogin] = useState(true);
  const [isLoader, setLoader] = useState(false);

  const checkToken = async () => {
    setLoader(true);
    try {
      const response = await sessionValidate(userToken);
      
      if (response?.success) {
        setLogin(true);
        setLoader(false);
      }
    } catch (e) {
      setLogin(false);
      setLoader(false);
      localStorage.clear()
     
    }
  };

  useEffect(() => {
    checkToken();
  }, []);

  return isLoader ? (
    <div className="flex h-full w-full justify-center items-center">
      <span>please wait...</span>
    </div>
  ) : isLogin ? (
    <Layout />
  ) : (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route path="/signup" element={<UserStory />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/forget-password" element={<ForgetPassword />} />
    </Routes>
  );
};

export default AuthRoute;
