import React, { useState,useRef } from 'react'
import { fundDummyData } from '../../utils/sidebarContent';
import FutureTable from '../table/FutureTable';


function TabulatedView()  {
  const [fundTableData,setFundTableData] = useState(fundDummyData)

  const tableColumns = [
    { key: "year", value: "Year" },
    { key: "accountValue", value: "Account Value" },
    { key: "annualDiv", value: "Annual Dividend" },
    { key: "divYield", value: "Div Yield" },
  ];

  const [tableData, setTableData] = useState([
    {
      year:2024,
      accountValue:50000,
      annualDiv:134366,
      divYield:0.56,
    },
    {
      year:2025,
      accountValue:60000,
      annualDiv:234366,
      divYield:2.56,
    },
    {
      year:2026,
      accountValue:70000,
      annualDiv:334366,
      divYield:0.6,
    },
    {
      year:2027,
      accountValue:90000,
      annualDiv:344366,
      divYield:2.65,
    },
    {
      year:2028,
      accountValue:100000,
      annualDiv:734366,
      divYield:9.56,
    },
  ]);


  return (
    <>
      <div className='flex flex-col w-full h-full font-inter gap-2'>
          <div className="text-[20px] ">
            <span className='text-[#94A3B8] text-[14px]'>Below are the compounded dividend returns achieved from investing $50,000.00 for 10 years investing $200.00 per month with an annual return of 10.50% and a dividend growth rate of 2.00%</span>
          </div>
          <FutureTable columns={tableColumns} data={tableData} />
      </div>
    </>
  )
}

export default TabulatedView