import React, { useEffect, useState } from 'react'
import Loader from "../../assets/Loader/IVD.gif"

function PrimaryButton({mainClass,title, _click, isLoader=false, isDisable = false, image}) {
  const [check , setCheck] = useState(isLoader)
  return (
    isDisable ? 
    <div onClick={_click} className={`flex justify-center items-center h-10 rounded-md text-[13px] space-x-2 ${mainClass}`}>
      {isLoader && <img src={Loader} className='w-4 h-4' />} 
      {image && <span className='mr-3'>{image}</span>} 
      <span>{title}</span>
    </div>
    :
    <div className={`flex justify-center items-center h-10 rounded-md text-[13px] space-x-2 ${mainClass}`}>
      {isLoader && <img src={Loader} className='w-4 h-4' />} 
      {image && <span className='mr-3'>{image}</span>} 
      
      <span>{title}</span>
    </div>
  )
}

export default PrimaryButton