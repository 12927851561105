import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { getOneUserPortolio } from "../../ApiControler";
function RenderTable({ portfolioId, document }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const columns = [
    "Stock/Ticker name",
    "%Change",
    "P/L($)",
    "P/L(%)",
    "Equity",
    "Market",
    "Avg Price",
    "Div Yield",
  ];
  const [data, setData] = useState([null]);
  const [loading, setLoading] = useState(true);

  const fetchData = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem("token");
      const response = await getOneUserPortolio(token, portfolioId);
      setData(response?.data?.ticker);
    } catch (error) {
      console.log("Error in getting portfolio graph", error.message);
    } finally {
      setLoading(false);
    }
  };

  //calling the about api
  useEffect(() => {
    try {
      if (portfolioId) {
        fetchData();
      } else {
        setData(document);
      }
    } catch (error) {
      console.log("Error", error?.message);
    } finally {
      setLoading(false);
    }
  }, [portfolioId]);

  const totalPages = Math.ceil(data?.length / itemsPerPage);
  const startIndex = () => {
    return (currentPage - 1) * itemsPerPage + 1;
  };
  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const endIndex = () => {
    return currentPage * itemsPerPage;
  };

  const firstPage = () => {
    setCurrentPage(1);
    onPageChange(1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  };

  const lastPage = () => {
    setCurrentPage(totalPages);
    onPageChange(totalPages);
  };

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data?.slice(startIndex, endIndex);
  };
  if (loading) {
    return (
      <div className="flex items-center justify-center h-full">
        <div className="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-500"></div>
      </div>
    );
  }

  return (
    <div className="border w-full max-h-[75vh] dark:bg-[#020617] overflow-hidden overflow-hidden dark:border-[#1E293B] rounded-[8px]">
      <div className="max-h-[65vh] relative border-b dark:border-[#1E293B] overflow-x-auto overflow-y-auto">
        <table className="w-full text-[15px] bg-white text-left rtl:text-right">
          <thead className="z-50 sticky top-0 bg-[#F1F5F9] dark:bg-[#0F172A]">
            <tr>
              <th className="py-3 whitespace-nowrap border-none px-1 px-[1rem] text-[12px] font-[500] w-6 text-center">
                #
              </th>
              {columns?.map((item, index) => (
                <th
                  key={index}
                  className="px-12 w-20 text-left py-3 whitespace-nowrap border-b text-black dark:text-white text-[14px] border-none font-medium"
                >
                  {item}
                </th>
              ))}
            </tr>
          </thead>

          {data?.length > 0 ? (
            <tbody className="w-full">
              {getPaginatedData()?.map((item, i) => (
                <tr className=" text-black dark:hover:bg-[#151d26] dark:text-white hover:bg-[#f9f9f9] border-b-[0.1vh] dark:bg-[#020617]  dark:border-[#292929a1] ">
                  <td className="py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center">
                    {i + 1}
                  </td>
                  <Link
                    to={`/quote?symbol=${item?.symbol}`} // Specify the URL for each item
                    key={i} // Ensure each row has a unique key
                  >
                    <td className="py-4 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6">
                      <div className="w-[10rem] h-full flex items-center space-x-3">
                        <div className="">
                          <img
                            style={{ height: "40px", width: "40px" }}
                            src={item?.logo}
                          />
                        </div>
                        <div className="flex flex-col">
                          <span className="text-[18px] font-bold">
                            {item?.symbol}
                          </span>
                          <span className="text-[11px] dark:text-[#94A3B8]">
                            {item?.closingPrice}{" "}
                            <span
                              className={`${
                                item?.profitOrLoss > 0
                                  ? "text-[#34D399]"
                                  : "text-[#DC2626]"
                              }`}
                            >
                              {Math.trunc(item?.profitOrLoss)}
                            </span>
                            <span
                              className={`${
                                item?.ratioProfitOrLoss > 0
                                  ? "text-[#34D399]"
                                  : "text-[#DC2626]"
                              }`}
                            >
                              ({Math.trunc(item?.ratioProfitOrLoss)}%)
                            </span>
                          </span>
                        </div>
                      </div>
                    </td>
                  </Link>

                  <td className="py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center">
                    {item?.share}
                  </td>
                  {/* <td className="py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center">
                    ${item?.closingPrice}
                  </td>
                  <td
                    className={`py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center ${
                      item?.change > 0 ? "text-[#34D399]" : "text-[#DC2626]"
                    } `}
                  >
                    {item?.change
                      ? `${item?.change > 0 ? "+" : ""}${item?.change}%`
                      : "N/A"}
                  </td> */}
                  <td
                    className={`py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center ${
                      item?.profitOrLoss > 0
                        ? "text-[#34D399]"
                        : "text-[#DC2626]"
                    }`}
                  >
                    {item?.profitOrLoss
                      ? `${item?.profitOrLoss > 0 ? "+$" : "$"}${Math.trunc(
                          item?.profitOrLoss
                        )}`
                      : "N/A"}
                  </td>
                  <td
                    className={`py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center ${
                      item?.ratioProfitOrLoss > 0
                        ? "text-[#34D399]"
                        : "text-[#DC2626]"
                    }`}
                  >
                    {item?.ratioProfitOrLoss
                      ? `${item?.ratioProfitOrLoss > 0 ? "+" : "$"}${Math.trunc(
                          item?.ratioProfitOrLoss
                        )}%`
                      : "N/A"}
                  </td>
                  <td className="py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center">
                    {item?.equity || 0}
                  </td>
                  <td className="py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center">
                    {item?.market || 0}
                  </td>
                  <td className="py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center">
                    ${item?.avgPrices}
                  </td>
                  <td className="py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center">
                    {item?.dividendYield} %
                  </td>
                </tr>
              ))}
            </tbody>
          ) : (
            <div className="h-[40vh] flex relative items-center justify-center w-full"></div>
          )}
        </table>

        {data?.length == 0 && (
          <div className="absolute w-full h-[100%] flex-col top-0 dark:bg-[#020617]  flex items-center justify-center">
            <span className="mb-4">
              <svg
                width="33"
                height="32"
                className="text-[#6D6D6D] dark:text-[#F1F5F9]"
                viewBox="0 0 33 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g clip-path="url(#clip0_346_49401)">
                  <path
                    opacity="0.96"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.0938 -0.03125C16.0104 -0.03125 16.9271 -0.03125 17.8438 -0.03125C24.1104 0.75255 28.5791 4.00255 31.25 9.71875C31.8935 11.2928 32.2997 12.9178 32.4688 14.5938C32.4688 15.5104 32.4688 16.4271 32.4688 17.3438C31.6849 23.6104 28.4349 28.0791 22.7188 30.75C21.1448 31.3935 19.5198 31.7997 17.8438 31.9688C16.9271 31.9688 16.0104 31.9688 15.0938 31.9688C8.82713 31.1849 4.35838 27.9349 1.6875 22.2188C1.044 20.6448 0.637746 19.0198 0.46875 17.3438C0.46875 16.4271 0.46875 15.5104 0.46875 14.5938C1.25255 8.32713 4.50255 3.85838 10.2188 1.1875C11.7928 0.543996 13.4178 0.137746 15.0938 -0.03125ZM15.7188 1.71875C20.7704 1.64017 24.8433 3.57767 27.9375 7.53125C31.0221 12.0242 31.5221 16.795 29.4375 21.8438C27.3232 26.1658 23.9169 28.8637 19.2188 29.9375C13.906 30.7847 9.41638 29.2534 5.75 25.3438C2.21296 20.9972 1.35879 16.1639 3.1875 10.8438C5.19391 6.10842 8.70431 3.1605 13.7188 2C14.3918 1.88476 15.0584 1.79101 15.7188 1.71875Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.958"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M10.7185 10.6563C12.1352 10.5837 12.854 11.2503 12.8748 12.6563C12.6048 13.7548 11.907 14.2444 10.781 14.125C9.72397 13.793 9.27604 13.0743 9.43729 11.9688C9.62522 11.2913 10.0523 10.8538 10.7185 10.6563Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.958"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M21.4063 10.6563C22.509 10.5247 23.2277 10.9831 23.5626 12.0313C23.705 13.1114 23.2571 13.8094 22.2188 14.125C21.1379 14.2671 20.44 13.8192 20.1251 12.7813C19.9958 11.7261 20.4228 11.0177 21.4063 10.6563Z"
                    fill="currentColor"
                  />
                  <path
                    opacity="0.955"
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.4688 18.3437C19.1034 18.1099 22.0617 19.3912 24.3438 22.1875C23.8988 22.5666 23.4404 22.9311 22.9688 23.2812C20.6344 20.5629 17.7178 19.615 14.2188 20.4375C12.5173 20.9649 11.1006 21.9129 9.96875 23.2812C9.49706 22.9311 9.03875 22.5666 8.59375 22.1875C10.3606 19.9809 12.6523 18.6996 15.4688 18.3437Z"
                    fill="currentColor"
                  />
                </g>
                <defs>
                  <clipPath id="clip0_346_49401">
                    <rect
                      width="32"
                      height="32"
                      fill="white"
                      transform="translate(0.5)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </span>
            <span className="text-[#6D6D6D] dark:text-[#94A3B8] text-[14px] font-normal">
              Seems empty portfolio. Add holdings by manual
            </span>
            <span className="text-[#6D6D6D] dark:text-[#94A3B8] text-[14px] font-normal">
              method or linking with brokerage account
            </span>
          </div>
        )}
      </div>
      <div className="w-full h-[10vh] paginatedContainer px-4 sm:flex-1 mb-2 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-[14px] font-[500] leading-5 font-[500] dark:text-[#64748B] text-[#6D6D6D]">
            Showing:
            <span> {data?.length > 0 ? startIndex() : "0"} </span>
            to
            <span> {data?.length > 0 ? endIndex() : "0"} </span>
            of
            <span> {data?.length} </span>
            holdings
          </p>
        </div>
        <div>
          {data?.length > 0 && (
            <nav className="paginationArea">
              <ul className="flex items-center border dark:border-[#1F2A37] rounded-[8px] h-10 text-base bg-[#f1f5f9] dark:bg-[#0f172a]">
                {/* <li>
        <button
          onClick={firstPage}
          disabled={currentPage === 1}
          className={`${
            currentPage === 1
              ? 'cursor-not-allowed text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px] border-r dark:border-[#1F2A37] h-10 flex items-center justify-center px-4 ms-0 leading-tight`}
        >
          First
        </button>
      </li> */}
                <li>
                  <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className={`${
                      currentPage === 1
                        ? "cursor-not-allowed  text-gray-400 dark:text-[#475569]"
                        : "cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]"
                    } text-[14px] h-10 flex items-center justify-center px-4 ms-0 leading-tight`}
                  >
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(
                  (pageNumber) => (
                    <li key={pageNumber}>
                      <button
                        onClick={() => changePage(pageNumber)}
                        className={`${
                          currentPage === pageNumber
                            ? "bg-[#E2E8F0]  dark:bg-[#F8FAFC] text-[#020617]"
                            : "cursor-pointer hover:bg-[#e6ecf4] dark:text-[#94A3B8] dark:hover:bg-[#1F2A37]"
                        } text-[14px]  h-10 flex items-center border-l borde-r dark:border-[#1F2A37] justify-center px-4 ms-0 leading-tight `}
                      >
                        {pageNumber}
                      </button>
                    </li>
                  )
                )}
                <li>
                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className={`${
                      currentPage === totalPages
                        ? "cursor-not-allowed  text-gray-400 dark:text-[#475569]"
                        : "cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]"
                    } text-[14px]  border-l border-r dark:border-[#1F2A37] h-10 flex items-center justify-center px-4 ms-0 leading-tight `}
                  >
                    Next
                  </button>
                </li>
                {/* <li>
        <button
          onClick={lastPage}
          disabled={currentPage === totalPages}
          className={`${
            currentPage === totalPages
              ? 'cursor-not-allowed text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px]  flex h-10 items-center justify-center px-4 ms-0 leading-tight `}
        >
          Last
        </button>
      </li> */}
              </ul>
            </nav>
          )}
        </div>
      </div>
    </div>
  );
}

export default RenderTable;
