export const sidebarContent = [
  {
    id: 1,
    name: "Dashboard",
    children: [],
    icon: "dashboard.svg",
    route: "/",
    isParent: "",
  },
  {
    id: 2,
    name: "Payout Calender",
    children: [],
    icon: "payout.svg",
    route: "/payout-calender",
    isParent: "",
  },
  {
    id: 3,
    name: "Portfolio",
    children: [],
    icon: "portfolio.svg",
    route: "/portfolio",
    isParent: "",
  },
  {
    id: 4,
    name: "Watchlist",
    children: [],
    icon: "watchlist.svg",
    route: "/watchlist",
    isParent: "",
  },
  {
    id: 5,
    name: "Ideas",
    children: [
      {
        id: 6,
        name: "Most Popular Stocks",
        children: [],
        icon: "",
        route: "/most-popular-stocks",
        isParent: "ideas",
      },
      {
        id: 7,
        name: "Most Popular Funds",
        children: [],
        icon: "",
        route: "/most-popular-funds",
        isParent: "ideas",
      },
      {
        id: 8,
        name: "Highest Dividend Grade",
        children: [],
        icon: "",
        route: "/highest-dividend-grade",
        isParent: "ideas",
      },
      {
        id: 9,
        name: "Monthly Dividend Payers",
        children: [],
        icon: "",
        route: "/monthly-dividend-payers",
        isParent: "ideas",
      },
      {
        id: 10,
        name: "Dividend Kings",
        children: [],
        icon: "",
        route: "/dividend-kings",
        isParent: "ideas",
      },
      {
        id: 11,
        name: "Dividend Aristocrats",
        children: [],
        icon: "",
        route: "/dividend-aristocrats",
        isParent: "ideas",
      },
      {
        id: 12,
        name: "Most Popular REITS",
        children: [],
        icon: "",
        route: "/most-popular-reits",
        isParent: "ideas",
      },
      {
        id: 13,
        name: "Covered Calls",
        children: [],
        icon: "",
        route: "/covered-calls",
        isParent: "ideas",
      },
    ],
    icon: "ideas.svg",
    isParent: "",
  },

  {
    id: 14,
    name: "Calculators",
    children: [
      {
        id: 15,
        name: "Income Goals",
        children: [],
        icon: "",
        route: "/income-goals",
        isParent: "calculators",
      },
      {
        id: 16,
        name: "Future Value",
        children: [],
        icon: "",
        route: "/future-value",
        isParent: "calculators",
      },
      {
        id: 17,
        name: "Fund Overlap",
        children: [],
        icon: "",
        route: "/fund-overlap",
        isParent: "calculators",
      },
      {
        id: 25,
        name: "Stock Locator",
        children: [],
        icon: "",
        route: "/stock-locator",
        isParent: "calculators",
      },
    ],
    icon: "calculator.svg",
    isParent: "",
  },
  {
    id: 18,
    name: "Goals",
    children: [],
    icon: "goal.svg",
    route: "/goals",
    isParent: "",
  },
  // {id:19,name:"Refer & Earns 30$+",children:[],icon:"refer.svg",route:'/refer-earn',isParent:""},
  {
    id: 20,
    name: "Help & Support",
    children: [],
    icon: "help.svg",
    route: "/support",
    isParent: "",
  },
  {
    id: 21,
    name: "Send Feedback",
    children: [],
    icon: "feedback.svg",
    route: "/feedback",
    isParent: "",
  },
];

export const miniAreaContent = [
  {
    id: 22,
    name: "Billing",
    children: [],
    icon: "billing.svg",
    route: "/billing",
    isParent: "",
  },
  {
    id: 23,
    name: "Settings",
    children: [],
    icon: "setting.svg",
    route: "/settings",
    isParent: "",
  },
  { id: 24, name: "Log out", children: [], icon: "logout.svg", isParent: "" },
];

export const tableDummyData = [
  {
    id: 1,
    ticker: "NU",
    img: "nu.png",
    price: 988.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 2,
    ticker: "IBM",
    img: "ibm.png",
    price: 18.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: -1.37,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 3,
    ticker: "TSLA",
    img: "tesla.png",
    price: 2.2,
    change: -1.2,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: -88.1,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 4,
    ticker: "MSFT",
    img: "msft.png",
    price: 9.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: -2.27,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 1,
    ticker: "AAPL",
    img: "nu.png",
    price: 9.5,
    change: -3.44,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 2,
    ticker: "CBC",
    img: "ibm.png",
    price: 18.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: -22.5,
    pl_percent: -5.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 1,
    ticker: "NU",
    img: "nu.png",
    price: 9.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 2,
    ticker: "IBM",
    img: "ibm.png",
    price: 18.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 3,
    ticker: "TSLA",
    img: "tesla.png",
    price: 2.2,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 4,
    ticker: "MSFT",
    img: "msft.png",
    price: 9.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 1,
    ticker: "AAPL",
    img: "nu.png",
    price: 9.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 2,
    ticker: "CBC",
    img: "ibm.png",
    price: 18.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 1,
    ticker: "NU",
    img: "nu.png",
    price: 9.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 2,
    ticker: "IBM",
    img: "ibm.png",
    price: 18.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 3,
    ticker: "TSLA",
    img: "tesla.png",
    price: 2.2,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 4,
    ticker: "MSFT",
    img: "msft.png",
    price: 9.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 1,
    ticker: "AAPL",
    img: "nu.png",
    price: 9.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
  {
    id: 2,
    ticker: "CBC",
    img: "ibm.png",
    price: 18.5,
    change: 0.17,
    change_percent: 1.82,
    shares: 6.77,
    pl_dollar: 93.5,
    pl_percent: 0.17,
    equity: 12166,
    market: 13132,
    avg_price: 186.43,
    div_yeild: 0.46,
  },
];

export const fundDummyData = [
  {
    id: 1,
    ticker: "NU",
    img: "nu.png",
    weight_in_qqq: 7.88,
    weight_in_spy: 7.88,
  },
  {
    id: 2,
    ticker: "IBM",
    img: "ibm.png",
    weight_in_qqq: 7.88,
    weight_in_spy: 7.88,
  },
  {
    id: 3,
    ticker: "TSLA",
    img: "tesla.png",
    weight_in_qqq: 7.88,
    weight_in_spy: 7.88,
  },
  {
    id: 4,
    ticker: "MSFT",
    img: "msft.png",
    weight_in_qqq: 7.88,
    weight_in_spy: 7.88,
  },
  {
    id: 5,
    ticker: "AAPL",
    img: "apple.png",
    weight_in_qqq: 7.88,
    weight_in_spy: 7.88,
  },
];

export const companyIcons = {
  NU: "nu.png",
  IBM: "ibm.png",
  TSLA: "tesla.png",
  MSFT: "msft.png",
  AAPL: "apple.png",
};
