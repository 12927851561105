import React from 'react'

function PriceArrow({title,price,svgcolor,titleClass,priceClass,width="19",height="17"}) {
  return (
    <div className='flex items-center flex-col'>
    <span className={`dark:text-[#94A3B8] text-black text-[12px] font-normal ${titleClass}`}>{title}</span>
    <span className={`dark:text-[#F1F5F9] text-black text-[12px] font-bold ${priceClass}`}>${price}</span>
    <span>
    <svg width={width} height={height} className={svgcolor} viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M7.76795 16C8.53775 17.3333 10.4623 17.3333 11.2321 16L18.5933 3.25C19.3631 1.91667 18.4008 0.25 16.8612 0.25H2.13878C0.599184 0.25 -0.363067 1.91667 0.406733 3.25L7.76795 16Z" fill="currentColor"/>
        </svg>
    </span>
        </div>
  )
}

export default PriceArrow