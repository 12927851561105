import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setSelectedRoute } from "../store/slices/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import { SET_SIDE_BAR,toggleFeedbackModal,toggleSettingModal } from "../store/slices/themeSlice";

function SidebarItems({ item }) {
  const [isOpen, setIsOpen] = useState(false);
  const currentRoute = useSelector((state) => state.theme.selectedRoute);
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const dropdownId = () => {
    return "dropdown-" + item.name.replace(/\s+/g, "-").toLowerCase();
  };

  const hasChildren = () => {
    return item.children && item.children.length > 0;
  };

  const handleToggleDropdown = () => {
   
    if(item?.name == 'Send Feedback'){
      setIsOpen(!isOpen);
      dispatch(SET_SIDE_BAR(false));
      dispatch(toggleFeedbackModal())
    }else{
      setIsOpen(!isOpen);
      if (item?.route && location.pathname !== item?.route) {
      const animatedElement = document.getElementById("animatedElement");
      animatedElement.classList.toggle("translate-animation");
      setTimeout(() => {
        dispatch(SET_SIDE_BAR(false));
      }, 300);
      dispatch(setSelectedRoute(item));
      localStorage.setItem("selectedRoute", JSON.stringify(item));
      navigate(item?.route);
    }
  }   
  };

  return (
    <li>
      <div
        onClick={handleToggleDropdown}
        className={`flex items-center  cursor-pointer w-full text-[14px] p-2 justify-between pr-4 text-base font-[400]  text-[#020617] dark:text-[#F1F5F9] transition duration-75 rounded-[10px] group ${
          currentRoute?.id == item?.id ? "bg-[#E2E8F0] dark:bg-[#020617]" : "hover:bg-[#E2E8F0] dark:hover:bg-[#141d31]"
        }`}
      >
        <div className="flex items-center space-x-3">
          {item?.icon && (
            <div>
              <img
                src={require(`../assets/${item?.icon}`)}
                className="w-5 h-5"
              />
            </div>
          )}
          {hasChildren() ? (
            <div className="flex  space-x-4 text-[14px] items-center text-left">
              <span>{item.name}</span>
            </div>
          ) : (
            <div className={`flex  text-left items-center text-[14px]  pr-2 ${item?.isParent?'text-[#9EA3AB]':''}`}>
              <span>{item?.isParent?'- ':''}{item.name}</span>
            </div>
          )}
        </div>

        {hasChildren() && (
          <span>
            <svg
              className={`w-3 h-3 ${isOpen ? "rotate-180" : ""}`}
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 10 6"
            >
              <path
                stroke="#4A545E"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1"
                d="m1 1 4 4 4-4"
              />
            </svg>
          </span>
        )}
      </div>
      {hasChildren() && isOpen && (
        <ul id={dropdownId()} className="py-2 space-y-2 pl-[2rem]">
          {item.children.map((child, i) => (
            <SidebarItems
              key={i}
              item={child}
              className="pl-4 whitespace-nowrap"
            />
          ))}
        </ul>
      )}
    </li>
  );
}

export default SidebarItems;
