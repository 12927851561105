import React from 'react'
import IdeasTable from '../components/table/IdeasTable'

function MostPopularFunds() {
  const columns = [{key:'ticker',value:'Fund'},{key:'price',value:'Price'},{key:'divYield',value:'Div Yield'},{key:'frequency',value:'Frequency'},{key:'divAmount',value:'Div Amount'}]
  const data = [
    {ticker:'META',image:'meta.png',price:172.70,divYield:0.56,frequency:'Quarterly',divAmount:0.56},
    {ticker:'AMZN',image:'amazon.png',price:172.70,divYield:0.56,frequency:'Quarterly',divAmount:0.56},
    {ticker:'AAPL',image:'apple.png',price:172.70,divYield:0.56,frequency:'Monthly',divAmount:0.56},
    {ticker:'IBM',image:'ibm.png',price:172.70,divYield:0.56,frequency:'Quarterly',divAmount:0.56},
    {ticker:'TSLA',image:'tesla.png',price:172.70,divYield:0.56,frequency:'Monthly',divAmount:0.56},
    {ticker:'NVDA',image:'nvda.png',price:172.70,divYield:0.56,frequency:'Monthly',divAmount:0.56},
]
  const detail = 'This compilation showcases the 50 most popular funds among The IVY Dividends community, providing insight into the investment preferences and trends within the group.'

  return (
    <div className='w-full h-full pt-[1rem]'>
      <IdeasTable title='Most Popular Funds' columns={columns} data={data} detail={detail} />
    </div>
  )
}

export default MostPopularFunds