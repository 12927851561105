import React,{useState,useEffect} from 'react'
import ModalBtn from '../Button/ModalBtn'
import infoIcon from '../../assets/info.svg'
import checkedIcon from '../../assets/checked.svg'
import unCheckedIcon from '../../assets/unchecked.svg'


function Notifications() {
  const [isChange,setIsChange] = useState(false)
  const [isReset,setIsReset] = useState(false)
  const [selectedNotification,setSelectedNotification] = useState([{name:'News & Announcements',id:1},{name:'New Dividend Amounts',id:2}])
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const notificationList = [
    {name:'News & Announcements',id:1},
    {name:'New Dividend Amounts',id:2},
    {name:'New Dividend Payment Dates',id:3},
    {name:'New Dividend Ex-Dates',id:4},
  ]
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleReset = ()=>{
    setIsChange(false)
    setSelectedNotification([{name:'News & Announcements',id:1},{name:'New Dividend Amounts',id:2}])
  }

  const handleToggle = (item)=>{
    const isPresent = selectedNotification.some(val=>val.id == item.id)
    if(isPresent){
      let removeFromArr = selectedNotification.filter((val)=> val.id != item.id)
      setSelectedNotification(removeFromArr)
    }else{
      let addInArr = [...selectedNotification,item]
      setSelectedNotification(addInArr)
    }
    setIsChange(true)
    setIsReset(true)
  }

  return (
    <div className='w-full h-full relative'>

        <div className='px-4 py-3 dark:bg-[#0f172a] bg-[#E2E8F0]'>
          <span className='label dark:text-[#F1F5F9]'>Email</span>
        </div>

        <div>
          {notificationList.map((item,i)=>(
             <div key={i} className='w-full flex items-center px-4 dark:border-[#1E293B] pb-4 pt-[1.5rem] border-[#cecece70] border-b justify-between'>
             <div className='flex items-center space-x-2'>
               <span className='dark:text-[#94A3B8] font-normal text-[14px]'>{item.name}</span>
               <span className='pt-1'>
                 <img src={infoIcon} />
               </span>
             </div>
             <div>
                <span onClick={()=>handleToggle(item)}>
                   <img src={selectedNotification?.some(val=>val.id == item.id) ? checkedIcon : unCheckedIcon} />
                </span>               
             </div>
           </div>
     
          ))}
        </div>


        <div className='absolute bottom-6 flex items-center justify-end space-x-3 right-4'>
           <div>
              <ModalBtn onPress={handleReset} title={screenWidth < 400 ? 'Reset':"Reset Changes"} customClass={` ${isReset ? 'pointer-events-auto cursor-pointer' : 'opacity-50 pointer-events-none cursor-default'} !font-medium dark:bg-[#080e20] bg-[#d8d8d8] text-[#383838] dark:text-[#797d88]`} />
            </div>               
           <div>
              <ModalBtn title={screenWidth < 400 ?'Save':"Save Changes"} customClass={`${isChange ? 'pointer-events-auto cursor-pointer':'opacity-50 pointer-events-none cursor-default'} !font-semibold !bg-[#FFac39] text-black`} />
            </div>               
      </div>
    </div>
  )
}

export default Notifications