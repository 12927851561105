import React, { useState } from 'react'
import GoalComp from "../components/GoalComp"

function Goals() {
  const [active, setActived] = useState(false)
  const GoalMission = [
    {
      name: 'Monthly',
      isAct: false
    },
    {
      name: 'Yearly',
      isAct: false
    },
    {
      name: 'Diversification',
      isAct: false
    },
  ]
  return (
    <div className='flex flex-wrap h-full w-full'>
      {
        GoalMission?.map((item)=>
        <div className='p-4'>
          {/* <GoalComp header={"Income"} _isActive={active} _setActive={setActived} /> */}
          <GoalComp item={item} header={item?.name} _isActive={active} _setActive={setActived} />
        </div>
      )
      }
    </div>
  )
}

export default Goals