import React, { useEffect, useState } from "react";
import { ReactComponent as Logo } from "../../assets/lightLogo.svg";
import { verifyEmail } from "../../ApiControler";
import { useNavigate } from "react-router-dom";

export default function VerifyEmail({ email, name, setState }) {
  const [seconds, setSeconds] = useState(0);

  const navigate = useNavigate();

  useEffect(() => {
    if (seconds > 0) {
      const timerId = setTimeout(() => {
        setSeconds(seconds - 1);
      }, 1000);
      return () => clearTimeout(timerId);
    } 
  }, [seconds]);

  const verify_Email = async () => {
    if (seconds === 0) {
      try {
        const response = await verifyEmail(email);
        if (response?.success) {
          setSeconds(30);
          
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  useEffect(()=>{
    verify_Email()
    setSeconds(30)
  },[])

  return (
    <div className="space-y-3 flex flex-col">
      <div className="flex flex-col w-full space-y-4 px-6 pb-4 rounded-xl bg-white">
        <div className="flex justify-center items-center py-2 w-full border-b border-b-slate-400">
          <Logo className="h-10" />
        </div>
        <div className="flex flex-col bg-emerald-100 w-full text-green-700 text-[12px] text-justify p-4 rounded-lg">
          <span>{`Hello ${name ? name : ""},`}</span>
          <br></br>
          <p>{`We're thrilled to have you on board as part of our Smart Traders
      community. We've sent a verification email to `}</p>
      <span className="text-center font-medium text-[15px]">{`${email}.`}</span>
          <p>{`
      Simply click the verification link in that email to activate your account
      and start exploring our platform's features.`}</p>
        </div>
        {seconds > 0 ? (
          <span
          className="text-slate-400 text-[12px]"
          >{"Resend link in: "+seconds + "s"}</span>
        ) : (
          <span
            className="cursor-pointer text-blue-600 text-[12px]"
            onClick={() => {
              verify_Email();
            }}
          >
            Resend link
          </span>
        )}
      </div>
      <div className="items-end justify-end flex w-full text-[12px]">
        <span className="text-right cursor-pointer">
          {"back to "}
          <span  onClick={()=>{
            setState(false)
            navigate("/")
          }} className="text-right text-blue-600">
            {" login"} </span>
        </span>
      </div>
    </div>
  );
}
