import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import IbmLogo from "../../assets/images/ibm.png";
import ReactApexChart from "react-apexcharts";

export default function Weights({ goal, tabName, datas, setSData }) {

  const [tOtherValue, setOther] = useState(0);
  const [rValue, setRemain] = useState(0);
  const [data, setData] = useState(datas);
  const darkMode = useSelector((state) => state.theme.darkMode);
  const [totalValue, setTotalValue] = useState(parseFloat(goal));
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      //   width: 600,
      type: "donut",
    },
    dataLabels: {
      enabled: false,
    },
    colors: [],
    tooltip: {
      enabled: false,
    },
    legend: {
      show: false,
    },
    stroke: {
      show: true,
      width: 1, // Remove the spacing between segments
    },
  });

  useEffect(() => {
    setOptions({
     ...options,
     stroke:{
      ...options.stroke,
      colors:'transparent'
     }
    })
 }, [])

  useEffect(() => {
    const checkVal = datas?.map((e) => e?.value);
    setSeries((prev) => [...checkVal]);
    datas?.forEach((item) => options?.colors?.push(item?.color));
    changeSeries();
  }, [tOtherValue]);

  useEffect(() => {
    datas?.forEach((item) =>
      options?.legend?.customLegendItems?.push(item?.symbolName?.toUpperCase())
    );
  }, []);

  const changeSeries = () => {
    series?.forEach((item, i) => {
      item = (rValue * item) / tOtherValue;
    });
  };

  

  const adjustValues = (index, newValue) => {
    const newData = [...data];
    // console.log()
    const remainingValue = totalValue - newValue;
    const totalOtherValues = newData.reduce(
      (sum, item, i) => (i !== index ? sum + item.value : isNaN(sum)? 1 : sum),
      0
    );

    
    setOther(totalOtherValues);
    setRemain(remainingValue);

    newData[index].value = newValue;

    newData.forEach((item, i) => {
      if (i !== index) {
        const xyz = (remainingValue * item.value) / totalOtherValues;
        item.value = isNaN(xyz) ? 1 : xyz
      }
    });
    setData(newData);
    setSData(newData);
  };

  const OnChangeEventTriggerd = (newValue, index) => {
    adjustValues(index, newValue);
  };

  const mapData = (item, index) => {
    return (
      <div className="flex-col py-3">
        <div className="flex items-center justify-between">
          <div className="flex space-x-2 items-center">
            <img src={IbmLogo} className="h-7 w-7" />
            <span className="text-[13px]">
              {item?.symbolName?.toUpperCase()}
            </span>
          </div>
          <div className="flex-col flex">
            <span className="text-[13px]">
              {"Shares " +
                parseInt(
                  item.value / parseFloat(item?.annualDividend)
                ).toLocaleString('en-US') +
                " (" +
                parseInt((item.value / goal) * 100).toLocaleString('en-US') +
                "%)"}
            </span>
            <span className="text-[13px]">
              {"$ " +
                (
                  (item.value / parseFloat(item?.annualDividend)) *
                  parseFloat(item?.closingPrice)
                ).toLocaleString('en-US')}
            </span>
          </div>
        </div>
        <div className="items-center flex h-8 px-4">
          <Slider
            value={item?.value}
            onChange={(val) => OnChangeEventTriggerd(val, index)}
            min={0}
            max={goal}
            trackStyle={{ backgroundColor: item?.color, height: 10 }}
            railStyle={{
              backgroundColor: darkMode ? "#3d3b4c" : "#eaf1fc",
              height: 10,
            }}
            handleStyle={{
              borderColor: item?.color,
              height: 20,
              width: 20,
              backgroundColor: item?.color,
            }}
          />
        </div>
      </div>
    );
  };

  return (
    <div className="flex h-full w-full">
      <div className="flex flex-col h-full w-full">
        <span className="text-[18px]">{tabName}</span>
        <p className="text-justify text-[10px] text-slate-500 mt-3">
          Drag the slider to dynamically change how <br></br>much of each stock
          you need to reach your <br></br>dividend goal
        </p>
        <div className="overflow-y-auto p-5">
          {datas?.map((item, index) => mapData(item, index))}
        </div>
      </div>
      <div className="flex h-full w-full items-center justify-center">
        <ReactApexChart
          options={options}
          series={series}
          type="donut"
          width={380}
        />
        <div className={`flex justify-center items-center text-center absolute w-12 h-20 flex-col right-42 top-18`}>
          <p className="text-[28px] font-bold h-9">{totalValue.toLocaleString('en-US')}</p>
          <p className="flex text-[14px] text-[#94A3B8]">Goal</p>
        </div>
      </div>
    </div>
  );
}
