import React from 'react'

function NewsCard({item}) {

  const {headline,summary,image,datetime}= item
  

  return (
    <div className='w-[22rem] rounded-[10px] h-[27rem] dark:bg-[#0f172a] shadow-lg bg-[#f8fafc]'>
       <div className='w-full h-[50%] rounded-tl-[10px] rounded-tr-[10px] -bg-red-200'>
        <img src={image} className='w-full rounded-tl-[10px] rounded-tr-[10px] h-full object-cover' />
      </div> 
       <div className='w-full h-[48%] space-y-2 p-4 rounded-bl-[10px] rounded-br-[10px]'>
            <div className='dark:text-[#94A3B8] text-[12px] font-normal'>{new Date()?.toISOString().split('T')[0]}</div>
            <div className='dark:text-[#F1F5F9] text-[14px] font-semibold'>{headline}</div>
            <div className='text-[#94A3B8] font-normal text-[12px]'>
                {/* {summary.length > 200 ?  summary.slice(0, 200) + "...": summary} */}
            </div>

            <div className='flex items-center text-[#F88F00] cursor-pointer space-x-2 w-fit hover:underline'>
              <span className=' font-medium text-[14px]'> Read more </span> 
              <span className='pt-[5px]'>
              <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1.33316 6H10.6665" stroke="#F88F00" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5.99994 1.3335L10.6666 6.00016L5.99994 10.6668" stroke="#F88F00" stroke-width="1.6" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

              </span>
            </div>
      </div> 
    </div>
  )
}

export default NewsCard