import React, { useEffect, useState } from "react";
import Carousel from "../components/Carousel";
import PrimaryButton from "../components/Button";
import TextInput from "../components/TextInput";
import { ReactComponent as Apple } from "../assets/apple.svg";
import { ReactComponent as Google } from "../assets/google.svg";
import { ReactComponent as Logo } from "../assets/lightLogo.svg";
import { createSession, loginApi } from "../ApiControler";
import { useNavigate } from "react-router-dom";
import VerifyEmail from "../components/VerifyEmail";

export default function Login() {
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  const [isError, setError] = useState("");
  const [netError, setNetError] = useState("");
  // const [email, setEmail] = useState("");
  // const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("taha.ali@dimensionalsys.com");
  const [password, setPassword] = useState("qwerty123!");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [isSecure, setSecure] = useState(false);
  const platform = window.navigator.platform;
  const deviceID = Math.floor(1000000000 + Math.random() * 9000000000);
  const [myIP, setIP] = useState("");
  const [user, setUser] = useState(false);

  const handleSubmit = async () => {
    setNetError("");
    setLoader(true);
    const payload = {
      applicationName: "IvyDividends",
      deviceBrand: platform,
      deviceId: deviceID?.toString(),
      deviceName: platform + " " + deviceID?.toString(),
      deviceType: platform,
      ipAddress: myIP,
    };
    try {
      const response = await loginApi(email, password);
      if (response?.success) {
       
        setName(response?.data?.name)
        if(!response?.data?.emailVerify){
          setUser(true)
        } else handleCreateSession(response?.token, payload);
      }
    } catch (error) {
      setLoader(false);
      setError(error?.response?.data?.message);
      console.log("Failed", error?.response?.data?.message);
    }
  };

  const handleCreateSession = async (token, sessionData) => {
    try {
      const response = await createSession(token, sessionData);
      if (response?.success) {
        localStorage.setItem("token", response?.token);
        localStorage.setItem("sessionID", response?._id);
        setLoader(false);
        // navigate("/");
        window.location.reload();
      }
    } catch (error) {
      setLoader(false);
      setNetError("Check your network connection");
      console.log("Failed", error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetch("https://api.ipify.org?format=json")
      .then((response) => response.json())
      .then((data) => {
        console.log("ipAddress:", data?.ip);
        setIP(data.ip);
      })
      .catch((error) => {
        console.error("Error fetching IP:", error);
      });
  }, []);

  return (
    <div className="flex w-full h-full bg-[#F1F5F9]">
    <div className="flex w-full h-full justify-center items-center">
      {/*   */}
     {
      user ? 
      <div className="flex h-[40rem] w-3/6 rounded-xl justify-center items-center">
      <VerifyEmail email={email} setState={setUser} name={name}/>
      </div>
      :
      <div className="flex h-[40rem] w-4/6 rounded-xl">
        <div className="flex flex-col items-center justify-center space-y-4 w-full h-full">
          <Logo className="w-full h-12" />
          <div className="flex flex-col rounded-xl bg-white justify-center items-center space-y-4 p-6">
            <p className="text-[18px] font-inter font-semibold text-[#1E293B]">
              Sign In
            </p>
            <PrimaryButton
              isDisable={true}
              title={"Continue with Apple"}
              mainClass={
                "border border-[#CBD5E1] w-[360px] h-[44px] cursor-pointer hover:opacity-80"
              }
              image={<Apple className="w-4 h-4" />}
            />
            <PrimaryButton
              isDisable={true}
              title={"Continue with Google"}
              mainClass={
                "border border-[#CBD5E1] w-[360px] h-[44px] cursor-pointer hover:opacity-80"
              }
              image={<Google className="w-4 h-4" />}
            />
            <div className="flex w-full h-8 justify-center items-center space-x-4">
              <div className="flex w-full border-b border-b-[#E2E8F0]" />
              <p className="text-[14px] font-bold font-inter text-[#94A3B8]">
                OR
              </p>
              <div className="flex w-full border-b border-b-[#E2E8F0]" />
            </div>
            <TextInput
              _value={email}
              _onChange={(text) => {
                setEmail(text.target.value);
                setError("");
              }}
              label={"Your Email Address"}
              type="email"
              // placeholder={"email@domain.com"}
              mainClass={"w-[360px]"}
              inputClass={isError && " border-red-400"}
              _error={isError}
            />
            <TextInput
              _value={password}
              _onChange={(text) => {
                setPassword(text.target.value);
                setError("");
              }}
              label={"Password"}
              type={!isSecure ? "password" : "text"}
              // placeholder={"email@domain.com"}
              mainClass={"w-[360px]"}
              inputClass={isError && " border-red-400"}
              _error={isError}
            />
            <span className=" text-[11px] text-right w-full text-blue-500 cursor-pointer hover:opacity-50" onClick={() => navigate("/forget-password")}>
              Forget Password?
            </span>
            <div className="w-full flex flex-col">
              <span className=" text-[11px] w-full text-red-400 ">
                {netError}
              </span>
              <PrimaryButton
                isDisable={
                  password?.length > 1 && emailRegex.test(email)
                    ? true
                    : false
                }
                isLoader={isLoader}
                title={!isLoader ? "Login with Email" : "Loging in..."}
                _click={() => {
                  handleSubmit();
                }}
                mainClass={` w-[360px]   ${
                  emailRegex.test(email) && password && !isLoader
                    ? "bg-[#FFAA36] cursor-pointer hover:opacity-80"
                    : "bg-[#fbd8a4] text-slate-500"
                }`}
              />
            </div>
          </div>
          <p className="text-[14px] font-inter text-[#94A3B8] text-center mt-4">
            Don't have an account?{" "}
            <span className="text-[#2563EB] cursor-pointer hover:opacity-50" onClick={() => navigate("/signup")}>
              Sign up
            </span>
          </p>
        </div>
      {/* <SignUp /> */}
      </div>
     }
    </div>
    <div className="w-full h-full sm:flex hidden ">
      <Carousel />
    </div>
  </div>
  );
}
