import React,{useEffect,useState} from 'react'
import ModalContainer from './ModalContainer'
import ModalBtn from '../Button/ModalBtn'
import {toggleSettingModal,toggleSessionInfoModal} from '../../store/slices/themeSlice'
import { useDispatch } from 'react-redux'

function SessionInfoModal() {
    const [info,setInfo] = useState(null)
    const dispatch = useDispatch()
    useEffect(() => {
    let x = JSON.parse(localStorage.getItem('selectedSession'))
    if(x){
        setInfo(x)
    }
    }, [])

    const handleGoBack = ()=>{
        localStorage.removeItem('selectedSession')
        localStorage.setItem("currentSetting","Sessions")
        dispatch(toggleSessionInfoModal())
        dispatch(toggleSettingModal())
    }

  return (
    <div className='w-[30rem] h-[29rem]'>
        <ModalContainer onClose={handleGoBack} title='Session Info' customClass='w-full h-full relative'>
        
        <div className='w-full h-[19rem] p-4'>
                <div className='w-full h-full rounded-[12px] space-y-6 p-4 bg-[#F1F5F9] shadow-lg  dark:bg-[#0F172A]'>
                    <div className='flex space-x-3'>
                        <div className='w-2/12'>
                        <div className='w-16 h-16 rounded-full bg-[#E2E8F0] dark:bg-[#020617] flex items-center justify-center'>
                            <img src={require(`../../assets/${info?.image ? info?.image : 'apple.svg'}`)} />
                            </div>
                        </div>
                        <div className='w-10/12'>
                        <div className='space-y-[2px]'>
                        <div  className='text-[16px] dark:text-[#F1F5F9] cursor-pointer font-medium'>{info?.name}</div>
                        <div className='label font-normal dark:font-light  dark:text-[#F1F5F9]'>{info?.detail}</div>
                        <div className='text-[12px] font-light dark:text-[#94A3B8] flex items-center space-x-2'>
                        <span>{info?.location}</span>
                        <span>
                        <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="2" cy="2" r="2" fill="#94A3B8"/>
                        </svg>
                        </span>
                        <span>
                            {info?.time}
                        </span>
                        </div>
                        </div>
                        </div>
                    </div>
                    <div className='flex items-center pb-2 justify-between dark:border-[#1E293B] border-[#b5b5b5] border-b border-dashed'>
                    <span className='label'>
                        Application
                    </span>
                    <span className='label dark:text-[#F1F5F9]'>
                        {info?.detail}
                    </span>

                </div>
                <div className='flex items-center pb-2 justify-between dark:border-[#1E293B] border-[#b5b5b5] border-b border-dashed'>
                    <span className='label'>
                        IP Address
                    </span>
                    <span className='label dark:text-[#F1F5F9]'>
                        123.567.891.134
                    </span>

                </div>
                <div className='flex items-center pb-2 justify-between dark:border-[#1E293B] border-[#b5b5b5] border-b border-dashed'>
                    <span className='label'>
                    Location
                    </span>
                    <span className='label dark:text-[#F1F5F9]'>
                        {info?.location}
                    </span>

                </div>
                </div>
              
        </div>
    
        <div className='absolute bottom-6 w-full px-4'>
           <div className='w-full'>
              <ModalBtn onPress={handleGoBack} title="Go Back" customClass={`pointer-events-auto w-full text-center cursor-pointer !font-normal dark:bg-[#0f172a] bg-[#d8d8d8] text-[#383838] dark:text-[#F1F5F9]`} />
            </div>                            
            </div>
        </ModalContainer>
    </div>
  )
}

export default SessionInfoModal