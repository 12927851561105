import React from 'react'

function IconCircle({children,handleClick,customClass}) {
    const handlePress = ()=>{
        handleClick()
    }
  return (
    <div onClick={handlePress} className={`w-10 h-10 dark:bg-[#0F172A] dark:hover:bg-[#182238] hover:bg-[#ced8e5] flex items-center justify-center bg-[#E2E8F0] cursor-pointer rounded-full ${customClass}`}>
        {children}
    </div>
  
  )
}

export default IconCircle