import React,{useState,useEffect} from 'react'
import MainCard from '../components/CardBox/MainCard'
import { useSelector } from 'react-redux'
import IconCircle from '../components/iconCircle'
import ReadFaq from '../components/HelpSupport/ReadFaq'
import SupportArticle from '../components/HelpSupport/SupportArticle'
import BaseModal from '../components/BaseModal'
import ModalContainer from '../components/Modal/ModalContainer'
import ModalBtn from '../components/Button/ModalBtn'


function HelpSupport() {
  const theme = useSelector(state=>state.theme.darkMode)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [email,setEmail] = useState('')
  const [subject,setSubject] = useState('')
  const [heading,setHeading] = useState('Help & Support')
  const [platform,setPlatform] = useState('Web App')
  const [openPlatform,setOpenPlatform] = useState(false)
  const platformList = ["Web App","Mobile App"]
  const [text, setText] = useState('');
  const [openModal,setOpenModal] = useState(false)
  const [modalHeading,setModalHeading] = useState('')
  const [detail,setDetail] = useState('Explore our extensive collection of help articles for solutions to common issues. For personalized assistance, submit a support request and our team will be happy to assist you')
  const [faq,setFaq] = useState([
    {id:1,question:'What is IVY Dividends?',answer:'IVY Dividends is a mobile application designed to assist users in tracking, managing, and optimizing their dividend income from various investments such as stocks, ETFs, and mutual funds. It offers tools to monitor dividend payouts, reinvest earnings, and analyze portfolio performance.'},
    {id:2,question:'How does IVY Dividends work?',answer:'IVY Dividends is a mobile application designed to assist users in tracking, managing, and optimizing their dividend income from various investments such as stocks, ETFs, and mutual funds. It offers tools to monitor dividend payouts, reinvest earnings, and analyze portfolio performance.'},
    {id:3,question:'Is IVY Dividends compatible with my brokerage account?',answer:'IVY Dividends is a mobile application designed to assist users in tracking, managing, and optimizing their dividend income from various investments such as stocks, ETFs, and mutual funds. It offers tools to monitor dividend payouts, reinvest earnings, and analyze portfolio performance.'},
  ])
  const [article,setArticle] = useState([
    {image: theme ? 'smile.svg' : 'smilelight.svg',title:'General',list:['Getting Started with IVY Dividends','Navigating the IVY Dividends Interface','Understanding IVY Dividends Features','Troubleshooting Common Issues in IVY Dividends']},
    {image: theme ? 'bill.svg' : 'billLight.svg',title:'Billing',list:['Managing Your Subscription in IVY Dividends','Updating Payment Information','Billing Inquiries and FAQ','Cancelling Your IVY Dividends Subscription']},
    {image: theme ? 'brokerage.svg' : 'brokeragelight.svg',title:'Brokerages',list:['Linking Your Brokerage Account to IVY Dividends','Supported Brokerages and Compatibility','Troubleshooting Brokerage Account Connection Issues','Adding Multiple Brokerage Accounts']},
    {image: theme ? 'account.svg' : 'accountlight.svg',title:'Accounts',list:['Creating and Managing Your IVY Dividends Account','Resetting Your Password','Updating Account Information','Deleting Your IVY Dividends Account']},

  ])

  const supportDetail = [
    {image: theme ? 'faq.svg': 'faqlight.svg',title:'Read Our FAQs',mainHeading:'Frequently Asked Questions',detail:'Find quick answers to some of the most commonly-asked questions about the IVY Dividends'},
    {image: theme ? 'support.svg' : 'supportlight.svg',mainHeading:'Support Articles',title:'Read Support Articles',detail:"Search all our existing knowledgebase articles and you may just be able to find what you're looking for"},
    {image:'feature.svg',title:'Request a New Feature',detail:'Have an idea that you think all users can benefit from? Request a new feature'},
    {image:theme?'request.svg':'requestlight.svg',title:'Support Request',detail:"If you're unsure about our features or have any problems, just send a support request."},
  ]

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const handleChange = (event) => {
    if (event.target.value.length <= 255) {
      setText(event.target.value);
    }
  };

  const handleSubmitModal = ()=>{
    if(modalHeading == 'Support Request'){
      console.log("support request==>",subject,platform,text)
    }else{
      console.log("new feature==>",email,text)
    }
    handleCloseModal()
  }

  const handleCloseModal = ()=>{
    setOpenModal(false)
    setSubject('')
    setText('')
    setEmail('')
  }

  return (
    <>    
     {heading != 'Help & Support' && 
      <div className=''>
        <IconCircle handleClick={()=>{setHeading('Help & Support');setDetail('Explore our extensive collection of help articles for solutions to common issues. For personalized assistance, submit a support request and our team will be happy to assist you')}}>
          <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.735 8.43381H1.73499" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8.73499 15.4338L1.73499 8.43381L8.73499 1.43381" stroke="#94A3B8" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </IconCircle>
      </div>}
    <div className='w-full h-fit flex justify-center relative'>
      <div className={`${screenWidth < 1330 ? 'w-full':'w-10/12'} h-fit flex flex-col gap-y-2 !mt-[1rem] items-center justify-center`}>
        <span className={`${screenWidth < 457 ? 'text-[1.5rem]':'text-[2rem]'} text-center font-semibold dark:text-[#F8FAFC]`}>{heading}</span>
        <span className={`text-[14px] text-[#94A3B8] font-normal  text-center ${screenWidth < 768 ? 'w-10/12':'w-8/12'} `}>
            {detail}
        </span>


        {heading=='Help & Support' &&
          <>
          <div className='gap-6 mt-[1.5rem] w-full flex flex-wrap items-center justify-center'>
          {supportDetail?.slice(0,2)?.map((item,index) => (
              <div onClick={()=>{setHeading(item.mainHeading);setDetail(item.detail)}} key={index} className='w-[26rem] h-[15rem]'>
               <MainCard customClass='W-full h-full px-6 border-none flex-col cursor-pointer hover:shadow-lg dark:hover:bg-[#131d33]  text-center gap-y-3 flex items-center justify-center p-0'>
                <div>
                  <img src={require(`../assets/${item.image}`)} />
                </div>
                <div className='dark:text-[#F1F5F9] text-black font-normal text-[18px]'>
                  {item.title}
                </div>
                <div>
                  <span className='text-[#94A3B8] text-[14px] font-normal'>{item.detail}</span>
                </div>
               </MainCard>
              </div>
              ))}
        </div>
        
        <div className='gap-6 mt-[1.5rem]  w-full flex flex-wrap items-center justify-center'>
          {supportDetail?.slice(2,4)?.map((item,index) => (
              <div onClick={()=>{setModalHeading(item.title);setOpenModal(true)}} key={index} className='w-[26rem] h-[15rem]'>
               <MainCard customClass='W-full h-full px-6 cursor-pointer hover:shadow-lg dark:hover:bg-[#131d33] border-none flex-col text-center gap-y-3 flex items-center justify-center p-0'>
                <div>
                  <img src={require(`../assets/${item.image}`)} />
                </div>
                <div className='dark:text-[#F1F5F9] text-black font-normal text-[18px]'>
                  {item.title}
                </div>
                <div>
                  <span className='text-[#94A3B8] text-[14px] font-normal'>{item.detail}</span>
                </div>
               </MainCard>
              </div>
              ))}
        </div>
        </>}

        
        {heading == 'Frequently Asked Questions' &&  <ReadFaq faq={faq}/>}

        {heading == 'Support Articles' &&  <SupportArticle article={article} />}

        {openModal && 
          <BaseModal>
              <ModalContainer title={modalHeading} customClass={`${screenWidth < 441 ? 'w-full': screenWidth < 521 ? '!w-[25rem]' : '!w-[30rem]'} min-h-[20rem] dark:bg-[#020617]`} onClose={handleCloseModal}>
               <div className='w-full h-full py-4'>
                <div className='w-full flex items-center border-b dark:border-[#1E293B] px-4 justify-between py-4'>
                  <span className='dark:text-[#94A3B8] text-[14px] font-normal'>
                     {modalHeading == 'Request a New Feature' ? 'Email' : 'Subject'}
                  </span>
                  <div>
                  {modalHeading == 'Request a New Feature' ?
                      <input placeholder='john123@example.com' value={email} onChange={(e)=>{setEmail(e.target.value)}} className='bg-transparent dark:text-[#94A3B8] text-[14px] font-normal border-none focus:outline-none'/>
                    :
                      <input placeholder='Type here...' value={subject} onChange={(e)=>{setSubject(e.target.value)}} className='bg-transparent text-right dark:text-[#94A3B8] text-[14px] font-normal border-none focus:outline-none'/>
}
                    </div>
                </div>
                
                 {modalHeading == 'Support Request' &&
                 <div className='w-full flex items-center border-b dark:border-[#1E293B] px-4 justify-between py-4'>
                  <span className='dark:text-[#94A3B8] text-[14px] font-normal'>
                     Platform
                  </span>

                  <div className='flex items-center space-x-2 py-2 relative'>
                    <span className='dark:text-[#F1F5F9] text-[14px] font-normal'>{platform}</span>
                    <span onClick={()=>{setOpenPlatform(!openPlatform)}} className='p-2 rounded-full mt-1 hover:bg-[#E2E8F0] dark:hover:bg-[#1E293B] cursor-pointer'>
                      <svg width="14" height="8" className='dark:text-[#F1F5F9]' viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                      </svg>
                    </span>

                    {openPlatform &&
                    <div className='absolute z-30 py-2 bg-[#d7dde5] w-[10rem] rounded-[4px] max-h-[15rem] dark:bg-[#1b2233] bg-[#e1e5ee] overflow-y-auto no-scrollbar top-[2.5rem] right-1'>
                      {platformList?.map((item,i)=>(
                          <div onClick={()=>{setPlatform(item);setOpenPlatform(false)}} key={i} className='py-2 px-3 text-[15px] hover:bg-[#c5ccd6] dark:hover:bg-[#161c2b] cursor-pointer'>
                              {item}
                          </div>
                      ))}
                  </div>
}

                  </div>

                  
                </div>
                }
                
                
                <div className='w-full border-b dark:border-[#1E293B] px-4 py-6'>
                  <textarea  onChange={handleChange} value={text} maxLength="255" style={{ resize: 'none' }} placeholder='Write message here....' className='bg-transparent overflow-none w-full h-[3rem] dark:text-[#94A3B8] text-[14px] font-normal border-none focus:outline-none' />
                  <span className='w-full flex justify-end text-[#94A3B8] text-[14px] font-normal'>
                  {`${text?.length}/255`}
                  </span>
                </div>
                <div className='w-full flex justify-end gap-3 pt-[1.5rem] pb-[0.5rem] px-4'>
                <ModalBtn title='Go Back' onPress={handleCloseModal}/>
                <ModalBtn title='Submit Request' onPress={handleSubmitModal} customClass='!bg-[#FFB34B] !text-[#020617] !font-semibold' />
                </div>
               </div>
              </ModalContainer>
          </BaseModal>
        }


      </div>
    </div>
    </>
  )
}

export default HelpSupport