import React from 'react'

function MainCard({children,customClass}) {
  return (
    <div className={`w-full h-full border rounded-[8px] p-6 overflow-y-auto no-scrollbar bg-[#F1F5F9] border-[#CBD5E1] dark:bg-[#0F172A] dark:border-[#1F2A37] ${customClass}`}>
        {children}
    </div>
  )
}

export default MainCard