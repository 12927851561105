import React from 'react'
import IdeasTable from '../components/table/IdeasTable'

function HighestDividendGrade() {
  const columns = [{key:'ticker',value:'Stock/Ticker Name'},{key:'price',value:'Price'},{key:'change',value:'%Change'},{key:'divYield',value:'Div Yield'},{key:'divAmount',value:'Div Amount'},{key:'payout',value:'Payout Ratio'},{key:'grade',value:'Grade'}]
  const data = [
    {ticker:'TSLA',image:'tesla.png',price:172.70,change:1.15,divYield:0.56,divAmount:0.56,payout:14.91,grade:39},
    {ticker:'IBM',image:'ibm.png',price:172.70,change:-1.03,divYield:0.56,divAmount:0.56,payout:14.91,grade:89},
    {ticker:'AMZN',image:'amazon.png',price:172.70,change:1.15,divYield:0.56,divAmount:0.56,payout:14.91,grade:59},
    {ticker:'META',image:'meta.png',price:172.70,change:-1.25,divYield:0.56,divAmount:0.56,payout:14.91,grade:10},
    {ticker:'AAPL',image:'apple.png',price:172.70,change:1.24,divYield:0.56,divAmount:0.56,payout:14.91,grade:89},
    {ticker:'NVDA',image:'nvda.png',price:172.70,change:1.15,divYield:0.56,divAmount:0.56,payout:14.91,grade:50},
]
  const detail = 'This list shows the top 50 tickers from our database with a dividend grade of 80 or higher, ranked from highest to lowest. It offers a comprehensive view of the top dividend-grade stocks within our dataset.'

  return (
    <div className='w-full h-full pt-[1rem]'>
      <IdeasTable title='Highest Dividend Grade' columns={columns} data={data} detail={detail} />
    </div>
  )
}

export default HighestDividendGrade