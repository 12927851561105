import React, { useState,useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { useSelector } from "react-redux";

function BarChart({series}) {
  const theme = useSelector((state) => state.theme.darkMode);


  useEffect(() => {
    setOptions({
      ...options,
      dataLabels:{
        ...options.dataLabels,
        style: {
          ...options.dataLabels.style,
          colors: theme ? ["#FFFFFF"] : ["#000000"]
        }
      },
      xaxis:{
        ...options.xaxis,
        labels:{
          ...options.xaxis.labels,
          style: {
            ...options.xaxis.labels.style,
            colors: theme ? "#FFFFFF" : '#000000'
          }
        }
      },
      yaxis:{
        ...options.yaxis,
        labels:{
          ...options.yaxis.labels,
          style: {
            ...options.yaxis.labels.style,
            colors: theme ? "#FFFFFF" : '#000000'
          }
        }
      }
    })
 }, [theme])

  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 350,
      toolbar: { show: false },
      
    },
    plotOptions: {
      bar: {
        dataLabels: {
          position: 'top' // Show data labels on top of each bar
        },
        borderRadius: 4,
        colors: {
          ranges: [{
            from: 0,
            to: Infinity,
            color: '#F88F00' // Yellow color for all bars
          }]
        }
      }
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        const formattedVal = (val / 100).toLocaleString('en-IN', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }).replace('.', ',');
          return formattedVal + 'B'; // Display the values with 'B' suffix
      },
      offsetY: -20,
      style: {
        fontSize: '12px',
        colors: ["#FFFFFF"]
      }
    },
    xaxis: {
      categories: ['2016', '2017', '2018', '2019', '2020', '2021', '2022', '2023', '2024'],
      labels: {
        offsetY: 0,
        style: {
            colors: '#FFFFFF', // Set the color of the x-axis values to white
            fontSize: '12px'
          }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      tooltip: {
        enabled: false
      }
    },
    yaxis: {
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        style:{
          colors:'gray'
        }
      }
    },
    tooltip: {
        enabled: false
    },
    grid: {
        show: false // Disable grid lines
      },
  });

  return (
    <div id="chart" className="w-full h-full">
        <ReactApexChart options={options} series={series} type="bar" width="100%" height={350} />
    </div>
  );
}

export default BarChart;


