import React, { useState, useEffect } from "react";
import AddPortfolio from "../components/AddPortfolio";
import MainCard from "../components/CardBox/MainCard";
import HeadWidthLine from "../components/HeadWidthLine";
import PieChart from "../components/charts/PieChart";
import PortfolioTable from "../components/table/PortfolioTable";
import PortfolioChart from "../components/PortfolioChart";
import SmallCard from "../components/SmallCard";
import RenderTable from "../components/table/RenderTable";
import Loader from "../assets/Loader/IVD.gif"
import {getOneUserPortolio, getPortfolio, GetUserOnePortfolio} from "../ApiControler/index";
import { ReactComponent as Foldersvg} from "../assets/Folder.svg"

function Portfolio() {
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [currentPortfolio,setCurrentPortfolio] = useState([{portfolioName:"All Portfolios",ticker:[{symbol: 'Data 1',data: [0,0,0,0,0,0,0],color: '#1C64F2'}],cardHolding:[]}])
const [isloader, setLoader] = useState(true)
const [errMsg, setErr] = useState("");
const [data, setData] = useState([]);
const [ticker, setTicker] = useState([]);
const [openPorrtfolioModal, setOpenPorrtfolioModal] = useState(false);
  // const [currentPortfolio, setCurrentPortfolio] = useState({});
  const [view, setView] = useState("grid");
  const [holdingBtnPressed, setHoldingBtnPressed] = useState(false);
  const tablulatedHolding = [
    {},
    {
      ticker: "TSLA",
      price: "$187.42",
      change: 0.17,
      change_percent: 1.82,
      img: "tesla.png",
      portolio_percent: 49,
    },
    {
      ticker: "NVDA",
      price: "$187.42",
      change: 0.17,
      change_percent: 1.82,
      img: "nvda.png",
      portolio_percent: 33,
    },
    {
      ticker: "META",
      price: "$187.42",
      change: 0.17,
      change_percent: 1.82,
      img: "nu.png",
      portolio_percent: 79,
    },
    {
      ticker: "COIN",
      price: "$187.42",
      change: 0.17,
      change_percent: 1.82,
      img: "coin.png",
      portolio_percent: 19,
    },
    {
      ticker: "IBM",
      price: "$187.42",
      change: 0.17,
      change_percent: 1.82,
      img: "ibm.png",
      portolio_percent: 96,
    },
    {
      ticker: "Apple",
      price: "$187.42",
      change: 0.17,
      change_percent: 1.82,
      img: "apple.png",
      portolio_percent: 12,
    },
  ];
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const tabulated = [
    { name: "Number of Holdings", value: "83" },
    { name: "Largest Holding", value: "COST ($3,230.38)" },
    { name: "Smallest Holding", value: "ETH ($0.00)" },
    { name: "Total Sectors", value: "11" },
    { name: "Largest Sector", value: "Technology" },
    { name: "Smallest Sector", value: "Misc" },
    { name: "Total Industries", value: "46" },
    { name: "Largest Industry", value: "Semiconductors" },
    { name: "Smallest Industry", value: "Trucking" },
  ];
  const dividendIncome = [
    { name: "Dividend Stocks", value: "66" },
    { name: "Non Dividend Stocks", value: "17" },
    { name: "Highest Yield", value: "PBR" },
    { name: "Lowest Yield", value: "NVDA" },
    { name: "Highest Income", value: "PBR ($268.38)" },
    { name: "Lowest Income", value: "PWR ($0.38)" },
  ];
  const handleSelectedPortfolio = (val) => {
    // console.log("Current portfolio :) ",val)
    setCurrentPortfolio([val]);
  };


  // const get_portfolio = async () => {
  //   const token = localStorage.getItem("token");
  //   if (!errMsg) {
  //     // setLoader(true);
  //     try {
  //       const response = await getPortfolio(token);
  //       console.log("Get Portfolio list: ", response?.data);
        
  //         }
  //       }
  //     } catch (e) {
  //       console.log("get portfolio error", e);
  //     }
  //   } else {
  //     navigate("/");
  //   }
  // };


  

  const getPrtFolioData = async() =>{
    setLoader(true)
    const token = localStorage.getItem("token")
    try{
const  res = await getPortfolio(token)
if(res?.data){
  console.log("res---> ",res?.data)
  setData(res?.data)
  setLoader(false)
}
    }catch(error){  
      console.log(error)
    }


    

    const closePortfolio = (val) =>{
       console.log("value",val)
    }





  }


  const getTickerData = async () =>{
    const token = localStorage.getItem("token")
    console.log(currentPortfolio?.portfolioName, )
     try{
      if(currentPortfolio?.portfolioName !== "All Portfolios"){
        const responce = await getOneUserPortolio(token,currentPortfolio?._id)
        if(responce?.data){
           console.log("tickerdata---->", responce?.data?.ticker);
           setTicker(responce?.data?.ticker)
        }
      }
        

     }catch(error){
      console.log(error)
     }
  }




  useEffect(() => {
  getPrtFolioData();
  }, []);

  
useEffect(() => {
  // getTickerData();
}, [currentPortfolio]);


  const tickerHandler = (e) => {
    console.log(e)
  }




  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handlePressHolding = () => {
    setHoldingBtnPressed(true);
    setTimeout(() => {
      setHoldingBtnPressed(false);
    }, 1000);
  };


  const handlegetPortFolio = (value)=>{
      setLoader(true);
      if(value){
        getPrtFolioData()
      }
  }


  console.log("ttt", data)


console.log("specipic--->", currentPortfolio)



  const HoldingBtn = ({ num }) => {
    return (
      <div
        onClick={handlePressHolding}
        className="w-[24rem] space-x-3 h-[4rem]  cursor-pointer smallCardContainer p-3 rounded-[8px] flex items-center justify-center border-[#E2E8F0] hover:bg-[#f2f2f2] dark:border-[#1F2A37] bg-[#f8fafc] border dark:hover:bg-[#131b2d] dark:bg-[#0F172A] dark:bg-[#0f172a]"
      >
        <span>
          <svg
            width="21"
            height="20"
            className="dark:text-[94A3B8]"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 4.16663V15.8333"
              stroke="currentColor"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.6665 10H16.3332"
              stroke="currentColor"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span>Add Holdings {num}</span>
      </div>
    );
  };

  return (


    <>
    {
      isloader ? 

      (<div className="w-full h-full flex items-center justify-center">
        <img src={Loader} className='w-12 h-12'/>
      </div>) :


(


<div className="w-full h-fit">
      <div className="mb-[2rem] mt-2">
        <AddPortfolio
          handleSelect={handleSelectedPortfolio}
          holdingBtnPressed={holdingBtnPressed}
          setAllPortfolios={setAllPortfolios}
          handelTicker={tickerHandler}
          portFolioHandler={handlegetPortFolio}
          PortfolioData={data}
          openModal={openPorrtfolioModal}
          // handleCloseModal={handleClose}
          // handelCloseModal={ha}
        />
      </div>
      {currentPortfolio[0]?.portfolioName == "All Portfolios" ? (
         <>
           {
          data.length < 1 ?
             (
               <div className="w-full h-[40rem] flex items-center justify-center overflow-hidden">
                <div className="w-full h-full flex flex-col items-center !justify-center ">
                <Foldersvg height="100" width="70"/>
                <p>You have not created any portfolio yet. </p>
                <p>    Click here to <span  className="underline cursor-pointer font-semibold"  onClick={()=>{setOpenPorrtfolioModal(!openPorrtfolioModal)}}> add your portfolio. </span></p>
                
                </div>

                
               </div>

             ):
             (<div className="w-full h-full ">
             <div
               className={`w-full flex  items-center gap-4 ${
                 screenWidth < 1000 ? "cardMainContainer" : ""
               }`}
             >
               <div
                 className={`w-6/12 space-y-3 h-[60vh] ${
                   screenWidth < 1000 ? "cardSubContainer" : ""
                 }`}
               >
                 <HeadWidthLine title="Sector Diversification" />
                 <MainCard customClass="border-none  relative flex items-center justify-center">
                   <div className="w-full h-[90%] pieHeight">
                     <PieChart />
                   </div>
                   <div className="absolute dark:text-[#F8FAFC] font-bold text-[2rem] pieText pb-[1rem]">
                     $70,456
                   </div>
                 </MainCard>
               </div>
               <div
                 className={`w-6/12 space-y-3 h-[60vh] ${
                   screenWidth < 1000 ? "cardSubContainer" : ""
                 }`}
               >
                 <HeadWidthLine title="Industry Diversification" />
                 <MainCard customClass="border-none relative flex items-center justify-center">
                   <div className="w-10/12 h-[90%] pieHeight">
                     <PieChart series={[44, 55, 13, 33, 50, 33, 11]} />
                   </div>
                   <div className="absolute dark:text-[#F8FAFC] font-bold text-[2rem] pieText pb-[1rem]">
                     $80,456
                   </div>
                 </MainCard>
               </div>
             </div>
   
             <div
               className={`w-full flex my-[6rem] items-center gap-y-[5rem] gap-4 ${
                 screenWidth < 1000 ? "cardMainContainer" : ""
               }`}
             >
               <div
                 className={`w-6/12 space-y-3 h-[60vh] ${
                   screenWidth < 1000 ? "cardSubContainer" : ""
                 }`}
               >
                 <HeadWidthLine title="Tabulated Breakdown" />
                 <MainCard customClass="border-none">
                   {tabulated.map((item, index) => (
                     <div
                       key={index}
                       className="flex py-3 items-center border-b border-dashed border-b-[#E2E8F0] dark:border-b-[#1E293B] justify-between"
                     >
                       <span className="text-[#94A3B8] text-[14px] font-normal">
                         {item.name}
                       </span>
                       <span className="text-[#94A3B8] text-[14px] font-normal">
                         {item.value}
                       </span>
                     </div>
                   ))}
                 </MainCard>
               </div>
               <div
                 className={`w-6/12 space-y-3 h-[60vh] ${
                   screenWidth < 1000 ? "cardSubContainer" : ""
                 }`}
               >
                 <HeadWidthLine title="Holding Breakdown" />
                 <MainCard customClass="border-none relative flex items-center justify-center">
                   <div className="w-10/12 h-[90%]">
                     <PieChart series={[50, 22, 74, 23, 35, 44, 78]} />
                   </div>
                   <div className="absolute dark:text-[#F8FAFC] font-bold text-[2rem] pieText pb-[1rem]">
                     $66,456
                   </div>
                 </MainCard>
               </div>
             </div>
   
             <div
               className={`w-full flex  items-center gap-y-[5rem] gap-4 ${
                 screenWidth < 1000 ? "cardMainContainer" : ""
               }`}
             >
               <div
                 className={`w-6/12 space-y-3 h-[60vh] ${
                   screenWidth < 1000 ? "cardSubContainer" : ""
                 }`}
               >
                 <HeadWidthLine title="Dividend Income Breakdown" />
                 <MainCard customClass="border-none">
                   {dividendIncome.map((item, index) => (
                     <div
                       key={index}
                       className="flex py-3 items-center border-b border-dashed border-b-[#E2E8F0] dark:border-b-[#1E293B] justify-between"
                     >
                       <span className="text-[#94A3B8] text-[14px] font-normal">
                         {item.name}
                       </span>
                       <span className="text-[#94A3B8] text-[14px] font-normal">
                         {item.value}
                       </span>
                     </div>
                   ))}
                 </MainCard>
               </div>
               <div
                 className={`w-6/12 space-y-3 h-[60vh] ${
                   screenWidth < 1000 ? "cardSubContainer" : ""
                 }`}
               >
                 <HeadWidthLine title="Individualized Income Breakdown" />
                 <MainCard customClass="border-none relative flex items-center justify-center">
                   <div className="w-10/12 h-[90%]">
                     <PieChart series={[44, 55, 50, 10, 8, 33, 31]} />
                   </div>
                   <div className="absolute dark:text-[#F8FAFC] font-bold text-[2rem] pieText pb-[1rem]">
                     $80,456
                   </div>
                 </MainCard>
               </div>
             </div>
   
             <div className="w-full mt-[6rem] mb-[2rem]">
               <HeadWidthLine title="Tabulated Holding Breakdown" />
             </div>
   
             <div className="w-full h-full">
               <PortfolioTable data={tablulatedHolding} />
             </div>
           </div>)
           }
         
         
         
         </>
      ) : (




        <div className="w-full h-full">
          <div className=" h-[70vh]">
            <PortfolioChart
              _id={currentPortfolio?._id}
              asset={
                currentPortfolio?.totalPrices
                  ? currentPortfolio?.totalPrices
                  : "0.00"
              }
              change={Math.floor(currentPortfolio?.totalProfitOrLoss)}
              changeper={Math.floor(currentPortfolio?.ratioProfitOrLoss)}
              showGoTo={false}
              ticker={currentPortfolio?.ticker}
            />
          </div>

          <div className="w-full flex items-center mt-[3rem] mb-[1rem] justify-between">
            <div className="flex items-center space-x-2">
              <span>Current Holdings ({currentPortfolio?.ticker?.length})</span>
              <span>
                <svg
                  width="12"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 1.5L6 6.5L11 1.5"
                    stroke="#020617"
                    stroke-width="1.66667"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </span>
            </div>
            <div className="flex items-center space-x-2">
              <div
                onClick={() => {
                  setView("table");
                }}
                className={`w-10 h-10 cursor-pointer flex items-center justify-center rounded-full ${
                  view == "table"
                    ? "bg-[#F88F00]"
                    : "bg-[#E2E8F0] dark:bg-[#0F172A]"
                } `}
              >
                <svg
                  width="20"
                  height="20"
                  className={`${
                    view == "table"
                      ? "text-[#020617]"
                      : "text-[#020617] dark:text-[#F8FAFC]"
                  } `}
                  viewBox="0 0 20 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.5 17.5H7.49996V8.33333H12.5V17.5ZM14.1666 17.5V8.33333H18.3333V16.6667C18.3333 16.8877 18.2455 17.0996 18.0892 17.2559C17.9329 17.4122 17.721 17.5 17.5 17.5H14.1666ZM5.83329 17.5H2.49996C2.27895 17.5 2.06698 17.4122 1.9107 17.2559C1.75442 17.0996 1.66663 16.8877 1.66663 16.6667V8.33333H5.83329V17.5ZM18.3333 6.66667H1.66663V3.33333C1.66663 3.11232 1.75442 2.90036 1.9107 2.74408C2.06698 2.5878 2.27895 2.5 2.49996 2.5H17.5C17.721 2.5 17.9329 2.5878 18.0892 2.74408C18.2455 2.90036 18.3333 3.11232 18.3333 3.33333V6.66667Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div
                onClick={() => setView("grid")}
                className={`w-10 h-10 cursor-pointer flex items-center justify-center rounded-full ${
                  view == "grid"
                    ? "bg-[#F88F00]"
                    : "bg-[#E2E8F0] dark:bg-[#0F172A]"
                } `}
              >
                <svg
                  width="16"
                  height="16"
                  className={`${
                    view == "grid"
                      ? "text-[#020617]"
                      : "text-[#020617] dark:text-[#F8FAFC]"
                  } `}
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M2.16654 7.16667H5.49988C6.41654 7.16667 7.16654 6.41667 7.16654 5.5V2.16667C7.16654 1.25 6.41654 0.5 5.49988 0.5H2.16654C1.24988 0.5 0.499878 1.25 0.499878 2.16667V5.5C0.499878 6.41667 1.24988 7.16667 2.16654 7.16667ZM2.16654 15.5H5.49988C6.41654 15.5 7.16654 14.75 7.16654 13.8333V10.5C7.16654 9.58333 6.41654 8.83333 5.49988 8.83333H2.16654C1.24988 8.83333 0.499878 9.58333 0.499878 10.5V13.8333C0.499878 14.75 1.24988 15.5 2.16654 15.5ZM8.83321 2.16667V5.5C8.83321 6.41667 9.58321 7.16667 10.4999 7.16667H13.8332C14.7499 7.16667 15.4999 6.41667 15.4999 5.5V2.16667C15.4999 1.25 14.7499 0.5 13.8332 0.5H10.4999C9.58321 0.5 8.83321 1.25 8.83321 2.16667ZM10.4999 15.5H13.8332C14.7499 15.5 15.4999 14.75 15.4999 13.8333V10.5C15.4999 9.58333 14.7499 8.83333 13.8332 8.83333H10.4999C9.58321 8.83333 8.83321 9.58333 8.83321 10.5V13.8333C8.83321 14.75 9.58321 15.5 10.4999 15.5Z"
                    fill="currentColor"
                  />
                </svg>
              </div>
            </div>
          </div>

          {view == "grid" ? (
            <div className="w-full flex items-center max-h-[40vh] overflow-y-auto no-scrollbar gap-4  pb-[2rem] flex-wrap">
              {ticker?.map((item, index) => (
                <SmallCard item={item} index={index} />
              ))}
              <>
                <HoldingBtn />
              </>
            </div>
          ) : (
            <div className="pb-[2rem]">
              {/* <RenderTable data={currentPortfolio?.ticker} portfolioId={currentPortfolio._id} /> */}
              <RenderTable portfolioId={currentPortfolio._id} />
            </div>
          )}
        </div>
      )}
    </div>


)
    }
    
    
    
    
    </>
    
  );
}

export default Portfolio;
