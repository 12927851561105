import BarChart2 from "../charts/BarChart2";
import VennDiagram from "../charts/VennDiagram";

export default function Summary() {


  return (
    <div className="flex h-full w-full gap-4 dark:text-[#F8FAFC]">
        <div className="w-full relative h-fit bg-[#F1F5F9] dark:bg-[#0F172A] rounded-[14px] pt-2 pl-2">
          <span>No. Companies that overlap</span>
          <VennDiagram/>
          <div className="absolute space-x-40 bottom-5 right-36 text-[20px]">
            <span className="text-[#F87171]">SPY</span>
            <span className="text-[#60A5FA]">QQQ</span>
          </div>
        </div>

        <div className="w-full h-fit bg-[#F1F5F9] dark:bg-[#0F172A] rounded-[14px] pt-2 pl-2">
            <span>Sector drift (QQQ minus SPY)</span>
            <div className="flex h-full w-96 items-center justify-center">
                <BarChart2/>
            </div>
        </div>
    </div>
  );
}
