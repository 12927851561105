import React from "react";
import { ReactComponent as Logo } from "../assets/lightLogo.svg";
import PasswordComp from "../components/PasswordComp"

export default function ResetPassword() {
  return (
    <div className="flex h-full w-full bg-[#F1F5F9] items-center justify-center">
      <div className="flex flex-col h-[30rem]  w-[40rem] bg-white rounded-lg">
        <div className="w-full border-b border-b-slate-200 justify-center items-center flex py-1"><Logo className="w-12 h-12" /></div>
        <div className="h-[25rem] py-4 px-6">
            <PasswordComp/>
        </div>
      </div>
    </div>
  );
}
