import React, { useState } from "react";
import AppleImage from "../assets/images/apple.png";
import AppleLightImage from "../assets/images/appleLight.png";
import PrimaryButton from "../components/Button";
import CardBox from "../components/CardBox";
import { useSelector } from "react-redux";
import LocatorTable from "../components/table/LocatorTable";

function StockLocator() {
  const darkMode = useSelector((state) => state.theme.darkMode);

  const tableColumns = [
    { key: "company", value: "Company" },
    { key: "currentPrice", value: "Current Price" },
    { key: "marketCap", value: "Market Cap" },
    { key: "exchange", value: "Exchange" },
    { key: "weight", value: "Weight" },
  ];

  const [tableData, setTableData] = useState([
    {
      company: "AAPU",
      img: "apple.png",
      currentPrice: "$29.00",
      marketCap: "-",
      exchange: "NASDAQ",
      weight: "20.68%",
    },
    {
      company: "AAPX",
      img: "tesla.png",
      currentPrice: "$26.00",
      marketCap: "-",
      exchange: "BATS",
      weight: "69.55%",
    },
    {
      company: "SPY.NEO",
      img: "apple.png",
      currentPrice: "$29.00",
      marketCap: "599.00B",
      exchange: "NASDAQ",
      weight: "20.68%",
    },
    {
      company: "AAPZ",
      img: "tesla.png",
      currentPrice: "$26.00",
      marketCap: "-",
      exchange: "BATS",
      weight: "69.55%",
    },
    {
      company: "APPL.USD",
      img: "ibm.png",
      currentPrice: "$29.00",
      marketCap: "459.00B",
      exchange: "NASDAQ",
      weight: "20.68%",
    },
    {
      company: "AAQW",
      img: "tesla.png",
      currentPrice: "$26.00",
      marketCap: "-",
      exchange: "BATS",
      weight: "69.55%",
    },
    {
      company: "SXLK.SE",
      img: "apple.png",
      currentPrice: "$29.00",
      marketCap: "549.00B",
      exchange: "LSE",
      weight: "20.68%",
    },
    {
      company: "AAQY",
      img: "amazon.png",
      currentPrice: "$26.00",
      marketCap: "987.00B",
      exchange: "BATS",
      weight: "69.65%",
    },
  ]);

  const [addedSymbolData, setAddedData] = useState([
    {
      id: 1,
      logo: AppleImage,
      symbolName: "AAPL",
      companyName: "Apple Inc",
      companyDetails: "SPDR S&P 500 ETF",
      value: "194.48",
      increament: "0.17",
      increamentinPercent: "1.82",
      time: "11:14 PM",
      date: "03/11/2024",
      belongsTo: "NYSE ARCA",
      currency: "$USD",
      amount_of_funds: 490,
    },
  ]);

  const childData = (item) => {
    return (
      <div className="flex h-full w-full">
        <div className="flex flex-col p-4 space-y-1 h-full w-full border-r border-r-slate-200 dark:border-r-slate-700">
          <img
            src={darkMode ? AppleLightImage : AppleImage}
            className={"h-9 w-9"}
          />
          <span className="text-[18px] font-bold">{item.companyName}</span>
          <div className="flex w-full space-x-1">
            <span className="text-[14px]">+{item.value}</span>
            <span className="text-[#34D399] text-[14px]">
              +{item.increament} (+{item.increamentinPercent})
            </span>
          </div>
          <p className="text-[#94A3B8] text-[12px]">
            {item.time} {item.date}
          </p>
          <p className="text-[#94A3B8] text-[12px]">
            {item.belongsTo} | {item.currency}
          </p>
        </div>
        <div className="flex flex-col p-4 h-full w-full items-end justify-center text-[#34D399] text-[38px]">
          <span>{item.amount_of_funds}</span>
          <span className="text-[#94A3B8] text-[12px]">
            Funds contain {item.companyName}
          </span>
        </div>
      </div>
    );
  };
  return (
    <div className="flex flex-col h-full w-full p-8 px-20 gap-4">
      <div className="flex w-full h-48 justify-between">
        <div className="flex flex-col h-full w-full justify-center space-y-3">
          <span className="w-64 text-[36px] font-bold font-inter bg-gradient-to-r from-[#206CFF] to-[#F88F00] bg-clip-text text-transparent">
            Stock Locator
          </span>
          <div className="flex space-x-3 items-center">
            <span className="text-[14px] text-[#94A3B8] font-inter">
              See which ETFs hold a position in the provided Stock
            </span>
          </div>
        </div>
        <div className="flex flex-col h-full w-full space-y-4 justify-center items-end">
          <div className="flex items-center w-64 dark:text-white space-x-3 px-2 py-2 cursor-pointer text-sm dark:bg-[#0f172a] dark:border-[#334155] bg-[#F8FAFC] border border-[#D1D1D1] rounded-[10px]">
            <svg
              width="20"
              height="20"
              className="dark:text-[#94A3B8]"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                stroke="currentColor"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5 17.5L13.875 13.875"
                stroke="currentColor"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <input
              className="bg-transparent text-[#181818] dark:text-[white] placeholder:text-[#6D6D6D] placeholder:dark:text-[#94A3B8] placeholder:text-[14px] text-[14px] focus:outline-none w-full "
              placeholder="Search Ticker"
            />
          </div>
          <PrimaryButton
            isDisable={addedSymbolData?.length > 0 ? true : false}
            title={"Locate Stocks"}
            mainClass={` w-64 ${
              addedSymbolData?.length > 0
                ? "bg-[#FFAA36] font-semibold text-black cursor-pointer hover:opacity-80"
                : "bg-[#fbd8a4] dark:bg-[#f2b868] text-slate-600"
            }`}
          />
        </div>
      </div>
      <div className="w-full h-48 p-1">
        <div className="flex h-full w-full justify-between">
          {addedSymbolData?.map((item) => {
            return (
              <CardBox
                mainClass={"w-full h-full"}
                childData={childData(item)}
              />
            );
          })}
          {/* <CardBox mainClass={"w-full h-full "} childData={childData}/>
            <CardBox mainClass={"w-full h-full"} childData={childData}/> */}
        </div>
      </div>
      <div className="w-full h-60">
        <LocatorTable columns={tableColumns} data={tableData} />
      </div>
    </div>
  );
}

export default StockLocator;
