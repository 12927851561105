import React, { useEffect, useLayoutEffect } from 'react';
import * as d3 from 'd3';
import * as venn from 'venn.js';

function VennDiagram() {

    const testFunc = () =>{
        const sets = [
            { sets: ["SPY"], size: 30 },
            { sets: ["QQQ"], size: 30 },
            { sets: ["SPY", "QQQ"], size: 6 }
          ];
      
          const textColors = {
              SPY: "#F87171", 
              QQQ: "#60A5FA", 
              "SPY,QQQ": "gray" 
          };
      
          const vennColors = {
              SPY: "none", 
              QQQ: "none", 
              "SPY,QQQ": "none"
          };
      
          const borderColors = {
              SPY: "#F87171", 
              QQQ: "#2563EB",  
              "SPY,QQQ": ""
          };
      
          const vennTexts = {
              SPY: "418",
              QQQ: "16",
              "SPY,QQQ": "84"
          }
      
          const buildVenn = venn.VennDiagram().width(460).height(370);
          
          // Build the Venn diagram
          const vennChart = d3.select("#venn").datum(sets).call(buildVenn);
      
          // Set colors for each area
          vennChart.selectAll("path")
            .style("fill-opacity", 1)
            .style("mix-blend-mode", "none")
            .style("fill", function(d) { return vennColors[d.sets.join(",")]; })  // Set border color
            .style("stroke", function(d) { return borderColors[d.sets.join(",")]; })  // Set border color
            .style("stroke-width", "2px");  // Set border width
      
          // Add and style text labels
          vennChart.selectAll("text")
            .style("fill", function(d) { return textColors[d.sets.join(",")]; })
            .style("font-size", "32px")
            .style("font-weight", "400")
            .attr("dy", ".35em")
            .text(function(d) {
              return vennTexts[d.sets.join(",")];  // Customize text label
            });
    }

  useEffect(() => {
    testFunc()

  }, []);

  return (
    <>
      <div id="venn" className='flex justify-center items-center h-full'></div>
    </>
  );
}

export default VennDiagram;
