import React, { useState, useEffect } from "react";
import NewsCard from "../NewsCard";
import { getNews } from "../../ApiControler";
import { useNavigate, useLocation } from "react-router-dom";
function News() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const symbol = queryParams.get("symbol");
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await getNews(token, symbol);
     
      setNews(response.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching news:", error);
      setLoading(false);
    }
  };



  
  useEffect(() => {
    fetchData();
    
  }, []);

  // const [news, setNews] = useState([
  //   {
  //     title:
  //       "Apple Stock Surges to New Heights Amid Record Sales and Strong Earnings Report",
  //     detail:
  //       "Apple's stock (AAPL) reaches unprecedented heights as the tech giant reports record-breaking sales figures and impressive earnings for the quarter. With robust demand for its latest products and services, investors are bullish on Apple's future prospects, driving the stock price to soar.",
  //     publish: "12 Mar, 2024",
  //     imgae: "img1.png",
  //   },
  //   {
  //     title: "Apple Stock Hits All-Time High on Strong Q4 Performance",
  //     detail:
  //       "Apple's stock (AAPL) reaches an unprecedented peak as the company reports stellar performance in its fourth-quarter earnings. With impressive sales figures across its product lines and promising projections for the future, investor confidence in Apple's trajectory drives the stock price to its highest level yet",
  //     publish: "13 Mar, 2024",
  //     imgae: "img2.png",
  //   },
  //   {
  //     title:
  //       "Apple Stock Surges to New Heights Amid Record Sales and Strong Earnings Report",
  //     detail:
  //       "Apple's stock (AAPL) reaches unprecedented heights as the tech giant reports record-breaking sales figures and impressive earnings for the quarter. With robust demand for its latest products and services, investors are bullish on Apple's future prospects, driving the stock price to soar.",
  //     publish: "14 Mar, 2024",
  //     imgae: "img1.png",
  //   },
  //   {
  //     title:
  //       "Apple Stock Surges to New Heights Amid Record Sales and Strong Earnings Report",
  //     detail:
  //       "Apple's stock (AAPL) reaches unprecedented heights as the tech giant reports record-breaking sales figures and impressive earnings for the quarter. With robust demand for its latest products and services, investors are bullish on Apple's future prospects, driving the stock price to soar.",
  //     publish: "12 Mar, 2024",
  //     imgae: "img1.png",
  //   },
  //   {
  //     title: "Apple Stock Hits All-Time High on Strong Q4 Performance",
  //     detail:
  //       "Apple's stock (AAPL) reaches an unprecedented peak as the company reports stellar performance in its fourth-quarter earnings. With impressive sales figures across its product lines and promising projections for the future, investor confidence in Apple's trajectory drives the stock price to its highest level yet",
  //     publish: "13 Mar, 2024",
  //     imgae: "img2.png",
  //   },
  //   {
  //     title:
  //       "Apple Stock Surges to New Heights Amid Record Sales and Strong Earnings Report",
  //     detail:
  //       "Apple's stock (AAPL) reaches unprecedented heights as the tech giant reports record-breaking sales figures and impressive earnings for the quarter. With robust demand for its latest products and services, investors are bullish on Apple's future prospects, driving the stock price to soar.",
  //     publish: "14 Mar, 2024",
  //     imgae: "img1.png",
  //   },
  //   {
  //     title:
  //       "Apple Stock Surges to New Heights Amid Record Sales and Strong Earnings Report",
  //     detail:
  //       "Apple's stock (AAPL) reaches unprecedented heights as the tech giant reports record-breaking sales figures and impressive earnings for the quarter. With robust demand for its latest products and services, investors are bullish on Apple's future prospects, driving the stock price to soar.",
  //     publish: "12 Mar, 2024",
  //     imgae: "img1.png",
  //   },
  //   {
  //     title: "Apple Stock Hits All-Time High on Strong Q4 Performance",
  //     detail:
  //       "Apple's stock (AAPL) reaches an unprecedented peak as the company reports stellar performance in its fourth-quarter earnings. With impressive sales figures across its product lines and promising projections for the future, investor confidence in Apple's trajectory drives the stock price to its highest level yet",
  //     publish: "13 Mar, 2024",
  //     imgae: "img2.png",
  //   },
  //   {
  //     title:
  //       "Apple Stock Surges to New Heights Amid Record Sales and Strong Earnings Report",
  //     detail:
  //       "Apple's stock (AAPL) reaches unprecedented heights as the tech giant reports record-breaking sales figures and impressive earnings for the quarter. With robust demand for its latest products and services, investors are bullish on Apple's future prospects, driving the stock price to soar.",
  //     publish: "14 Mar, 2024",
  //     imgae: "img1.png",
  //   },
  //   {
  //     title:
  //       "Apple Stock Surges to New Heights Amid Record Sales and Strong Earnings Report",
  //     detail:
  //       "Apple's stock (AAPL) reaches unprecedented heights as the tech giant reports record-breaking sales figures and impressive earnings for the quarter. With robust demand for its latest products and services, investors are bullish on Apple's future prospects, driving the stock price to soar.",
  //     publish: "12 Mar, 2024",
  //     imgae: "img1.png",
  //   },
  //   {
  //     title: "Apple Stock Hits All-Time High on Strong Q4 Performance",
  //     detail:
  //       "Apple's stock (AAPL) reaches an unprecedented peak as the company reports stellar performance in its fourth-quarter earnings. With impressive sales figures across its product lines and promising projections for the future, investor confidence in Apple's trajectory drives the stock price to its highest level yet",
  //     publish: "13 Mar, 2024",
  //     imgae: "img2.png",
  //   },
  //   {
  //     title:
  //       "Apple Stock Surges to New Heights Amid Record Sales and Strong Earnings Report",
  //     detail:
  //       "Apple's stock (AAPL) reaches unprecedented heights as the tech giant reports record-breaking sales figures and impressive earnings for the quarter. With robust demand for its latest products and services, investors are bullish on Apple's future prospects, driving the stock price to soar.",
  //     publish: "14 Mar, 2024",
  //     imgae: "img1.png",
  //   },
  // ]);

  return (
    <>
      <div className="w-full h-fit flex flex-wrap gap-5 mt-4">
        {news?.map((item, index) => (
          <div key={index}>
            <NewsCard item={item} />
          </div>
        ))}
      </div>
      <div className="w-full h-10" />
    </>
  );
}

export default News;
