import carousel1 from  "../assets/carousel_images/carousel1.png";
import carousel2 from  "../assets/carousel_images/carousel2.png";
import carousel3 from  "../assets/carousel_images/carousel3.png";
import carousel4 from  "../assets/carousel_images/carousel4.png";
import carousel5 from  "../assets/carousel_images/carousel5.png";

export const carousel_items = [
    {
        id: 1,
        title: 'Effortless Stock Tracking',
        description: 'Keep an eye on unlimited stocks effortlessly with IVY Dividends. Access pricing, valuation metrics, dividend income, safety insights, and more—all in one place',
        image: carousel1,
        alt: 'carousel1'
    },
    {
        id: 2,
        title: 'Stay Informed Anytime, Anywhere',
        description: 'Track your dividend income on-the-go with IVY Dividends. Our intuitive payout calendar keeps you updated on projected earnings for the upcoming months, no matter where you are.',
        image: carousel2,
        alt: 'carousel2'
    },
    {
        id: 3,
        title: 'Community-Powered Stock Insights',
        description: 'Join IVY Dividends: a vibrant community of investors sharing stock recommendations. Access in-depth analysis tools and Dividend safety scores for informed decisions.',
        image: carousel3,
        alt: 'carousel3'
    },
    {
        id: 4,
        title: 'Tailored Portfolio Management',
        description: 'Tailor your investment strategy effortlessly with IVY Dividends. Organize and analyze your portfolio seamlessly, using personalized metrics and insights to meet your financial goals.',
        image: carousel4,
        alt: 'carousel4'
    },
    {
        id: 5,
        title: 'Accessible Investment Education',
        description: "Boost your investment knowledge with IVY Dividends' accessible educational resources. From beginner tips to advanced strategies, gain the confidence to navigate the stock market.",
        image: carousel5,
        alt: 'carousel5'
    }
];