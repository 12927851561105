import React from 'react'

function ModalContainer({children,onClose=()=>{},title='Modal Title',customClass}) {

    const handleClose = ()=>{
        onClose()
    }

  return (
    <div className={`w-auto h-auto border  dark:border-[#1E293B]  bg-[#f8fafc] border-[#cecece] rounded-[14px] dark:bg-[#020617] ${customClass}`}>
        <div className='w-full h-fit border-b dark:border-[#1E293B]  flex items-center justify-between p-4'>
            <span className='dark:text-[#F8FAFC] text-[18px] font-[400]'>{title}</span>
            <span onClick={handleClose} className='p-1 rounded-full hover:bg-[#E2E8F0] dark:hover:bg-[#1E293B] cursor-pointer'>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-6 h-6 text-[#777777]">
                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
            </svg>
            </span>
        </div>
        <div>
            {children}
        </div>
    </div>
  )
}

export default ModalContainer