import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import ReactApexChart from "react-apexcharts";

export default function FutureGrowth({ tabName }) {
  const darkMode = useSelector((state) => state.theme.darkMode);

  const [series, setSeries] = useState([
    {
      name: 'Total Annual Contribution',
      data: [100000, 180000, 250000, 300000, 360000, 420000, 480000, 550000, 620000, 700000]
    },
    {
      name: 'Account Value',
      data: [500000, 600000, 700000, 690000, 760000, 580000, 780000, 500000, 930000, 990000]

    },
    {
      name: 'Principal Growth',
      data: [100000, 180000, 250000, 300000, 360000, 420000, 480000, 550000, 620000, 700000]
    },
    {
      name: 'Dividend',
      data: [100000, 180000, 250000, 300000, 360000, 420000, 480000, 550000, 620000, 700000]
    }
  ]);
  const [options, setOptions] = useState({
      chart: {
        type: 'bar',
        height: 350,
        stacked: true,
        toolbar: {
          show: false
        },
      },
      plotOptions: {
        bar: {
          columnWidth: '85%',
          dataLabels: {
            position: 'top', // Show data labels on top of each bar
          }
        },
      },
      colors: ['#F472B6', '#4338CA', '#818CF8', '#4c5ae1'],
      dataLabels: {
        enabled: true,
        formatter: (val, { seriesIndex, dataPointIndex, w }) => {
          // Display only the last value for each bar
          const totalSeries = w.config.series.length;
          const lastSeriesValue = w.config.series[totalSeries - 1].data[dataPointIndex];
          if (seriesIndex === totalSeries - 1) {
            return `$${lastSeriesValue.toLocaleString('en-US')}`;
          } else {
            return '';
          }
        },
        offsetY: -20,
      },
      xaxis: {
        categories: [
          '2024', '2025', '2026', '2027', '2028', '2029', '2030', '2031', '2032', '2033'
        ],
        axisBorder: {
          show: false, // Hide x-axis border
        },
        axisTicks: {
          show: false, // Hide x-axis ticks
        },
      },
      yaxis: {
        labels: {
          formatter: (val) => `$${val.toLocaleString('en-US')}`,
        },
      },
      grid: {
        show: false,
      },
      tooltip: {
        y: {
          formatter: (val) => `$${val.toLocaleString('en-US')}`,
        },
      },
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'right',
        fontSize: '14px',
      },
  });

    useEffect(() => {
        setOptions({
        ...options,
        dataLabels:{
          style: {
          ...options.colors,
          colors:[darkMode ? '#FFFFFF': '#000000']
          }
        },
        xaxis: {
          labels:{
            style: {
              ...options.colors,
              colors: darkMode ? '#FFFFFF': '#000000'
            }
          }
        },
        yaxis: {
          labels: {
            style: {
              ...options.colors,
              colors: darkMode ? '#FFFFFF': '#000000'
            }
          }
        },
        legend: {
          labels: {
            ...options.colors,
            colors: darkMode ? '#FFFFFF': '#000000'
          }
        }
        })
    }, [darkMode])

  return (
    <div className="flex flex-col h-full w-full ">
      <style jsx>{`
        .apexcharts-tooltip span {
          color: ${darkMode ? '#FFFFFF': '#000000'};
      }
      `}</style>
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <span className="text-[18px]">{tabName}</span>
        </div>
      </div>
      <div className="h-full w-full py-3">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          // width={380}
          height={400}
        />
      </div>
    </div>
  );
}
