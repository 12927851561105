import React,{useState} from 'react'
import ModalBtn from '../Button/ModalBtn'
import { useSelector } from 'react-redux'

function Profile() {
  const [fullName,setFullName] = useState('')
  const theme = useSelector((state) => state.theme.darkMode);

  return (
    <div className='w-full h-full relative'>
      <div className='w-full h-[40%] flex items-center justify-center'>
          <span className='cursor-pointer'>
          <svg width="107" height="111" className='dark:text-[#0F172A] text-[#d6d6d6]' viewBox="0 0 107 111" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="107" height="107" rx="53.5" fill="currentColor"/>
            <path fill-rule="evenodd" clip-rule="evenodd" d="M43 40.5C43 37.8478 44.0536 35.3043 45.9289 33.4289C47.8043 31.5536 50.3478 30.5 53 30.5C55.6522 30.5 58.1957 31.5536 60.0711 33.4289C61.9464 35.3043 63 37.8478 63 40.5C63 43.1522 61.9464 45.6957 60.0711 47.5711C58.1957 49.4464 55.6522 50.5 53 50.5C50.3478 50.5 47.8043 49.4464 45.9289 47.5711C44.0536 45.6957 43 43.1522 43 40.5ZM43 55.5C39.6848 55.5 36.5054 56.817 34.1612 59.1612C31.817 61.5054 30.5 64.6848 30.5 68C30.5 69.9891 31.2902 71.8968 32.6967 73.3033C34.1032 74.7098 36.0109 75.5 38 75.5H68C69.9891 75.5 71.8968 74.7098 73.3033 73.3033C74.7098 71.8968 75.5 69.9891 75.5 68C75.5 64.6848 74.183 61.5054 71.8388 59.1612C69.4946 56.817 66.3152 55.5 63 55.5H43Z" fill="#475569"/>
            <rect x="75.5" y="79.5" width="31" height="31" rx="15.5" fill="currentColor"/>
            <rect x="75.5" y="79.5" width="31" height="31" rx="15.5" stroke={theme ? "#1E293B" : "#4f4f4f"}/>
            <path d="M91 89.75V100.25" stroke={`${theme ? '#F1F5F9' : '#4f4f4f'}`} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M85.75 95H96.25" stroke={`${theme ? '#F1F5F9' : '#4f4f4f'}`} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </span>
      </div>
      <div className='w-full justify-between px-4 dark:border-[#1E293B] py-4 border-[#cecece70] border-b flex items-center'>
        <span className='dark:text-[#94A3B8] font-normal text-[14px]'>Full Name</span>
        <input type="text" value={fullName} onChange={(e)=>setFullName(e.target.value)} className='border-none text-right dark:text-[#F1F5F9] placeholder:text-black placeholder:dark:text-[#F1F5F9] font-normal text-[14px] focus:outline-none bg-transparent' placeholder="Dinz Smith" />
      </div>
      <div className='w-full justify-between px-4 dark:border-[#1E293B] py-6 border-[#cecece70] border-b flex items-center'>
        <span className='dark:text-[#94A3B8] font-normal text-[14px]'>Email</span>
        <input type="text"  disabled className='border-none text-right dark:text-[#F1F5F9] placeholder:dark:text-[#94A3B8] font-normal text-[14px] focus:outline-none bg-transparent' placeholder="mohdineth@gmail.com" />
      </div>
      <div className='px-4 py-1'>
        <span className='text-[12px] text-[#94A3B8]'>If you need to change email address,  <span className='text-[#2563EB] cursor-pointer hover:underline'> contact us</span></span>
      </div>
      <div className='w-full absolute bottom-6 items-end flex justify-end right-4'>
        <div>
          <ModalBtn title="Save Changes" customClass={`${fullName ? 'pointer-events-auto cursor-pointer':'opacity-50 pointer-events-none cursor-default'} !font-semibold !bg-[#FFac39] text-black`} />
        </div>
      </div>
    </div>
  )
}

export default Profile