import React,{useState,useEffect} from 'react'

function SupportArticle({article=[]}) {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <div className={`${screenWidth<1200?'w-full ':'w-10/12'} h-full  flex-wrap gap-x-8 gap-y-[4rem] flex justify-center mt-[3rem] items-center`}>

        {article.map((item,i)=>(
          <div key={i} className={`${screenWidth < 1000 ? 'w-full justify-center':'w-5/12'} flex items-start space-x-3 -bg-green-200`}>
            <div>
              <img src={require(`../../assets/${item.image}`)} alt="" className='w-[1.5rem] h-[1.5rem]' />
            </div>
            <div className='flex flex-col gap-y-3'>
              <span className='dark:text-[#F1F5F9] font-semibold text-[18px]'>{item.title}</span>
              <div>
              {item.list?.map((data,index)=>(
                <div key={index} className='py-2 text-[#94A3B8] dark:text-[#CBD5E1] font-normal text-[14px]'>{data}</div>
              ))}
              </div>
            </div>
          </div>
        ))

        }
    </div>
  )
}

export default SupportArticle