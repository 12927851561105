import "./App.css";
import Layout from "./layout/Layout";
import UserStory from "./userstory/UserStory";
import { BrowserRouter as Router } from "react-router-dom";
import AuthRoute from "./route";
import { useSelector } from "react-redux";

function App() {
  const darkMode = useSelector((state) => state.theme.darkMode);

  return (
    <Router>
      <div className={`h-[100vh] fixed w-[100vw] ${darkMode?'dark':''}`}>
        <AuthRoute/>
      </div>
   </Router>
  );
}

export default App;
