import React, { useState,useRef } from 'react'
import WatchlistTable from '../components/table/WatchlistTable'
import { tableDummyData } from '../utils/sidebarContent'
import MultiLineChart from '../components/charts/MultiLineChart'
import SymbolSearch from '../components/SymbolSearch'
import BaseModal from '../components/BaseModal'
import { addWatchlist } from '../ApiControler'
function Watchlist() {
  const elementRef = useRef(null);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [header, setHeader] = useState(["Price","Dividend","Financials"])
  const [selectedHead,setSelectedHead] = useState("Price")
  const [toggleSearch,setToggleSearch] = useState(false)
  const [selectedTimePeriod,setSelectedTimePeriod] = useState({key:"1w",value:"1W"})
  const [timeperiod,setTimeperiod] = useState([{key:"1w",value:"1W"},{key:"1m",value:"1M"},{key:"3m",value:"3M"},{key:"1y",value:"1Y"},{key:"all",value:"All"},])
  const [watchlistTableData,setWatchlistTableData] = useState(tableDummyData)
  const [compareSymbols,setCompareSymbols] = useState([
    {id:1,ticker:"NU",img:'nu.png', price:187.42, change:0.17, change_percent:1.82},
    {id:2,ticker:"IBM",img:'ibm.png', price:18.50, change:0.17, change_percent:1.82},
    {id:3,ticker:"TSLA",img:'tesla.png', price:2.20, change:-1.2, change_percent:1.82},

  ])

  const handleClose = ()=>{
    setToggleSearch(false)
  }

  const handleSymbolAdd = (item)=>{
   
    setToggleSearch(false)
   
  }

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setHideLeftArrow(true);
      } else {
        setHideLeftArrow(false);
      }


      if (element.scrollLeft === element.scrollWidth - element.clientWidth) {
        setHideRightArrow(true);
      } else {
        setHideRightArrow(false);
      }

    }, speed);
  };


  return (
    <>
    {toggleSearch && 
      <BaseModal>
        <SymbolSearch onClose={handleClose} page="watchlist" addToWatchlist={handleSymbolAdd} />
      </BaseModal>
    }
    <div className='w-full h-full space-y-[2rem] !pb-[2rem]'>
      <div className='w-full h-fit py-3 flex items-center justify-between'>
        <div className='flex items-center space-x-2 mt-3'>
          {header?.map((item, index) => (
            <div key={index} onClick={()=>setSelectedHead(item)} className={`rounded-[8px] px-[0.8rem] cursor-pointer  text-[#475569]   pt-[0.25rem] pb-[0.3rem] -bg-[#E2E8F0] text-[14px]  ${item == selectedHead ? 'dark:bg-[#431407] font-[500] text-[#020617] bg-[#f88f00] dark:text-[#F88F00]':'dark:text-[#475569] dark:hover:bg-[#182238] hover:bg-[#d0d8e2] dark:hover:text-[#E2E8F0] bg-[#E2E8F0] font-[400] dark:bg-transparent'}`}>{item}</div>
          ))}
        </div>
        <div onClick={()=>setToggleSearch(true)}  className={`w-10 h-10 cursor-pointer flex items-center justify-center rounded-full bg-[#E2E8F0] dark:bg-[#0F172A] hover:dark:bg-[#182238] hover:bg-[#cbd0d6]`}>
        <svg width="25" height="25" className='dark:text-[#F8FAFC] text-[#020617]' viewBox="0 0 25 25" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M11.2778 19.0822C15.6961 19.0822 19.2778 15.5005 19.2778 11.0822C19.2778 6.66394 15.6961 3.08222 11.2778 3.08222C6.85955 3.08222 3.27783 6.66394 3.27783 11.0822C3.27783 15.5005 6.85955 19.0822 11.2778 19.0822Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          <path d="M21.2779 21.0822L16.9279 16.7322" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>

        </div>
      </div>

      <div>
          <WatchlistTable  data={watchlistTableData} />
      </div>

      <div className='flex items-center space-x-4 pt-[1rem]'>
          <div className='whitespace-nowrap'>
              <span className='dark:text-[#F8FAFC] text-[16px] font-[400]'>Compare Symbols</span>
          </div>
          <div className='w-full h-[1px] bg-[#CBD5E1] dark:bg-[#0F172A]' />
      </div>  

      <div className='w-full h-[62vh] pb-[2rem]'>
        <div className='w-full h-[60vh] border rounded-[8px] p-8 bg-[#f8fafc] border-[#CBD5E1] dark:bg-[#0F172A] dark:border-[#1F2A37]'>

            <div className='w-full h-fit flex items-center compareSymbolHeader justify-between'>
              <div className='flex w-8/12 compareSymbolsArea items-center'>
             {!hideLeftArrow && 
              <div onClick={() => {handleHorizantalScroll(elementRef.current, 25, 100, -10)}} className='mr-2 w-6 h-6 cursor-pointer rounded-full hover:bg-[#d5dae0] bg-[#E2E8F0] dark:hover:bg-[#242e3d] dark:bg-[#1E293B] flex items-center'>
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M15.75 19.5 8.25 12l7.5-7.5" />
                      </svg>
                  </div>
                }
                <div ref={elementRef} className='max-w-11/12 -bg-red-200 overflow-x-hidden flex items-center'>
                  {compareSymbols?.map((item, index) => (
                    <div key={index} className='w-full flex whitespace-nowrap items-center space-x-2'>
                        <img src={require(`../assets/images/${item.img}`)} className='w-8 h-8' />
                      <div className='flex flex-col w-[10rem]'>
                        <span className='dark:text-[#F8FAFC] text-black font-bold text-[18px]'>{item.ticker}</span>
                        <span className='text-[10px] text-[#94A3B8]'>${item.price} <span className='text-[#34D399]'>+{item.change}(+{item.change_percent}%)</span> </span>
                      </div>
                    </div>
                  ))}
                </div>
               {!hideRightArrow && 
               <div  onClick={() => {handleHorizantalScroll(elementRef.current, 25, 100, 10)}} className='mx-4 w-6 h-6 cursor-pointer rounded-full hover:bg-[#d5dae0] bg-[#E2E8F0] dark:hover:bg-[#242e3d] dark:bg-[#1E293B] flex items-center'>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                  </svg>

                  </div>}
                <div className='w-1/12 flex justify-center'>
                  <div  className={`w-10 h-10 cursor-pointer flex items-center justify-center rounded-full hover:bg-[#d5dae0] bg-[#E2E8F0] dark:hover:bg-[#242e3d] dark:bg-[#1E293B]`}>
                  <svg width="16" height="16" className='dark:text-[#F8FAFC] text-black' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8 1V15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M1 8H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>


                  </div>
                </div>
              </div>
              <div className='w-3/12 flex items-center comparePerios justify-end space-x-[0.5rem]'>
                  {timeperiod?.map((item, index) => (
                    <div onClick={() => setSelectedTimePeriod(item)} key={index} className={`px-[1rem] py-[0.3rem]  text-[12px] text-semibold rounded-[10px] cursor-pointer text-center ${selectedTimePeriod?.key === item.key ? 'dark:text-[#F88F00] dark:border-[#F97316] border-[#F88F00] border dark:bg-[#431407] text-[black] bg-[#F88F00]' : 'text-[#94A3B8]'}`}>
                      <span>{item?.value}</span>
                    </div>
                  ))}
              </div>
            </div>
            <div className='w-full h-[95%] compareChart'>
                  <MultiLineChart />
            </div>
        </div>
      </div>   

    </div>
    </>
  )
}

export default Watchlist