import React, { useState, useEffect } from 'react'
import CardBox from '../components/CardBox'
import SpdrImage from '../assets/images/spdr.png'
import QqqImage from '../assets/images/qqq.png'
import GreenLine from '../assets/images/green_line_chart.png'
import BlueLine from '../assets/images/blue_line_chart.png'
import PurpleLine from '../assets/images/purple_line_chart.png'
import PrimaryButton from '../components/Button'
import { useSelector } from 'react-redux'
import CalculatorChart2 from '../components/CalculatorChart2'
import { fundDummyData } from '../utils/sidebarContent'

function FundOverlap() {

  const [fundData, setFundTableData] = useState(fundDummyData);

  console.log("fundData", fundData);

  const [addedSymbolData, setAddedData] = useState([{
    id: 1,
    logo: SpdrImage,
    symbolName: "SPDR",
    companyName: "SPDR Inc.",
    companyDetails: "SPDR S&P 500 ETF",
    value: "510.93",
    increament: "0.17",
    increamentinPercent: "1.82",
    time: "11:14 PM",
    date: "03/11/2024",
    belongsTo: "NYSE ARCA",
    currency: "$USD",
  },
  {
    id: 2,
    logo: QqqImage,
    symbolName: "QQQ",
    companyName: "QQQ Inc.",
    companyDetails: "Invesco QQQ Trust",
    value: "437.20",
    increament: "0.17",
    increamentinPercent: "1.82",
    time: "11:14 PM",
    date: "03/11/2024",
    belongsTo: "NYSE ARCA",
    currency: "$USD",
  },
  
]);

const [addedSymbolData2, setAddedData2] = useState([{
  id: 1,
  logo: GreenLine,
  description: "Number of overlapped holdings",
  value: 84,
},
{
  id: 2,
  logo: BlueLine,
  description: "% of QQQ's 100 holdings also on SPY",
  value: 83.3,
},
{
  id: 3,
  logo: PurpleLine,
  description: "% of SPY's 502 holdings also on QQQ",
  value: 16.0,
}

]);
  const [goalValue, setGoal] = useState();
  const [tickerData, setData] = useState(null);
  const [showChart, setShowChart] = useState(false);


  const dataSet = () => {
    let dividedValue = parseInt(goalValue) / addedSymbolData?.length;
    addedSymbolData?.forEach((item) => {
      item.value = dividedValue;
    });
    // console.log("ad", addedSymbolData);
    setData(addedSymbolData);
    // setAddedData([]);
    // setInputCount(1);
    setShowChart(true);
  };

  const deletItem = (val) => {
    const sortedArray = addedSymbolData?.filter(
      (item) => item?.symbolName !== val
    );
    
    setAddedData(sortedArray);
  };
  const childData = (item) => {
    return (
      <div className="flex h-full w-full flex-col px-3 py-6 hover:bg-slate-100 dark:hover:bg-slate-800">
        <div className="h-full w-full space-x-3">
            <div className='flex justify-between'>
              <img src={item.logo} className="h-9 w-9" />
              <div
              onClick={() => deletItem(item?.symbolName)}
              className="flex items-center justify-center h-8 w-8 bg-red-100 dark:bg-red-900/40 flex-col rounded-full hover:cursor-pointer mb-3"
              >
              <div className="h-0.5 w-3 bg-red-900" />
            </div>
          </div>
        </div>
        <div className="flex items-center h-full w-full">
          <div className="flex flex-col font-inter">
              <span className="text-[18px] font-bold">
                  {item.companyDetails}
              </span>
            <p>
            <span className='text-[14px]'>
              +{item.value}`
            </span>
            <span className='text-[#34D399] text-[14px]'>
              +{item.increament} (+{item.increamentinPercent})
            </span>
            </p>
            <p className='text-[#94A3B8] text-[12px]'>
              {item.time} {item.date}
            </p>
            <p className='text-[#94A3B8] text-[12px]'>
              {item.belongsTo} | {item.currency}
            </p>
          </div>
          
        </div>
      </div>
    );
  };

  const childData2 = (item) => {
    return (
      <div className="flex h-full w-full flex-col px-3 py-2 hover:bg-slate-100 dark:hover:bg-slate-800">
        <div className="h-full w-full space-x-3">
          <div className='flex justify-between'>
              <p className='text-[#94A3B8] text-[14px] font-inter'>{item.description}</p>
              <svg
                width="25"
                height="25"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99996 18.3333C14.6023 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39762 14.6023 1.66666 9.99996 1.66666C5.39759 1.66666 1.66663 5.39762 1.66663 10C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333Z"
                  stroke="#94A3B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 6.66666V10"
                  stroke="#94A3B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 13.3333H10.0083"
                  stroke="#94A3B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
          </div>
            
        </div>
        <div className="h-full w-full space-x-3">
          <div className="flex justify-between">
            <span className='text-[32px] font-inter font-bold'>{item.value}%</span>
            <img src={item.logo} className="h-12 w-24" />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`flex flex-col w-full h-full p-8 gap-4 ${showChart ? 'px-0' : 'px-20'}`}>
      {
        !showChart ?
        (
          <>
          <div className='flex w-full h-48 justify-between'>
            <div className="flex flex-col h-full w-full justify-center space-y-3">
              <span className="w-64 text-[36px] font-bold font-inter bg-gradient-to-r from-[#206CFF] to-[#F88F00] bg-clip-text text-transparent">
                Fund Overlap
              </span>
              <div className="flex space-x-3 items-center">
                <span className="text-[14px] text-[#94A3B8] font-inter">
                See which holdings any two equity ETFs have in common, along with top differences in exposure
                </span>
              </div>
            </div>
            <div className="flex flex-col h-full w-full space-y-4 justify-center items-end">
              <PrimaryButton
              _click={()=>dataSet()}
              isDisable={addedSymbolData?.length > 0 ? true : false}
                title={"Find Overlap"}
                mainClass={` w-64 ${addedSymbolData?.length > 0 ? 'bg-[#FFAA36] font-semibold text-black cursor-pointer hover:opacity-80' : 'bg-[#fbd8a4] dark:bg-[#f2b868] text-slate-600'}`}
              />
              <PrimaryButton
                isDisable={false}
                _click={() => {
                  setAddedData([]);
                  setGoal(0);
                }}
                mainClass={`dark:bg-red-500/15 bg-red-100 w-64 text-red-500 cursor-pointer hover:opacity-80`}
                title={"Reset Values"}
              />
            </div>
      </div>
      <div className='w-full h-48 '>
          <div className='flex h-full w-full justify-between gap-6'>
            {addedSymbolData?.map((item) => {
              return (
                <CardBox
                  mainClass={"w-full h-full"}
                  childData={childData(item)}
                />
              );
            })}
            {/* <CardBox mainClass={"w-full h-full "} childData={childData}/>
            <CardBox mainClass={"w-full h-full"} childData={childData}/> */}
          </div>
      </div>
      <div className='w-full h-48'>
          <div className='flex h-full w-full justify-between gap-6'>
          {addedSymbolData2?.map((item) => {
              return (
                <CardBox
                  mainClass={"w-full h-full"}
                  childData={childData2(item)}
                />
              );
            })}
            {/* <CardBox mainClass={"w-full h-full"} childData={childData}/>
            <CardBox mainClass={"w-full h-full"} childData={childData}/>
            <CardBox mainClass={"w-full h-full"} childData={childData}/> */}
          </div>
      </div>
          </>
        ) : (
          <CalculatorChart2
            setOData={setFundTableData}
            data={fundData}
            goalValue={goalValue}
            showChart={setShowChart}
          />
        )}
    </div>
  )
}

export default FundOverlap