import React, { useState, useEffect } from "react";
import IconCircle from "../iconCircle";
import CardSlider from "../CardSlider";
import MainCard from "../CardBox/MainCard";
import BarChart from "../charts/BarChart";
import CardYearHeading from "../cardYearHeading";
import SimpleTable from "../table/SimpleTable";
import { getDividends } from "../../ApiControler";
import { useNavigate, useLocation } from "react-router-dom";
function Dividends() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const symbol = queryParams.get("symbol");

  const [selectedView, setSelectedView] = useState("cardSlider");
  const [dividendSeries, setDividendSeries] = useState([
    {
      name: "Dividend Growth",
      data: [2652, 2052, 1902, 2352, 1152, 1652, 1352, 1352, 1952],
    },
  ]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const tableColumns = [
    { key: "declaredDate", value: "Declared Date" },
    { key: "exDate", value: "Ex-Date" },
    { key: "recordDate", value: "Record Date" },
    { key: "paymentDate", value: "Payment Date" },
    { key: "amount", value: "Amount" },
    { key: "type", value: "Type" },
    { key: "change_per", value: "Change(%)" },
  ];

  const [tableData, setTableData] = useState([
    {
      declaredDate: "02/15/24",
      exDate: "02/09/24",
      recordDate: "02/15/24",
      paymentDate: "02/15/24",
      amount: "$0.96",
      type: "Dividend",
      change_per: "0.00%",
    },
    {
      declaredDate: "02/15/24",
      exDate: "02/09/24",
      recordDate: "02/15/24",
      paymentDate: "02/15/24",
      amount: "$0.96",
      type: "Dividend",
      change_per: "0.00%",
    },
    {
      declaredDate: "02/15/24",
      exDate: "02/09/24",
      recordDate: "02/15/24",
      paymentDate: "02/15/24",
      amount: "$0.96",
      type: "Dividend",
      change_per: "0.00%",
    },
    {
      declaredDate: "02/15/24",
      exDate: "02/09/24",
      recordDate: "02/15/24",
      paymentDate: "02/15/24",
      amount: "$0.96",
      type: "Dividend",
      change_per: "0.00%",
    },
    {
      declaredDate: "02/15/24",
      exDate: "02/09/24",
      recordDate: "02/15/24",
      paymentDate: "02/15/24",
      amount: "$0.96",
      type: "Dividend",
      change_per: "0.00%",
    },
    {
      declaredDate: "02/15/24",
      exDate: "02/09/24",
      recordDate: "02/15/24",
      paymentDate: "02/15/24",
      amount: "$0.96",
      type: "Dividend",
      change_per: "0.00%",
    },
    {
      declaredDate: "02/15/24",
      exDate: "02/09/24",
      recordDate: "02/15/24",
      paymentDate: "02/15/24",
      amount: "$0.96",
      type: "Dividend",
      change_per: "0.00%",
    },
    {
      declaredDate: "02/15/24",
      exDate: "02/09/24",
      recordDate: "02/15/24",
      paymentDate: "02/15/24",
      amount: "$0.96",
      type: "Dividend",
      change_per: "0.00%",
    },
    {
      declaredDate: "02/15/24",
      exDate: "02/09/24",
      recordDate: "02/15/24",
      paymentDate: "02/15/24",
      amount: "$0.96",
      type: "Dividend",
      change_per: "0.00%",
    },
    {
      declaredDate: "02/15/24",
      exDate: "02/09/24",
      recordDate: "02/15/24",
      paymentDate: "02/15/24",
      amount: "$0.96",
      type: "Dividend",
      change_per: "0.00%",
    },
    {
      declaredDate: "02/15/24",
      exDate: "02/09/24",
      recordDate: "02/15/24",
      paymentDate: "02/15/24",
      amount: "$0.96",
      type: "Dividend",
      change_per: "0.00%",
    },
    {
      declaredDate: "02/15/24",
      exDate: "02/09/24",
      recordDate: "02/15/24",
      paymentDate: "02/15/24",
      amount: "$0.96",
      type: "Dividend",
      change_per: "0.00%",
    },
  ]);

  const [cashSeries, setCashSeries] = useState([
    {
      name: "Free Cash",
      data: [1123, 1523, 1882, 2000, 2302, 2630, 2833, 3000, 3322],
    },
  ]);
  const general = [
    { name: "Payment Schedule", value: "Quarterly" },
    { name: "Latest Div. Date", value: "02/15/24" },
    { name: "Ex Date", value: "02/09/24" },
    { name: "Annual Income", value: "$0.96" },
  ];
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      
      const data = await getDividends(token, symbol);
     
      setData(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };
  //calling the dividends api
  useEffect(() => {
    fetchData();
  }, [symbol]);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return (
    <div className="w-full h-full space-y-[3rem]">
      <div className="w-full flex items-center space-x-3">
        <IconCircle
          handleClick={() => setSelectedView("cardSlider")}
          customClass={`${
            selectedView == "cardSlider"
              ? "dark:bg-[#431407] bg-[#F88F00] dark:hover:bg-[#431407] hover:bg-[#F88F00]"
              : ""
          }`}
        >
          <svg
            className={`${
              selectedView == "cardSlider"
                ? "dark:text-[#F88F00] text-black"
                : "dark:text-[#F8FAFC] text-black"
            }`}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.1665 7.16667H5.49983C6.4165 7.16667 7.1665 6.41667 7.1665 5.5V2.16667C7.1665 1.25 6.4165 0.5 5.49983 0.5H2.1665C1.24983 0.5 0.499832 1.25 0.499832 2.16667V5.5C0.499832 6.41667 1.24983 7.16667 2.1665 7.16667ZM2.1665 15.5H5.49983C6.4165 15.5 7.1665 14.75 7.1665 13.8333V10.5C7.1665 9.58333 6.4165 8.83333 5.49983 8.83333H2.1665C1.24983 8.83333 0.499832 9.58333 0.499832 10.5V13.8333C0.499832 14.75 1.24983 15.5 2.1665 15.5ZM8.83317 2.16667V5.5C8.83317 6.41667 9.58317 7.16667 10.4998 7.16667H13.8332C14.7498 7.16667 15.4998 6.41667 15.4998 5.5V2.16667C15.4998 1.25 14.7498 0.5 13.8332 0.5H10.4998C9.58317 0.5 8.83317 1.25 8.83317 2.16667ZM10.4998 15.5H13.8332C14.7498 15.5 15.4998 14.75 15.4998 13.8333V10.5C15.4998 9.58333 14.7498 8.83333 13.8332 8.83333H10.4998C9.58317 8.83333 8.83317 9.58333 8.83317 10.5V13.8333C8.83317 14.75 9.58317 15.5 10.4998 15.5Z"
              fill="currentColor"
            />
          </svg>
        </IconCircle>
        <IconCircle
          handleClick={() => setSelectedView("card")}
          customClass={`${
            selectedView == "card"
              ? "dark:bg-[#431407] bg-[#F88F00] dark:hover:bg-[#431407] hover:bg-[#F88F00]"
              : ""
          }`}
        >
          <svg
            className={`${
              selectedView == "card"
                ? "dark:text-[#F88F00] text-black"
                : "dark:text-[#F8FAFC] text-black"
            }`}
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 6C1.55 6 2 5.55 2 5C2 4.45 1.55 4 1 4C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6ZM1 10C1.55 10 2 9.55 2 9C2 8.45 1.55 8 1 8C0.45 8 0 8.45 0 9C0 9.55 0.45 10 1 10ZM1 2C1.55 2 2 1.55 2 1C2 0.45 1.55 0 1 0C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2ZM5 6H17C17.55 6 18 5.55 18 5C18 4.45 17.55 4 17 4H5C4.45 4 4 4.45 4 5C4 5.55 4.45 6 5 6ZM5 10H17C17.55 10 18 9.55 18 9C18 8.45 17.55 8 17 8H5C4.45 8 4 8.45 4 9C4 9.55 4.45 10 5 10ZM4 1C4 1.55 4.45 2 5 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H5C4.45 0 4 0.45 4 1ZM1 6C1.55 6 2 5.55 2 5C2 4.45 1.55 4 1 4C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6ZM1 10C1.55 10 2 9.55 2 9C2 8.45 1.55 8 1 8C0.45 8 0 8.45 0 9C0 9.55 0.45 10 1 10ZM1 2C1.55 2 2 1.55 2 1C2 0.45 1.55 0 1 0C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2ZM5 6H17C17.55 6 18 5.55 18 5C18 4.45 17.55 4 17 4H5C4.45 4 4 4.45 4 5C4 5.55 4.45 6 5 6ZM5 10H17C17.55 10 18 9.55 18 9C18 8.45 17.55 8 17 8H5C4.45 8 4 8.45 4 9C4 9.55 4.45 10 5 10ZM4 1C4 1.55 4.45 2 5 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H5C4.45 0 4 0.45 4 1Z"
              fill="currentColor"
            />
          </svg>
        </IconCircle>
      </div>

      {selectedView == "cardSlider" && (
        <div className="w-full inset-shadows">
          <div className="w-full">
            <CardSlider title="General" data={general} showChart={false} />
          </div>
        </div>
      )}

      {selectedView == "card" && (
        <div className="w-full">
          <div
            className={`w-full flex  items-center gap-4 ${
              screenWidth < 600 ? "cardMainContainer" : ""
            }`}
          >
            <div
              className={`w-6/12 h-[60vh] ${
                screenWidth < 600 ? "cardSubContainer" : ""
              }`}
            >
              <MainCard customClass="border-none ">
                <div className="mb-[1rem]">General</div>
                {general?.map((item, index) => (
                  <div
                    key={index}
                    className="flex py-3 items-center border-b border-dashed border-b-[#E2E8F0] dark:border-b-[#1E293B] justify-between"
                  >
                    <span className="text-[#94A3B8] text-[14px] font-normal">
                      {item.name}
                    </span>
                    <span className="text-[#94A3B8] text-[14px] font-normal">
                      {item.value}
                    </span>
                  </div>
                ))}
              </MainCard>
            </div>
          </div>
        </div>
      )}

      <div
        className={`w-full flex items-center  gap-4 ${
          screenWidth < 1059 ? "cardMainContainer" : ""
        }`}
      >
        <div
          className={`w-6/12 h-[50vh] ${
            screenWidth < 1059 ? "cardSubContainer" : ""
          }`}
        >
          <MainCard customClass="border-none">
            <div>
              <CardYearHeading heading="Dividend Growth" />
            </div>
            <div className="w-full  h-[88%] mt-4">
              <BarChart series={dividendSeries} />
            </div>
          </MainCard>
        </div>
        <div
          className={`w-6/12 h-[50vh] ${
            screenWidth < 1059 ? "cardSubContainer" : ""
          }`}
        >
          <MainCard customClass="border-none">
            <div>
              <CardYearHeading heading="Free Cash" />
            </div>
            <div className="w-full  h-[88%] mt-4">
              <BarChart series={cashSeries} />
            </div>
          </MainCard>
        </div>
      </div>

      <div className="w-full space-y-5">
        <div className="flex items-center space-x-4">
          <span className="dark:text-[#F8FAFC] whitespace-nowrap text-[18px] font-normal">
            Historical Dividends
          </span>
          <span className="w-full h-[2px] dark:bg-[#0F172A] bg-[#E2E8F0]" />
        </div>
        <div className="w-full h-[62vh]">
          <SimpleTable columns={tableColumns} data={tableData} />
        </div>
      </div>
    </div>
  );
}

export default Dividends;
