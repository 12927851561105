import React,{useRef,useState,useEffect} from 'react'
import MainCard from './CardBox/MainCard'
import CardHeading from './cardHeading'
import IconCircle from './iconCircle';

export default function CardSlider({title,data,showChart=true}) {
  const elementRef = useRef(null);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);


    useEffect(() => {
        handleHorizantalScroll(elementRef.current, 25, 10, 10)
    }, []);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setHideLeftArrow(true);
      } else {
        setHideLeftArrow(false);
      }


      if (element.scrollLeft === element.scrollWidth - element.clientWidth) {
        setHideRightArrow(true);
      } else {
        setHideRightArrow(false);
      }

    }, speed);
  };

  return (
    <div className='w-full space-y-4'>
        <div className='flex items-center space-x-4'>
            <span className='dark:text-[#F8FAFC] whitespace-nowrap text-[18px] font-normal'>
                {title}
            </span>
            <span className='w-full h-[2px] dark:bg-[#0F172A] bg-[#E2E8F0]' />
        </div>
        <div className='flex items-center space-x-2'>
        {!hideLeftArrow && 
                   <IconCircle handleClick={() => {handleHorizantalScroll(elementRef.current, 25, 100, -10)}}>
                 <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className='dark:text-[#F8FAFC] w-5 h-5 text-black'>
                    <path stroke-linecap="round" stroke-linejoin="round" d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18" />
                </svg>

              </IconCircle>
                }
        <div ref={elementRef} className='w-full  overflow-x-hidden flex items-center space-x-4'>
            {data?.map((item,index)=>(
                <div className='w-[23rem] h-[10rem]' key={index}>
                   <MainCard customClass='!p-4 flex flex-col w-full justify-between'>
                        <div>
                            <CardHeading heading={item.name} />
                        </div>
                            <div className='flex items-center justify-between w-[20rem]'>
                                <span className='dark:text-[#F8FAFC] font-bold text-[1.8rem]'>
                                    {item.value}
                                </span>
                              {showChart &&  
                              <span>
                                <svg width="103" height="59" viewBox="0 0 103 59" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M2 57L13.9384 47.8334L19.0338 37.5209L26.6044 52.073L31.7 45.198L37.0868 2L50.9177 44.2814L60.9632 30.1876L80.1809 57L86.15 50.2397L89.7897 39.0105L101 30.1876" stroke="#2D5BFF" stroke-width="2.70648" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>

                                </span>}
                            </div>
                   </MainCard>
                </div>
            ))}
        </div>
        {!hideRightArrow &&

            <IconCircle handleClick={() => {handleHorizantalScroll(elementRef.current, 25, 100, 10)}}>
               <svg width="16" height="16" className='dark:text-[#F8FAFC] text-black' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 8H15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M8 1L15 8L8 15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>

            </IconCircle>
        }

        </div>
    </div>
  )
}
