import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
export default function Summary({ tabName, datas }) {
  const [investment, setInvestment] = useState(0);
  const [series, setSeries] = useState([]);
  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: "bar",
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        columnWidth: "40%",
      },
    },
    colors: "#1E293B",
    dataLabels: {
      enabled: false,
    },
    grid: {
      show: false, // Disables the grid lines on the chart
    },
    yaxis: {
      labels: {
        style: {
          colors: "#94A3B8", // Set the color for y-axis labels
          fontSize: "12px",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
    xaxis: {
      labels: {},
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
    },
  });

  
  useEffect(() => {
    const totalValue = datas?.reduce((sum, obj) => {
      const value = obj.value;
      const annualDividend = parseFloat(obj.annualDividend);
      const closingPrice = parseFloat(obj.closingPrice);
      const calculation = (value / annualDividend) * closingPrice;
      return sum + calculation;
    }, 0);
    setInvestment(totalValue);
    

    if (datas && datas.length > 0) {
      const newSeries = datas.map((item) => ({
        x: item?.symbolName?.toUpperCase(),
        y: parseInt(
          (item.value / parseFloat(item.annualDividend)) *
            parseFloat(item.closingPrice)
        ),
        fillColor: item.color,
        textColor: "#b7b7b7",
      }));

      setSeries([
        {
          name: "Value",
          data: newSeries,
        },
      ]);

      const labelColors = datas.map((item) => item.color);
      setOptions((prevOptions) => ({
        ...prevOptions,
        xaxis: {
          ...prevOptions.xaxis,
          labels: {
            ...prevOptions.xaxis.labels,
            style: {
              ...prevOptions.xaxis.labels.style,
              colors: labelColors,
            },
          },
        },
      }));
    }
  }, [datas]);

  return (
    <div className="flex flex-col h-full w-full">
      <div className="flex items-center justify-between">
        <div className="flex flex-col">
          <span className="text-[18px]">{tabName}</span>
          <p className="text-justify text-[10px] text-slate-500 mt-3">
            To earn your annual income goal from <br></br>dividends, you would
            need to invest a total<br></br>of $
            {investment?.toLocaleString("en-US")}{" "}
          </p>
        </div>
        <div className="flex flex-col">
          <span className="text-end text-[10px] text-slate-500">
            Need to invest
          </span>
          <span className="text-[25px] font-bold">
            ${investment?.toLocaleString("en-US")}
          </span>
        </div>
      </div>
      <div className="h-full w-full py-3">
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          // width={380}
          height={350}
        />
      </div>
    </div>
  );
}
