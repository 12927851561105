import React from 'react'

function ModalBtn({title,customClass,onPress=()=>{}}) {
    const handleClick = ()=>{
        onPress()
    }
  return (
    <div onClick={handleClick} className={`rounded-[10px] py-2.5 whitespace-nowrap mainBtnStyle text-[14px] font-normal cursor-pointer hover:opacity-80 px-[2rem] bg-[#E2E8F0] dark:bg-[#0F172A] ${customClass}`}>
        {title}
    </div>
  )
}

export default ModalBtn