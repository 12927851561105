import React, { useState } from 'react';

function FundTable({ data, currency = '%', showPagination = true, showColumns = ["Stock/Ticker name", "Weight in QQQ", "Weight in SPY", "Combined "] }) {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const columns = ["Stock/Ticker name", "Weight in QQQ", "Weight in SPY", "Combined "];
  const totalPages = Math.ceil(data?.length / itemsPerPage);

  const startIndex = () => {
    return (currentPage - 1) * itemsPerPage + 1;
  };

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const endIndex = () => {
    return currentPage * itemsPerPage;
  };

  const firstPage = () => {
    setCurrentPage(1);
    onPageChange(1);
  };

  const prevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
      onPageChange(currentPage - 1);
    }
  };

  const nextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
      onPageChange(currentPage + 1);
    }
  };

  const lastPage = () => {
    setCurrentPage(totalPages);
    onPageChange(totalPages);
  };

  const changePage = (pageNumber) => {
    setCurrentPage(pageNumber);
    onPageChange(pageNumber);
  };

  const getPaginatedData = () => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    
    return data?.slice(startIndex, endIndex);
  };

  return (
    <div className='border w-full max-h-[65vh] dark:bg-[#020617] overflow-hidden overflow-hidden dark:border-[#1E293B] rounded-[8px]'>
      <div className='max-h-[45vh] border-b dark:border-[#1E293B] overflow-x-auto w-full overflow-y-auto'>
        <table className='w-full text-[15px] bg-white text-left rtl:text-right'>
          <thead className='z-50 sticky top-0 bg-[#F1F5F9] dark:bg-[#0F172A]'>
            <tr>
              <th className='py-3 whitespace-nowrap border-none px-[1rem] text-[12px] font-[500] w-6 text-center'>
                #
              </th>
              {columns.filter(col => showColumns.includes(col)).map((item, index) => (
                <th key={index} className="w-30 -bg-red-200 text-center px-[2rem] py-3 whitespace-nowrap border-b text-black dark:text-white text-[14px] border-none font-medium">
                  {item === "Combined " ? item + currency : item.includes("Weight") && currency === "$" ? item.replace("Weight", "Price") : item}
                </th>
              ))}
            </tr>
          </thead>
          <tbody className='w-full'>
            {getPaginatedData()?.map((item, i) => (
              <tr key={i} className="text-black dark:hover:bg-[#151d26] dark:text-white hover:bg-[#f9f9f9] border-b-[0.1vh] dark:bg-[#020617]  dark:border-[#020617] ">
                <td className='py-3 whitespace-nowrap px-1 dark:border-[#020617]  text-[12px] font-[500] w-6 -bg-red-200 text-center'>
                  {i + 1}
                </td>
                {showColumns.includes("Stock/Ticker name") && (
                  <td className='py-4 whitespace-nowrap px-1 px-[1rem] dark:border-[#020617] -bg-green-200 border-b text-[12px] font-[500] w-6'>
                    <div className='w-full h-full flex items-center justify-center space-x-3'>
                      <div>
                        <img src={require(`../../assets/images/${item?.img}`)} className='w-8 h-8' />
                      </div>
                      <div className='flex flex-col'>
                        <span className='text-[18px] font-bold'>
                          {item?.ticker}
                        </span>
                        <span className='text-[11px] dark:text-[#94A3B8]'>
                          $187.42 <span className='text-[#34D399]'>+0.17(+1.82%)</span>
                        </span>
                      </div>
                    </div>
                  </td>
                )}
                {showColumns.includes("Weight in QQQ") && (
                  <td className='py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#020617] border-b text-[12px] font-[500] w-6 text-center'>
                    {currency === "$" ? currency + item?.weight_in_qqq : item?.weight_in_qqq + currency}
                  </td>
                )}
                {showColumns.includes("Weight in SPY") && (
                  <td className={`py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#020617] border-b text-[12px] font-[500] w-6 text-center `}>
                    {currency === "$" ? currency + item?.weight_in_spy : item?.weight_in_spy + currency}
                  </td>
                )}
                {showColumns.includes("Combined ") && (
                  <td className={`py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#020617] border-b text-[12px] font-[500] w-6 text-center `}>
                    {currency === "$" ? currency + (item?.weight_in_qqq + item?.weight_in_spy) : (item?.weight_in_qqq + item?.weight_in_spy) + currency}
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showPagination && (
        <div className='w-full h-[10vh] px-4 sm:flex-1 mb-2 sm:flex sm:items-center sm:justify-between'>
          <div>
            <p className='text-[14px] font-[500] tableShowing leading-5 font-[500] dark:text-[#64748B] text-[#6D6D6D]'>
              Showing:
              <span> {startIndex()} </span>
              to
              <span> {endIndex()} </span>
              of
              <span> {data?.length} </span>
              results
            </p>
          </div>
          <div>
            <nav className="paginationArea">
              <ul className="flex items-center border dark:border-[#1F2A37] rounded-[8px] h-10 text-base dark:bg-transparent">
                <li>
                  <button
                    onClick={firstPage}
                    disabled={currentPage === 1}
                    className={`${
                      currentPage === 1
                        ? 'cursor-not-allowed text-[#D1D1D1] dark:text-[#5D5D5D]'
                        : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-white dark:hover:bg-[#1F2A37]'
                    } text-[14px] border-r dark:border-[#1F2A37] text-[#5D5D5D] h-10 flex items-center justify-center px-4 ms-0 leading-tight font-[500]`}
                  >
                    First
                  </button>
                </li>
                <li>
                  <button
                    onClick={prevPage}
                    disabled={currentPage === 1}
                    className={`${
                      currentPage === 1
                        ? 'cursor-not-allowed text-[#D1D1D1] dark:text-[#5D5D5D]'
                        : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-white dark:hover:bg-[#1F2A37]'
                    } text-[14px] text-[#5D5D5D] h-10 flex items-center justify-center px-4 ms-0 leading-tight font-[500]`}
                  >
                    Previous
                  </button>
                </li>
                {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
                  <li key={pageNumber}>
                    <button
                      onClick={() => changePage(pageNumber)}
                      className={`${
                        currentPage === pageNumber
                          ? 'bg-[#E2E8F0]  dark:bg-[#F8FAFC] text-[#020617]'
                          : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#94A3B8] dark:hover:bg-[#1F2A37]'
                      } text-[14px] h-10 flex items-center border-l border-r dark:border-[#1F2A37] justify-center px-4 ms-0 leading-tight font-[500]`}
                    >
                      {pageNumber}
                    </button>
                  </li>
                ))}
                <li>
                  <button
                    onClick={nextPage}
                    disabled={currentPage === totalPages}
                    className={`${
                      currentPage === totalPages
                        ? 'cursor-not-allowed text-[#D1D1D1] dark:text-[#5D5D5D]'
                        : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-white dark:hover:bg-[#1F2A37]'
                    } text-[14px] text-[#5D5D5D] border-l border-r dark:border-[#1F2A37] h-10 flex items-center justify-center px-4 ms-0 leading-tight font-[500]`}
                  >
                    Next
                  </button>
                </li>
                <li>
                  <button
                    onClick={lastPage}
                    disabled={currentPage === totalPages}
                    className={`${
                      currentPage === totalPages
                        ? 'cursor-not-allowed text-[#D1D1D1] dark:text-[#5D5D5D]'
                        : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[white] dark:hover:bg-[#1F2A37]'
                    } text-[14px] text-[#5D5D5D] flex h-10 items-center justify-center px-4 ms-0 leading-tight font-[500]`}
                  >
                    Last
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      )}
    </div>
  );
}

export default FundTable;
