// src/api.js
import axios, { Axios } from "axios";

// const API_URL = "http://192.168.0.101:8080"; // Replace with your actual API URL
// const API_URL = "http://192.168.0.110:8080"; // Replace with your actual API URL
// const API_URL = "http://192.168.0.107:8080"; // Replace with your actual API URL
const API_URL = "https://ivd-dev.dimensionalsys.com"; // Replace with your actual API URL
// const API_URL = "https://broadly-certain-beetle.ngrok-free.app/"; // Replace with your actual API URL

const loginApi = async (email, password) => {
  try {
    const response = await axios.post(`${API_URL}/auth/login`, {
      email,
      password,
    });
    return response.data;
  } catch (error) {
    console.error("Error during login:", error);
    throw error;
  }
};

const createUser = async (email, password, name) => {
  try {
    const response = await axios.post(`${API_URL}/auth/register`, {
      email,
      password,
      name,
    });
    return response.data;
  } catch (error) {
    console.error("Error during signup:", error);
    throw error;
  }
};

const verifyEmail = async (email) => {
  try {
    const response = await axios.post(
      `${API_URL}/auth/mailForEmailVerificaiton`,
      { email }
    );
    return response.data;
  } catch (error) {
    console.error("Error during verify Email:", error);
    throw error;
  }
};

const forgetPassword = async (email) => {
  try {
    const response = await axios.post(`${API_URL}/auth/forgetPassword`, {
      email,
    });
    return response.data;
  } catch (error) {
    console.error("Error during forgetPassword:", error);
    throw error;
  }
};

const createSession = async (token, sessionData) => {
  try {
    const response = await axios.post(
      `${API_URL}/session/device`,
      sessionData,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during session creation:", error);
    throw error;
  }
};

const deleteSession = async (token, id) => {
  try {
    const response = await axios.delete(
      `${API_URL}/session/delete/device/${id}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during session deletion:", error);
    throw error;
  }
};

const sessionValidate = async (token) => {
  try {
    const response = await axios.get(`${API_URL}/session`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during session validation:", error);
    throw error;
  }
};

// my new api about Quote pages
const getSummary = async (token, symbol) => {
  try {
    const response = await axios.get(
      `${API_URL}/finhub/companyData/summery/${symbol}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );

    return response.data;
  } catch (error) {
    console.error("Error during getSummary:", error);
    throw error;
  }
};

//about api calling
const getAbout = async (token, symbol) => {
  try {
    
    const response = await axios.get(
      `${API_URL}/finhub/companyData/about/${symbol}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
   
    return response.data;
  } catch (error) {
    console.error("Error during getAbout:", error);
    throw error;
  }
};

//get dividends data
const getDividends = async (token, symbol) => {
  try {
    const response = await axios.get(
      `${API_URL}/finhub/companyData/dividends/${symbol}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    // console.log("About data ", response?.data)
    return response.data;
  } catch (error) {
    console.error("Error during getDividends:", error);
    throw error;
  }
};

//get Grades data
const getDGrades = async (token, symbol) => {
  try {
    const response = await axios.get(`${API_URL}/grade/get/${symbol}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    // console.log("About data ", response?.data)
    return response.data;
  } catch (error) {
    console.error("Error during getDGrades:", error);
    throw error;
  }
};

//news Data Fetching
const getNews = async (token, symbol) => {




  function getPreviousDate() {
    // Get the current date
    const currentDate = new Date();
  
    // Subtract one day (24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    const previousDate = new Date(currentDate.getTime() - (24 * 60 * 60 * 1000));
  
    // Return the previous date
    return previousDate;
  }
  try {
    const startingDate = getPreviousDate()?.toISOString().split('T')[0];
    const closingDate = new Date().toISOString().split('T')[0];
    const response = await axios.get(
      `${API_URL}/finhub/companyNews/${symbol}/${startingDate}/${closingDate}?page=1`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during getNews:", error);
    throw error;
  }
};

// Search APi
const getSearch = async (token, symbol) => {
  try {
    const response = await axios.get(`${API_URL}/finhub/symbol/${symbol}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during getSearch:", error);
    throw error;
  }
};

// Creating the portfolio
const createPortfolio = async (token, name) => {
  try {
    const response = await axios.post(
      `${API_URL}/v1/create/portfolio`,
      { name },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during creating portfolio:", error.message);
    throw error;
  }
};

const getPortfolio = async (token) => {
  try {
  
    const response = await axios.get(`${API_URL}/v1/user/portfolio`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during creating portfolio:", error.message);
    throw error;
  }
};

// Adding symbol to the portfolio
const addSymbol = async (token,portfolio_Id,symbol,noOfShare,costPerShare) => {
  try {
    const response = await axios.post(
      `${API_URL}/v1/add/symbol`,
      { portfolio_Id,symbol,noOfShare,costPerShare },
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    return response.data;
  } catch (error) {
    console.error("Error during creating portfolio:", error.message);
    throw error;
  }
};

// const getPortfolioGraph = async (token, portfolioId, filter) => {
//   try {
//     console.log("id",portfolioId, filter)
//     const response = await axios.get(`${API_URL}/v1/get/user/portfolio/graph/${portfolioId}`, {
//       headers: {
//         Authorization: `Bearer ${token}`, 
//       },
//       params: {
//        filter
//       },
//     });
//     console.log("response of Graph data ", response?.data)
//     return response.data;
//   } catch (error) {
//     console.error("Error during fetching portfolio graph data:", error.message);
//     throw error;
//   }
// };
const getPortfolioGraph = async (token, portfolioId, filter) => {
  try {
    const response = await axios.get(`${API_URL}/v1/get/user/portfolio/graph/${portfolioId}?filter=${filter}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error("Error during fetching portfolio graph data:", error.message);
    throw error;
  }
};

const getOneUserPortolio=async (token, portfolioId) => {
  try {
    const response = await axios.get(`${API_URL}/v1/user/protfolio/${portfolioId}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during fetching user portfolio graph data:", error.message);
    throw error;
  }
};

// adding to the watchlist
const addWatchlist = async (token,symbol) => {
  try {
    
    const response = await axios.get(
      `${API_URL}/auth/addWatchlist/${symbol}`,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    );
    
    return response.message;
  } catch (error) {
    console.error("Error during adding watchlist:", error.message);
    throw error;
  }
};


const getDashboard=async (token) => {
  try {
    const response = await axios.get(`${API_URL}/v1/get/all/data/for/dashboard`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    return response.data;
  } catch (error) {
    console.error("Error during fetching user portfolio graph data:", error.message);
    throw error;
  }
};

const getDashBoardChart= async (token,filter) => {
  try {
    const response = await axios.get(`${API_URL}/v1/get/user/Graph?filter=${filter}`, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    
    return response.data;
  } catch (error) {
    console.error("Error during fetching user portfolio graph data:", error.message);
    throw error;
  }
};



const getUserWtachListData = async (token) =>{
  try{
    const Responce = await axios.get(`${API_URL}/auth/get/watchlist`,{
      headers:{
        Authorization:`Bearer ${token}`
      }

    });
    return Responce.data;

  }catch(error){
     console.log(error.message);
     throw error
  }
}




const GetUserOnePortfolio = async(portfolioId) =>{
    const token = localStorage.getItem("token");
    try{
      const responce = await Axios.get(`${API_URL}//v1/user/protfolio/${portfolioId}`,{
        headers:{
          Authorization:`Bearer ${token}`
        }
      });
      return responce.data

    }catch(err){
      console.log(err);
      throw err
    }
}
export {
  loginApi,
  createSession,
  deleteSession,
  sessionValidate,
  createUser,
  verifyEmail,
  forgetPassword,
  getSummary,
  getAbout,
  getDividends,
  getDGrades,
  getNews,
  getSearch,
  createPortfolio,
  getUserWtachListData,
  getPortfolio,addSymbol,getPortfolioGraph,getOneUserPortolio,addWatchlist,getDashboard,getDashBoardChart,
  GetUserOnePortfolio
};
