import React,{useState} from 'react'

function WatchlistTable({data}) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage,setItemsPerPage] = useState(10)
    const columns = ["Stock/Ticker name","Last","%Change","$Change","Volume","Market Cap"]
    const totalPages = Math.ceil(data?.length / itemsPerPage);
    const [favourties,setFovourites] = useState(["IBM","TSLA"])


    const handleAddToFavourites = (ticker) => {
      let isPresent = favourties.includes(ticker);
      if (isPresent) {
        setFovourites(favourties.filter((item) => item !== ticker));
      }else{
        setFovourites([...favourties, ticker]);
      }
    }

    const startIndex = ()=>{
        return ((currentPage - 1) * itemsPerPage) + 1
    };
    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };

    const endIndex = ()=>{
        return currentPage * itemsPerPage
    };

    const firstPage = () => {
        setCurrentPage(1);
        onPageChange(1);
      };
    
      const prevPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          onPageChange(currentPage - 1);
        }
      };

      const nextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
          onPageChange(currentPage + 1);
        }
      };

      const lastPage = () => {
        setCurrentPage(totalPages);
        onPageChange(totalPages);
      };

      const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
      };

      const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage + 1;
        const endIndex = startIndex * itemsPerPage;
        return data?.slice(startIndex, endIndex);
      };

  return (
    <div className='border w-full max-h-[65vh] dark:bg-[#020617] overflow-hidden overflow-hidden dark:border-[#1E293B] rounded-[8px]'>
        <div className='max-h-[55vh] border-b dark:border-[#1E293B] overflow-x-auto w-full overflow-y-auto'>
            <table className='w-full text-[15px] bg-white text-left rtl:text-right'>
                <thead className='z-50 sticky top-0 bg-[#F1F5F9] dark:bg-[#0F172A]'>
                    <tr>
                        <th className='py-3 whitespace-nowrap border-none px-[1rem] text-[12px] font-[500] w-6 text-center'>
                        
                        </th>
                        <th className='py-3 whitespace-nowrap border-none px-[1rem] text-[12px] font-[500] w-6 text-center'>
                            #
                        </th>
                        {columns?.map((item,index) => (
                            <th key={index} className="w-30 -bg-red-200 text-center px-[2rem] py-3 whitespace-nowrap border-b text-black dark:text-white text-[14px] border-none font-medium">
                                {item}
                            </th>
                        ))}
                    </tr>
                </thead>
                
                <tbody className='w-full'>
                    
                    {getPaginatedData()?.map((item,i)=>(
                        <tr  onClick={()=>{console.log(item)}} className=" text-black dark:hover:bg-[#151d26] dark:text-white hover:bg-[#f9f9f9] border-b-[0.1vh] dark:bg-[#020617]  dark:border-[#292929a1] ">
                            <td className='py-3 whitespace-nowrap px-1 dark:border-b-[#292929a1] border-b text-[12px] font-[500] w-6 -bg-red-200 text-center'>
                            <div className='w-full h-full text-center flex justify-center'>
                            <svg onClick={()=>handleAddToFavourites(item?.ticker)} className={`cursor-pointer text-[#F97316] ${favourties.includes(item?.ticker) ? 'text-[#F97316]' : 'text-gray-300'}`} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 14.27L13.15 16.78C13.91 17.24 14.84 16.56 14.64 15.7L13.54 10.98L17.21 7.79999C17.88 7.22 17.52 6.12 16.64 6.04999L11.81 5.64L9.92 1.17999C9.58 0.369995 8.42 0.369995 8.08 1.17999L6.19 5.62999L1.36 6.03999C0.479996 6.10999 0.119996 7.20999 0.789996 7.78999L4.46 10.97L3.36 15.69C3.16 16.55 4.09 17.23 4.85 16.77L9 14.27Z" fill="currentColor"/>
                            </svg>
                            </div>

                            </td>
                            <td className='py-3 whitespace-nowrap px-1 dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 -bg-red-200 text-center'>
                                {i+1}
                            </td>
                            <td className='py-4 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] -bg-green-200 border-b text-[12px] font-[500] w-6'>
                                <div className='w-full h-full flex items-center justify-center space-x-3'>
                                    <div>
                                        <img src={require(`../../assets/images/${item?.img}`)} className='w-8 h-8' />
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='text-[18px] font-bold'>
                                            {item?.ticker}
                                        </span>
                                        <span className='text-[11px] dark:text-[#94A3B8]'>
                                            $187.42 <span className='text-[#34D399]'>+0.17(+1.82%)</span>
                                        </span>

                                    </div>

                                </div>
                            </td>
                          
                            <td className='py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center'>
                                {item?.price}
                            </td>
                            <td className={`py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center ${item.change > 0 ? 'text-[#34D399]' : 'text-[#DC2626]'} `}>
                                {item?.change ? `${item?.change > 0 ?'+':''}${item?.change}%`:'N/A'}
                            </td>
                            <td className={`py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center ${item.pl_dollar > 0 ? 'text-[#34D399]' : 'text-[#DC2626]'}`}>
                            {item?.pl_dollar ? `${item?.pl_dollar > 0 ?'+':''}${item?.pl_dollar}`:'N/A'}
                            </td>
                          
                            <td className='py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center'>
                                {item?.equity}
                            </td>
                            <td className='py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center'>    
                                {item?.market}
                            </td>
                        
                            
                        </tr>
                    )) }
                </tbody>
            </table>
        </div>
        <div className='w-full h-[10vh] px-4 sm:flex-1 mb-2 sm:flex sm:items-center sm:justify-between'>
            <div>
           <p className='text-[14px] font-[500] tableShowing leading-5 font-[500] dark:text-[#64748B] text-[#6D6D6D]'>
            Showing:
                <span> { startIndex() } </span>
                to
                <span> { endIndex() } </span>
                of
                <span> { data?.length } </span>
                results   
                </p>
            </div>
            <div>
          {data?.length > 0 &&  
             <nav className="paginationArea">
    <ul className="flex items-center border dark:border-[#1F2A37] rounded-[8px] h-10 text-base bg-[#f1f5f9] dark:bg-[#0f172a]">
      {/* <li>
        <button
          onClick={firstPage}
          disabled={currentPage === 1}
          className={`${
            currentPage === 1
              ? 'cursor-not-allowed text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px] border-r dark:border-[#1F2A37] h-10 flex items-center justify-center px-4 ms-0 leading-tight`}
        >
          First
        </button>
      </li> */}
      <li>
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          className={`${
            currentPage === 1
              ? 'cursor-not-allowed  text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px] h-10 flex items-center justify-center px-4 ms-0 leading-tight`}
        >
          Previous
        </button>
      </li>
      {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
        <li key={pageNumber}>
          <button
            onClick={() => changePage(pageNumber)}
            className={`${
              currentPage === pageNumber
                ? 'bg-[#E2E8F0]  dark:bg-[#F8FAFC] text-[#020617]'
                : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#94A3B8] dark:hover:bg-[#1F2A37]'
            } text-[14px]  h-10 flex items-center border-l borde-r dark:border-[#1F2A37] justify-center px-4 ms-0 leading-tight `}
          >
            {pageNumber}
          </button>
        </li>
      ))}
      <li>
        <button
          onClick={nextPage}
          disabled={currentPage === totalPages}
          className={`${
            currentPage === totalPages
              ? 'cursor-not-allowed  text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px]  border-l border-r dark:border-[#1F2A37] h-10 flex items-center justify-center px-4 ms-0 leading-tight `}
        >
          Next
        </button>
      </li>
      {/* <li>
        <button
          onClick={lastPage}
          disabled={currentPage === totalPages}
          className={`${
            currentPage === totalPages
              ? 'cursor-not-allowed text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px]  flex h-10 items-center justify-center px-4 ms-0 leading-tight `}
        >
          Last
        </button>
      </li> */}
    </ul>
            </nav>
            }
    </div>


        </div>
    </div>
  )
}

export default WatchlistTable