import React, { useState,useRef } from 'react'
import FundTable from '../table/FundTable'


function TabulatedView({data})  {
  const [fundTableData,setFundTableData] = useState(data)
  const [isPercentage, setPercentage] = useState("%");

  const handlePercentage = () => {
    if(isPercentage === "$"){
      setPercentage("%")
    } else setPercentage("$")
  };


  return (
    <>
      <div className='w-full h-full font-inter'>
          <div className="flex space-x-2 pb-4 text-[20px]">
            <span className={`${isPercentage ==="$" ? '':'text-[#F88F00]'}`}>%</span>
            <button
              onClick={handlePercentage}
              className={`relative w-12 h-7 flex items-center bg-slate-300 dark:bg-[#1E293B] rounded-full cursor-pointer`}
            >
              <div
                className={`w-6 h-6 bg-slate-100 dark:bg-[#475569] rounded-full shadow-md transform transition-transform ${
                isPercentage === "$" ? 'translate-x-6' : 'translate-x-0'
                }`}
              />
            </button>
            <span className={`${isPercentage === '$' ? 'text-[#F88F00]':''}`}>$</span>
            <span className='text-[#94A3B8] text-[14px] mt-1 pl-2'>Assuming one share in each fund at the current market value</span>
          </div>
            <FundTable  data={fundTableData} currency={isPercentage}/>
      </div>
    </>
  )
}

export default TabulatedView