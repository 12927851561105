import React, { useEffect, useState } from "react";
import PrimaryButton from "../components/Button";
import CardBox from "../components/CardBox";
import IbmImage from "../assets/images/ibm.png";
import { useSelector } from "react-redux";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";
import CalculatorChart from "../components/CalculatorChart";

function IncomeGoals() {
  const darkMode = useSelector((state) => state.theme.darkMode);
  const [goalValue, setGoal] = useState(0);
  const [tickerData, setData] = useState(null);
  const [loader, setLoader] = useState(false);
  const [inputCount, setInputCount] = useState(1);
  const [symbolName, setSymbolName] = useState("");
  const [symbolPrice, setSymbolPrice] = useState("");
  const [symbolDY, setSymbolDY] = useState("");
  const [symbolAD, setSymbolAD] = useState("");
  const [prifeDif, setPDif] = useState("");
  const [diffInPercDif, setRDif] = useState("");
  const [tickerLogo, setLogo] = useState("");
  const [addedSymbolData, setAddedData] = useState([]);
  // const [addedSymbolData, setAddedData] = useState([
  //   {
  //     id: 1,
  //     logo: "../assets/images/ibm.png",
  //     value: 50000,
  //     color: "#177AD5",
  //     symbolName: "IBM",
  //     companyName: "Apple Inc.",
  //     shareValue: "167.36",
  //     dividendYield: "3.99",
  //     annualDividend: "6.68",
  //     totalShareValue: "8000",
  //     diffInPrice: "-0.514", //this should be in %
  //     open: "21.16",
  //     high: "418.348",
  //     low: "410.00",
  //     close: "21.50",
  //     diffInPerc: "-2.13", //net change
  //     svg: "",
  //     ideasKey: "15",
  //   }
  // ]);
  const [showChart, setShowChart] = useState(false);

  const data = [
    {
      id: 1,
      logo: "../assets/images/ibm.png",
      value: 50000,
      color: "#177AD5",
      symbolName: "IBM",
      companyName: "Apple Inc.",
      shareValue: "167.36",
      dividendYield: "3.99",
      annualDividend: "6.68",
      totalShareValue: "8000",
      diffInPrice: "-0.514", //this should be in %
      open: "21.16",
      high: "418.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "-2.13", //net change
      svg: "",
      ideasKey: "15",
    },
    {
      id: 2,
      logo: "../assets/images/ibm.png",
      value: 12000,
      color: "#177AD5",
      symbolName: "TSLA",
      companyName: "Tesla Inc.",
      shareValue: "110.71",
      dividendYield: "2.03",
      annualDividend: "8.68",
      totalShareValue: "10000",
      diffInPrice: "2.15",
      open: "11.16",
      high: "12.00",
      low: "11.00",
      low: "10.20",
      close: "12.50",
      diffInPerc: "+0.5",
      svg: "",
      ideasKey: "45",
    },
    {
      id: 3,
      logo: "../assets/images/ibm.png",
      value: 12000,
      color: "#177AD5",
      symbolName: "AMZN",
      companyName: "Apple Inc.",
      shareValue: "165.71",
      dividendYield: "4.03",
      annualDividend: "6.68",
      totalShareValue: "8000",
      diffInPrice: "1.15",
      open: "21.16",
      high: "22.00",
      low: "21.00",
      low: "20.20",
      close: "21.50",
      diffInPerc: "-0.5",
      svg: "",
      ideasKey: "15",
    },
    {
      id: 4,
      logo: "../assets/images/ibm.png",
      value: 12000,
      color: "#177AD5",
      symbolName: "AAPL",
      companyName: "Tesla Inc.",
      shareValue: "110.71",
      dividendYield: "2.03",
      annualDividend: "8.68",
      totalShareValue: "10000",
      diffInPrice: "2.15",
      open: "11.16",
      high: "12.00",
      low: "11.00",
      low: "10.20",
      close: "12.50",
      diffInPerc: "+0.5",
      svg: "",
      ideasKey: "75",
    },
    {
      id: 5,
      logo: "../assets/images/ibm.png",
      value: 12000,
      color: "#177AD5",
      symbolName: "MSFT",
      companyName: "Microsoft Coproation stock",
      shareValue: "165.71",
      dividendYield: "4.03",
      annualDividend: "6.68",
      totalShareValue: "8000",
      diffInPrice: "1.15", //this should be in %
      open: "21.16",
      high: "22.00",
      low: "21.00",
      close: "21.50",
      diffInPerc: "-0.5", //net change
      svg: "",
      ideasKey: "00",
    },
    {
      id: 6,
      logo: "../assets/images/ibm.png",
      value: 50000,
      color: "#177AD5",
      symbolName: "AAPL",
      companyName: "Apple Inc.",
      shareValue: "165.71",
      dividendYield: "0.03%",
      annualDividend: "3.68",
      totalShareValue: "8000",
      diffInPrice: "1.514", //this should be in %
      open: "21.16",
      high: "445.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "+2.13", //net change
      svg: "",
      ideasKey: "95",
    },
    {
      id: 7,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "NVDA",
      companyName: "NVIDIA CORP. stock",
      shareValue: "565.71",
      dividendYield: "9.03",
      annualDividend: "0.68",
      totalShareValue: "8000",
      diffInPrice: "-0.414", //this should be in %
      open: "21.16",
      high: "618.348",
      low: "210.00",
      close: "21.50",
      diffInPerc: "-4.13", //net change
      svg: "",
      ideasKey: "65",
    },
    {
      id: 8,
      logo: "../assets/images/ibm.png",
      value: 30000,
      color: "#177AD5",
      symbolName: "GOOG",
      companyName: "AlphaGo Inc. stock",
      shareValue: "165.71",
      dividendYield: "5.03",
      annualDividend: "6.68",
      totalShareValue: "7000",
      diffInPrice: "-0.414", //this should be in %
      open: "21.16",
      high: "618.348",
      low: "510.00",
      close: "21.50",
      diffInPerc: "-8.13", //net change in $
      svg: "",
      ideasKey: "95",
    },
    {
      id: 9,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "META",
      companyName: "Meta Platform Inc. stock",
      shareValue: "565.71",
      dividendYield: "6.03",
      annualDividend: "8.68",
      totalShareValue: "7000",
      diffInPrice: "2.414", //this should be in %
      open: "21.16",
      high: "618.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "+4.13", //net change
      svg: "",
      ideasKey: "25",
    },
    {
      id: 10,
      logo: "../assets/images/ibm.png",
      value: 40000,
      color: "#177AD5",
      symbolName: "BRK/A",
      companyName: "Berkshire Hathaway Inc. stock",
      shareValue: "765.71",
      dividendYield: "3.03",
      annualDividend: "4.68",
      totalShareValue: "8000",
      diffInPrice: "-3.414", //this should be in %
      open: "21.16",
      high: "618.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "-2.13", //net change
      svg: "",
      ideasKey: "75",
    },
    {
      id: 11,
      logo: "../assets/images/ibm.png",
      value: 60000,
      color: "#177AD5",
      symbolName: "BRK?B",
      companyName: "Berkshire Hathaway Inc. stock",
      shareValue: "965.71",
      dividendYield: "9.03",
      annualDividend: "6.68",
      totalShareValue: "9000",
      diffInPrice: "8.414", //this should be in %
      open: "21.16",
      high: "718.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "-4.13", //net change
      svg: "",
      ideasKey: "00",
    },
    {
      id: 12,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "TSM",
      companyName: "Taiwan Semicounder Inc. stock",
      shareValue: "151.95",
      dividendYield: "1.62",
      annualDividend: "2.46",
      totalShareValue: "7000",
      diffInPrice: "2.414", //this should be in %
      open: "21.16",
      high: "618.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "+4.13", //net change
      svg: "",
      ideasKey: "05",
    },
    {
      id: 13,
      logo: "../assets/images/ibm.png",
      value: 60000,
      color: "#177AD5",
      symbolName: "LLY",
      companyName: "Eli Lilly Inc. stock",
      shareValue: "765.71",
      dividendYield: "3.03",
      annualDividend: "4.68",
      totalShareValue: "7000",
      diffInPrice: "-2.414", //this should be in %
      open: "21.16",
      high: "618.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "+6.13", //net change
      svg: "",
      ideasKey: "00",
    },
    {
      id: 14,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "NVO",
      companyName: "Novo Nordisk A/S. stock",
      shareValue: "865.71",
      dividendYield: "6.03",
      annualDividend: "7.68",
      totalShareValue: "9000",
      diffInPrice: "9.414", //this should be in %
      open: "21.16",
      high: "918.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "+7.13", //net change
      svg: "",
      ideasKey: "65",
    },
    {
      id: 15,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "TSML",
      companyName: "Taiwan Semicounder Inc. stock",
      shareValue: "565.71",
      dividendYield: "6.03",
      annualDividend: "8.68",
      totalShareValue: "7000",
      diffInPrice: "2.414", //this should be in %
      open: "21.16",
      high: "618.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "+4.13", //net change
      svg: "",
      ideasKey: "00",
    },
    {
      id: 16,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "MA",
      companyName: "Master card Inc. stock",
      shareValue: "465.71",
      dividendYield: "3.03",
      annualDividend: "2.68",
      totalShareValue: "4000",
      diffInPrice: "-2.414", //this should be in %
      open: "21.16",
      high: "318.348",
      low: "310.00",
      close: "21.50",
      diffInPerc: "-4.13", //net change
      svg: "",
      ideasKey: "55",
    },
    {
      id: 17,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "V",
      companyName: "Visa",
      shareValue: "865.71",
      dividendYield: "2.03",
      annualDividend: "1.68",
      totalShareValue: "7000",
      diffInPrice: "7.414", //this should be in %
      open: "21.16",
      high: "818.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "+5.13", //net change
      svg: "",
      ideasKey: "95",
    },
    {
      id: 18,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "PG",
      companyName: "Protective shield Inc. stock",
      shareValue: "765.71",
      dividendYield: "6.03",
      annualDividend: "8.68",
      totalShareValue: "7000",
      diffInPrice: "-0.414", //this should be in %
      open: "21.16",
      high: "618.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "-0.13", //net change
      svg: "",
      ideasKey: "85",
    },
    {
      id: 19,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "JNJ",
      companyName: "Jonson & Johnson. stock",
      shareValue: "595.71",
      dividendYield: "5.03",
      annualDividend: "3.68",
      totalShareValue: "7000",
      diffInPrice: "1.414", //this should be in %
      open: "21.16",
      high: "618.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "+0.13", //net change
      svg: "",
      ideasKey: "00",
    },
    {
      id: 20,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "ASML",
      companyName: "ASML Holdings. stock",
      shareValue: "265.71",
      dividendYield: "2.03",
      annualDividend: "1.68",
      totalShareValue: "7000",
      diffInPrice: "-0.414", //this should be in %
      open: "21.16",
      high: "318.348",
      low: "310.00",
      close: "21.50",
      diffInPerc: "+0.13", //net change
      svg: "",
      ideasKey: "45",
    },
    {
      id: 21,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "COST",
      companyName: "Costoco Wholesale Corp. stock",
      shareValue: "565.71",
      dividendYield: "2.03",
      annualDividend: "1.68",
      totalShareValue: "7000",
      diffInPrice: "2.414", //this should be in %
      open: "21.16",
      high: "648.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "+8.13", //net change
      svg: "",
      ideasKey: "45",
    },
    {
      id: 22,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "HD",
      companyName: "Home Depot. stock",
      shareValue: "365.71",
      dividendYield: "6.03",
      annualDividend: "4.68",
      totalShareValue: "7000",
      diffInPrice: "1.414", //this should be in %
      open: "21.16",
      high: "518.348",
      low: "210.00",
      close: "21.50",
      diffInPerc: "+2.13", //net change
      svg: "",
      ideasKey: "95",
    },
    {
      id: 23,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "XOM",
      companyName: "Exxon Mobil. stock",
      shareValue: "565.71",
      dividendYield: "2.03",
      annualDividend: "3.68",
      totalShareValue: "7000",
      diffInPrice: "6.414", //this should be in %
      open: "21.16",
      high: "818.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "+7.13", //net change
      svg: "",
      ideasKey: "45",
    },
    {
      id: 24,
      logo: "../assets/images/ibm.png",
      value: 80000,
      color: "#177AD5",
      symbolName: "WMT",
      companyName: "Wal-mart. stock",
      shareValue: "365.71",
      dividendYield: "4.03",
      annualDividend: "8.68",
      totalShareValue: "7000",
      diffInPrice: "6.414", //this should be in %
      open: "21.16",
      high: "618.348",
      low: "410.00",
      close: "21.50",
      diffInPerc: "-0.13", //net change
      svg: "",
      ideasKey: "45",
    },
  ];

  const ChartColors = [
    "#FF0000",
    "#008000",
    "#0000FF",
    "#FFFF00",
    "#FFA500",
    "#800080",
    "#FFC0CB",
    "#00FFFF",
    "#FF00FF",
    "#00FF00",
    "#008080",
    "#A52A2A",
    "#800000",
    "#000080",
    "#808000",
    "#87CEEB",
    "#40E0D0",
    "#FFD700",
    "#C0C0C0",
    "#808080",
    "#FFFFFF",
    "#000000",
    "#F5F5DC",
    "#4B0082",
    "#EE82EE",
    "#FF007F",
    "#E0115F",
    "#0F52BA",
    "#50C878",
    "#FFBF00",
    "#FFC87C",
    "#F0EAD6",
    "#00A86B",
    "#7FFFD4",
    "#E6E200",
    "#A8C3BC",
    "#FF7F50",
    "#E6E6FA",
    "#FFF700",
    "#FFDAB9",
    "#DDA0DD",
    "#708090",
    "#36454F",
    "#D2691E",
    "#D4AF37",
    "#FFFFF0",
    "#3EB489",
    "#353839",
    "#F4C430",
    "#F28500",
    "#F3E5AB",
    "#F4F8FF",
    "#CCCCCC",
    "#1560BD",
    "#800020",
    "#FAD6A5",
    "#8A496B",
    "#228B22",
    "#4F42B5",
    "#C19A6B",
    "#66FF00",
    "#6495ED",
    "#FF0000",
    "#B9D9EB",
    "#FFD700",
    "#FF4500",
    "#DFF0F5",
    "#696969",
    "#F0FFFF",
    "#C2B280",
    "#8B4513",
    "#6B8E23",
    "#E25822",
    "#8A795D",
    "#DDE4E8",
    "#FFFAFA",
    "#E0F2F1",
    "#DAA520",
    "#FFD700",
    "#0080FF",
    "#F0FFF0",
    "#4E5180",
    "#FFC280",
    "#E6E6FA",
    "#191970",
    "#FFC280",
    "#FAD6A5",
    "#FFD700",
    "#FFBF00",
    "#8A496B",
    "#4E5180",
    "#42426F",
    "#FFFF00",
    "#FFD700",
    "#FFA500",
    "#FF0000",
    "#008000",
    "#0000FF",
    "#FFFF00",
    "#FFA500",
  ];

  useEffect(() => {
    data?.find((e) => {
      if (e?.symbolName === symbolName?.toUpperCase()) {
        setSymbolAD(e?.annualDividend);
        setSymbolDY(e?.dividendYield);
        setSymbolPrice(e?.shareValue);
        setPDif(e?.diffInPrice);
        setRDif(e?.diffInPerc);
        setLogo(e?.logo);
      }
    });
    if(symbolName?.length < 1){
      setSymbolAD("")
      setSymbolDY("")
      setSymbolPrice("")
    }
  }, [symbolName]);

  const addTextInput = () => {
    let obj = {
      symbolName: null,
      closingPrice: null,
      dividendYield: null,
      annualDividend: null,
      value: null,
      color: null,
      diffInPrice: null,
      diffInPerc: null,
      logo: null,
    };
    if (symbolPrice) {
      obj.symbolName = symbolName;
      obj.closingPrice = symbolPrice;
      obj.dividendYield = symbolDY;
      obj.annualDividend = symbolAD;
      obj.diffInPrice = prifeDif;
      obj.diffInPerc = diffInPercDif;
      obj.logo = tickerLogo;
      obj.color = ChartColors[Math.floor(Math.random() * ChartColors.length)];
      // console.log("obj test", obj);
      setAddedData([...addedSymbolData, obj]);
      setSymbolName("");
      setSymbolPrice("");
      setSymbolDY("");
      setSymbolAD("");
      setInputCount(inputCount + 1);
      setPDif("");
      setRDif("");
      setLogo("");
    }
  };

  const dataSet = () => {
    let dividedValue = parseInt(goalValue) / addedSymbolData?.length;
    addedSymbolData?.forEach((item) => {
      item.value = dividedValue;
    });
    // console.log("ad", addedSymbolData);
    setData(addedSymbolData);
    // setAddedData([]);
    // setInputCount(1);
    setShowChart(true);
  };

  const deletItem = (val) => {
    const sortedArray = addedSymbolData?.filter(
      (item) => item?.symbolName !== val
    );
    
    setAddedData(sortedArray);
  };

  const childData = (item) => {
    return (
      <div className="flex h-full w-full flex-col px-3 py-2 hover:bg-slate-100 dark:hover:bg-slate-800">
        <div className="flex h-full w-full space-x-3 justify-between items-center">
          <div className="flex items-center space-x-2">
            <img src={IbmImage} className="h-6 w-6" />
            <div className="flex-col flex">
              <span className="text-[14px] font-bold">
                {item?.symbolName.toUpperCase()}
              </span>
              <div className="flex space-x-1">
                <span className="text-[10px] text-slate-400">
                  {"$" + item?.closingPrice}
                </span>
                <span className={`text-[10px] ${item?.diffInPrice.includes("-") ? "text-[#EF4444]" : "text-[#22C55E]"}`}>
                  {item?.diffInPrice}
                </span>
                <span className={`text-[10px] ${item?.diffInPerc.includes("-") ? "text-[#EF4444]" : "text-[#22C55E]"}`}>
                  {"(" + item?.diffInPerc + ")"}
                </span>
              </div>
            </div>
          </div>
          <div className="flex flex-col ">
            <span className="text-[10px] text-slate-400">Div. Yield</span>
            <span className="text-[12px]">{item?.dividendYield + "%"}</span>
          </div>
        </div>
        <div className="flex items-center justify-between h-full w-full">
          <div className="flex flex-col ">
            <span className="text-[10px] text-slate-400">Div. Yield</span>
            <span className="text-[18px] font-bold">
              {"$" + item?.annualDividend}
            </span>
          </div>
          <div
            onClick={() => deletItem(item?.symbolName)}
            className="flex items-center justify-center h-8 w-8 bg-red-100 dark:bg-red-900/40 flex-col rounded-full hover:cursor-pointer"
          >
            <div className="h-0.5 w-3 bg-red-900" />
          </div>
        </div>
      </div>
    );
  };

  const OnChangeEventTriggerd = (newValue) => {
    
    setGoal(newValue);
  };

  return (
    <div className={`flex h-full w-full justify-center flex-col  ${showChart ? 'px-0' : 'px-20'}`}>
     {
      !showChart ?
      (
        <>
        <div className="flex h-3/4 w-full">
          <div className="flex flex-col h-full w-full justify-center space-y-3">
            <span className="text-[35px] font-bold">
              {"$" + parseFloat(goalValue)?.toLocaleString("en-US")}
            </span>
            <div className="flex space-x-3 items-center">
              <span className="text-[16px] text-slate-400">
                Annual Dividend Goal
              </span>
              <svg
                width="16"
                height="16"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.99996 18.3333C14.6023 18.3333 18.3333 14.6024 18.3333 10C18.3333 5.39762 14.6023 1.66666 9.99996 1.66666C5.39759 1.66666 1.66663 5.39762 1.66663 10C1.66663 14.6024 5.39759 18.3333 9.99996 18.3333Z"
                  stroke="#94A3B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 6.66666V10"
                  stroke="#94A3B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10 13.3333H10.0083"
                  stroke="#94A3B8"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            <div className="flex w-4/6 rounded-lg">
              <Slider
                value={goalValue}
                onChange={OnChangeEventTriggerd}
                min={0}
                max={250000}
                trackStyle={{ backgroundColor: "#FFAA36", height: 10 }}
                railStyle={{
                  backgroundColor: darkMode ? "#1E293B" : "#eaf1fc",
                  height: 10,
                }}
                handleStyle={{
                  borderColor: "#FFAA36",
                  height: 20,
                  width: 20,
                  // marginLeft: -10,
                  // marginTop: -5,
                  backgroundColor: "#FFAA36",
                }}
              />
            </div>
          </div>
          <div className="flex flex-col h-full w-full space-y-4 justify-center">
            <PrimaryButton
            _click={()=>dataSet()}
            isDisable={addedSymbolData?.length > 0 ? true : false}
              title={"Calculate income goal"}
              mainClass={` w-64 ${addedSymbolData?.length > 0 ? 'bg-[#FFAA36] font-semibold text-black cursor-pointer hover:hover:opacity-80' : 'bg-[#fbd8a4] dark:bg-[#f2b868] text-slate-600'}`}
            />
            <PrimaryButton
              isDisable={addedSymbolData?.length > 0 ? true : false}
              _click={() => {
                
                setAddedData([]);
                setGoal(0);
              }}
              mainClass={`dark:bg-red-500/15 bg-red-100 w-64 text-red-500 cursor-pointer hover:opacity-80`}
              title={"Reset Values"}
            />
          </div>
      </div>
      <div className="flex h-2/6 w-full items-center justify-evenly">
        <div className=" w-40 flex-col ">
          <span className="text-[10px]">Ticker Name</span>
          <input
            value={symbolName.toUpperCase()}
            onChange={(e) => setSymbolName(e.target.value)}
            className="bg-transparent hover:bg-slate-100 dark:hover:bg-gray-800 border rounded-md dark:border-slate-700 border-slate-200 items-center px-2 text-[13px] outline-none h-10 "
            placeholder="e.g: IBM"
          />
        </div>
        <div className=" w-40 flex-col ">
          <span className="text-[10px]">Current Price $</span>
          <input
            value={symbolPrice}
            readOnly
            className="bg-transparent hover:bg-slate-100 dark:hover:bg-gray-800 border rounded-md dark:border-slate-700 border-slate-200 items-center px-2 text-[13px] outline-none h-10 "
          />
        </div>
        <div className=" w-40 flex-col ">
          <span className="text-[10px]">Dividend Yield %</span>
          <input
            value={symbolDY}
            readOnly
            className="bg-transparent hover:bg-slate-100 dark:hover:bg-gray-800 border rounded-md dark:border-slate-700 border-slate-200 items-center px-2 text-[13px] outline-none h-10 "
          />
        </div>
        <div className=" w-40 flex-col ">
          <span className="text-[10px]">Annual Dividend $</span>
          <input
            value={symbolAD}
            readOnly
            className="bg-transparent hover:bg-slate-100 dark:hover:bg-gray-800 border rounded-md dark:border-slate-700 border-slate-200 items-center px-2 text-[13px] outline-none h-10 "
          />
        </div>
        <PrimaryButton
          isDisable={symbolName?.length > 0 && goalValue > 0 ? true : false}
          // isLoader={loader}
          title={"Add Ticker"}
          _click={() => addTextInput()}
          mainClass={`w-28 mt-6 ${symbolName?.length > 0 && goalValue > 0 ? 'bg-[#FFAA36]  font-semibold text-black cursor-pointer hover:opacity-80':'bg-[#fbd8a4] dark:bg-[#f2b868] text-slate-600 w-28'} `}//""//
        />
      </div>
      <div className="flex h-full w-full overflow-y-auto flex-wrap py-4">
        {addedSymbolData?.length > 0 ? (
          addedSymbolData?.map((item, index) => (
            <div key={index} className="p-2">
              <CardBox mainClass={"w-64 h-32"} childData={childData(item)} />
            </div>
          ))
        ) : (
          <div className=" justify-center items-center flex h-full w-full ">
            <span className="text-slate-400">
              Set goal then add tickers to calculate your goal
            </span>
          </div>
        )}
      </div>
        </>
      )
      :
        <CalculatorChart setOData={setAddedData} data={addedSymbolData} goal={goalValue} showChart={setShowChart} setGoal={setGoal} />
     }
    </div>
  );
}

export default IncomeGoals;
