import React,{useEffect,useState} from 'react'
import { setSelectedRoute } from "../store/slices/themeSlice";
import { useDispatch, useSelector } from "react-redux";
import { useLocation,useNavigate } from 'react-router-dom';
import IconCircle from '../components/iconCircle';
import Summary from '../components/Quote/Summary';
import About from '../components/Quote/About';
import Dividends from '../components/Quote/Dividends';
import Grade from '../components/Quote/Grade';
import Financials from '../components/Quote/Financials';
import News from '../components/Quote/News';

function Quote() {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate()
    const [selectedHeader,setSelectedHeader] = useState("Summary")
    const queryParams = new URLSearchParams(location.search);
    const symbol = queryParams.get('symbol');
    const header = ["Summary","About","Dividends","Grade","Financials","News"]
    const tabComponents = {
        Summary: <Summary />,
        About: <About />,
        Dividends: <Dividends />,
        Grade: <Grade />,
        Financials: <Financials />,
        News: <News />
      };

    useEffect(()=>{
        dispatch(setSelectedRoute({name:"Quote",id:123}))
        getSymbolData()    
    },[])

    const getSymbolData = () => {
        console.log("symbol",symbol);
    }

    const handleBack = ()=>{
        
        dispatch(setSelectedRoute({name:"Dashboard",id:1}))
        navigate('/')
    }

  return (
    <div className='w-full h-full space-y-[1.5rem]'>
        <div className='w-full flex items-center space-x-3'>
            <IconCircle handleClick={handleBack}>
            <svg width="17" height="17" viewBox="0 0 17 17" className='text-[#020617] dark:text-[#94A3B8]' fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M15.7349 8.43384H1.73494" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.73494 15.4338L1.73494 8.43384L8.73494 1.43384" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </IconCircle>
            <span onClick={handleBack} className='dark:text-[#94A3B8] cursor-pointer  hover:underline text-[15px] font-normal'>Back to Dashboard</span>
        </div>
        <div className='flex w-full items-center flex-wrap gap-2'>
            {header?.map((item,i)=>(
                <div key={i} onClick={()=>setSelectedHeader(item)} className={`px-[0.8rem] pt-[0.2rem] pb-[0.3rem]  text-[14px] text-medium rounded-[8px] cursor-pointer text-center ${selectedHeader === item ? 'dark:bg-[#431407] font-[500] text-[#020617] bg-[#f88f00] dark:text-[#F88F00]':'dark:text-[#475569] dark:hover:bg-[#182238] hover:bg-[#d0d8e2] dark:hover:text-[#E2E8F0] bg-[#E2E8F0] font-[400] dark:bg-transparent'}`}>
                    <span>{item}</span>
                </div>
            ))}
        </div>

            {tabComponents[selectedHeader]}
    </div>
  )
}

export default Quote