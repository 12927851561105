import React, { useState, useEffect } from "react";
import MultiAreaChart from "./charts/MultiAreaChart";
import { getDashBoardChart, getPortfolioGraph } from "../ApiControler";
import { useNavigate } from "react-router-dom";
function PortfolioChart({
  _id,
  asset = "0.0",
  change = "0.00",
  changeper = "0.0",
  showGoTo = true,
  series = [],
}) {
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [filter, setFilter] = useState("w");
  const [chartData, setChartData] = useState([]);
  const navigate = useNavigate();
  const handleChange = (event) => {
    setFilter(event.target.value);
    console.log("Filter is changing ", event.target.value,);
  };
  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const fetchData = async () => {
    try {
      
      const token = localStorage.getItem("token");
      // console.log("Token is ", token);
      const data = await getPortfolioGraph(token, _id, filter)
      const transformedData = [
        {
          name: "Prices",
          data: data.data.map((item) => ({
            x: new Date(item.date).getTime(), 
            y: item.prices,
          })),
        },
      ];
      setChartData(transformedData);
    } catch (error) {
      console.log("Error in getting portfolio graph", error.message);
    }
  };


  const fetchData2 = async () => {
    try {
      
      const token = localStorage.getItem("token");
      const data = await getDashBoardChart(token, filter)
      
      const transformedData = [
        {
          name: "Prices",
          data: data.data.map((item) => ({
            x: new Date(item.date).getTime(), 
            y: item.totalPrices,
          })),
        },
      ];
      setChartData(transformedData);
    } catch (error) {
      console.log("Error in getting portfolio graph", error.message);
    }
  };


  //calling the about api
  useEffect(() => {
    if (_id) {
      fetchData();
    }else{
      fetchData2();
    }
    
  }, [_id, filter]);

  const upArrowSvg = (
    <svg
      className="w-3 h-3"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.3029 1.1127L5.30326 1.11304L8.89875 4.53803C8.96726 4.60684 9.00076 4.69328 8.99999 4.77798C8.9992 4.86371 8.96327 4.95087 8.89158 5.01916C8.8191 5.0882 8.71619 5.1313 8.60386 5.13223C8.49279 5.13315 8.38997 5.09269 8.31614 5.02599L6.25664 3.06415L5.41178 2.25934V3.42618V11.6428C5.41178 11.7295 5.37586 11.8179 5.30336 11.8869C5.23004 11.9568 5.12562 12 5.01196 12C4.89829 12 4.79388 11.9568 4.72055 11.8869C4.64805 11.8179 4.61214 11.7295 4.61214 11.6428V3.42618V2.26004L3.76743 3.064L1.70234 5.02945L1.70229 5.02939L1.69595 5.03564C1.6602 5.0709 1.61611 5.1003 1.56561 5.12096C1.51508 5.14164 1.45991 5.15283 1.4035 5.1533C1.34709 5.15376 1.29166 5.14349 1.24065 5.12361C1.18968 5.10374 1.14493 5.075 1.10842 5.04022C1.07196 5.00549 1.04452 4.96555 1.02642 4.92341C1.00836 4.88134 0.999613 4.83718 1.00001 4.79327C1.00041 4.74935 1.00996 4.70527 1.02885 4.66339C1.04777 4.62143 1.07602 4.58182 1.11324 4.54758L1.11329 4.54763L1.11957 4.54165L4.71885 1.11304L4.71921 1.1127C4.75494 1.07859 4.7988 1.05039 4.84882 1.0309L4.85051 1.03024C4.95291 0.98992 5.0692 0.98992 5.1716 1.03024L5.1733 1.0309C5.22332 1.05039 5.26717 1.07859 5.3029 1.1127Z"
        fill="#34D399"
        stroke="#34D399"
      />
    </svg>
  );

  const downArrowSvg = (
    <svg
      className="w-3 h-3"
      viewBox="0 0 10 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.6971 11.8873L4.69674 11.8869L1.10125 8.46197C1.03274 8.39316 0.999238 8.30672 1.00001 8.22202C1.0008 8.13629 1.03673 8.04913 1.10842 7.98084C1.1809 7.9118 1.28381 7.8687 1.39614 7.86777C1.50721 7.86685 1.61003 7.90731 1.68386 7.97401L3.74336 9.93585L4.58822 10.7407V9.57382V1.35721C4.58822 1.27049 4.62414 1.18207 4.69664 1.11306C4.76996 1.04316 4.87438 1 4.98804 1C5.10171 1 5.20612 1.04316 5.27945 1.11306C5.35195 1.18207 5.38786 1.27049 5.38786 1.35721V9.57382V10.739L6.23257 9.935L8.29766 7.97055L8.29771 7.97061L8.30405 7.96436C8.3398 7.9291 8.38389 7.8997 8.43439 7.87904C8.48492 7.85836 8.54009 7.84717 8.5965 7.8467C8.65291 7.84624 8.70834 7.85651 8.75935 7.87639C8.81032 7.89626 8.85507 7.925 8.89158 7.95978C8.92804 7.99451 8.95548 8.03445 8.97358 8.07659C8.99164 8.11866 9.00039 8.16282 8.99999 8.20673C8.99959 8.25065 8.99004 8.29473 8.97115 8.33661C8.95223 8.37857 8.92398 8.41818 8.88676 8.45242L8.88671 8.45237L8.88043 8.45835L5.28115 11.887L5.28079 11.8873C5.24506 11.9214 5.2012 11.9496 5.15118 11.9691L5.14949 11.9698C5.04709 12.0101 4.9308 12.0101 4.8284 11.9698L4.8267 11.9691C4.77668 11.9496 4.73283 11.9214 4.6971 11.8873Z"
        fill="#EF4444"
        stroke="#EF4444"
      />
    </svg>
  );

  const arrow = (
    <svg
      className="w-4 h-4"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.3333 1L8.99999 7.33333L5.66666 4L0.666656 9"
        stroke="#34D399"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M11.3333 1H15.3333V5"
        stroke="#34D399"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
  return (
    <div className="w-full h-full border rounded-[8px] p-4 bg-[#f8fafc] border-[#CBD5E1] dark:bg-[#0F172A] dark:border-[#1F2A37]">
      <div className="w-full h-fit">
        <div className="w-full flex items-start justify-between">
          <div className="flex flex-col">
            <span className="dark:text-[#94A3B8] text-[0.9rem] text-[#475569] font-normal">
              Total Asset
            </span>
            <span className="dark:text-[#FFFFFF] text-[1.8rem] font-[700]">
              ${asset.toLocaleString("en-US")}
            </span>
            <div className="flex items-center space-x-2">
              <span className="text-[#34D399] text-[0.8rem] font-medium">
                Profit/Loss
              </span>

              <span
                className={`font-bold text-[0.9rem] ${
                  change < 0
                    ? "text-red-500"
                    : change > 0
                    ? "text-[#34D399]"
                    : "text-gray-500"
                }`}
              >
             
                {change > 0
                  ? "+" + Math.floor(change) || 0
                  : Math.floor(change) || 0}
              </span>
              {/* <span className="text-[#34D399] text-[0.8rem] font-medium">
                ({Math.floor(changeper)}%)
              </span> */}
              <span
                className={`font-bold text-[0.9rem] ${
                  change < 0
                    ? "text-red-500"
                    : change > 0
                    ? "text-[#34D399]"
                    : "text-gray-500"
                }`}
              >
              {/* <span
                className={`font-bold text-[0.9rem] ${
                  changeper < 0 ? "text-red-500" : "text-[#34D399]"
                }`}
              > */}
                ({Math.floor(changeper) || 0}%)
              </span>
              <span>
                {changeper > 0 ? arrow : changeper < 0 ? downArrowSvg : ""}
                {/* <svg
                  className="w-4 h-4"
                  viewBox="0 0 16 10"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M15.3333 1L8.99999 7.33333L5.66666 4L0.666656 9"
                    stroke="#34D399"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M11.3333 1H15.3333V5"
                    stroke="#34D399"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg> */}
              </span>
            </div>
          </div>
          <div className="flex flex-col items-end space-y-4">
            {/* <span>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20 5C20 4.73478 19.8946 4.48043 19.7071 4.29289C19.5196 4.10536 19.2652 4 19 4H14C13.7348 4 13.4804 4.10536 13.2929 4.29289C13.1054 4.48043 13 4.73478 13 5C13 5.26522 13.1054 5.51957 13.2929 5.70711C13.4804 5.89464 13.7348 6 14 6H16.57L13.29 9.29C13.1963 9.38296 13.1219 9.49356 13.0711 9.61542C13.0203 9.73728 12.9942 9.86799 12.9942 10C12.9942 10.132 13.0203 10.2627 13.0711 10.3846C13.1219 10.5064 13.1963 10.617 13.29 10.71C13.383 10.8037 13.4936 10.8781 13.6154 10.9289C13.7373 10.9797 13.868 11.0058 14 11.0058C14.132 11.0058 14.2627 10.9797 14.3846 10.9289C14.5064 10.8781 14.617 10.8037 14.71 10.71L18 7.42V10C18 10.2652 18.1054 10.5196 18.2929 10.7071C18.4804 10.8946 18.7348 11 19 11C19.2652 11 19.5196 10.8946 19.7071 10.7071C19.8946 10.5196 20 10.2652 20 10V5ZM10.71 13.29C10.617 13.1963 10.5064 13.1219 10.3846 13.0711C10.2627 13.0203 10.132 12.9942 10 12.9942C9.86799 12.9942 9.73728 13.0203 9.61542 13.0711C9.49356 13.1219 9.38296 13.1963 9.29 13.29L6 16.57V14C6 13.7348 5.89464 13.4804 5.70711 13.2929C5.51957 13.1054 5.26522 13 5 13C4.73478 13 4.48043 13.1054 4.29289 13.2929C4.10536 13.4804 4 13.7348 4 14V19C4 19.2652 4.10536 19.5196 4.29289 19.7071C4.48043 19.8946 4.73478 20 5 20H10C10.2652 20 10.5196 19.8946 10.7071 19.7071C10.8946 19.5196 11 19.2652 11 19C11 18.7348 10.8946 18.4804 10.7071 18.2929C10.5196 18.1054 10.2652 18 10 18H7.42L10.71 14.71C10.8037 14.617 10.8781 14.5064 10.9289 14.3846C10.9797 14.2627 11.0058 14.132 11.0058 14C11.0058 13.868 10.9797 13.7373 10.9289 13.6154C10.8781 13.4936 10.8037 13.383 10.71 13.29Z"
                  fill="#64748B"
                />
              </svg>
            </span> */}
            <div className="flex items-center space-x-2 pr-[1rem]">
              {/* <span className="text-[#34D399] font-bold text-[0.9rem]">
                {Math.floor(changeper)}%
              </span> */}
          <span
                className={`font-bold text-[0.9rem] ${
                  change < 0
                    ? "text-red-500"
                    : change > 0
                    ? "text-[#34D399]"
                    : "text-gray-500"
                }`}
              >
                {changeper ? Math.floor(changeper) : 0}%
              </span>

              <span>
                {changeper && changeper > 0
                  ? upArrowSvg
                  : changeper < 0
                  ? downArrowSvg
                  : ""}
                {/* <svg
                  className="w-3 h-3"
                  viewBox="0 0 10 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.3029 1.1127L5.30326 1.11304L8.89875 4.53803C8.96726 4.60684 9.00076 4.69328 8.99999 4.77798C8.9992 4.86371 8.96327 4.95087 8.89158 5.01916C8.8191 5.0882 8.71619 5.1313 8.60386 5.13223C8.49279 5.13315 8.38997 5.09269 8.31614 5.02599L6.25664 3.06415L5.41178 2.25934V3.42618V11.6428C5.41178 11.7295 5.37586 11.8179 5.30336 11.8869C5.23004 11.9568 5.12562 12 5.01196 12C4.89829 12 4.79388 11.9568 4.72055 11.8869C4.64805 11.8179 4.61214 11.7295 4.61214 11.6428V3.42618V2.26004L3.76743 3.064L1.70234 5.02945L1.70229 5.02939L1.69595 5.03564C1.6602 5.0709 1.61611 5.1003 1.56561 5.12096C1.51508 5.14164 1.45991 5.15283 1.4035 5.1533C1.34709 5.15376 1.29166 5.14349 1.24065 5.12361C1.18968 5.10374 1.14493 5.075 1.10842 5.04022C1.07196 5.00549 1.04452 4.96555 1.02642 4.92341C1.00836 4.88134 0.999613 4.83718 1.00001 4.79327C1.00041 4.74935 1.00996 4.70527 1.02885 4.66339C1.04777 4.62143 1.07602 4.58182 1.11324 4.54758L1.11329 4.54763L1.11957 4.54165L4.71885 1.11304L4.71921 1.1127C4.75494 1.07859 4.7988 1.05039 4.84882 1.0309L4.85051 1.03024C4.95291 0.98992 5.0692 0.98992 5.1716 1.03024L5.1733 1.0309C5.22332 1.05039 5.26717 1.07859 5.3029 1.1127Z"
                    fill="#34D399"
                    stroke="#34D399"
                  />
                </svg> */}
              </span>
            </div>
          </div>
        </div>
      </div>

      <div className="w-full h-[72%]">
        <MultiAreaChart data={chartData} />
      </div>

      <div
        className={`w-full h-[10%] border-[#CBD5E1]  dark:border-[#1F2A37] ${
          screenHeight > 900 ? "mt-[2rem]" : "mt-[1rem]"
        }  border-t flex items-center justify-between`}
      >
        <div className="flex items-center space-x-2">
          <select value={filter} onChange={handleChange}>
            <option value="w">7 days</option>
            <option value="M">1 Month</option>
            <option value="3M">3 Months</option>
            <option value="6M">6 Months</option>
            <option value="Y">1 Year</option>
          </select>
          {/* <span className="dark:text-[#94A3B8] text-[#94A3B8] text-[0.9rem] font-medium">
            Last 7 days 
          </span> */}
          {/* <span>
            <svg
              className="w-[14px] h-[14px]"
              viewBox="0 0 12 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M5.51452 9.30001L5.51457 9.30006C5.64469 9.42635 5.82429 9.49991 6.01464 9.5L5.51452 9.30001ZM5.51452 9.30001L0.714518 4.64288L0.714571 4.64283M5.51452 9.30001L0.714571 4.64283M0.714571 4.64283L0.708236 4.63689M0.714571 4.64283L0.708236 4.63689M0.708236 4.63689C0.642004 4.57483 0.590048 4.50142 0.554618 4.4214C0.519204 4.34141 0.500796 4.25597 0.500025 4.16992C0.499255 4.08388 0.516129 3.99818 0.550073 3.91768C0.584033 3.83715 0.634612 3.76296 0.699644 3.69986C0.764709 3.63674 0.842913 3.58603 0.930076 3.55143C1.01726 3.51682 1.11118 3.49922 1.20621 3.50003C1.30124 3.50083 1.39479 3.52 1.48128 3.55604C1.56774 3.59208 1.64494 3.64405 1.70879 3.70819L1.70874 3.70824M0.708236 4.63689L1.70874 3.70824M1.70874 3.70824L1.71497 3.7143M1.70874 3.70824L1.71497 3.7143M1.71497 3.7143L5.66657 7.54828L6.01475 7.88609M1.71497 3.7143L6.01475 7.88609M6.01475 7.88609L6.36292 7.54828M6.01475 7.88609L6.36292 7.54828M6.36292 7.54828L10.311 3.71774C10.4418 3.59647 10.6193 3.52706 10.8062 3.52864C10.9945 3.53023 11.1716 3.6036 11.3004 3.72848C11.4286 3.85288 11.4985 4.01796 11.5 4.18726C11.5015 4.35536 11.4355 4.52029 11.3114 4.64634L6.51497 9.30001L6.51492 9.30006M6.36292 7.54828L6.51492 9.30006M6.51492 9.30006C6.3848 9.42635 6.2052 9.49991 6.01485 9.5L6.51492 9.30006Z"
                fill="#94A3B8"
                stroke="#94A3B8"
              />
            </svg>
          </span> */}
        </div>
        {showGoTo && (
          <div className="flex items-end space-x-2">
           <span onClick={() => navigate("/portfolio")} className="dark:text-[#F88F00] font-medium text-[0.9rem] hover:underline cursor-pointer">
  Go to Portfolio
</span>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke-width="3"
                stroke="currentColor"
                className="w-4 dark:text-[#F88F00] h-4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="m8.25 4.5 7.5 7.5-7.5 7.5"
                />
              </svg>
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

export default PortfolioChart;
