import { createSlice } from '@reduxjs/toolkit';

const themeSlice = createSlice({
  name: 'theme',
  initialState: {
    darkMode: false,
    feedbackModal:false,
    settingModal:false,
    changePasswordModal:false,
    verificationModal:false,
    sessionInfoModal:false,
    deleteModal:false,
    twofaModal:false,
    selectedRoute:{
      id:1,
      name:"Dashboard"
    },
    isSideBarOpen:false,
  },
  reducers: {
    toggleTheme(state) {
      state.darkMode = !state.darkMode;
    },
    toggleFeedbackModal(state) {
      state.feedbackModal = !state.feedbackModal;
    },
    toggleTwoFaModal(state) {
      state.twofaModal = !state.twofaModal;
    },
    toggleDeleteModal(state) {
      state.deleteModal = !state.deleteModal;
    },
    toggleSessionInfoModal(state) {
      state.sessionInfoModal = !state.sessionInfoModal;
    },
    toggleVerificationModal(state) {
      state.verificationModal = !state.verificationModal;
    },
    toggleChangePasswordModal(state) {
      state.changePasswordModal = !state.changePasswordModal;
    },
    toggleSettingModal(state) {
      state.settingModal = !state.settingModal;
    },
    setSelectedRoute(state,action){
      state.selectedRoute = action.payload
    },
    SET_SIDE_BAR(state, action) {
      state.isSideBarOpen = action.payload;
    },
   
  },
});

export const { toggleTheme,setSelectedRoute,SET_SIDE_BAR,toggleFeedbackModal,toggleSettingModal,toggleChangePasswordModal,toggleVerificationModal,toggleTwoFaModal,toggleSessionInfoModal,toggleDeleteModal } = themeSlice.actions;
export default themeSlice.reducer;
