import React,{useState,useRef,useEffect} from 'react'
import IconCircle from '../iconCircle'
import {companyIcons} from '../../utils/sidebarContent'
import ModalContainer from '../Modal/ModalContainer'
import BaseModal from '../BaseModal'
import PriceArrow from '../PriceArrow'
import { useNavigate } from 'react-router-dom'
// import Lin

function IdeasTable({title,columns=[],data=[],detail,showPriceRange=false}) {
    const [showSearch,setShowSearch] = useState(false)
    const [showInfoModal,setShowInfoModal] = useState(false)
    const [searchVal,setSearchVal] = useState('')
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage,setItemsPerPage] = useState(10)
    const totalPages = Math.ceil(data?.length / itemsPerPage);
    const [favourties,setFovourites] = useState(["IBM","TSLA"])
    const searchRef = useRef(null)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const navigate = useNavigate()

    const handleAddToFavourites = (ticker) => {
        let isPresent = favourties.includes(ticker);
        if (isPresent) {
          setFovourites(favourties.filter((item) => item !== ticker));
        }else{
          setFovourites([...favourties, ticker]);
        }
      }

      useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
    
        window.addEventListener('resize', handleResize);
        
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      useEffect(() => {
        function handleClickOutside(event) {
          if (searchRef.current && !searchRef.current.contains(event.target)) {
            setShowSearch(false)
          }
        
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [searchRef]);
      const startIndex = ()=>{
        return ((currentPage - 1) * itemsPerPage) + 1
    };
    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };

      const endIndex = ()=>{
        return currentPage * itemsPerPage
    };

    const handleSearchVal = (event) => {
      setSearchVal(event.target.value);
    };
  
    const firstPage = () => {
        setCurrentPage(1);
        onPageChange(1);
      };
    
      const prevPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          onPageChange(currentPage - 1);
        }
      };

      const nextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
          onPageChange(currentPage + 1);
        }
      };
      const lastPage = () => {
        setCurrentPage(totalPages);
        onPageChange(totalPages);
      };

      const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
      };

      const getFilteredData = () => {
        if (!searchVal) {
          return data;
        }
        return data.filter(item =>
          item.ticker && item.ticker.toString().toLowerCase().includes(searchVal.toLowerCase())
        );
        // return data?.filter(item => 
        //   Object.values(item).some(
        //     value => value.toString().toLowerCase().includes(searchVal.toLowerCase())
        //   )
        // );
      };

      const getPaginatedData = () => {
        const filteredData = getFilteredData();
        const startIndex = (currentPage - 1) * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return filteredData?.slice(startIndex, endIndex);
      };

      const getGradeColor = (grade) => {
        if (grade > 80) {
          return "bg-[#059669]";
        } else if (grade > 60) {
          return "bg-[#EA580C]";
        }else if (grade > 40){
            return "bg-[#EA580C]"
        } 
        else {
          return "bg-[#DC2626]";
        }
      };

    return (
      <>
      {showInfoModal &&
       <BaseModal>
        <ModalContainer customClass='dark:bg-[#0F172A]' title={title+'?'} onClose={() => setShowInfoModal(false)}>
          <div className='w-[35rem] h-[14rem]'>
            <div className='flex items-center justify-between px-4 w-full h-[40%] gap-2 -bg-red-200'>
                {data?.slice(0,3)?.map((item,i)=>(
                    <div className='w-full -bg-yellow-200 h-full flex items-center space-x-4'>
                    <div>
                        <img src={require(`../../assets/images/${item.image}`)} className='w-9 h-8 rounded-full' />
                    </div>
                    <div className='flex flex-col'>
                        <span className='text-[18px] font-bold'>
                            {item?.ticker}
                        </span>
                        <span className='text-[11px] dark:text-[#94A3B8]'>
                            $187.42 <span className='text-[#34D399]'>+0.17(+1.82%)</span>
                        </span>

                    </div>

                </div>
                ))
                }
            </div>
            <div className='flex items-center w-full overflow-y-auto no-scrollbar px-4 h-[50%] -bg-green-200'>
                <span className='dark:text-[#94A3B8] text-[16px] font-normal'>
                  {detail}
                </span>
            </div>
          </div>
        </ModalContainer>
      </BaseModal>}
    <div className='w-full h-full '>
        <div className={`w-full flex  ${screenWidth < 700 ? 'flex-col gap-y-2 h-[8vh]' : 'items-center  h-[6vh] justify-between'}`}>
            <div className='flex items-center gap-2'>
                <div className='rounded-[8px] px-[0.8rem]  cursor-default  pt-[0.25rem] pb-[0.3rem] text-[14px]   dark:bg-[#431407] font-normal text-[#020617] bg-[#f88f00] dark:text-[#F88F00]'>
                    {title} ({data?.length})
                </div>
                <div className='rounded-[8px] px-[0.8rem]  cursor-default  pt-[0.25rem] pb-[0.3rem] text-[14px] dark:text-[#475569]  bg-[#E2E8F0] font-[400] dark:bg-transparent'>
                    Watchlist ({favourties?.length})
                </div>
            </div>
            <div className={`flex items-center gap-2 ${screenWidth < 700 ?'w-full justify-end mt-2':''}`}>
                <div>
                    {
                    showSearch ? 
                        <div ref={searchRef} className={`${screenWidth < 420 ? 'w-[15rem] py-1.5 px-3 text-[12px]':'w-[20rem] py-2 px-3'} space-x-3 flex items-center rounded-full border dark:bg-[#0F172A] dark:border-[#F8FAFC] -bg-red-200`}>
                            <span>
                                <svg className='w-5 h-5 dark:text-[#F8FAFC]' viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.27783 17.0822C13.6961 17.0822 17.2778 13.5005 17.2778 9.08222C17.2778 4.66394 13.6961 1.08222 9.27783 1.08222C4.85955 1.08222 1.27783 4.66394 1.27783 9.08222C1.27783 13.5005 4.85955 17.0822 9.27783 17.0822Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19.2779 19.0822L14.9279 14.7322" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
                            </span>
                            <span>
                                <input placeholder='Search stock/ticker name' value={searchVal} onChange={handleSearchVal} className='w-full text-[14px] outline-none bg-transparent dark:text-[#F8FAFC]' />
                            </span>
                        </div>
                        :
                        <IconCircle handleClick={()=>setShowSearch(true)}>
                        <svg className='w-5 h-5 dark:text-[#F8FAFC]' viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.27783 17.0822C13.6961 17.0822 17.2778 13.5005 17.2778 9.08222C17.2778 4.66394 13.6961 1.08222 9.27783 1.08222C4.85955 1.08222 1.27783 4.66394 1.27783 9.08222C1.27783 13.5005 4.85955 17.0822 9.27783 17.0822Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19.2779 19.0822L14.9279 14.7322" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    </svg>
    
                        </IconCircle>
                    }
                   
                </div>
                <div>
                    <div onClick={()=>setShowInfoModal(true)} className='w-10 h-10 dark:bg-[#431407] dark:hover:bg-[#541c0e] hover:bg-[#e5880d] flex items-center justify-center dark:text-[#F88F00] text-[#020617] bg-[#f88f00] cursor-pointer rounded-full'>
                    <svg className='w-5 h-5' viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 7V11" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 15H11.01" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </div>
                </div>
            </div>
        </div>

        <div className='border w-full !mt-[2rem] max-h-[65vh] dark:bg-[#020617] overflow-hidden overflow-hidden dark:border-[#1E293B] rounded-[8px]'>
        <div className='max-h-[55vh] border-b dark:border-[#1E293B] overflow-x-auto w-full overflow-y-auto'>
            <table className='w-full text-[15px] bg-white text-left rtl:text-right'>
                <thead className='z-50 sticky top-0 bg-[#F1F5F9] dark:bg-[#0F172A]'>
                    <tr>
                        <th className='py-3 whitespace-nowrap border-none px-[1rem] text-[12px] font-[500] w-6 text-center'>
                        
                        </th>
                        <th className='py-3 whitespace-nowrap border-none px-[1rem] text-[12px] font-[500] w-6 text-center'>
                            #
                        </th>
                        {columns?.map((item,index) => (
                            <th key={index} className="w-30 text-center px-[2rem] py-3 whitespace-nowrap border-b text-black dark:text-white text-[14px] border-none font-medium">
                                <div className={`${item?.key=='ticker'?'flex':''}`}>
                                  {item?.value}
                                </div>
                            </th>
                        ))}
                    </tr>
                </thead>
                
                <tbody className='w-full'>
                    
                    {getPaginatedData()?.map((item,i)=>(
                        <tr  onClick={()=>{ navigate(`/quote?symbol=${item?.ticker}`)}}  className=" text-black dark:hover:bg-[#151d26] dark:text-white hover:bg-[#f9f9f9] border-b-[0.1vh] dark:bg-[#020617]  dark:border-[#292929a1] ">
                            <td className='py-3 whitespace-nowrap px-1 dark:border-b-[#292929a1] border-b text-[12px] font-[500] w-6 -bg-red-200 text-center'>
                            <div className='w-full h-full text-center flex justify-center'>
                            <svg onClick={()=>handleAddToFavourites(item?.ticker)} className={`cursor-pointer text-[#F97316] ${favourties.includes(item?.ticker) ? 'text-[#F97316]' : 'text-gray-300'}`} width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9 14.27L13.15 16.78C13.91 17.24 14.84 16.56 14.64 15.7L13.54 10.98L17.21 7.79999C17.88 7.22 17.52 6.12 16.64 6.04999L11.81 5.64L9.92 1.17999C9.58 0.369995 8.42 0.369995 8.08 1.17999L6.19 5.62999L1.36 6.03999C0.479996 6.10999 0.119996 7.20999 0.789996 7.78999L4.46 10.97L3.36 15.69C3.16 16.55 4.09 17.23 4.85 16.77L9 14.27Z" fill="currentColor"/>
                            </svg>
                            </div>
                            </td>
                            <td className='py-3 whitespace-nowrap px-1 dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 -bg-red-200 text-center'>
                                {i+1}
                            </td>
                            
                            <td className='py-4 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] -bg-green-200 border-b text-[12px] font-[500] w-6'>
                                <div className='w-[11rem] pl-3 -bg-red-200 h-full flex items-center space-x-3'>
                                    <div>
                                        <img src={require(`../../assets/images/${item.image}`)} className='w-8 h-8' />
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='text-[18px] font-bold'>
                                            {item?.ticker}
                                        </span>
                                        <span className='text-[11px] dark:text-[#94A3B8]'>
                                            $187.42 <span className='text-[#34D399]'>+0.17(+1.82%)</span>
                                        </span>

                                    </div>

                                </div>
                            </td>
                          

                            {columns?.map((col,i)=>{
                                if(col?.key !='ticker'){
                                  if(col.key == 'price' && showPriceRange){
                                    return <td  key={i} className='py-5 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center'>
                                        <div className='flex flex-col w-full space-y-1'>
                                        <div className='w-full flex items-center justify-between'>
                                            <PriceArrow title='Low Price' price={item.lowPrice} svgcolor='text-[#FB4644]' titleClass='text-[10px]' priceClass='text-[11px]' width='12' height='12' />
                                            <PriceArrow title='Price' price={item?.price} svgcolor='text-[#CFC51B]' titleClass='text-[10px]' priceClass='text-[11px]' width='12' height='12'/>
                                            <PriceArrow title='High Price' price={item.highPrice} svgcolor='text-[#07FE82]'titleClass='text-[10px]' priceClass='text-[11px]' width='12' height='12' />
                                        </div>
                                        <div className='w-full h-[6px] rounded-[10px] gradient-bg' />
                                    </div>
                                    </td>
                                  }else{

                                    return <td key={i} className='py-3 whitespace-nowrap px-1 px-[1rem] dark:border-[#292929a1] border-b text-[12px] font-[500] w-6 text-center'>
                                    <span className={`${col?.key=='grade'?`py-1 px-2.5 rounded-full text-white ${getGradeColor(item[col?.key])}`:''} ${col?.key == 'change' ? item[col?.key] < 0 ? 'text-[#EF4444]' :'text-[#34D399]':''}`}>
                                       {(col?.key=='price' || col?.key=='divAmount')?'$':''} {col?.key == 'change' ? item[col?.key] < 0 ? '' :'+':''}{item[col?.key]}{(col?.key == 'divYield' || col?.key == 'payout' || col?.key == 'change') ? '%':''}
                                    </span>
                                    </td>
                                }
                                }
                            }) 
                            }
                           
                        
                            
                        </tr>
                    )) }
                </tbody>
            </table>
        </div>
        <div className='w-full h-[10vh] px-4 sm:flex-1 mb-2 sm:flex sm:items-center sm:justify-between'>
            <div>
           <p className='text-[14px] font-[500] tableShowing leading-5 font-[500] dark:text-[#64748B] text-[#6D6D6D]'>
            Showing:
                <span> { startIndex() } </span>
                to
                <span> { endIndex() } </span>
                of
                <span> { data?.length } </span>
                Records   
                </p>
            </div>
            <div>
           {data?.length > 0 && 
            <nav className="paginationArea">
    <ul className="flex items-center border dark:border-[#1F2A37] rounded-[8px] h-10 text-base bg-[#f1f5f9] dark:bg-[#0f172a]">
      {/* <li>
        <button
          onClick={firstPage}
          disabled={currentPage === 1}
          className={`${
            currentPage === 1
              ? 'cursor-not-allowed text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px] border-r dark:border-[#1F2A37] h-10 flex items-center justify-center px-4 ms-0 leading-tight`}
        >
          First
        </button>
      </li> */}
      <li>
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          className={`${
            currentPage === 1
              ? 'cursor-not-allowed  text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px] h-10 flex items-center justify-center px-4 ms-0 leading-tight`}
        >
          Previous
        </button>
      </li>
      {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
        <li key={pageNumber}>
          <button
            onClick={() => changePage(pageNumber)}
            className={`${
              currentPage === pageNumber
                ? 'bg-[#E2E8F0]  dark:bg-[#F8FAFC] text-[#020617]'
                : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#94A3B8] dark:hover:bg-[#1F2A37]'
            } text-[14px]  h-10 flex items-center border-l borde-r dark:border-[#1F2A37] justify-center px-4 ms-0 leading-tight `}
          >
            {pageNumber}
          </button>
        </li>
      ))}
      <li>
        <button
          onClick={nextPage}
          disabled={currentPage === totalPages}
          className={`${
            currentPage === totalPages
              ? 'cursor-not-allowed  text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px]  border-l border-r dark:border-[#1F2A37] h-10 flex items-center justify-center px-4 ms-0 leading-tight `}
        >
          Next
        </button>
      </li>
      {/* <li>
        <button
          onClick={lastPage}
          disabled={currentPage === totalPages}
          className={`${
            currentPage === totalPages
              ? 'cursor-not-allowed text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px]  flex h-10 items-center justify-center px-4 ms-0 leading-tight `}
        >
          Last
        </button>
      </li> */}
    </ul>
            </nav>}
    </div>


        </div>
    </div>
    </div>
    </>
  )
}

export default IdeasTable