// CustomToolbar.js
import React from 'react';

const CustomToolbar = (toolbar) => {
  const goToBack = () => {
    toolbar.onNavigate('PREV');
  };

  const goToNext = () => {
    toolbar.onNavigate('NEXT');
  };

  const goToCurrent = () => {
    toolbar.onNavigate('TODAY');
  };

  const label = () => {
    const date = new Date(toolbar.date);
    const month = date.toLocaleString('default', { month: 'long' });
    const year = date.getFullYear();
    return `${month} ${year}`;
  };

  return (
    <div className="flex justify-between items-center my-2">
      <span className="font-bold">{label() + " - Dividend Calendar"}</span>
      <div className='space-x-3'>
        <button className='text-[20px] text-slate-500' onClick={goToBack}>&#8249;</button>
        <button className=' text-slate-500' onClick={goToCurrent}>Today</button>
        <button className='text-[20px] text-slate-500' onClick={goToNext}>&#8250;</button>
      </div>
    </div>
  );
};

export default CustomToolbar;
