import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/lightLogo.svg";
import TextInput from "../components/TextInput";
import PrimaryButton from "../components/Button";
import { ReactComponent as Apple } from "../assets/apple.svg";
import { ReactComponent as Google } from "../assets/google.svg";
import { ReactComponent as Back } from "../assets/BackArrow.svg";
import PasswordComp from "../components/PasswordComp";
import { createUser, verifyEmail } from "../ApiControler";
import VerifyEmail from "../components/VerifyEmail";
import { useNavigate } from "react-router-dom";

function SignUp() {
  const navigate = useNavigate();
  const [isLoader, setLoader] = useState(false);
  const [user, setUser] = useState(false);
  const [step, setStep] = useState(0);
  const [next, setNext] = useState("password");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");
  const [name, setName] = useState("");
  const [errMsg, setErr] = useState("")
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const specialCharRegex = /\W/;
  const checkNumber = /\d/;

  const backHandler = () => {
    if (next === "password") {
      setStep(0);
      setPassword("")
      setRePassword("")
    } else if (next !== "password") {
      setNext("password");
      setName("")
    }
  };

  const create_User = async () => {
   
   if(!errMsg){
    setLoader(true);
    try {
      const response = await createUser(email, password, name);
      if (response?.success) {
       
        setUser(true)
        // verify_Email();
      }
    } catch (e) {
      setLoader(false);
      setErr(e?.response?.data?.message)
      console.log("sign up error", e)
      // throw e;
    }
   }else{
    navigate("/")
   }
  };



  return (
    <div className="flex w-full h-full justify-center items-center">
      {user ? (
          <div className="flex h-[40rem] w-3/6 rounded-xl justify-center items-center">
            <VerifyEmail email={email} name={name} setState={setUser} />
          </div>
        ) : (
          <>
            {step === 0 && (
              <div className="flex flex-col bg-white items-center justify-center space-y-4 rounded-xl p-6 w-full h-full">
                <Logo className="w-full h-12" />
                <p className="text-[18px] font-inter text-[#1E293B]">
                  Invest Smarter, Earn Better
                </p>
                <p className="text-[14px] font-inter text-[#64748B]">
                  Invest Smarter, Earn Better
                </p>
                <PrimaryButton
                  isDisable={true}
                  title={"Continue with Apple"}
                  mainClass={
                    "border border-[#CBD5E1] xl:w-[360px] sm:w-[220px] w-[280px] h-[44px] cursor-pointer hover:opacity-80"
                  }
                  image={<Apple className="w-4 h-4" />}
                />
                <PrimaryButton
                  isDisable={true}
                  title={"Continue with Google"}
                  mainClass={
                    "border border-[#CBD5E1] xl:w-[360px] sm:w-[220px] w-[280px] h-[44px] cursor-pointer hover:opacity-80"
                  }
                  image={<Google className="w-4 h-4" />}
                />
                <div className="flex w-full h-12 justify-center items-center space-x-4 ">
                  <div className="flex w-full border-b border-b-[#E2E8F0]" />
                  <p className="text-[14px] font-bold font-inter text-[#94A3B8]">
                    OR
                  </p>
                  <div className="flex w-full border-b border-b-[#E2E8F0]" />
                </div>
                <TextInput
                  _value={email}
                  _onChange={(text) => setEmail(text.target.value)}
                  label={"Your Email Address"}
                  type="email"
                  placeholder={"email@domain.com"}
                  mainClass={" xl:w-[360px] sm:w-[220px] w-[280px]"}
                />
                <PrimaryButton
                  isDisable={emailRegex.test(email) ? true : false}
                  title={"Sign up with Email"}
                  _click={() => {
                    emailRegex.test(email) && setStep(1);
                  }}
                  mainClass={` xl:w-[360px] sm:w-[220px] w-[280px]  ${
                    emailRegex.test(email)
                      ? "bg-[#FFAA36] cursor-pointer hover:opacity-80"
                      : "bg-[#fbd8a4] text-slate-400"
                  }`}
                />
                <div className="items-center justify-center pt-2">
                  <p className="text-[14px] font-inter text-[#94A3B8] text-center">
                    By clicking the button above, you agree to
                  </p>
                  <p className="text-[14px] font-inter text-[#94A3B8] text-center">
                    our{" "}
                    <span className="text-[#2563EB] cursor-pointer hover:opacity-50" onClick={() => navigate("#")}>
                      Terms of Use
                    </span>{" "}
                    and{" "}
                    <span className="text-[#2563EB] cursor-pointer hover:opacity-50" onClick={() => navigate("#")}>
                      Privacy Policy
                    </span>
                  </p>
                </div>
                <p className="text-[14px] font-inter text-[#94A3B8] text-center mt-4">
                  Already have an account?{" "}
                  <span className="text-[#2563EB] cursor-pointer hover:opacity-50" onClick={() => navigate("/")}>
                    Sign in
                  </span>
                </p>
              </div>
            )}
            {step === 1 && (
              <div className="flex bg-white flex-col items-center  w-full h-full rounded-xl py-5">
                <div className=" w-full flex items-center px-5">
                  <Back className="h-3 cursor-pointer hover:opacity-80" onClick={() => backHandler()} />{" "}
                  <Logo className="w-full h-10" />
                </div>
                <div className="flex w-full h-1 items-center justify-center pt-4">
                  {next === "password" ? (
                    <>
                      <div className="border-b border-b-[#FFAA36] flex w-full" />
                      <div className="flex w-full" />
                    </>
                  ) : (
                    <>
                      {/* <div className="flex w-full" /> */}
                      <div className="border-b border-b-[#FFAA36] flex w-full" />
                    </>
                  )}
                </div>
                {next === "password" ? (
                  <PasswordComp
                    password={password}
                    rePassword={rePassword}
                    setPassword={setPassword}
                    setRePassword={setRePassword}
                    _click={() => {
                      password.length > 7 &&
                        specialCharRegex.test(password) &&
                        checkNumber.test(password) &&
                        password === rePassword &&
                        setNext("next");
                    }}
                  />
                ) : (
                  <div className="w-full h-full items-center py-14 flex flex-col px-14">
                    <span className="flex font-semibold text-[14px] ">
                      Set your profile name
                    </span>
                    <span className="flex text-[11px] text-center text-slate-400">
                      You're almost there! Stand out in the <br></br> community by
                      setting up your profile name
                    </span>
                    <div className="w-[15rem] mt-7 h-[5rem] rounded-xl  shadow-lg shadow-green-100  border border-green-100 items-center flex space-x-3 px-3 ">
                      <div className="h-[3rem] w-[3rem] rounded-md bg-green-100 justify-center items-center text-green-700  font-bold text-[22px] flex">
                        <span className="lineclamp-1 leading-1">
                          {name ? name?.charAt(0).toUpperCase() : "Y"}
                        </span>
                      </div>
                      <div className="flex flex-col space-y-1">
                        <span className=" text-[12px] font-semibold">
                          {name ? name : "Your name..."}
                        </span>
                        <div className="bg-emerald-500 py-1 px-2 text-[9px] justify-center items-center flex text-white font-semibold rounded-full">
                          <span>Smart Investor</span>
                        </div>
                      </div>
                    </div>
                    <div className="pt-8">
                      <TextInput
                        _value={name}
                        _onChange={(text) => setName(text.target.value)}
                        label={"Full name"}
                        type="text"
                        placeholder={"Enter full name"}
                        mainClass={" xl:w-[360px] sm:w-[220px] w-[280px] "}
                      />
                    </div>
                    <div className="flex-col space-y-1 py-2">
                    <span className="text-[12px] text-red-700">{errMsg}</span>
                    <PrimaryButton
                      isDisable={name.length > 3 ? true : false}
                      isLoader={isLoader}
                      title={!isLoader && !errMsg ? "Send" : !isLoader && errMsg ? "Back to login" : "Sending..."}
                      _click={() => {
                        create_User();
                      }}
                      mainClass={` xl:w-[360px] sm:w-[220px] w-[280px] ${
                        name.length > 3 && !isLoader
                          ? "mt-5 bg-[#FFAA36] cursor-pointer hover:opacity-80"
                          : "mt-5 bg-[#fbd8a4] text-slate-500"
                      }`}
                    />
                    </div>
                  </div>
                )}
              </div>
            )}
          </>
        )}
    </div>
  );
}

export default SignUp;
