import React,{useState,useEffect} from 'react'

function ReadFaq({faq=[]}) {
    const [selectedId,setSelectedId] = useState(0)
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className='w-full h-full flex flex-col -bg-red-200 justify-center mt-[3rem] items-center'>
    {faq?.map((item,i)=>(
      <div key={i} className={`${screenWidth<961?'w-11/12':'w-8/12'} h-full flex flex-col gap-y-6 py-[2rem] border-b dark:border-[#1E293B50]`}>
          <div className='w-full flex items-center  justify-between'>
              <span className='dark:text-[#F1F5F9] font-semibold text-[18px]'>{item?.question}</span>
              {item.id == selectedId ?
                <span onClick={()=>setSelectedId(0)} className='cursor-pointer p-1 rounded-full dark:hover:bg-[#182238] hover:bg-[#ced8e5]'>
                <svg width="24" height="24" viewBox="0 0 24 24" className='dark:text-[#f8fafc]' fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M18 15L12 9L6 15" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
                    :
                    <span onClick={()=>setSelectedId(item.id)} className='cursor-pointer px-2 py-3 rounded-full dark:hover:bg-[#182238] hover:bg-[#ced8e5]'>
                    <svg width="14" height="8" className='dark:text-[#f8fafc]' viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                     <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                  </span>
              }
            
          </div>
          {item.id == selectedId && 
          <div>
          <span className='text-[#94A3B8] text-[14px] font-normal'>
                {item?.answer}
              </span>
          </div>}
              
      </div>
    ))}
  </div>
  )
}

export default ReadFaq