import React,{useState,useEffect,useRef} from 'react'
import { useDispatch,useSelector } from 'react-redux';
import { toggleTheme,SET_SIDE_BAR,setSelectedRoute } from '../store/slices/themeSlice';
import BaseModal from './BaseModal';
import SymbolSearch from './SymbolSearch';
import {sidebarContent} from '../utils/sidebarContent' 
import { useLocation, useNavigate } from "react-router-dom";//../../assets/svgs/command.svg
import {ReactComponent as CommandLOGO} from "../assets/command.svg"

function Navbar() {
    const dispatch = useDispatch();
    const location = useLocation();
  const navigate = useNavigate();

    const dropdownRef = useRef(null)
    const [toggleModal,setToggleModal] = useState(false)
    const currentRoute = useSelector((state) => state.theme.selectedRoute);
    const [platform, setPlatform] = useState('');
    const [selectedSymbol,setSelectedSymbol] = useState('')
    const [dropdownItems,setDropdownItems] = useState([])
    const [openDropdown,setOpenDropdown] = useState(false)

    useEffect(() => {
        function handleClickOutside(event) {
          if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setOpenDropdown(false)
          }
        
        }
        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
          document.removeEventListener("mousedown", handleClickOutside);
        };
      }, [dropdownRef]);

    useEffect(() => {
        const detectedPlatform = getPlatform();
        setPlatform(detectedPlatform);
      }, []);

      useEffect(() => {
        if(currentRoute?.isParent){
            let findParent = sidebarContent.find((item) => item?.name?.toLowerCase() == currentRoute?.isParent)
            if(findParent?.children?.length > 0){
                setDropdownItems(findParent?.children)
            }
        }
      }, [currentRoute]);

      useEffect(() => {
        document.addEventListener('keydown', handleEscKey);
    
        return () => {
          document.removeEventListener('keydown', handleEscKey);
        };
      }, []); // Empty dependency array ensures this runs once on mount and cleanup on unmount
    

      const handleEscKey = (event) => {
        if (event.key === 'Escape') {
            setToggleModal(false)
        }

        if((event.ctrlKey || event.metaKey) && event.key.toLowerCase() === 'f'){
            event.preventDefault();
            setToggleModal(true)
        }
      };
    

    const handleTheme = ()=>{
        dispatch(toggleTheme());
    }

    const handleSidebarOpen = ()=>{
        dispatch(SET_SIDE_BAR(true));
    }

    const getPlatform = () => {
        const platform = window.navigator.platform.toLowerCase();
        if (platform.includes('win')) {
          return 'Windows';
        } else if (platform.includes('mac')) {
          return 'Mac';
        } else {
          return 'Other';
        }
      };
      
    const handleToggleModal = ()=>{
        setToggleModal(true)
    }
    
    const handleCloseModal = ()=>{
        setToggleModal(false)
    }

    const capitalizeFirstLetter = (string) => {
        if (!string) return '';
        return string?.charAt(0)?.toUpperCase() + string.slice(1);
      };

      const handleRoute = (item)=>{
        setOpenDropdown(false)
        if (item?.route && location.pathname !== item?.route) {      
            dispatch(setSelectedRoute(item));
            localStorage.setItem("selectedRoute", JSON.stringify(item));
            navigate(item?.route);
          }
      }

  return (
    <>
       {toggleModal && 
        <BaseModal>
            <SymbolSearch onClose={handleCloseModal} setSelectedSymbol={setSelectedSymbol} />
        </BaseModal>}
        <div className='flex flex-col h-full'>
        <div className='w-full h-full flex items-center justify-between'>
            <div className='flex items-center space-x-3'>
                <div className='toggleIcon' onClick={handleSidebarOpen}>
                    <span className='w-10 h-10 flex items-center dark:bg-[#0F172A] justify-center bg-[#E2E8F0] dark:hover:bg-[#182238] hover:bg-[#ced8e5] cursor-pointer rounded-full'>
                    <svg className='w-4 h-4 text-[#020617] dark:text-[#F8FAFC]' viewBox="0 0 20 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 7H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 1H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                        <path d="M1 13H19" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    </svg>
                    </span>
                </div>
                <div className='flex flex-col -space-y-1.5'>
                    {currentRoute?.isParent && <span className='text-[#94A3B8] text-[12px] font-normal'> {capitalizeFirstLetter(currentRoute?.isParent)}</span>}
                    <span className='flex items-center gap-2'>
                        <span className='text-[#020617] text-[1.2rem] dark:text-[#F8FAFC] font-semibold'>{currentRoute?.name}</span>
                       {currentRoute?.isParent && 
                        <span onClick={()=>setOpenDropdown(!openDropdown)} className='relative'>
                           <span className='cursor-pointer flex p-1 hover:bg-[#d6d6d6] dark:hover:bg-[#2b2b2b] rounded-full'>
                            <svg width="12" height="7" className='dark:text-[#F8FAFC] mt-[2px]' viewBox="0 0 12 7" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M1 1L6 6L11 1" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                           </span>
                            {openDropdown&&
                            <div ref={dropdownRef} className='absolute'>
                            <div className='absolute z-[999] py-2 bg-[#d7dde5] w-[12rem] rounded-[4px] max-h-[15rem] dark:bg-[#1b2233] bg-[#e1e5ee] overflow-y-auto no-scrollbar top-[1rem] -right-4'>
                                    {dropdownItems?.map((item,i)=>(
                                        <div onClick={()=>handleRoute(item)}  key={i} className='py-2 px-3 text-[15px] hover:bg-[#c5ccd6] dark:text-white dark:hover:bg-[#161c2b] cursor-pointer'>
                                            {item?.name}
                                        </div>
                                    ))}
                                </div>
                            </div>}
                        </span>
                        }
                    </span>
                </div>
            </div>
            <div className='flex items-center space-x-3'>
                <div onClick={handleToggleModal} className="flex items-center w-[23rem] justify-between px-2 cursor-pointer searchBar text-sm bg-[#f8fafc] hover:bg-[#ededed] dark:bg-[#0F172A] dark:hover:bg-[#141e35] py-[6px] border border-[#CBD5E1] dark:border-[#1F2A37] rounded-md">
                    <div className="flex items-center space-x-3 searchBarGap pr-[3.5rem]">
                        <span>
                            <svg className='w-4 h-4' viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.16667 14.8333C11.8486 14.8333 14.8333 11.8486 14.8333 8.16667C14.8333 4.48477 11.8486 1.5 8.16667 1.5C4.48477 1.5 1.5 4.48477 1.5 8.16667C1.5 11.8486 4.48477 14.8333 8.16667 14.8333Z" stroke="#94A3B8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.5 16.5L12.875 12.875" stroke="#94A3B8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                        <span className=" text-[#94A3B8] truncate">{(selectedSymbol && currentRoute?.name == 'Quote') ? selectedSymbol : 'Search stock or company name'}</span>
                    </div>
                <div className="flex items-center border dark:border-[#1F2A37] dark:bg-[#0F172A] border-[#CBD5E1] text-[#94A3B8] bg-[#ffffff] px-2 py-1 space-x-[2px]  rounded-[6px]">
                    {/* <img src="../../assets/svgs/command.svg" /> */}
                    {/* {platform === "Mac" ? <img src="../../assets/svgs/command.svg" /> : <span>Ctrl</span>} */}
                    {platform === "Mac" ? <CommandLOGO/> : <span>Ctrl</span>}
                    <span>+</span>
                    <span>F</span>
                </div>
                </div>
                <div>
                    <span onClick={handleTheme} className='w-10 h-10 dark:bg-[#0F172A] dark:hover:bg-[#182238] hover:bg-[#ced8e5] flex items-center justify-center bg-[#E2E8F0] cursor-pointer rounded-full'>
                        <svg className='w-5 h-5 text-[#020617] dark:text-[#F8FAFC]' viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <g clipPath="url(#clip0_246_1613)">
                            <path d="M10.639 14.6672C12.9401 14.6672 14.8056 12.8017 14.8056 10.5005C14.8056 8.19936 12.9401 6.33388 10.639 6.33388C8.33777 6.33388 6.47229 8.19936 6.47229 10.5005C6.47229 12.8017 8.33777 14.6672 10.639 14.6672Z" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.6389 1.33388V3.00054" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M10.6389 18.0005V19.6672" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.15564 4.01721L5.33897 5.20055" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M15.939 15.8006L17.1223 16.9839" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M1.47229 10.5005H3.13896" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M18.1389 10.5005H19.8056" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M4.15564 16.9839L5.33897 15.8006" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            <path d="M15.939 5.20055L17.1223 4.01721" stroke="currentColor" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            </g>
                            <defs>
                            <clipPath id="clip0_246_1613">
                            <rect width="20" height="20" fill="white" transform="translate(0.638916 0.500549)"/>
                            </clipPath>
                            </defs>
                            </svg>
                    </span>
                </div>
            </div>
        </div>
        <div className='hidden mobileSearch'>
            <div onClick={handleToggleModal} className="flex items-center justify-between w-full px-2 cursor-pointer text-[12px] bg-[#f8fafc] dark:bg-[#0F172A] py-[10px] border border-[#CBD5E1] dark:border-[#1F2A37] rounded-md">
                    <div className="flex items-center space-x-3 pr-[1rem]">
                        <span>
                            <svg className='w-4 h-4' viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M8.16667 14.8333C11.8486 14.8333 14.8333 11.8486 14.8333 8.16667C14.8333 4.48477 11.8486 1.5 8.16667 1.5C4.48477 1.5 1.5 4.48477 1.5 8.16667C1.5 11.8486 4.48477 14.8333 8.16667 14.8333Z" stroke="#94A3B8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                                <path d="M16.5 16.5L12.875 12.875" stroke="#94A3B8" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round"/>
                            </svg>
                        </span>
                        <span className=" text-[#94A3B8] truncate">{(selectedSymbol && currentRoute?.name == 'Quote') ? selectedSymbol : 'Search stock or company name'}</span>
                    </div>
                <div className="flex items-center border dark:border-[#1F2A37] searchCommand dark:bg-[#0F172A] border-[#CBD5E1] text-[#94A3B8] bg-[#ffffff] px-2 py-1 space-x-[2px]  rounded-[6px]">
                    {platform === "Mac" ? <CommandLOGO/> : <span>Ctrl</span>}
                    {/* {platform === "Mac" ? <span>command</span> : <span>Ctrl</span>} */}
                    <span>+</span>
                    <span>F</span>
                </div>
            </div>
        </div>
        </div>
    </>
  )
}

export default Navbar