import React,{useState} from 'react'

function PortfolioTable({data}) {
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage,setItemsPerPage] = useState(10)
    const columns = ["Stock/Ticker name","% of Portfolio",]
    const totalPages = Math.ceil(data?.length / itemsPerPage);
    const startIndex = ()=>{
        return ((currentPage - 1) * itemsPerPage) + 1
    };
    const onPageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
      };

    const endIndex = ()=>{
        return currentPage * itemsPerPage
    };

    const firstPage = () => {
        setCurrentPage(1);
        onPageChange(1);
      };
    
      const prevPage = () => {
        if (currentPage > 1) {
          setCurrentPage(currentPage - 1);
          onPageChange(currentPage - 1);
        }
      };

      const nextPage = () => {
        if (currentPage < totalPages) {
          setCurrentPage(currentPage + 1);
          onPageChange(currentPage + 1);
        }
      };

      const lastPage = () => {
        setCurrentPage(totalPages);
        onPageChange(totalPages);
      };

      const changePage = (pageNumber) => {
        setCurrentPage(pageNumber);
        onPageChange(pageNumber);
      };

      const getPaginatedData = () => {
        const startIndex = (currentPage - 1) * itemsPerPage + 1;
        const endIndex = startIndex * itemsPerPage;
        
        return data?.slice(startIndex, endIndex);
      };

  return (
    <div className='border w-full max-h-[75vh] dark:bg-[#020617] overflow-hidden overflow-hidden dark:border-[#1E293B] rounded-[8px]'>
        <div className='max-h-[65vh] border-b dark:border-[#1E293B] overflow-x-auto overflow-y-auto'>
            <table className='w-full text-[15px] bg-white text-left rtl:text-right'>
                <thead className='z-50 sticky top-0 bg-[#F1F5F9] dark:bg-[#0F172A]'>
                    <tr>
                        <th className='py-3 w-[3rem] whitespace-nowrap border-none px-1 px-[1rem] text-[12px] font-[500] w-6 text-center'>
                            #
                        </th>
                        <th  className="px-6 w-[17rem] text-left py-3 whitespace-nowrap border-b text-black dark:text-white text-[14px] border-none font-medium">
                         Stock/Ticker name 
                        </th>
                        <th  className="px-6 w-[80rem] text-right  text-left py-3 whitespace-nowrap border-b text-black dark:text-white text-[14px] border-none font-medium">
                         % of Portfolio
                        </th>
                    </tr>
                </thead>
                
                <tbody className='w-full'>
                    
                    {getPaginatedData()?.map((item,i)=>(
                        <tr className=" text-black dark:hover:bg-[#151d26] dark:text-white hover:bg-[#f9f9f9] dark:bg-[#020617]">
                            <td className='py-3 whitespace-nowrap px-1 px-[1rem] text-[12px] font-[500] w-6 text-center'>
                                {i+1}
                            </td>
                            <td className='py-4 whitespace-nowrap px-1 px-[1rem] text-[12px] font-[500] w-6'>
                                <div className='w-full h-full flex items-center space-x-3'>
                                    <div>
                                        <img src={require(`../../assets/images/${item?.img}`)} className='w-8 h-8' />
                                    </div>
                                    <div className='flex flex-col'>
                                        <span className='text-[18px] font-bold'>
                                            {item?.ticker}
                                        </span>
                                        <span className='text-[11px] dark:text-[#94A3B8]'>
                                            $187.42 <span className='text-[#34D399]'>+0.17(+1.82%)</span>
                                        </span>

                                    </div>

                                </div>
                            </td>

                            <td className='w-[40rem]'>
                                <span className='text-right px-4 w-full flex space-y-1 items-end flex-col'>
                                    <span>
                                        {item?.portolio_percent}%
                                    </span>
                                    <span className='w-full h-2 rounded-full bg-[#CED8E5] relative dark:bg-[#0F172A]'>
                                        <span style={{width:`${item?.portolio_percent}%`}} className={`h-2 rounded-full bg-[#3B82F6] left-0 absolute`} />
                                    </span>
                                </span>
                            </td>
                           
                            
                        </tr>
                    )) }
                </tbody>
            </table>
        </div>
        <div className='w-full h-[8vh] px-4 sm:flex-1 mb-2 border-t dark:border-[#1E293B] sm:flex sm:items-center sm:justify-between'>
            <div>
           <p className='text-[14px] font-[500] leading-5 font-[500] dark:text-[#64748B] text-[#6D6D6D]'>
            Showing:
                <span> { startIndex() } </span>
                to
                <span> { endIndex() } </span>
                of
                <span> { data?.length - 1 } </span>
                results   
                </p>
            </div>
            <div>
           {data?.length > 0 && 
           <nav className="paginationArea">
    <ul className="flex items-center border dark:border-[#1F2A37] rounded-[8px] h-10 text-base bg-[#f1f5f9] dark:bg-[#0f172a]">
      {/* <li>
        <button
          onClick={firstPage}
          disabled={currentPage === 1}
          className={`${
            currentPage === 1
              ? 'cursor-not-allowed text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px] border-r dark:border-[#1F2A37] h-10 flex items-center justify-center px-4 ms-0 leading-tight`}
        >
          First
        </button>
      </li> */}
      <li>
        <button
          onClick={prevPage}
          disabled={currentPage === 1}
          className={`${
            currentPage === 1
              ? 'cursor-not-allowed  text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px] h-10 flex items-center justify-center px-4 ms-0 leading-tight`}
        >
          Previous
        </button>
      </li>
      {Array.from({ length: totalPages }, (_, i) => i + 1).map(pageNumber => (
        <li key={pageNumber}>
          <button
            onClick={() => changePage(pageNumber)}
            className={`${
              currentPage === pageNumber
                ? 'bg-[#E2E8F0]  dark:bg-[#F8FAFC] text-[#020617]'
                : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#94A3B8] dark:hover:bg-[#1F2A37]'
            } text-[14px]  h-10 flex items-center border-l borde-r dark:border-[#1F2A37] justify-center px-4 ms-0 leading-tight `}
          >
            {pageNumber}
          </button>
        </li>
      ))}
      <li>
        <button
          onClick={nextPage}
          disabled={currentPage === totalPages}
          className={`${
            currentPage === totalPages
              ? 'cursor-not-allowed  text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px]  border-l border-r dark:border-[#1F2A37] h-10 flex items-center justify-center px-4 ms-0 leading-tight `}
        >
          Next
        </button>
      </li>
      {/* <li>
        <button
          onClick={lastPage}
          disabled={currentPage === totalPages}
          className={`${
            currentPage === totalPages
              ? 'cursor-not-allowed text-gray-400 dark:text-[#475569]'
              : 'cursor-pointer hover:bg-[#e6ecf4] dark:text-[#F8FAFC] dark:hover:bg-[#1F2A37]'
          } text-[14px]  flex h-10 items-center justify-center px-4 ms-0 leading-tight `}
        >
          Last
        </button>
      </li> */}
    </ul>
            </nav>}
    </div>


        </div>
    </div>
  )
}

export default PortfolioTable