import React from 'react'
import ModalBtn from '../Button/ModalBtn'
import {toggleSettingModal,toggleDeleteModal} from '../../store/slices/themeSlice'
import { useDispatch } from 'react-redux'

function YourData() {
  const dispatch = useDispatch()

  const handleDeleteAccount = ()=>{
    dispatch(toggleSettingModal())
    dispatch(toggleDeleteModal())
  }

  return (
    <div className='w-full h-full'>
      <div className='w-full h-[80%]  border-b border-[#cecece70] dark:border-[#1E293B] text-[16px] font-normal dark:text-[#94A3B8] p-4'>
      <div className='yourDataText'>
          Once your account is deleted, all of its resources and data will be permanently deleted as well. 
        </div>

        <div className='my-10 yourDataText'>
          This includes, but is not limited to: holdings, <span className='dark:text-[#F1F5F9] font-semibold dark:font-normal underline'>brokerage account data, historical metrics</span>, and more. Before deleting your account, please download any data or information that you wish to retain.
        </div>

        <div>
          <div className='w-fit'>
            <ModalBtn title='Request My Data' customClass='!font-[650] px-[4rem] !bg-[#FFac39] text-black' />
          </div>
        </div>
      </div>
      <div className='w-full h-[20%] pr-6 dark:bg-[#0f0714] flex justify-end items-center'>
        <div onClick={handleDeleteAccount} className='flex items-center cursor-pointer hover:opacity-80 space-x-2 bg-[#DC2626] dark:bg-[#300c17] py-3 px-8 rounded-[8px]'>
          <span>
          <svg width="16" height="16" className='text-white dark:text-[#EF4444]' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4H3.33333H14" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M12.6668 4.00016V13.3335C12.6668 13.6871 12.5264 14.0263 12.2763 14.2763C12.0263 14.5264 11.6871 14.6668 11.3335 14.6668H4.66683C4.31321 14.6668 3.97407 14.5264 3.72402 14.2763C3.47397 14.0263 3.3335 13.6871 3.3335 13.3335V4.00016M5.3335 4.00016V2.66683C5.3335 2.31321 5.47397 1.97407 5.72402 1.72402C5.97407 1.47397 6.31321 1.3335 6.66683 1.3335H9.3335C9.68712 1.3335 10.0263 1.47397 10.2763 1.72402C10.5264 1.97407 10.6668 2.31321 10.6668 2.66683V4.00016" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.66675 7.3335V11.3335" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M9.3335 7.3335V11.3335" stroke="currentColor" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </span>
          <span className='text-white dark:text-[#EF4444] font-semibold text-[14px]'>
            Delete My Account
          </span>
        </div>
      </div>
    
    </div>
  )
}

export default YourData