
export const TruncateText = (text,num) => {
    if (text.length > num) {
      return text.slice(0, num) + "...";
    } else {
      return text;
    }
   } 

export const RandomIdGenerator = (length) => {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}