import React, { useState } from "react";
import CardYearHeading from "../components/cardYearHeading";
import BarChart from "../components/charts/BarChartNew";
import CardSlider from "../components/CardSlider";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "../components/CalendarCSS/index.css"
import CustomToolbar from "../components/CalendarEvents";
import SimpleTable from "../components/table/SimpleTable";

const localizer = momentLocalizer(moment);

function PayoutCalender() {
  const [events, setEvents] = useState([
    {
      title: "IBM",
      start: new Date(2023, 4, 11, 10, 0), // Month is 0-based, so May is 4
      end: new Date(2023, 4, 11, 12, 0),
    },
    {
      title: "TSLA",
      start: new Date(2023, 4, 12, 12, 0),
      end: new Date(2023, 4, 12, 14, 0),
    },
  ]);
  const [revenueSeries, setRevenueSeries] = useState([
    {
      name: "Revenue Shares",
      data: [1123, 1523, 1882, 2000, 2302, 2630, 2833, 3000, 3322],
    },
  ]);
  const [tableData, setTableData] = useState([
    { declaredDate: "Week 1", exDate: "02/09/24", recordDate: "$4.32" },
    { declaredDate: "Week 1", exDate: "02/09/24", recordDate: "$6.66" },
    { declaredDate: "Week 2", exDate: "02/09/24", recordDate: "$7.54" },
    { declaredDate: "Week 3", exDate: "02/09/24", recordDate: "$8.32" },
    { declaredDate: "Week 4", exDate: "02/09/24", recordDate: "$9.44" },
    { declaredDate: "Week 5", exDate: "02/09/24", recordDate: "$3.54" },
  ]);
  const tableColumns = [
    { key: "declaredDate", value: "Week" },
    { key: "exDate", value: "Stock/Ticker name" },
    { key: "recordDate", value: "Total" },
  ];
  const [currencyTable, setCurrencyTable] = useState([
    {
      exDate: "IBM",
      declaredDateMar: "$2.22",
      declaredDateApr: "$4.32",
      declaredDateMar: "$2.22",
      declaredDateMay: "$4.32",
      declaredDateJun: "$2.22",
      declaredDateJul: "$4.32",
      declaredDateAug: "$2.22",
    },
    {
      exDate: "TSLA",
      declaredDateMar: "$2.22",
      declaredDateApr: "$6.66",
      declaredDateMar: "$2.22",
      declaredDateMay: "$6.66",
      declaredDateJun: "$2.22",
      declaredDateJul: "$6.66",
      declaredDateAug: "$2.22",
    },
    {
      exDate: "AAPL",
      declaredDateMar: "$2.22",
      declaredDateApr: "$7.54",
      declaredDateMar: "$2.22",
      declaredDateMay: "$7.54",
      declaredDateJun: "$2.22",
      declaredDateJul: "$7.54",
      declaredDateAug: "$2.22",
    },
    {
      exDate: "NVDA",
      declaredDateMar: "$2.22",
      declaredDateApr: "$8.32",
      declaredDateMar: "$2.22",
      declaredDateMay: "$8.32",
      declaredDateJun: "$2.22",
      declaredDateJul: "$8.32",
      declaredDateAug: "$2.22",
    },
    {
      exDate: "META",
      declaredDateMar: "$2.22",
      declaredDateApr: "$9.44",
      declaredDateMar: "$2.22",
      declaredDateMay: "$9.44",
      declaredDateJun: "$2.22",
      declaredDateJul: "$9.44",
      declaredDateAug: "$2.22",
    },
    {
      exDate: "COIN",
      declaredDateMar: "$2.22",
      declaredDateApr: "$3.54",
      declaredDateMar: "$2.22",
      declaredDateMay: "$3.54",
      declaredDateJun: "$2.22",
      declaredDateJul: "$3.54",
      declaredDateAug: "$2.22",
    },
  ]);
  const currencyColumns = [
    { key: "exDate", value: "Stock/Ticker name" },
    { key: "declaredDateMar", value: "Mar, 24" },
    { key: "declaredDateApr", value: "Apr, 24" },
    { key: "declaredDateMay", value: "May, 24" },
    { key: "declaredDateJun", value: "Jun, 24" },
    { key: "declaredDateJul", value: "Jul, 24" },
    { key: "declaredDateAug", value: "Aug, 24" },
  ];
  const [scheduleTable, setScheduleTable] = useState([
    { exDate: "IBM",Shares:'12341', Amount:'$0.123', Total:'$1.77', PaymentDate:'2024-05-17'},
    { exDate: "TSLA",Shares:'43123', Amount:'$0.123', Total:'$1.77', PaymentDate:'2024-05-17'},
    { exDate: "AAPL",Shares:'13234', Amount:'$0.123', Total:'$1.77', PaymentDate:'2024-05-17'},
    { exDate: "GOOGL",Shares:'134', Amount:'$0.123', Total:'$1.77', PaymentDate:'2024-05-17'},
    { exDate: "MSFT",Shares:'1324', Amount:'$0.123', Total:'$1.77', PaymentDate:'2024-05-17'},
  ]);
  const scheduleColumns = [
    { key: "exDate", value: "Stock/Ticker name" },
    { key: "Shares", value: "Shares" },
    { key: "Amount", value: "Amount" },
    { key: "Total", value: "Total" },
    { key: "PaymentDate", value: "Payment Date" },
  ];
  const profitability = [
    { name: "YTD Income", value: "44.13%" },
    { name: "Monthly Average", value: "33.76%" },
    { name: "March Income", value: "26.16%" },
    { name: "Annual Income", value: "154.27%" },
    // {name:"ROI (TTM)",value:"-/"},
  ];
  const handleSelectSlot = ({ start, end }) => {
    const title = window.prompt("New Event name");
    if (title) {
      setEvents([...events, { start, end, title }]);
    }
  };

  const renderFooter = () => {
    return (
      <div className="w-full items-center flex justify-around">
        <span className="bg-red-400 w-[25rem] text-emerald-500 text-[14px]"></span>
        <div className="flex w-[58rem] justify-around items-center pl-12 ">
        <span className="flex w-[10rem] ml-2 text-emerald-500 text-[14px]">$2.34</span>
        <span className="flex w-[10rem] ml-10 text-emerald-500 text-[14px]">$2.34</span>
        <span className="flex w-[10rem] ml-10 text-emerald-500 text-[14px]">$2.34</span>
        <span className="flex w-[10rem] ml-10 text-emerald-500 text-[14px]">$2.34</span>
        <span className="flex w-[10rem] ml-10 text-emerald-500 text-[14px]">$2.34</span>
        <span className="flex w-[10rem] ml-10 text-emerald-500 text-[14px]">$2.34</span>
        </div>
      </div>
    );
  };

  return (
    <div className=" flex flex-col h-[90vh] w-full py-4 space-y-3 ">
      <span className="mt-2">Yearly Dividend Cast</span>
      <div className="flex flex-col dark:bg-slate-900 bg-slate-100 w-full h-[25rem] rounded-lg p-4">
        {/* <CardYearHeading heading='Yearly Dividend Cast' /> */}
        <div className="h-[40rem]">
          <BarChart series={revenueSeries} />
        </div>
      </div>
      <CardSlider title="Basic Statistics" data={profitability} />

      <div className="h-[55rem] w-full py-10">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: 800 }}
          selectable
          onSelectSlot={handleSelectSlot}
          components={{
            toolbar: CustomToolbar,
          }}
        />
      </div>
      <div className="h-[50rem] space-y-3">
        <span>Weekly Summary for March</span>
        <SimpleTable
          columns={tableColumns}
          data={tableData}
          condition={
            <div className="w-full items-center flex justify-end">
              <span>
                Monthly Total{" "}
                <span className="text-emerald-400 font-semibold text-[20px]">
                  $9.44
                </span>
              </span>
            </div>
          }
        />
      </div>
      <div className="h-[50rem] space-y-3 py-10 flex flex-col">
        <span>Dividend paid in US currency</span>
        <SimpleTable
          columns={currencyColumns}
          data={currencyTable}
          condition={renderFooter()}
        />
      </div>
      <div className="h-[50rem] space-y-3 flex flex-col pb-6">
        <span>Dividend Payout Schedule</span>
        <SimpleTable columns={scheduleColumns} data={scheduleTable}  />
      </div>
    </div>
  );
}

export default PayoutCalender;