import React from 'react'
import IdeasTable from '../components/table/IdeasTable'
function DividendKings() {
  const columns = [{key:'ticker',value:'Company'},{key:'price',value:'Price'},{key:'divYield',value:'Div Yield'},{key:'frequency',value:'Frequency'},{key:'divAmount',value:'Div Amount'},{key:'payout',value:'Payout Ratio'},{key:'grade',value:'Grade'}]
  const data = [
    {ticker:'META',image:'meta.png',price:172.70,lowPrice:171.66,highPrice:173.56,divYield:0.56,frequency:'Quarterly',divAmount:0.56,payout:14.91,grade:10},
    {ticker:'NVDA',image:'nvda.png',price:172.70,lowPrice:171.66,highPrice:173.56,divYield:0.56,frequency:'Monthly',divAmount:0.56,payout:14.91,grade:50},
    {ticker:'TSLA',image:'tesla.png',price:172.70,lowPrice:171.66,highPrice:173.56,divYield:0.56,frequency:'Quarterly',divAmount:0.56,payout:14.91,grade:39},
    {ticker:'IBM',image:'ibm.png',price:172.70,lowPrice:171.66,highPrice:173.56,divYield:0.56,frequency:'Monthly',divAmount:0.56,payout:14.91,grade:89},
    {ticker:'AMZN',image:'amazon.png',price:172.70,lowPrice:171.66,highPrice:173.56,divYield:0.56,frequency:'Quarterly',divAmount:0.56,payout:14.91,grade:59},
    {ticker:'AAPL',image:'apple.png',price:172.70,lowPrice:171.66,highPrice:173.56,divYield:0.56,frequency:'Monthly',divAmount:0.56,payout:14.91,grade:89},
]
  const detail = 'The Dividend King is a publicly traded company that has increased its shareholder dividends every year for at least the past 50 years. These companies have a proven track record of rewarding shareholders with regular dividends.'

  return (
    <div className='w-full h-full pt-[1rem]'>
      <IdeasTable title='Dividend Kings' columns={columns} data={data} detail={detail} showPriceRange={true} />
    </div>
  )
}

export default DividendKings