import React, { useState, useRef, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import MainCard from "../CardBox/MainCard";
import tesla from "../../assets/images/tesla.png";
import SingleAreaChart from "../charts/SingleAreaChart";
import CardHeading from "../cardHeading";
import GaugeComponent from "react-gauge-component";
import PriceArrow from "../PriceArrow";
import IconCircle from "../iconCircle";
import CardSlider from "../CardSlider";
import CardYearHeading from "../cardYearHeading";
import BarChart from "../charts/BarChart";
import NewsCard from "../NewsCard";
import { tableDummyData } from "../../utils/sidebarContent";
import SmallCard from "../SmallCard";
import { symbol } from "d3";
import { getSummary } from "../../ApiControler";
function Summary() {
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const symbol = queryParams.get("symbol");
  const [summaryData, setSummaryData] = useState([]);
  const [logoImage, setLogoImage] = useState();
  const elementRef = useRef(null);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  const [similarStocks, setSimilarStocks] = useState(tableDummyData);
  const [hideLeftArrow, setHideLeftArrow] = useState(true);
  const [hideRightArrow, setHideRightArrow] = useState(true);
  const [selectedView, setSelectedView] = useState("cardSlider");
  const [news, setNews] = useState([
    {
      title:
        "Apple Stock Surges to New Heights Amid Record Sales and Strong Earnings Report",
      detail:
        "Apple's stock (AAPL) reaches unprecedented heights as the tech giant reports record-breaking sales figures and impressive earnings for the quarter. With robust demand for its latest products and services, investors are bullish on Apple's future prospects, driving the stock price to soar.",
      publish: "12 Mar, 2024",
      imgae: "img1.png",
    },
    {
      title: "Apple Stock Hits All-Time High on Strong Q4 Performance",
      detail:
        "Apple's stock (AAPL) reaches an unprecedented peak as the company reports stellar performance in its fourth-quarter earnings. With impressive sales figures across its product lines and promising projections for the future, investor confidence in Apple's trajectory drives the stock price to its highest level yet",
      publish: "13 Mar, 2024",
      imgae: "img2.png",
    },
    {
      title:
        "Apple Stock Surges to New Heights Amid Record Sales and Strong Earnings Report",
      detail:
        "Apple's stock (AAPL) reaches unprecedented heights as the tech giant reports record-breaking sales figures and impressive earnings for the quarter. With robust demand for its latest products and services, investors are bullish on Apple's future prospects, driving the stock price to soar.",
      publish: "14 Mar, 2024",
      imgae: "img1.png",
    },
  ]);
  const [outstandingSeries, setOutstandingSeries] = useState([
    {
      name: "Outstanding Shares",
      data: [2652, 2052, 1902, 2352, 1152, 1652, 1352, 1352, 1952],
    },
  ]);
  const [revenueSeries, setRevenueSeries] = useState([
    {
      name: "Revenue Shares",
      data: [1123, 1523, 1882, 2000, 2302, 2630, 2833, 3000, 3322],
    },
  ]);
  const [epsSeries, setEpsSeries] = useState([
    {
      name: "Eps Shares",
      data: [1123, 1523, 1882, 2000, 2302, 2630, 2833, 3000, 3322],
    },
  ]);
  const [netMarginSeries, setNetMarginSeries] = useState([
    {
      name: "Net Margin Shares",
      data: [3223, 3003, 2900, 2700, 3302, 2630, 2333, 3200, 3022],
    },
  ]);
  const general = [
    {
      name: "Market Cap",
      value: `$${summaryData?.stockMetrics?.MarketCapitalization ?? 0}`,
    },
    {
      name: "Dividend Yield",
      value: `${summaryData?.stockMetrics?.DividendYieldIndicatedAnnual ?? 0}%`,
    },
    {
      name: "Dividend Payout Ratio",
      value: `${summaryData?.stockMetrics?.PayoutRatioTTM ?? 0}%`,
    },
    {
      name: "Dividend Growth (5Y)",
      value: `${summaryData?.stockMetrics?.DividendGrowthRate5Y ?? 0}%`,
    },
    { name: "Dividend Streak", value: "17 Years" },
    { name: "Shares Owned", value: "6.94" },
    { name: "EPS (TTM)", value: `${summaryData?.stockMetrics?.EpsTTM ?? 0}%` },
    {
      name: "10-Day Volume",
      value: `${summaryData?.stockMetrics?._10DayAverageTradingVolume ?? 0}%`,
    },
    {
      name: "Shares Outstanding",
      value: `${summaryData?.companyProfile?.shareOutstanding ?? 0}%`,
    },
    { name: "Beta", value: `${summaryData?.stockMetrics?.Beta ?? 0}%` },
  ];

  const profitability = [
    {
      name: "Gross Margin (TTM)",
      value: `${summaryData?.stockMetrics?.GrossMarginTTM ?? 0}%`,
    },
    {
      name: "Op.Margin (TTM)",
      value: `${summaryData?.stockMetrics?.OperatingMarginTTM ?? 0}%`,
    },
    {
      name: "Net Margin (TTM)",
      value: `${summaryData?.stockMetrics?.NetProfitMarginTTM ?? 0}%`,
    },
    { name: "ROE (TTM)", value: `${summaryData?.stockMetrics?.RoeTTM ?? 0}%` },
    { name: "ROI (TTM)", value: `${summaryData?.stockMetrics?.RoiTTM ?? 0}%` },
  ];

  const valuation = [
    { name: "PE Ratio", value: `${summaryData?.metaData?.peRatio ?? 0}%` },
    { name: "PEG Ratio", value: "2.10%" },
    { name: "Price to Sales", value: "6.83%" },
    { name: "Price to Book", value: "35.58%" },
  ];

  const financialHealth = [
    {
      name: "Current Ratio",
      value: `${summaryData?.stockMetrics?.CurrentRatioAnnual ?? 0}%`,
    },
    {
      name: "Quick Ratio",
      value: `${summaryData?.stockMetrics?.QuickRatioAnnual ?? 0}%`,
    },
    {
      name: "Debt to Equity",
      value: `${summaryData?.stockMetrics?.LongTermDebtEquityAnnual ?? 0}%`,
    },
    {
      name: "EPS Growth (3Y)",
      value: `${summaryData?.stockMetrics?.EpsGrowth3Y ?? 0}%`,
    },
    {
      name: "EPS Growth (5Y)",
      value: `${summaryData?.stockMetrics?.EpsGrowth5Y ?? 0}%`,
    },
  ];

  const hypothetical = [
    { name: "Capital Growth", value: "$9,491.24" },
    { name: "Dividend Return to Date", value: "$155.52" },
    { name: "Dividend Payout year Est.", value: "$51.84" },
    { name: "Expenses", value: "$0.00" },
    { name: "Total Return", value: "$9,646.76" },
    { name: "Total Return %", value: "-3.53%" },
  ];

  const companyStats = [
    { name: "High Price", value: "$174.93" },
    { name: "Low Price", value: "$174.05" },
    { name: "Open Price", value: "$174.05" },
    { name: "Previously Close Price", value: "$170.05" },
    { name: "52 Week High", value: "$199.05" },
    { name: "52 Week Low", value: "$146.92" },
  ];

  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const data = await getSummary(token, symbol)
      setSummaryData(data?.data);
      setLogoImage(data?.data?.companyProfile?.logo.replace(".svg", ".png"));
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    fetchData();
  }, [symbol,logoImage]);

  useEffect(() => {
    handleHorizantalScroll(elementRef.current, 25, 10, 10);
  }, []);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleHorizantalScroll = (element, speed, distance, step) => {
    let scrollAmount = 0;
    const slideTimer = setInterval(() => {
      element.scrollLeft += step;
      scrollAmount += Math.abs(step);
      if (scrollAmount >= distance) {
        clearInterval(slideTimer);
      }
      if (element.scrollLeft === 0) {
        setHideLeftArrow(true);
      } else {
        setHideLeftArrow(false);
      }

      if (element.scrollLeft === element.scrollWidth - element.clientWidth) {
        setHideRightArrow(true);
      } else {
        setHideRightArrow(false);
      }
    }, speed);
  };

  const convertImageSVG = () => {
    const logoImage = summaryData?.companyProfile?.logo.replace(".svg", ".png");
    return logoImage;
  };

  const previousClose = summaryData?.data?.lastclose || 0;
  const latestClose = summaryData?.data?.close || 0;

  const difference = latestClose - previousClose;
  const percentageChange = (difference / latestClose) * 100;

  const formattedDifference = difference.toFixed(2);
  const formattedPercentageChange = percentageChange.toFixed(2);

  return (
    <div className="w-full h-full space-y-[1.5rem]">
      <div className="flex items-center gap-4 cardMainContainer w-full">
        <div className="w-8/12 cardSubContainer h-[60vh]">
          <MainCard customClass={"border-none"}>
            <div className="w-full h-fit -bg-red-200 flex items-center space-x-4">
              <img src={logoImage} className="w-[3rem] h-[3rem]" />
              <div className="flex flex-col">
                <span className="text-[16px] font-bold dark:text-[#F8FAFC]">
                  {symbol}{" "}
                  <span className="font-normal">
                    {summaryData?.companyProfile?.name}
                  </span>
                </span>
                <span className="text-[14px] dark:text-[#94A3B8]">
                  { summaryData?.companyProfile?.exchange}
                </span>
                <span className="mt-1 text-[16px] font-semibold dark:text-[#F8FAFC]">
                  {"$"+summaryData?.data?.lastclose}{" "}

                  <span className="text-[12px] font-medium text-[#10B981]">
                    {`${difference > 0 ? "+" : ""}${formattedDifference} (${
                      difference > 0 ? "+" : ""
                    }${formattedPercentageChange}%)`}
                  </span>
                </span>
                <span className="text-[9px] font-medium dark:text-[#64748B]">
                  Last updated: Today
                </span>
              </div>
            </div>
            <div className="w-full h-[80%] -bg-green-100">
              <SingleAreaChart />
            </div>
          </MainCard>
        </div>

        <div className="w-4/12 cardSubContainer h-[60vh]">
          <MainCard customClass={"border-none"}>
            <div className="h-[10%]">
              <CardHeading heading="Dividend Grade" showLine={true} />
            </div>
            <div className="h-[90%] w-full flex flex-col items-center justify-center">
              <div className="w-full h-[60%] relative">
                <GaugeComponent
                  type="semicircle"
                  arc={{
                    colorArray: ["#F87171", "#FDBA74", "#FFEB3A", "#10B981"],
                    padding: 0.04,
                    width: 0.1,
                    subArcs: [
                      { limit: 45 },
                      { limit: 68 },
                      { limit: 82 },
                      { limit: 100 },
                    ],
                  }}
                  pointer={{ type: "blob", animationDelay: 0, width: 15 }}
                  value={89}
                  labels={{
                    valueLabel: {
                      hide: true,
                    },
                    tickLabels: {
                      hideMinMax: true,
                    },
                  }}
                />

                <div className="absolute dark:text-[#FFFFFF] font-bold -bg-red-200 bottom-2 w-full flex justify-center items-center text-[3.2rem]">
                  89
                </div>
              </div>
              <div className="text-black dark:text-[#E2E8F0] largeText text-[14px] font-medium">
                APPL Dividend Grade is
              </div>
              <div className="text-black dark:text-[#94A3B8] mediumText text-[12px] font-light">
                Updated Nov 24, 2024
              </div>
            </div>
          </MainCard>
        </div>
      </div>

      <div
        className={`flex items-center gap-4 w-full ${
          screenWidth < 891 ? "cardMainContainer" : ""
        }`}
      >
        <div
          className={`w-6/12 h-[25vh] ${
            screenWidth < 891 ? "cardSubContainer" : ""
          }`}
        >
          <MainCard customClass={"border-none"}>
            <div className="w-full h-full flex flex-col justify-between">
              <CardHeading heading="Today" showLine={true} />
              <div className="flex flex-col w-full space-y-1">
                <div className="w-full flex items-center justify-between">
                  <PriceArrow
                    title="Low Price"
                    price={summaryData?.data?.low}
                    svgcolor="text-[#FB4644]"
                  />
                  <PriceArrow
                    title="Open Price"
                    price={summaryData?.data?.open}
                    svgcolor="text-[#CFC51B]"
                  />
                  <PriceArrow
                    title="High Price"
                    price={summaryData?.data?.high}
                    svgcolor="text-[#07FE82]"
                  />
                </div>
                <div className="w-full h-3 rounded-[10px] gradient-bg" />
              </div>
            </div>
          </MainCard>
        </div>
        <div
          className={`w-6/12 h-[25vh] ${
            screenWidth < 891 ? "cardSubContainer" : ""
          }`}
        >
          <MainCard customClass={"border-none"}>
            <div className="w-full h-full flex flex-col justify-between">
              <CardHeading heading="52 Week" showLine={true} />
              <div className="flex flex-col w-full space-y-1">
                <div className="w-full flex items-center justify-between">
                  <PriceArrow
                    title="Low Price"
                    price={summaryData?.stockMetrics?._52WeekLow}
                    svgcolor="text-[#FB4644]"
                  />
                  <PriceArrow
                    title="High Price"
                    price={summaryData?.stockMetrics?._52WeekHigh}
                    svgcolor="text-[#07FE82]"
                  />
                </div>
                <div className="w-full h-3 rounded-[10px] gradient-bg" />
              </div>
            </div>
          </MainCard>
        </div>
      </div>

      <div className="w-full flex items-center space-x-3">
        <IconCircle
          handleClick={() => setSelectedView("cardSlider")}
          customClass={`${
            selectedView == "cardSlider"
              ? "dark:bg-[#431407] bg-[#F88F00] dark:hover:bg-[#431407] hover:bg-[#F88F00]"
              : ""
          }`}
        >
          <svg
            className={`${
              selectedView == "cardSlider"
                ? "dark:text-[#F88F00] text-black"
                : "dark:text-[#F8FAFC] text-black"
            }`}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.1665 7.16667H5.49983C6.4165 7.16667 7.1665 6.41667 7.1665 5.5V2.16667C7.1665 1.25 6.4165 0.5 5.49983 0.5H2.1665C1.24983 0.5 0.499832 1.25 0.499832 2.16667V5.5C0.499832 6.41667 1.24983 7.16667 2.1665 7.16667ZM2.1665 15.5H5.49983C6.4165 15.5 7.1665 14.75 7.1665 13.8333V10.5C7.1665 9.58333 6.4165 8.83333 5.49983 8.83333H2.1665C1.24983 8.83333 0.499832 9.58333 0.499832 10.5V13.8333C0.499832 14.75 1.24983 15.5 2.1665 15.5ZM8.83317 2.16667V5.5C8.83317 6.41667 9.58317 7.16667 10.4998 7.16667H13.8332C14.7498 7.16667 15.4998 6.41667 15.4998 5.5V2.16667C15.4998 1.25 14.7498 0.5 13.8332 0.5H10.4998C9.58317 0.5 8.83317 1.25 8.83317 2.16667ZM10.4998 15.5H13.8332C14.7498 15.5 15.4998 14.75 15.4998 13.8333V10.5C15.4998 9.58333 14.7498 8.83333 13.8332 8.83333H10.4998C9.58317 8.83333 8.83317 9.58333 8.83317 10.5V13.8333C8.83317 14.75 9.58317 15.5 10.4998 15.5Z"
              fill="currentColor"
            />
          </svg>
        </IconCircle>
        <IconCircle
          handleClick={() => setSelectedView("card")}
          customClass={`${
            selectedView == "card"
              ? "dark:bg-[#431407] bg-[#F88F00] dark:hover:bg-[#431407] hover:bg-[#F88F00]"
              : ""
          }`}
        >
          <svg
            className={`${
              selectedView == "card"
                ? "dark:text-[#F88F00] text-black"
                : "dark:text-[#F8FAFC] text-black"
            }`}
            width="18"
            height="10"
            viewBox="0 0 18 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M1 6C1.55 6 2 5.55 2 5C2 4.45 1.55 4 1 4C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6ZM1 10C1.55 10 2 9.55 2 9C2 8.45 1.55 8 1 8C0.45 8 0 8.45 0 9C0 9.55 0.45 10 1 10ZM1 2C1.55 2 2 1.55 2 1C2 0.45 1.55 0 1 0C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2ZM5 6H17C17.55 6 18 5.55 18 5C18 4.45 17.55 4 17 4H5C4.45 4 4 4.45 4 5C4 5.55 4.45 6 5 6ZM5 10H17C17.55 10 18 9.55 18 9C18 8.45 17.55 8 17 8H5C4.45 8 4 8.45 4 9C4 9.55 4.45 10 5 10ZM4 1C4 1.55 4.45 2 5 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H5C4.45 0 4 0.45 4 1ZM1 6C1.55 6 2 5.55 2 5C2 4.45 1.55 4 1 4C0.45 4 0 4.45 0 5C0 5.55 0.45 6 1 6ZM1 10C1.55 10 2 9.55 2 9C2 8.45 1.55 8 1 8C0.45 8 0 8.45 0 9C0 9.55 0.45 10 1 10ZM1 2C1.55 2 2 1.55 2 1C2 0.45 1.55 0 1 0C0.45 0 0 0.45 0 1C0 1.55 0.45 2 1 2ZM5 6H17C17.55 6 18 5.55 18 5C18 4.45 17.55 4 17 4H5C4.45 4 4 4.45 4 5C4 5.55 4.45 6 5 6ZM5 10H17C17.55 10 18 9.55 18 9C18 8.45 17.55 8 17 8H5C4.45 8 4 8.45 4 9C4 9.55 4.45 10 5 10ZM4 1C4 1.55 4.45 2 5 2H17C17.55 2 18 1.55 18 1C18 0.45 17.55 0 17 0H5C4.45 0 4 0.45 4 1Z"
              fill="currentColor"
            />
          </svg>
        </IconCircle>
      </div>

      {selectedView == "cardSlider" && (
        <div className="w-full -inset-shadows">
          <div className="w-full">
            <CardSlider title="General" data={general} />
          </div>
          <div className="w-full mt-[5rem]">
            <CardSlider title="Profitability" data={profitability} />
          </div>
          <div className="w-full mt-[5rem]">
            <CardSlider title="Valuation" data={valuation} />
          </div>
          <div className="w-full my-[5rem]">
            <CardSlider title="Financial Health" data={financialHealth} />
          </div>
        </div>
      )}

      {selectedView == "card" && (
        <div className="w-full space-y-4">
          <div
            className={`w-full flex  items-center gap-4 ${
              screenWidth < 600 ? "cardMainContainer" : ""
            }`}
          >
            <div
              className={`w-6/12 h-[60vh] ${
                screenWidth < 600 ? "cardSubContainer" : ""
              }`}
            >
              <MainCard customClass="border-none ">
                <div className="mb-[1rem]">General</div>
                {general.map((item, index) => (
                  <div
                    key={index}
                    className="flex py-3 items-center border-b border-dashed border-b-[#E2E8F0] dark:border-b-[#1E293B] justify-between"
                  >
                    <span className="text-[#94A3B8] text-[14px] font-normal">
                      {item.name}
                    </span>
                    <span className="text-[#94A3B8] text-[14px] font-normal">
                      {item.value}
                    </span>
                  </div>
                ))}
              </MainCard>
            </div>
            <div
              className={`w-6/12 h-[60vh] ${
                screenWidth < 600 ? "cardSubContainer" : ""
              }`}
            >
              <MainCard customClass="border-none  ">
                <div className="mb-[1rem]">Profitability</div>
                {profitability.map((item, index) => (
                  <div
                    key={index}
                    className="flex py-3 items-center border-b border-dashed border-b-[#E2E8F0] dark:border-b-[#1E293B] justify-between"
                  >
                    <span className="text-[#94A3B8] text-[14px] font-normal">
                      {item.name}
                    </span>
                    <span className="text-[#94A3B8] text-[14px] font-normal">
                      {item.value}
                    </span>
                  </div>
                ))}
              </MainCard>
            </div>
          </div>

          <div
            className={`w-full flex  items-center gap-4 ${
              screenWidth < 600 ? "cardMainContainer" : ""
            }`}
          >
            <div
              className={`w-6/12 h-[35vh] ${
                screenWidth < 600 ? "cardSubContainer" : ""
              }`}
            >
              <MainCard customClass="border-none ">
                <div className="mb-[1rem]">Valuation</div>
                {valuation.map((item, index) => (
                  <div
                    key={index}
                    className="flex py-3 items-center border-b border-dashed border-b-[#E2E8F0] dark:border-b-[#1E293B] justify-between"
                  >
                    <span className="text-[#94A3B8] text-[14px] font-normal">
                      {item.name}
                    </span>
                    <span className="text-[#94A3B8] text-[14px] font-normal">
                      {item.value}
                    </span>
                  </div>
                ))}
              </MainCard>
            </div>
            <div
              className={`w-6/12 h-[35vh] ${
                screenWidth < 600 ? "cardSubContainer" : ""
              }`}
            >
              <MainCard customClass="border-none ">
                <div className="mb-[1rem]">Financial Health</div>
                {financialHealth.map((item, index) => (
                  <div
                    key={index}
                    className="flex py-3 items-center border-b border-dashed border-b-[#E2E8F0] dark:border-b-[#1E293B] justify-between"
                  >
                    <span className="text-[#94A3B8] text-[14px] font-normal">
                      {item.name}
                    </span>
                    <span className="text-[#94A3B8] text-[14px] font-normal">
                      {item.value}
                    </span>
                  </div>
                ))}
              </MainCard>
            </div>
          </div>
        </div>
      )}

      <div className="w-full space-y-5">
        <div className="flex items-center space-x-4">
          <span className="dark:text-[#F8FAFC] whitespace-nowrap text-[18px] font-normal">
            Hypothetical <span className="text-[#F88F00] font-bold">10K</span>{" "}
            Investment Jan 1, 2023
          </span>
          <span className="w-full h-[2px] dark:bg-[#0F172A] bg-[#E2E8F0]" />
        </div>
        <div className="w-full flex items-center flex-wrap gap-4">
          {hypothetical.map((item, index) => (
            <div
              className={`w-[23rem] h-[10rem] ${
                screenWidth < 1059 ? "w-full" : ""
              } `}
              key={index}
            >
              <MainCard customClass="!p-4 flex flex-col w-full justify-between">
                <div>
                  <CardHeading heading={item.name} />
                </div>
                <div className="flex items-center justify-between">
                  <span className="dark:text-[#F8FAFC] font-bold text-[1.8rem]">
                    {item.value}
                  </span>
                  <span>
                    <svg
                      className={`${
                        screenWidth > 442 && screenWidth < 1059
                          ? "w-[10rem]"
                          : "w-[7rem] h-[5rem]"
                      }`}
                      viewBox="0 0 103 59"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M2 57L13.9384 47.8334L19.0338 37.5209L26.6044 52.073L31.7 45.198L37.0868 2L50.9177 44.2814L60.9632 30.1876L80.1809 57L86.15 50.2397L89.7897 39.0105L101 30.1876"
                        stroke="#2D5BFF"
                        stroke-width="2.70648"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </span>
                </div>
              </MainCard>
            </div>
          ))}
        </div>
      </div>

      <div
        className={`w-full flex items-center !mt-[3rem] gap-4 ${
          screenWidth < 1059 ? "cardMainContainer" : ""
        }`}
      >
        <div
          className={`w-6/12 h-[50vh] ${
            screenWidth < 1059 ? "cardSubContainer" : ""
          }`}
        >
          <MainCard customClass="border-none">
            <div className="mb-[1rem]">More Company Stats</div>
            {companyStats.map((item, index) => (
              <div
                key={index}
                className="flex py-3 items-center border-b border-dashed border-b-[#E2E8F0] dark:border-b-[#1E293B] justify-between"
              >
                <span className="text-[#94A3B8] text-[14px] font-normal">
                  {item.name}
                </span>
                <span className="text-[#94A3B8] text-[14px] font-normal">
                  {item.value}
                </span>
              </div>
            ))}
          </MainCard>
        </div>
        <div
          className={`w-6/12 h-[50vh] ${
            screenWidth < 1059 ? "cardSubContainer" : ""
          }`}
        >
          <MainCard customClass="border-none">
            <div>
              <CardYearHeading heading="Outstanding Shares" />
            </div>
            <div className="w-full  h-[88%] mt-4">
              <BarChart series={outstandingSeries} />
            </div>
          </MainCard>
        </div>
      </div>

      <div
        className={`w-full flex items-center !mt-[3rem] gap-4 ${
          screenWidth < 1059 ? "cardMainContainer" : ""
        }`}
      >
        <div
          className={`w-6/12 h-[50vh] ${
            screenWidth < 1059 ? "cardSubContainer" : ""
          }`}
        >
          <MainCard customClass="border-none">
            <div>
              <CardYearHeading heading="Revenue Growth" />
            </div>
            <div className="w-full  h-[88%] mt-4">
              <BarChart series={revenueSeries} />
            </div>
          </MainCard>
        </div>
        <div
          className={`w-6/12 h-[50vh] ${
            screenWidth < 1059 ? "cardSubContainer" : ""
          }`}
        >
          <MainCard customClass="border-none">
            <div>
              <CardYearHeading heading="EPS Growth" />
            </div>
            <div className="w-full  h-[88%] mt-4">
              <BarChart series={epsSeries} />
            </div>
          </MainCard>
        </div>
      </div>

      <div className="w-full flex items-center !mt-4 gap-4">
        <div
          className={`w-6/12 h-[50vh] ${
            screenWidth < 1059 ? "cardSubContainer" : ""
          }`}
        >
          <MainCard customClass="border-none">
            <div>
              <CardYearHeading heading="Net Margin" />
            </div>
            <div className="w-full  h-[88%] mt-4">
              <BarChart series={netMarginSeries} />
            </div>
          </MainCard>
        </div>
      </div>

      <div className="w-full border-t border-b border-[#E2E8F0] dark:border-[#1F2A37] py-8 !mt-[3rem]">
        <div className="w-full space-y-4">
          <span className="dark:text-[#F8FAFC] text-black text-[16px] font-normal">
            News
          </span>
          <div className="flex items-center w-full space-x-4">
            {!hideLeftArrow && (
              <IconCircle
                handleClick={() => {
                  handleHorizantalScroll(elementRef.current, 25, 100, -10);
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  stroke="currentColor"
                  className="dark:text-[#F8FAFC] w-5 h-5 text-black"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M10.5 19.5 3 12m0 0 7.5-7.5M3 12h18"
                  />
                </svg>
              </IconCircle>
            )}
            <div
              ref={elementRef}
              className="flex w-full items-center overflow-x-hidden space-x-4"
            >
              {news?.map((item, index) => (
                <div key={index}>
                  <NewsCard item={item} />
                </div>
              ))}
            </div>
            {!hideRightArrow && (
              <IconCircle
                handleClick={() => {
                  handleHorizantalScroll(elementRef.current, 25, 100, 10);
                }}
              >
                <svg
                  width="16"
                  height="16"
                  className="dark:text-[#F8FAFC] text-black"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1 8H15"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8 1L15 8L8 15"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </IconCircle>
            )}
          </div>
        </div>
      </div>

      <div className="w-full space-y-4">
        <span className="dark:text-[#F8FAFC] text-black text-[16px] font-normal">
          Similar Stocks
        </span>
        <div className="w-full flex items-center max-h-[40vh] overflow-y-auto no-scrollbar gap-6  pb-[2rem] flex-wrap">
          {similarStocks?.map((item, index) => (
            <SmallCard item={item} index={index} />
          ))}
        </div>
      </div>
    </div>
  );
}

export default Summary;
