import React, { useState } from "react";
import { ReactComponent as Logo } from "../assets/lightLogo.svg";
import PrimaryButton from "../components/Button";
import TextInput from "../components/TextInput";
import { forgetPassword } from "../ApiControler";
import { useNavigate } from "react-router-dom";

export default function ForgetPassword() {
  const [email, setEmail] = useState("");
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [showMsg, setMsg] = useState(false);

  const navigate = useNavigate();

  const resetPassword = async() =>{
    if(emailRegex.test(email)){
      try{
        const response = await forgetPassword(email)
        if(response?.success){
          setMsg(true)
        }
      }catch(error){
        console.log("error",error)
        // throw error
      }
    }
  }

  return (
    <div className="flex h-full w-full bg-[#F1F5F9]">
      <div className="flex flex-col items-center justify-center space-y-4 w-full h-full">
        <Logo className="w-full h-12" />
        <div className="flex flex-col h-[18rem] w-[20rem]  rounded-xl bg-white  space-y-4 p-6">
          <p className="text-[18px] font-inter font-semibold text-[#1E293B]">
            Forget Password
          </p>
          <p className="text-[11px] text-slate-500">
            Enter the email address for your account <br></br>so we can send you
            reset instructions
          </p>
          <div className="flex w-full border-b border-b-[#E2E8F0]" />

          {showMsg ? (
            <div className="h-full w-full">
              <div className="h-full w-full p-5 rounded-lg bg-emerald-50 flex justify-center items-center text-justify text-[12px] text-green-600">
                Thank you. If an account exist with this emai address, you
                should receive an email to reset your password. Otherwise,
                please contact our support team.
              </div>
            </div>
          ) : (
            <>
              <TextInput
                _value={email}
                _onChange={(text) => setEmail(text.target.value)}
                label={"Email Address"}
                type="email"
                // placeholder={"email@domain.com"}
                className={""}
              />

              <PrimaryButton
                isDisable={emailRegex.test(email) ? true : false}
                title={"Send Reset Instructions"}
                _click={() => {
                  // setMsg(true)
                  resetPassword()
                }}
                mainClass={` w-[10rempx]  ${
                  emailRegex.test(email)
                    ? "bg-[#FFAA36] cursor-pointer hover:opacity-50"
                    : "bg-[#fbd8a4] text-slate-500"
                }`}
              />
            </>
          )}
        </div>
        <p className="text-[12px] font-inter text-[#94A3B8] text-center mt-4">
          Remeber my password{" "}
          <span className="text-[#2563EB] cursor-pointer" onClick={()=>{
              setMsg(false)
              navigate("/")
              }}>
            Back to login
          </span>
        </p>
      </div>
      {/* <SignUp /> */}
    </div>
  );
}
