import React from 'react'

function CardHeading({heading,showLine=false}) {
  return (
    <div className='w-full h-full  flex items-start justify-between'>
        <div className='flex flex-col space-y-1'>
            <span className='text-black dark:text-[#F8FAFC] text-[16px] font-normal'>{heading}</span>
           {showLine && <span className='h-[3px] w-[2.3rem] rounded-[10px] bg-[#727272] dark:bg-[#94A3B8]' />}
        </div>
        <span>
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_246_3506)">
            <path d="M10.0003 18.3333C14.6027 18.3333 18.3337 14.6023 18.3337 9.99996C18.3337 5.39759 14.6027 1.66663 10.0003 1.66663C5.39795 1.66663 1.66699 5.39759 1.66699 9.99996C1.66699 14.6023 5.39795 18.3333 10.0003 18.3333Z" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.0001 13.3333V10" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M10.0001 6.66663H10.0085" stroke="#475569" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
            <clipPath id="clip0_246_3506">
            <rect width="20" height="20" fill="white"/>
            </clipPath>
            </defs>
            </svg>

        </span>
    </div>
  )
}

export default CardHeading