import React,{useEffect, useState} from 'react'
import ModalContainer from '../components/Modal/ModalContainer'
import Profile from '../components/settings/Profile'
import Display from '../components/settings/Display'
import Notifications from '../components/settings/Notifications'
import Security from '../components/settings/Security'
import Sessions from '../components/settings/Sessions'
import YourData from '../components/settings/YourData'
import {toggleSettingModal} from '../store/slices/themeSlice'
import { useDispatch,useSelector } from "react-redux";
import IconCircle from '../components/iconCircle'

function Settings() {
  const [selectedSetting,setSelectedSetting] = useState({id:1,image:'profile.svg',name:'Profile',component:<Profile />})
  const theme = useSelector((state) => state.theme.darkMode);
  const dispatch = useDispatch();
  const [showSetting,setShowSetting] = useState(false)

  const allSettings = [
    {id:1,image: theme ? 'profile.svg' : 'profilelight.svg',name:'Profile',component:<Profile />},
    {id:2,image: theme ? 'display.svg' : 'displaylight.svg',name:'Display',component:<Display  />},
    {id:3,image: theme ? 'bell.svg' : 'belllight.svg',name:'Notifications',component:<Notifications />},
    {id:4,image: theme ? 'security.svg' : 'securitylight.svg',name:'Security',component:<Security />},
    {id:5,image: theme ? 'sessions.svg' : 'sessionslight.svg',name:'Sessions',component:<Sessions />},
    {id:6,image: theme ?'yourData.svg' : 'yourDatalight.svg',name:'Your Data',component:<YourData />},
  ]

  

  useEffect(()=>{
    let x = localStorage.getItem('currentSetting')
    if(x){
      x = allSettings?.find((item)=>item.name == x)
      setSelectedSetting(x)
      localStorage.removeItem('currentSetting')
    }
  },[])

  const handleCloseModal = ()=>{
    dispatch(toggleSettingModal())
  }

  const handleSelect = (item)=>{
    setSelectedSetting(item)
    setShowSetting(false)
  }

  const handleSetting = ()=>{
    setShowSetting(!showSetting)
  }

  return (
    <div className='w-[45rem] h-[30rem] mainSettingContainer'>
      <ModalContainer title='Settings' customClass='w-full relative  h-full' onClose={handleCloseModal}>

      <div className='text-white absolute top-[1.1rem] hidden settinIcon z-40 right-[3rem]'>
          <IconCircle handleClick={handleSetting} customClass='w-8 h-8 !bg-transparent'>
          <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" className="w-5 text-[#777777] h-5">
            <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z" />
            <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z" />
          </svg>
          </IconCircle>
           </div> 
          
          <div className='w-full h-full flex relative'>
          {showSetting && 
            <div className='w-full h-[99%] backdrop-blur-sm   absolute top-0 left-0 z-30'>
            <div className='w-6/12 flex-col absolute bg-white dark:bg-[#0F172A] shadow-lg h-[25.7rem] py-4 px-3'>
                {allSettings.map((item,i)=>(
                  <div onClick={()=>handleSelect(item)} key={i} className={`dark:text-[#F1F5F9] p-3 flex  rounded-[8px] items-center space-x-3 ${selectedSetting.id == item.id ? 'bg-[#E2E8F0] dark:bg-[#020617] cursor-default':'cursor-pointer hover:bg-[#E2E8F070] dark:hover:bg-[#0F172A70]'} `}>
                    <span>
                      <img src={require(`../assets/${item.image}`)} />
                    </span>
                    <span className='dark:text-[#F1F5F9] text-[14px] font-normal'>
                      {item.name}
                    </span>
                  </div>
                ))}
            </div>
            </div>
          }


            <div className='w-4/12 settingLeftSide flex-col h-[25.7rem] py-4 px-3'>
                {allSettings.map((item,i)=>(
                  <div onClick={()=>handleSelect(item)} key={i} className={`dark:text-[#F1F5F9] p-3 flex  rounded-[8px] items-center space-x-3 ${selectedSetting.id == item.id ? 'bg-[#E2E8F0] dark:bg-[#0F172A] cursor-default':'cursor-pointer hover:bg-[#E2E8F070] dark:hover:bg-[#0F172A70]'} `}>
                    <span>
                      <img src={require(`../assets/${item.image}`)} />
                    </span>
                    <span className='dark:text-[#F1F5F9] text-[16px] font-normal'>
                      {item.name}
                    </span>
                  </div>
                ))}
            </div>
            <div className='w-8/12 h-[25.7rem] settingRightSide dark:text-white border-l  border-[#cecece70] dark:border-[#1E293B]'>
              {selectedSetting?.component}
            </div>
          </div>
      </ModalContainer>
    </div>
  )
}

export default Settings