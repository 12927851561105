import React, { useState,useRef,useEffect } from 'react'

function Financials() {
  const typeRef = useRef(null)
  const periodRef = useRef(null)
  const expandRef = useRef(null)
  const type = ["Cash Flow","Cash","Cash Flow","Cash","Cash Flow"]
  const period = ["Quarterly","Yearly","Quarterly","Yearly"]
  const expand = ["Expand All","Expand Less","Expand All"]
  const [showType,setShowType] = useState(false)
  const [showPeriod,setShowPeriod] = useState(false)
  const [showExpand,setShowExpand] = useState(false)
  const [typeVal,setTypeVal] = useState("Cash Flow")
  const [periodVal,setPeriodVal] = useState("Quarterly")
  const [expandVal,setExpandVal] = useState("Expand All")

  useEffect(() => {
    function handleClickOutside(event) {
      if (typeRef.current && !typeRef.current.contains(event.target)) {
        setShowType(false)
      }
      if (periodRef.current && !periodRef.current.contains(event.target)) {
        setShowPeriod(false)
      }
      if (expandRef.current && !expandRef.current.contains(event.target)) {
        setShowExpand(false)
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [typeRef,periodRef,expandRef]);

  const columns =[
    {key:'fiscalYear',value:"Fiscal Year ends in Dec USD in Millions except per share data"},
    {key:'yoychange',value:"YoY %Chg"},
    {key:'dec23',value:"Dec 23"},
    {key:'sep23',value:"Sep 23"},
    {key:'jun23',value:"Jun 23"},
    {key:'mar23',value:"Mar 23"},
    {key:'dec22',value:"Dec 22"},
  ]

  const [tableData,setTableData] = useState([
    {fiscalYear:'Operating Cash Flow',yoychange:'5.69%',dec23:'4,463',sep23:'3,055',jun23:'2,640',mar23:'3,773',dec22:'3,965'},
    {fiscalYear:'Cash Flow From Continuing Operating Activities',yoychange:'5.69%',dec23:'4,463',sep23:'3,055',jun23:'2,640',mar23:'3,773',dec22:'3,965'},
    {fiscalYear:'Cash From Discontinued Operating Activities',yoychange:'5.69%',dec23:'4,463',sep23:'3,055',jun23:'2,640',mar23:'3,773',dec22:'3,965'},
    {fiscalYear:'Investing Cash Flow',yoychange:'5.69%',dec23:'4,463',sep23:'3,055',jun23:'2,640',mar23:'3,773',dec22:'3,965'},
    {fiscalYear:'Cash Flow From Continuing Investing Activities',yoychange:'5.69%',dec23:'4,463',sep23:'3,055',jun23:'2,640',mar23:'3,773',dec22:'3,965'},
    {fiscalYear:'Cash From Discontinued Operating Activities',yoychange:'5.69%',dec23:'4,463',sep23:'3,055',jun23:'2,640',mar23:'3,773',dec22:'3,965'},
  ])

  const getStyle = (colIndex,rowIndex)=>{
    if(colIndex == 0){
      if(rowIndex == 1 || rowIndex == 2 || rowIndex == 4 || rowIndex == 5){
        return 'text-[14px] font-[300] pl-[1.5rem]'
      }
    }
  }
  
  
    return (
    <div className='w-full h-fit pl-3 space-y-[1.5rem]'>
      <div className='w-full flex items-center flex-wrap justify-between gap-4 mt-[2rem]'>
        <div className='flex items-center flex-wrap gap-4'>
        <div className='flex items-center space-x-3'>
            <span className='text-[14px] font-medium dark:text-[#F1F5F9]'>Type</span>
            <div onClick={()=>setShowType(!showType)} ref={typeRef} className='flex items-center relative cursor-pointer justify-between space-x-3 px-3 max-w-[10rem] h-[2rem] rounded-[6px] bg-[#E2E8F0] dark:bg-[#0F172A]'>
              <span className='text-[15px] font-normal dark:text-[#E2E8F0]'>{typeVal}</span>
              <span className='cursor-pointer p-1 dark:hover:bg-[#222a3d] hover:bg-[#d7dde5] rounded-full'>
              <svg width="14" height="8" className='text-[#3f3f3f] dark:text-[#E2E8F0]' viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              </span>

              {showType &&
            <div className='absolute z-[999] py-2 bg-[#d7dde5] w-[10rem] rounded-[4px] max-h-[15rem] dark:bg-[#1b2233] bg-[#e1e5ee] overflow-y-auto no-scrollbar top-[2.5rem] right-0'>
                {type?.map((item,i)=>(
                    <div onClick={()=>{setTypeVal(item);setShowType(false)}}  key={i} className='py-2 px-3 text-[15px] hover:bg-[#c5ccd6] dark:hover:bg-[#161c2b] cursor-pointer'>
                        {item}
                    </div>
                ))}
            </div>}
            </div>
          </div>
          <div className='flex items-center space-x-3'>
            <span className='text-[14px] font-medium dark:text-[#F1F5F9]'>Period</span>
            <div onClick={()=>setShowPeriod(!showPeriod)}  ref={periodRef} className='flex cursor-pointer items-center justify-center relative space-x-3 px-3 max-w-[10rem] h-[2rem] rounded-[6px] bg-[#E2E8F0] dark:bg-[#0F172A]'>
              <span className='text-[15px] font-normal dark:text-[#E2E8F0]'>{periodVal}</span>
              <span className='cursor-pointer p-1 dark:hover:bg-[#222a3d] hover:bg-[#d7dde5] rounded-full' >
              <svg width="14" height="8" className='text-[#3f3f3f] dark:text-[#E2E8F0]' viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              </span>
              {showPeriod &&
            <div className='absolute z-[999] py-2 bg-[#d7dde5] w-[10rem] rounded-[4px] max-h-[15rem] dark:bg-[#1b2233] bg-[#e1e5ee] overflow-y-auto no-scrollbar top-[2.5rem] right-0'>
                {period?.map((item,i)=>(
                    <div onClick={()=>{setPeriodVal(item);setShowPeriod(false)}}  key={i} className='py-2 px-3 text-[15px] hover:bg-[#c5ccd6] dark:hover:bg-[#161c2b] cursor-pointer'>
                        {item}
                    </div>
                ))}
            </div>}
            </div>
          </div>
        </div>
        <div className='flex items-center flex-wrap gap-3'>
        <div onClick={()=>setShowExpand(!showExpand)}  ref={expandRef} className='flex items-center cursor-pointer justify-between relative space-x-3 px-3 max-w-[10rem] h-[2rem] rounded-[6px] bg-[#E2E8F0] dark:bg-[#0F172A]'>
              <span className='text-[15px] font-normal dark:text-[#E2E8F0]'>{expandVal}</span>
              <span className='cursor-pointer p-1 dark:hover:bg-[#222a3d] hover:bg-[#d7dde5] rounded-full'>
              <svg width="14" height="8" className='text-[#3f3f3f] dark:text-[#E2E8F0]' viewBox="0 0 14 8" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1L7 7L13 1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              </span>
              {showExpand &&
            <div className='absolute z-[999] py-2 bg-[#d7dde5]  w-[10rem] rounded-[4px] max-h-[15rem] dark:bg-[#1b2233] bg-[#e1e5ee] overflow-y-auto no-scrollbar top-[2.5rem] right-0'>
                {expand?.map((item,i)=>(
                    <div onClick={()=>{setExpandVal(item);setShowExpand(false)}}  key={i} className='py-2 px-3 text-[15px] hover:bg-[#c5ccd6] dark:hover:bg-[#161c2b] cursor-pointer'>
                        {item}
                    </div>
                ))}
            </div>}
            </div>
            <div className='flex items-center justify-center space-x-3 px-3 w-auto h-[2rem] rounded-[6px] bg-[#E2E8F0] dark:bg-[#0F172A]'>
              <span className='cursor-pointer'>
              <svg width="16" height="16" className='text-[#3f3f3f] dark:text-[#E2E8F0]' viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M8 15V1" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M1 8L8 1L15 8" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </span>
              <span className='text-[15px] font-normal dark:text-[#E2E8F0]'>.00</span>
            </div>
            <div className='flex items-center justify-center space-x-3 px-3 w-auto h-[2rem] rounded-[6px] bg-[#E2E8F0] dark:bg-[#0F172A]'>
              <span className='cursor-pointer'>
              <svg width="24" height="24" className='text-[#3f3f3f] dark:text-[#E2E8F0]' viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12 5L12 19" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              <path d="M19 12L12 19L5 12" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
              </svg>

              </span>
              <span className='text-[15px] font-normal dark:text-[#E2E8F0]'>.00</span>
            </div>
        </div>
      </div>

      <div className='w-full h-full border-t dark:border-[#0F172A]'>
      <div className='max-h-[55vh] bg-red-200 border-b dark:border-[#1E293B] mt-[1rem] overflow-x-auto overflow-y-auto'>
            <table className='w-full text-[15px] bg-white text-left rtl:text-right'>
                <thead className='z-50 sticky top-0 bg-transparent  border-b dark:border-[#0F172A] bg-white dark:bg-[#020617]'>
                    <tr>
                        {columns?.map((item,index) => (
                            <th key={index} className={`x-6 w-36 text-left py-5 whitespace-nowrap dark:text-[#F1F5F9] border-b text-[16px] border-none font-medium ${index == 0?'text-[18px] font-normal':''}`}>
                                
                                {index==0 ? (
                                    <span>
                                      Fiscal Year ends in Dec <br /> USD in Millions except per share data
                                    </span>
                                ):item?.value}
                            </th>
                        ))}
                    </tr>
                </thead>
                
                <tbody className='w-full'>
                    
                    {tableData?.map((item,i)=>(
                        <tr key={i} className=" text-black dark:hover:bg-[#151d26] dark:text-white hover:bg-[#f9f9f9] dark:bg-[#020617]   ">
                            {
                              columns?.map((val,index)=>(
                                <td key={index} className='py-5 whitespace-nowrap px-1 px-[1rem] dark:border-[#0F172A] border-b text-[14px] font-[400] w-6'>
                                <div className='w-full h-full flex items-start space-x-3'>
                              
                                  <span className={`${index==0?'w-[26rem] space-x-3 flex items-center text-[16px] font-semibold':''} ${getStyle(index,i)}`}>
                                    <span>
                                    {(index==0 && (i==1 || i == 4)) && <span>
                                      <svg width="14" height="9" className='dark:text-black text-gray-200' viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                                      <path d="M1 1.5L7 7.5L13 1.5" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                      </svg>

                                    </span>}
                                    </span>
                                    <span>
                                      {item[val.key]} 
                                    </span>
                                  </span>
                                 {index==0 && <span>
                                  <svg width="79" height="21" viewBox="0 0 79 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <rect x="0.357422" y="0.5" width="14" height="20" rx="2" fill="#F88F00"/>
                                  <rect x="16.3574" y="6.5" width="14" height="14" rx="2" fill="#F88F00"/>
                                  <rect x="32.3574" y="6.5" width="14" height="14" rx="2" fill="#F88F00"/>
                                  <rect x="48.3574" y="6.5" width="14" height="14" rx="2" fill="#F88F00"/>
                                  <rect x="64.3574" y="6.5" width="14" height="14" rx="2" fill="#F88F00"/>
                                  </svg>

                                  </span>}
                                </div>
                            </td>
                              ))
                            }
                        </tr>
                    )) }
                </tbody>
            </table>
        </div>
      </div>
    </div>
  )
}

export default Financials