import React from 'react'
import MainCard from './CardBox/MainCard'
import {TruncateText} from '../utils/calculations'

function GradeCard({item}) {
  return (
    <div className='w-[22rem] bg-[#31C45A] h-[12rem] rounded-[8px]'>
        <MainCard customClass='w-full !p-4 !h-[98%]'>
            <div className='w-full flex items-center justify-between'>
                <span className='dark:text-[#F1F5F9] text-[24px] font-medium'>{item?.per}%</span>
                <div className='flex flex-col text-right'>
                    <span className='font-bold gradient-text w-full text-[16px] text-right'>{item?.outof}/100</span>
                    <div className='w-[5rem] flex flex-col space-y-[2px]'>
                        <span className='w-full flex items-center justify-end'>
                        <svg className='text-[#31C45A] w-3 h-3' viewBox="0 0 19 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M7.76795 16C8.53775 17.3333 10.4623 17.3333 11.2321 16L18.5933 3.25C19.3631 1.91667 18.4008 0.25 16.8612 0.25H2.13878C0.599184 0.25 -0.363067 1.91667 0.406733 3.25L7.76795 16Z" fill="currentColor"/>
                        </svg>
                        </span>
                        <div className='w-full h-[6px] rounded-[10px] gradient-bg' />
                        
                    </div>
                </div>
              
            </div>
            <div className='flex flex-col'>
                <span className='text-[#94A3B8] text-[14px] font-normal'>{item?.heading}</span>
                <span className='text-[12px] py-2 text-[#64748B] font-normal'>
                    {TruncateText(item?.detail,150)}
                </span>
                <span className='dark:text-[#F1F5F9] hover:underline cursor-pointer text-[14px] font-normal'>Show more</span>
            </div>
        </MainCard>
    </div>
  )
}

export default GradeCard