import React, { useState } from 'react'

function CardYearHeading({heading}) {
    const [selectedYear,setSelectedYear] = useState({key:'10',value:'Last 10 years'})
    const [openDropdown,setOpenDropdown] = useState(false)
    const [yearList,setYearList] = useState([
        {key:'5',value:'Last 5 years'},
        {key:'10',value:'Last 10 years'},
        {key:'20',value:'Last 20 years'},
        {key:'30',value:'Last 30 years'},
    ])

    const handleSelectYear = (item) => {
        setSelectedYear(item)
        setOpenDropdown(false)
    }

  return (
    <div className='flex w-full items-center justify-between'>
        <span className='text-black dark:text-[#F8FAFC] text-[16px] font-normal'>
            {heading}
        </span>
        <div className='flex items-center space-x-1 relative'>
            <span className='dark:text-[#94A3B8] text-[#666666] font-medium text-[14px]'>
                {selectedYear?.value}
            </span>
            <div onClick={()=>setOpenDropdown(!openDropdown)} className='w-5 flex items-center hover:bg-[#d7dde5] dark:hover:bg-[#1b2233] cursor-pointer justify-center h-5 rounded-full'>
            <svg width="12" height="8" viewBox="0 0 12 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1.5L6 6.5L11 1.5" stroke="#94A3B8" stroke-width="1.8" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </div>
          {openDropdown &&
            <div className='absolute z-30 py-2 bg-[#d7dde5] w-[10rem] rounded-[4px] max-h-[15rem] dark:bg-[#1b2233] bg-[#e1e5ee] overflow-y-auto no-scrollbar top-[2rem] right-1'>
                {yearList?.map((item,i)=>(
                    <div onClick={()=>handleSelectYear(item)} key={i} className='py-2 px-3 text-[15px] hover:bg-[#c5ccd6] dark:hover:bg-[#161c2b] cursor-pointer'>
                        {item?.value}
                    </div>
                ))}
            </div>}
        </div>
    </div>
  )
}

export default CardYearHeading