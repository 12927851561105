import React, { useState, useEffect } from "react";
import MainCard from "../CardBox/MainCard";
import CardHeading from "../cardHeading";
import GaugeComponent from "react-gauge-component";
import GradeCard from "../GradeCard";
import { useNavigate, useLocation } from "react-router-dom";
import { getDGrades } from "../../ApiControler";
function Grade() {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  const queryParams = new URLSearchParams(location.search);
  const symbol = queryParams.get("symbol");

  const [gradeData, setGradeData] = useState([])
  const fetchData = async () => {
    try {
      const token = localStorage.getItem("token");
      const data = await getDGrades(token, symbol);
     
      setGradeData([
        {
          per: data?.data?.dividendPayoutRatio,
          outof: data?.data?.dividendScore,
          heading: "Dividend Payout Ratio",
          detail:
            "The dividend payout ratio is the ratio of the total amount of dividends paid out to shareholders relative to the net income of the company, typically expressed as a percentage.Free cash flow represents the cash a company generates after accounting for cash outflows to support operations and maintain its capital assets. Unlike earnings or net income, free cash flow is a measure of profitability that excludes the non-cash expenses of the income statement and includes spending on equipment and assets as well as changes in working capital from the balance sheet.",
        },
        {
          per: data?.data?.cashFlowRatio,
          outof: data?.data?.cashFlowScore,
          heading: "Free Cash Flow (FCF) Payout",
          detail:
            "The dividend payout ratio is the ratio of the total amount of dividends paid out to shareholders relative to the net income of the company, typically expressed as a percentage.Free cash flow represents the cash a company generates after accounting for cash outflows to support operations and maintain its capital assets. Unlike earnings or net income, free cash flow is a measure of profitability that excludes the non-cash expenses of the income statement and includes spending on equipment and assets as well as changes in working capital from the balance sheet.",
        },
        {
          per: data?.data?.EBITDA,
          outof: data?.data?.EBITDAScore,
          heading: "Net Debt to EBITDA Ratio",
          detail:
            "The dividend payout ratio is the ratio of the total amount of dividends paid out to shareholders relative to the net income of the company, typically expressed as a percentage.Free cash flow represents the cash a company generates after accounting for cash outflows to support operations and maintain its capital assets. Unlike earnings or net income, free cash flow is a measure of profitability that excludes the non-cash expenses of the income statement and includes spending on equipment and assets as well as changes in working capital from the balance sheet.",
        },
        {
          per: data?.data?.fiveYearEPSGrowth,
          outof: data?.data?.fiveYearEPSGrowthScore,
          heading: "Consecutive Dividend Growth",
          detail:
            "The dividend payout ratio is the ratio of the total amount of dividends paid out to shareholders relative to the net income of the company, typically expressed as a percentage.Free cash flow represents the cash a company generates after accounting for cash outflows to support operations and maintain its capital assets. Unlike earnings or net income, free cash flow is a measure of profitability that excludes the non-cash expenses of the income statement and includes spending on equipment and assets as well as changes in working capital from the balance sheet.",
        },
        {
          per: data?.data?.dividendYield,
          outof: data?.data?.dividendYieldScore,
          heading: "Dividend Yield",
          detail:
            "The dividend payout ratio is the ratio of the total amount of dividends paid out to shareholders relative to the net income of the company, typically expressed as a percentage.Free cash flow represents the cash a company generates after accounting for cash outflows to support operations and maintain its capital assets. Unlike earnings or net income, free cash flow is a measure of profitability that excludes the non-cash expenses of the income statement and includes spending on equipment and assets as well as changes in working capital from the balance sheet.",
        },
        {
          per: data?.data?.fiveYearDividendGrowth,
          outof: data?.data?.fiveYearDividendGrowthScore,
          heading: "Dividend Growth Rate",
          detail:
            "The dividend payout ratio is the ratio of the total amount of dividends paid out to shareholders relative to the net income of the company, typically expressed as a percentage.Free cash flow represents the cash a company generates after accounting for cash outflows to support operations and maintain its capital assets. Unlike earnings or net income, free cash flow is a measure of profitability that excludes the non-cash expenses of the income statement and includes spending on equipment and assets as well as changes in working capital from the balance sheet.",
        },
        {
          per: data?.data?.cashFlowRatio,
          outof: data?.data?.currentScore,
          heading: "Current Ratio",
          detail:
            "The dividend payout ratio is the ratio of the total amount of dividends paid out to shareholders relative to the net income of the company, typically expressed as a percentage.Free cash flow represents the cash a company generates after accounting for cash outflows to support operations and maintain its capital assets. Unlike earnings or net income, free cash flow is a measure of profitability that excludes the non-cash expenses of the income statement and includes spending on equipment and assets as well as changes in working capital from the balance sheet.",
        },
      ]);

    
      setData(data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

    //calling the about api
    useEffect(() => {
  
      fetchData();
    }, [symbol]);

   
  



  return (
    <div className="w-full h-full">
      <div className="w-full h-[50vh]">
        <MainCard customClass={"border-none"}>
          <div className="h-[10%]">
            <CardHeading heading="Dividend Grade" showLine={true} />
          </div>
          <div className="w-full h-[90%] flex items-center justify-center">
            <div className="h-[90%] w-4/12 flex flex-col items-center justify-center">
              <div className="w-full h-[80%] relative">
                <GaugeComponent
                  type="semicircle"
                  arc={{
                    colorArray: ["#F87171", "#FDBA74", "#FFEB3A", "#10B981"],
                    padding: 0.04,
                    width: 0.1,
                    subArcs: [
                      { limit: 45 },
                      { limit: 68 },
                      { limit: 82 },
                      { limit: 100 },
                    ],
                  }}
                  pointer={{ type: "blob", animationDelay: 0, width: 15 }}
                  value={89}
                  labels={{
                    valueLabel: {
                      hide: true,
                    },
                    tickLabels: {
                      hideMinMax: true,
                    },
                  }}
                />

                <div className="absolute dark:text-[#FFFFFF] font-bold -bg-red-200 bottom-2 w-full flex justify-center items-center text-[3.2rem]">
                  {data?.TotalDividendGrade}
                </div>
              </div>
              <div className="text-black dark:text-[#E2E8F0] largeText text-[14px] font-medium">{`${symbol} Dividend Growth is`}</div>
              <div className="text-black dark:text-[#94A3B8] mediumText text-[12px] font-light">
                {`Updated ${new Date().toLocaleDateString("en-US", {
                  month: "short",
                  day: "numeric",
                  year: "numeric",
                })}`}
              </div>
            </div>
          </div>
        </MainCard>
      </div>

      <div className="w-full flex flex-wrap !my-4 gap-4 max-h-[55vh] overflow-y-auto no-scrollbar">
        {gradeData?.map((item, index) => (
          <div key={index}>
            <GradeCard item={item} />
          </div>
        ))}
      </div>

      <div className="w-full h-[8vh] pt-2">
        <span className="text-[#F88F00] text-[16px] font-normal cursor-pointer hover:underline">
          How are these score calculated?
        </span>
      </div>
    </div>
  );
}

export default Grade;
