import React, { useState,useEffect } from 'react'
// import deviceIcon from '../../assets/device.svg'
import terminateIcon from '../../assets/terminate.svg'
import {toggleSettingModal,toggleSessionInfoModal} from '../../store/slices/themeSlice'
import { useDispatch } from 'react-redux'

function Sessions() {
  const dispatch = useDispatch()
  const [allSessions,setAllSessions] = useState([
    {image:'iphone.svg',name:'iPhone 15 Pro',detail:'IVY Dividends IOS 1.5',location:'Toronto, Canada',time:'5.54 PM '},
    {image:'android.svg',name:'Redmi 12 C',detail:'IVY Dividends Web 1.5',location:'Toronto, Canada',time:'4.55 AM'},
  ])
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener('resize', handleResize);
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  const deleteSession = (name)=>{
    let remove = allSessions?.filter((item)=>item.name != name)
    setAllSessions(remove)
  }

  const handleSessionInfo = (data)=>{
    localStorage.setItem("selectedSession", JSON.stringify(data));
    dispatch(toggleSettingModal())
    dispatch(toggleSessionInfoModal())
  }

  const SessionUi = ({image,name,detail,location,time,showTerminate=false})=>{
    let info = {image,name,detail,location,time}
    return(
      <div className='w-full h-fit flex items-center px-4 space-x-3'>
      <div className='w-2/12  h-full flex'>
         <div className={`${screenWidth < 450 ? 'w-12 h-12' : 'w-16 h-16'} rounded-full bg-[#E2E8F0] dark:bg-[#0F172A] flex items-center justify-center`}>
           <img src={require(`../../assets/${image}`)} className={screenWidth < 450 ? 'w-6 h-6' : ''} />
         </div>
      </div>
      <div className='w-10/12 h-full flex justify-between'>
        <div className='space-y-[2px]'>
         <div onClick={()=>handleSessionInfo(info)} className='text-[16px] dark:text-[#F1F5F9] cursor-pointer hover:underline font-medium'>{name}</div>
         <div className='label font-normal dark:font-light  dark:text-[#F1F5F9]'>{detail}</div>
         <div className='text-[12px] font-light dark:text-[#94A3B8] flex items-center space-x-2'>
           <span>{location}</span>
           <span>
           <svg width="4" height="4" viewBox="0 0 4 4" fill="none" xmlns="http://www.w3.org/2000/svg">
             <circle cx="2" cy="2" r="2" fill="#94A3B8"/>
           </svg>
           </span>
           <span>
             {time}
           </span>
           </div>
          {showTerminate && <div className='flex items-center space-x-2 pt-2'>
                <span>
                  <img src={terminateIcon} />
                </span>
                <span className='label dark:text-[#EF4444]'>
                  Terminate all other sessions
                </span>
              </div>}
          </div>
        {!showTerminate && 
          <div onClick={()=>deleteSession(info.name)} className='cursor-pointer p-1 rounded-full hover:bg-[#d7dde5] dark:hover:bg-[#1b2233] h-fit'>
           <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 6.5L6 18.5" stroke="#EF4444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6 6.5L18 18.5" stroke="#EF4444" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>

          </div>
          }
       
      </div>
   </div>
    )
  }

  return (
    <div className='w-full h-full'>
      <div className='w-full h-[50%]'>
        <div className='px-4 py-3 dark:bg-[#0f172a] bg-[#E2E8F0]'>
          <span className='label dark:text-[#F1F5F9]'>This Device</span>
        </div>
        <div className='py-4'>
          <SessionUi image={'device.svg'} name='Redmi 12C' detail='IVY Dividends Web 1.5' location='Toronto, Canada' time='Now' showTerminate={true} />
        </div>     
      </div>
      <div className='w-full h-[50%]'>
        <div className='px-4 py-3 h-[25%] dark:bg-[#0f172a] bg-[#E2E8F0]'>
          <span className='label dark:text-[#F1F5F9]'>Active Sessions</span>
        </div>
        <div className='h-[75%] w-full overflow-y-auto py-4 space-y-10 no-scrollbar'>
          {allSessions.map((item,index)=>(
            <div key={index}>
              <SessionUi image={item.image} name={item.name} detail={item.detail} location={item.location} time={item.time} />
            </div>
          ))
          }
        </div>
      </div>
    </div>
  )
}

export default Sessions