import React, { useState } from "react";
import PrimaryButton from "../components/Button";
import { ReactComponent as Exclamation } from "../assets/ExclamationCircle.svg";
import Slider from "rc-slider";
import { useSelector } from "react-redux";
import { ReactComponent as CheckedCircle } from "../assets/CheckedCircle.svg";
import CalculatorChart3 from "../components/CalculatorChart3";

function FutureValue() {
  const darkMode = useSelector((state) => state.theme.darkMode);

  const [tickerData, setData] = useState(null);

  const [step, setStep] = useState(1);
  const [advanceBtn, setAdvance] = useState(false);
  const [futureValue, setFutureValue] = useState(0);
  const [monthlyContro, setMonthlyContro] = useState(0);
  const [annualYield, setAnnualYield] = useState(0);
  const [divRate, setDivRate] = useState(0);
  const [annualShare, setAnnualShare] = useState(0);
  const [years, setYears] = useState(0);
  const [showChart, setShowChart] = useState(false);

  const [addedSymbolData, setAddedData] = useState([]);

  const OnChangeEventTriggerd = (newValue) => {
    setFutureValue(newValue);
    setMonthlyContro(newValue);
    setAnnualYield(newValue);
    setDivRate(newValue);
    setAnnualShare(newValue);
    setYears(newValue)
  };

  const next = () => {
    if (step < 6) {
      setStep(step + 1);
    }
  };
  const prev = () => {
    if (step > 1) {
      setStep(step - 1);
    }
  };



  const dataSet = () => {
    let dividedValue = parseInt(futureValue) / addedSymbolData?.length;
    addedSymbolData?.forEach((item) => {
      item.value = dividedValue;
    });
    // console.log("ad", addedSymbolData);
    setData(addedSymbolData);
    // setAddedData([]);
    // setInputCount(1);
    setShowChart(true);
  };

  return (
    <div className="flex flex-col h-full w-full items-center space-y-10">
      {!showChart ? 
      (
      <>
        <span className="text-[24px] font-medium bg-gradient-to-r from-[#4B93FF] to-[#FFAD3F] bg-clip-text text-transparent">
          Future Value
        </span>
        <div className="flex flex-col h-[30rem] w-[30rem] px-8 rounded-lg dark:bg-[#0F172A] bg-slate-100 items-center justify-around ">
          {/* Header button start */}
          <div className="flex w-full space-x-3">
            <PrimaryButton
              isDisable={true}
              _click={() => {
                // setAddedData([]);
                // setGoal(0);
                setAdvance(false);
              }}
              mainClass={` w-56 cursor-pointer hover:opacity-80 ${
                !advanceBtn
                  ? "dark:bg-orange-500/15 bg-orange-500/30 font-semibold text-orange-500 border dark:border-orange-500 border-orange-300"
                  : "text-slate-500 bg-slate-200 border dark:border-[#1E293B] dark:bg-[#0F172A]"
              } `}
              title={"Simple"}
            />
            <PrimaryButton
              isDisable={true}
              _click={() => {
                // setAddedData([]);
                // setGoal(0);
                setAdvance(true);
              }}
              mainClass={` w-56 cursor-pointer hover:opacity-80 ${
                advanceBtn
                  ? "dark:bg-orange-500/15 bg-orange-500/30 text-orange-500 font-semibold border dark:border-orange-500 border-orange-300"
                  : "text-slate-500 border bg-slate-200 dark:border-[#1E293B] dark:bg-[#0F172A]"
              } `}
              title={"Advance"}
            />
          </div>
          {/* Header button end */}

          {/* bullets start */}
          <div className="flex w-full items-center justify-center">
            {step > 1 ? (
              <CheckedCircle className="w-6 h-6"/>
            ) : (
              <div className="flex justify-center items-center w-6 h-6 rounded-full bg-orange-400">
                <div className="w-3 h-3 rounded-full dark:bg-slate-700 bg-slate-300" />
              </div>
            )}
            <div
              className={`h-0.5 w-14 ${
                step > 1 ? "bg-orange-400" : "dark:bg-slate-800 bg-slate-300"
              }`}
            />
            {
              step > 2 ? 
              <CheckedCircle className="w-6 h-6"/>
              : 
              <div
              className={`flex justify-center items-center w-6 h-6 rounded-full ${
                step > 1 ? "bg-orange-400" : "dark:bg-slate-800 bg-slate-300"
              }`}
            >
              <div className="w-3 h-3 rounded-full dark:bg-slate-700 bg-slate-100" />
            </div>
            }
            <div
              className={`h-0.5 w-14 ${
                step > 2 ? "bg-orange-400" : "dark:bg-slate-800 bg-slate-300"
              }`}
            />
          {
            step > 3 ? 
            <CheckedCircle className="h-6 w-6"/>
            :
            <div
            className={`flex justify-center items-center w-6 h-6 rounded-full ${
              step > 2 ? "bg-orange-400" : "dark:bg-slate-800 bg-slate-300"
            }`}
          >
            <div className="w-3 h-3 rounded-full dark:bg-slate-700 bg-slate-100" />
          </div>
          }
            <div
              className={`h-0.5 w-14 ${
                step > 3 ? "bg-orange-400" : "dark:bg-slate-800 bg-slate-300"
              }`}
            />
            {
              step > 4 ? 
              <CheckedCircle className="h-6 w-6"/>
              :
              <div
              className={`flex justify-center items-center w-6 h-6 rounded-full ${
                step > 3 ? "bg-orange-400" : "dark:bg-slate-800 bg-slate-300"
              }`}
            >
              <div className="w-3 h-3 rounded-full dark:bg-slate-700 bg-slate-100" />
            </div>
            }
            <div
              className={`h-0.5 w-14 ${
                step > 4 ? "bg-orange-400" : "dark:bg-slate-800 bg-slate-300"
              }`}
            />
            {
              step > 5 ?
              <CheckedCircle className="h-6 w-6"/>
              :
              <div
              className={`flex justify-center items-center w-6 h-6 rounded-full ${
                step > 4 ? "bg-orange-400" : "dark:bg-slate-800 bg-slate-300"
              }`}
            >
              <div className="w-3 h-3 rounded-full dark:bg-slate-700 bg-slate-100" />
            </div>
            }
            <div
              className={`h-0.5 w-14 ${
                step > 5 ? "bg-orange-400" : "dark:bg-slate-800 bg-slate-300"
              }`}
            />
            {
              step === 6 ? 
              <CheckedCircle className="w-6 h-6"/>
              :
              <div
              className={`flex justify-center items-center w-6 h-6 rounded-full ${
                step > 5 ? "bg-orange-400" : "dark:bg-slate-800 bg-slate-300"
              }`}
            >
              <div className="w-3 h-3 rounded-full dark:bg-slate-700 bg-slate-100" />
            </div>
            }
          </div>
          {/* bullets end */}

          {/* value start */}
          <span className="text-[64px] font-bold ">
            { step == 1 ? "$" + futureValue?.toLocaleString("en-US") : step === 2 ? "$" + monthlyContro?.toLocaleString("en-US") : step === 3 ? annualYield?.toFixed(1) + "%" :step == 4 ? divRate?.toFixed(1) + "%" : step === 5 ? annualShare?.toFixed(1) + "%" : years + "y" }
          </span>
          <div className="flex items-center gap-2">
            <span className="text-[18px] text-slate-500">
              {step === 1 ? "Starting Principal" : step === 2 ? "Monthly Contribution" : step === 3 ? "Annual Dividend Yield" : step === 4 ? "Dividend Growth Rate" : step === 5 ? "Annual Share Price Growth" : "Number of Years"}
            </span>
            <Exclamation className="fill-none stroke-slate-500 h-10" />
          </div>
          {/* value end */}

          {/* slider start */}
          <Slider
            value={futureValue}
            onChange={OnChangeEventTriggerd}
            min={0}
            max={step <= 2 ? 250000: step <= 5 ? 100 : 10}
            trackStyle={{ backgroundColor: "#FFAA36", height: 7 }}
            railStyle={{
              backgroundColor: darkMode ? "#1E293B" : "#eaf1fc",
              height: 7,
            }}
            handleStyle={{
              borderColor: "#FFAA36",
              height: 17,
              width: 17,
              // marginLeft: -10,
              // marginTop: -5,
              backgroundColor: "#FFAA36",
            }}
          />
          {/* slider end */}

          {/* Footer button start */}
          <div className="flex w-full space-x-3">
            <PrimaryButton
              isDisable={futureValue > 0 ? true : false}
              _click={() => {
                prev();
              }}
              mainClass={`dark:bg-slate-500/15 bg-slate-500/10 text-slate-500 w-56 cursor-pointer hover:opacity-80`}
              title={"Prev"}
            />
            { step === 6 ? 
            <PrimaryButton
              isDisable={futureValue > 0 ? true : false}
              _click={() => dataSet()}
              mainClass={` bg-[#FFAA36] font-semibold text-black w-56 cursor-pointer hover:opacity-80`}
              title={"Start Calculation"}
            />
            :
            <PrimaryButton
              isDisable={futureValue > 0 ? true : false}
              _click={() => {
                next();
              }}
              mainClass={` bg-[#FFAA36] font-semibold text-black w-56 cursor-pointer hover:opacity-80`}
              title={"Next"}
            />
              }
          </div>

          
          {/* Footer button end */}
        </div>
      </>
      )
      :
      <CalculatorChart3 setOData={setAddedData} data={addedSymbolData} future={futureValue} showChart={setShowChart} setFutureValue={setFutureValue} setStep={setStep}/>
      }
      
    </div>
  );
}

export default FutureValue;
