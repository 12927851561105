import React,{useState,useEffect} from 'react'
import MainCard from '../CardBox/MainCard'
import ModalBtn from '../Button/ModalBtn'
import {toggleSettingModal,toggleDeleteModal} from '../../store/slices/themeSlice'
import { useDispatch } from 'react-redux'

function DeleteModal() {
    const dispatch = useDispatch()
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);

    const handleBack = ()=>{
        localStorage.setItem("currentSetting",'Your Data');
        dispatch(toggleDeleteModal())
        dispatch(toggleSettingModal())
    }

    useEffect(() => {
      const handleResize = () => setScreenWidth(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
      
      return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleDelete = ()=>{
        handleBack()
    }

  return (
    <div className='w-[33rem] h-[15rem] deleteModal mainSettingContainer'>
        <MainCard customClass='w-full h-full border-none bg-white'>

            <div className={`dark:text-[#F1F5F9] ${screenWidth < 425 ? 'text-[14px]' : 'text-[16px]'} font-normal`}>
                 Are you sure you want to delete your account?
            </div>

            <div className={`label mt-4 text-[#686868] ${screenWidth < 425 ? 'text-[12px]' : ''}`}>
               If you're certain about closing your account, kindly choose 'Yes, Delete' to proceed. If you're not ready yet, simply click 'Go Back'. Remember, once deleted, this action can't be undone for any reason
            </div>





        <div className='absolute bottom-8 flex items-center justify-end space-x-1 right-8'>
           <div>
              <ModalBtn onPress={handleBack}  title="No, Go Back" customClass={`pointer-events-auto cursor-pointer font-semibold dark:font-normal bg-[white] dark:bg-transparent text-[#383838] dark:text-[#F1F5F9]`} />
            </div>               
           <div>
              <ModalBtn onPress={handleDelete} title="Yes, Delete" customClass={`pointer-events-auto cursor-pointer !font-medium !bg-[#DC2626] text-[#F8FAFC]`} />
            </div>               
      </div>
        </MainCard>
    </div>
  )
}

export default DeleteModal