import React, { useState, useEffect } from "react";
import MultiAreaChart from "../components/charts/MultiAreaChart";
import SmallCard from "../components/SmallCard";
import RenderTable from "../components/table/RenderTable";
import { tableDummyData } from "../utils/sidebarContent";
import AddPortfolio from "../components/AddPortfolio";
import PortfolioChart from "../components/PortfolioChart";
import { getDashboard, getPortfolio } from "../ApiControler";
function Dashboard() {
  const [currentPortfolio, setCurrentPortfolio] = useState({
    name: "All Portfolios",
    series: [{ name: "Data 1", data: [0, 0, 0, 0, 0, 0, 0], color: "#1C64F2" }],
    cardHolding: [],
  });
  const [data, setData] = useState([]);
  const [screenHeight, setScreenHeight] = useState(window.innerHeight);
  const [view, setView] = useState("grid");
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [holdingBtnPressed, setHoldingBtnPressed] = useState(false);
  const [allPortfolioSeries, setAllPortfolioSeries] = useState([
    {
      name: "Data 1",
      data: [40000, 60000, 20000, 125000, 160000, 30000, 20000],
      color: "#1C64F2",
    },
    {
      name: "Data 2",
      data: [30000, 80000, 120000, 165000, 130000, 20000, 50000],
      color: "#FDBA8C",
    },
  ]);

  const get_dashboard = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await getDashboard(token);
      
      setData(response?.data);
      // currentPortfolio.series = response?.data?.useTicker
    } catch (e) {
      console.log("get portfolio error", e);
    }
  };

  // get portfolio api calling
  useEffect(() => {
    get_dashboard();
  }, []);

  const [allPortfolioHolding, setAllPortfolioHolding] = useState([]);

  useEffect(() => {
    if (allPortfolios?.length > 0) {
      updateSeriesAndHolding();
    }
  }, [allPortfolios]);

  useEffect(() => {
    const updateHeight = () => {
      setScreenHeight(window.innerHeight);
    };

    window.addEventListener("resize", updateHeight);

    // Cleanup event listener on component unmount
    return () => {
      window.removeEventListener("resize", updateHeight);
    };
  }, []);

  const handleSelectedPortfolio = (val) => {
    setCurrentPortfolio(val);
  };

  const handlePressHolding = () => {
    setHoldingBtnPressed(true);
    setTimeout(() => {
      setHoldingBtnPressed(false);
    }, 1000);
  };

  const updateSeriesAndHolding = () => {
    const holdings = allPortfolios?.reduce((acc, curr) => {
      return acc.concat(curr.cardHolding);
    }, []);
    setAllPortfolioHolding(holdings);

    const ser = allPortfolios?.reduce((acc, curr) => {
      return acc.concat(curr.series);
    }, []);
    setAllPortfolioSeries(ser);
  };
  
  const HoldingBtn = ({ num }) => {
    return (
      <div
        onClick={handlePressHolding}
        className="w-[24rem] space-x-3 h-[4rem]  cursor-pointer smallCardContainer px-3   rounded-[8px] flex items-center justify-center border-[#E2E8F0] hover:bg-[#f2f2f2] dark:border-[#1F2A37] bg-[#f8fafc] border dark:hover:bg-[#131b2d] dark:bg-[#0F172A] dark:bg-[#0f172a]"
      >
        <span>
          <svg
            width="21"
            height="20"
            className="dark:text-[94A3B8]"
            viewBox="0 0 21 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M10.5 4.16663V15.8333"
              stroke="currentColor"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M4.6665 10H16.3332"
              stroke="currentColor"
              stroke-width="1.4"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
        <span>Add Holding {num}</span>
      </div>
    );
  };

  return (
    <div className="w-full h-full space-y-[1rem]">
      <div className="pt-2">
        <AddPortfolio
          handleSelect={handleSelectedPortfolio}
          holdingBtnPressed={holdingBtnPressed}
          setAllPortfolios={setAllPortfolios}
          is_dashboard={true}
        />
      </div>
      <div className="w-full h-[70vh]">
        {currentPortfolio?.name == "All Portfolios" ? (
          <PortfolioChart
            asset={data?.allAsset}
            change={data?.allProfiteLoss}
            changeper={data?.ratio}
            showGoTo={true}
            series={data?.userTicker}
          />
        ) : (
          <PortfolioChart
            asset={data?.allAsset ? data?.allAsset : "0.00"}
            change="0.00"
            changeper="0.0"
            showGoTo={true}
            series={data?.userTicker}
          />
        )}
      </div>
      
      <div className="w-full flex items-center pt-[1rem] justify-between">
        <div className="flex items-center space-x-2">
          <span>
            Current Holdings (
            {currentPortfolio?.name == "All Portfolios"
              ? data?.userTicker?.length
              : data?.userTicker?.length}
            )
          </span>
          <span>
            <svg
              width="12"
              height="8"
              viewBox="0 0 12 8"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1 1.5L6 6.5L11 1.5"
                stroke="#020617"
                stroke-width="1.66667"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <div
            onClick={() => setView("table")}
            className={`w-10 h-10 cursor-pointer flex items-center justify-center rounded-full ${
              view == "table"
                ? "bg-[#F88F00]"
                : "bg-[#E2E8F0] dark:bg-[#0F172A]"
            } `}
          >
            <svg
              width="20"
              height="20"
              className={`${
                view == "table"
                  ? "text-[#020617]"
                  : "text-[#020617] dark:text-[#F8FAFC]"
              } `}
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.5 17.5H7.49996V8.33333H12.5V17.5ZM14.1666 17.5V8.33333H18.3333V16.6667C18.3333 16.8877 18.2455 17.0996 18.0892 17.2559C17.9329 17.4122 17.721 17.5 17.5 17.5H14.1666ZM5.83329 17.5H2.49996C2.27895 17.5 2.06698 17.4122 1.9107 17.2559C1.75442 17.0996 1.66663 16.8877 1.66663 16.6667V8.33333H5.83329V17.5ZM18.3333 6.66667H1.66663V3.33333C1.66663 3.11232 1.75442 2.90036 1.9107 2.74408C2.06698 2.5878 2.27895 2.5 2.49996 2.5H17.5C17.721 2.5 17.9329 2.5878 18.0892 2.74408C18.2455 2.90036 18.3333 3.11232 18.3333 3.33333V6.66667Z"
                fill="currentColor"
              />
            </svg>
          </div>
          <div
            onClick={() => setView("grid")}
            className={`w-10 h-10 cursor-pointer flex items-center justify-center rounded-full ${
              view == "grid" ? "bg-[#F88F00]" : "bg-[#E2E8F0] dark:bg-[#0F172A]"
            } `}
          >
            <svg
              width="16"
              height="16"
              className={`${
                view == "grid"
                  ? "text-[#020617]"
                  : "text-[#020617] dark:text-[#F8FAFC]"
              } `}
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.16654 7.16667H5.49988C6.41654 7.16667 7.16654 6.41667 7.16654 5.5V2.16667C7.16654 1.25 6.41654 0.5 5.49988 0.5H2.16654C1.24988 0.5 0.499878 1.25 0.499878 2.16667V5.5C0.499878 6.41667 1.24988 7.16667 2.16654 7.16667ZM2.16654 15.5H5.49988C6.41654 15.5 7.16654 14.75 7.16654 13.8333V10.5C7.16654 9.58333 6.41654 8.83333 5.49988 8.83333H2.16654C1.24988 8.83333 0.499878 9.58333 0.499878 10.5V13.8333C0.499878 14.75 1.24988 15.5 2.16654 15.5ZM8.83321 2.16667V5.5C8.83321 6.41667 9.58321 7.16667 10.4999 7.16667H13.8332C14.7499 7.16667 15.4999 6.41667 15.4999 5.5V2.16667C15.4999 1.25 14.7499 0.5 13.8332 0.5H10.4999C9.58321 0.5 8.83321 1.25 8.83321 2.16667ZM10.4999 15.5H13.8332C14.7499 15.5 15.4999 14.75 15.4999 13.8333V10.5C15.4999 9.58333 14.7499 8.83333 13.8332 8.83333H10.4999C9.58321 8.83333 8.83321 9.58333 8.83321 10.5V13.8333C8.83321 14.75 9.58321 15.5 10.4999 15.5Z"
                fill="currentColor"
              />
            </svg>
          </div>
        </div>
      </div>
      {view == "grid" ? (
        <div className="w-full flex items-center max-h-[40vh] overflow-y-auto no-scrollbar gap-4  pb-[2rem] flex-wrap">
          {currentPortfolio?.name == "All Portfolios"
            ? data?.userTicker?.map((item, index) => (
                <SmallCard item={item} index={index} />
              ))
            : currentPortfolio?.userTicker?.map((item, index) => (
                <SmallCard item={item} index={index} />
              ))}
          <>{currentPortfolio?.name != "All Portfolios" && <HoldingBtn />}</>
        </div>
      ) : (
        <div className="pb-[2rem]">
          {currentPortfolio?.name == "All Portfolios" ? (
            <RenderTable document={data?.userTicker} />
          ) : (
            <RenderTable document={data?.userTicker} />
          )}
        </div>
      )}
    </div>
  );
}

export default Dashboard;
