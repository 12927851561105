import React from 'react'

function BaseModal({children}) {
  return (
    <div className="base-modal">
    <div className="fixed inset-0 backdrop-blur-sm bg-[#00000050] dark:bg-[#02061750] transition-opacity base-modal"></div>

    <div className="fixed base-modal inset-0 overflow-y-auto">
      <div className="flex items-center justify-center min-h-full p-4 text-center sm:p-0">
        <div
          onClick={(e) => e.stopPropagation()}
          className={`relative bg-transparent p-2 overflow-x-hidden text-left overflow-hidden transform transition-all sm:my-8 rounded-[10px]`}
        >
          {children}
        </div>
      </div>
    </div>
  </div>
  )
}

export default BaseModal