import FundTable from "../table/FundTable";

function Positions( {data} ) {
  return (
    <>
      <div className="flex h-full w-full gap-6">
          <div className="h-full w-full ">
            <p className="mb-2">SPY.US <span className="text-[#EF4444]">Overweight</span> Relative to QQQ.US</p>
            <FundTable data={data} showPagination={false} showColumns={['Stock/Ticker name', 'Combined ']}/>
          </div>
          <div className="h-full w-full ">
            <p className="mb-2">SPY.US <span className="text-[#22C55E]">Underweight</span> Relative to QQQ.US</p>
            <FundTable data={data} showPagination={false} showColumns={['Stock/Ticker name', 'Combined ']}/>
          </div>
      </div>
    </>
  );
}

export default Positions

