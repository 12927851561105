import React,{useEffect,useState} from 'react'
import './layout.css'
import { Routes,Route,useNavigate } from "react-router-dom";
import Dashboard from '../pages/Dashboard';
import PayoutCalender from '../pages/PayoutCalender';
import Portfolio from '../pages/Portfolio'
import Watchlist from '../pages/Watchlist'
import MostPopularStocks from '../pages/MostPopularStocks'
import MostPopularFunds from '../pages/MostPopularFunds'
import HighestDividendGrade from '../pages/HighestDividendGrade'
import MonthlyDividendPayers from '../pages/MonthlyDividendPayers'
import DividendKings from '../pages/DividendKings'
import DividendAristocrats from '../pages/DividendAristocrats'
import MostPopularReits from '../pages/MostPopularReits'
import CoveredCalls from '../pages/CoveredCalls'
import IncomeGoals from '../pages/IncomeGoals'
import FutureValue from '../pages/FutureValue'
import FundOverlap from '../pages/FundOverlap'
import StockLocator from '../pages/StockLocator';
import Goals from '../pages/Goals'
import ReferEarns from '../pages/ReferEarns'
import HelpSupport from '../pages/HelpSupport'
import Feedback from '../pages/Feedback'
import Billing from '../pages/Billing'
import Settings from '../pages/Settings'
import Sidebar from '../components/Sidebar';
import Navbar from '../components/Navbar';
import Quote from '../pages/Quote';
import { useSelector,useDispatch } from 'react-redux';
import { SET_SIDE_BAR,setSelectedRoute,toggleFeedbackModal,toggleSessionInfoModal } from '../store/slices/themeSlice';
import ModalContainer from '../components/Modal/ModalContainer';
import BaseModal from '../components/BaseModal';
import ModalBtn from '../components/Button/ModalBtn';
import yellowdot from '../assets/yellowdot.svg'
import ChangePasswordModal from '../components/Modal/ChangePasswordModal';
import VerificationModal from '../components/Modal/VerificationModal';
import TwoFaModal from '../components/Modal/TwoFaModal';
import SessionInfoModal from '../components/Modal/SessionInfoModal';
import DeleteModal from '../components/Modal/DeleteModal';

function Layout() {
    const isSidebarOpen = useSelector((state) => state.theme.isSideBarOpen);
    const openFeedback = useSelector((state) => state.theme.feedbackModal);
    const openSetting = useSelector((state) => state.theme.settingModal);
    const openChangePassword = useSelector((state) => state.theme.changePasswordModal);
    const openVerification = useSelector((state) => state.theme.verificationModal);
    const openTwoFa = useSelector((state) => state.theme.twofaModal);
    const openSessionInfo = useSelector((state) => state.theme.sessionInfoModal);
    const openDelete = useSelector((state) => state.theme.deleteModal);
    const dispatch = useDispatch()
    const navigate = useNavigate()
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [subject,setSubject] = useState('')
  const [text,setText] = useState('')
  const [openNotification,setOpenNotification] = useState(false)
  const [selectedNotification,setSelectedNotification] = useState(
    {name:'System',list:[
      {id:1,title:'Login attempted from new IP',desc:'The system has detected that your account is logged in from an unused IP address...',dateTime:'2024-04-01 15:28:50'},
      {id:2,title:'New device has been authorized ',desc:'The system has detected that your account is logged in from an unused IP address...',dateTime:'2024-04-02 15:28:50'},
    ]}
  )
  const notificationsItems = [
    {name:'System',list:[
      {id:1,title:'Login attempted from new IP',desc:'The system has detected that your account is logged in from an unused IP address...',dateTime:'2024-04-01 15:28:50'},
      {id:2,title:'New device has been authorized ',desc:'The system has detected that your account is logged in from an unused IP address...',dateTime:'2024-04-02 15:28:50'},
    ]},
    {name:'Announcement',list:[
      {id:1,title:'New Dividend Alert - IBM',desc:"IBM inaugural interior dividend has been issued. Explore your Dividend Calendar to effortlessly monitor key financial events",dateTime:'2024-04-01 15:28:50',image:'ibm.png'},
      {id:2,title:'New Dividend Alert - TSLA',desc:"TSLA’s inaugural interior dividend has been issued. Explore your Dividend Calendar to effortlessly monitor key financial events",dateTime:'2024-04-02 15:28:50',image:'tesla.png'},
    ]},
  ]


    const handleCloseDropdown = ()=>{
          dispatch(SET_SIDE_BAR(false))
    }

    useEffect(()=>{
        let storageRoute = JSON.parse(localStorage.getItem("selectedRoute"));
        if(storageRoute){
          dispatch(setSelectedRoute(storageRoute));
          navigate(storageRoute.route)
        }
      },[])

      useEffect(() => {
        const handleResize = () => setScreenWidth(window.innerWidth);
    
        window.addEventListener('resize', handleResize);
        
        return () => window.removeEventListener('resize', handleResize);
      }, []);

      const handleChange = (event) => {
        if (event.target.value.length <= 255) {
          setText(event.target.value);
        }
      };
    
      const handleSubmitModal = ()=>{
       
        handleCloseModal()
      }
    
      const handleCloseModal = ()=>{
        dispatch(toggleFeedbackModal())
        setSubject('')
        setText('')
      }

  return (
    <div className='w-full flex h-full'>
        <div>
        {openFeedback &&  
      <BaseModal>
        <ModalContainer title="Send Feedback" onClose={handleCloseModal} customClass={`${screenWidth < 441 ? 'w-full': screenWidth < 521 ? '!w-[25rem]' : '!w-[30rem]'} min-h-[20rem] dark:bg-[#020617]`}>
        <div className='w-full h-full py-4'>
           <div className="px-4 pb-4 border-b dark:border-[#1E293B]">
              <span className="text-[#94A3B8] font-normal text-[14px]">Continuous enhancements are being made to our system each day. We highly value your contributions and experiences, as they play a crucial role in ensuring a seamless user experience for <span className="font-medium text-[#F88F00]">IVY Dividends</span> users.</span>
            </div>
                <div className='w-full flex items-center border-b dark:border-[#1E293B] px-4 justify-between py-6'>
                  <span className='dark:text-[#94A3B8] text-[14px] font-normal'>
                     Subject
                  </span>
                  <div>
                      <input placeholder='Type here...' value={subject} onChange={(e)=>{setSubject(e.target.value)}} className='bg-transparent text-right dark:text-[#94A3B8] text-[14px] font-normal border-none focus:outline-none'/>
                  </div>
                  </div>
                <div className='w-full border-b dark:border-[#1E293B] px-4 py-6'>
                  <textarea  onChange={handleChange} value={text} maxLength="255" style={{ resize: 'none' }} placeholder='Write message here....' className='bg-transparent overflow-none w-full h-[3rem] dark:text-[#94A3B8] text-[14px] font-normal border-none focus:outline-none' />
                  <span className='w-full flex justify-end text-[#94A3B8] text-[14px] font-normal'>
                  {`${text?.length}/255`}
                  </span>
                </div>
                <div className='w-full flex justify-end gap-3 pt-[1.5rem] pb-[0.5rem] px-4'>
                <ModalBtn title='Go Back' onPress={handleCloseModal} customClass='text-black dark:text-[#F1F5F9]'/>
                <ModalBtn title='Submit Feedback' onPress={handleSubmitModal} customClass='!bg-[#FFB34B] !text-[#020617] !font-semibold' />
                </div>
               </div>
        </ModalContainer>
      </BaseModal>}
        </div>

        {openSetting && 
          <div>
            <BaseModal>
                <Settings />
            </BaseModal>
          </div>
        }

        {openChangePassword &&
        <div>
          <BaseModal>
            <ChangePasswordModal />
          </BaseModal>  
        </div>

        }
        {openDelete &&
        <div>
          <BaseModal>
            <DeleteModal />
          </BaseModal>  
        </div>

        }
        {openSessionInfo &&
        <div>
          <BaseModal>
            <SessionInfoModal />
          </BaseModal>  
        </div>

        }
        {openTwoFa &&
        <div>
          <BaseModal>
            <TwoFaModal />
          </BaseModal>  
        </div>

        }
        {openVerification &&
        <div>
          <BaseModal>
            <VerificationModal />
          </BaseModal>  
        </div>

        }

       {openNotification && <div>
            <BaseModal>
              <ModalContainer title="Notifications (10)" onClose={()=>setOpenNotification(false)} customClass={`${screenWidth < 600 ? '!w-full' : '!w-[34rem]'} min-h-[20rem] dark:bg-[#020617]`}>
                  <div className='w-full flex items-center flex-wrap gap-4 p-6'>
                    {notificationsItems?.map((item,i)=>(
                      <div onClick={()=>setSelectedNotification(item)} key={i} className={`  text-[14px] font-medium ${selectedNotification?.name == item?.name ? 'dark:bg-[#F1F5F9] bg-[#cccccc] text-black cursor-default':'dark:bg-[#0F172A]  bg-[#cccccc50] text-[#64748B] hover:opacity-70 cursor-pointer'} rounded-[2rem] px-4 py-2`}>
                        {item?.name} ({item?.list?.length} unread)
                      </div>
                    ))}
                  </div>

                  <div className='mt-[1rem] h-[60vh] overflow-y-auto no-scrollbar px-6'>
                 {selectedNotification?.list?.map((val,index)=>(
                   <div className='flex items-start w-full py-4'>
                    <div className='w-2/12'>
                      {val?.image ? 
                        <img src={require(`../assets/images/${val?.image}`)} className='' />
                        :
                        <svg width="40" className='dark:text-[#431407] text-[#561f10]' height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width="40" height="40" rx="20" fill="currentColor"/>
                        <path d="M20 11.25C22.3206 11.25 24.5462 12.1719 26.1872 13.8128C27.8281 15.4538 28.75 17.6794 28.75 20C28.75 22.3206 27.8281 24.5462 26.1872 26.1872C24.5462 27.8281 22.3206 28.75 20 28.75C17.6794 28.75 15.4538 27.8281 13.8128 26.1872C12.1719 24.5462 11.25 22.3206 11.25 20C11.25 17.6794 12.1719 15.4538 13.8128 13.8128C15.4538 12.1719 17.6794 11.25 20 11.25ZM20 15C19.8413 14.9999 19.6843 15.0328 19.5391 15.0968C19.3938 15.1607 19.2635 15.2543 19.1565 15.3714C19.0494 15.4886 18.968 15.6268 18.9174 15.7772C18.8668 15.9276 18.8481 16.087 18.8625 16.245L19.3188 21.2525C19.3364 21.4209 19.4158 21.5767 19.5416 21.69C19.6674 21.8032 19.8307 21.8659 20 21.8659C20.1693 21.8659 20.3326 21.8032 20.4584 21.69C20.5842 21.5767 20.6636 21.4209 20.6812 21.2525L21.1363 16.245C21.1507 16.0871 21.132 15.9278 21.0815 15.7775C21.0309 15.6272 20.9496 15.4891 20.8427 15.3719C20.7358 15.2548 20.6056 15.1612 20.4605 15.0972C20.3154 15.0331 20.1586 15 20 15ZM20 25C20.2652 25 20.5196 24.8946 20.7071 24.7071C20.8946 24.5196 21 24.2652 21 24C21 23.7348 20.8946 23.4804 20.7071 23.2929C20.5196 23.1054 20.2652 23 20 23C19.7348 23 19.4804 23.1054 19.2929 23.2929C19.1054 23.4804 19 23.7348 19 24C19 24.2652 19.1054 24.5196 19.2929 24.7071C19.4804 24.8946 19.7348 25 20 25Z" fill="#F88F00"/>
                        </svg>
                        
                      }
                    </div>
                    <div className='flex w-9/12 flex-col border-b border-[#E2E8F0] dark:border-[#1F2A37] space-y-2 py-4 pt-1'>
                        <div className='flex items-center space-x-2'>
                          <span>
                            <img src={yellowdot} />
                          </span>
                          <span className='dark:text-[#FFFFFF] text-[16px] font-normal'>{val.title}</span>
                        </div>
                        <span className='text-[14px] text-[#94A3B8] dark:text-[#94A3B8] font-normal'>
                          {val.desc}
                        </span>
                        <span className='dark:text-[#F1F5F9] text-[#94A3B8] text-[12px] font-normal'>{val.dateTime}</span>
                        <span className='text-[#F88F00] hover:underline cursor-pointer text-[14px] font-normal'>Mark as Read</span>
                    </div>
                  </div>
                 ))
                }
                </div>
              </ModalContainer>
            </BaseModal>
        </div>}


            {/* web sdiebar */}
            <div  className={`dark:bg-[#0F172A] border-r border-[#E2E8F0] dark:border-[#1F2A37] sideBarSize`}>
                    <Sidebar setOpenNotification={setOpenNotification} />
            </div>

            {/* mobile sidebar */}
            <div  className={`dark:bg-[#0F172A] showOnMobile border-r border-[#E2E8F0] dark:border-[#1F2A37] ${isSidebarOpen ? 'block mainSide':'hidden'}`}>
                <div id="animatedElement" className={`md:block ${isSidebarOpen ? 'block sideWidth sideBarOpen':'hidden sideBarClose'}`}>
                <div className="items-center justify-end relative crossSidebar hidden">
                    <svg onClick={handleCloseDropdown} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 absolute z-50 dark:text-white top-1 right-1 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M6 18 18 6M6 6l12 12" />
                    </svg>
                </div>
                    <Sidebar />
                </div>
            </div>
        <div className='mainPageSize dark:bg-[#020617]'>
            <div className='h-[8%] headerHeight px-4 border-b border-[#E2E8F0] dark:border-[#1F2A37]'>
                <Navbar />
            </div>
            <div className='h-[92%] mainContainer text-[#020617] dark:text-[#F8FAFC]  no-scrollbar overflow-y-auto py-4 px-6'>
                <Routes>
                    <Route path="/" element={<Dashboard />} />
                    <Route path="/payout-calender" element={<PayoutCalender />} />
                    <Route path="/portfolio" element={<Portfolio />} />
                    <Route path="/watchlist" element={<Watchlist />} />
                    <Route path="/most-popular-stocks" element={<MostPopularStocks />} />
                    <Route path="/most-popular-funds" element={<MostPopularFunds />} />
                    <Route path="/highest-dividend-grade" element={<HighestDividendGrade />} />
                    <Route path="/monthly-dividend-payers" element={<MonthlyDividendPayers />} />
                    <Route path="/dividend-kings" element={<DividendKings />} />
                    <Route path="/dividend-aristocrats" element={<DividendAristocrats />} />
                    <Route path="/most-popular-reits" element={<MostPopularReits />} />
                    <Route path="/covered-calls" element={<CoveredCalls />} />
                    <Route path="/income-goals" element={<IncomeGoals />} />
                    <Route path="/future-value" element={<FutureValue />} />
                    <Route path="/fund-overlap" element={<FundOverlap />} />
                    <Route path="/stock-locator" element={<StockLocator />} />
                    <Route path="/goals" element={<Goals />} />
                    <Route path="/refer-earn" element={<ReferEarns />} />
                    <Route path="/support" element={<HelpSupport />} />
                    <Route path="/feedback" element={<Feedback />} />
                    <Route path="/billing" element={<Billing />} />
                    <Route path="/quote" element={<Quote />} />
                </Routes>
            </div>
        </div>
    </div>
  )
}

export default Layout