import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getSearch } from "../ApiControler";
import { addWatchlist } from '../ApiControler'
function SymbolSearch({
  onClose,
  page,
  addToWatchlist,
  setSelectedSymbol,
  symbolClick = () => {},
}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const symbol1 = queryParams.get("symbol");

  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [inputValue, setInputValue] = useState("");
  const [selectedVal, setSelectedVal] = useState({ key: "all", value: "All" });
  const suggestion = [
    { key: "all", value: "All" },
    { key: "recentlySearched", value: "Recently Searched" },
    { key: "frequentlySearched", value: "Frequently Searched" },
  ];

  const [tickerInfo, setTickerInfo] = useState([]);

  const fetchData = async (symbol) => {
    try {
      if (!symbol){
      const token = localStorage.getItem("token");
      const data = await getSearch(token, symbol1);
      setTickerInfo(data?.data);
      setLoading(false);
      }
      else{
        
      const token = localStorage.getItem("token");
      const data = await getSearch(token, symbol);
      setTickerInfo(data?.data);
      setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   fetchData();
  // }, [symbol]);
  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);

    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleQuote = (symbol) => {
    symbolClick(symbol);
    if (page == "portfolio") {
    } else {
      navigate(`/quote?symbol=${symbol}`);
      setSelectedSymbol(symbol);
    }
    handleClose();
  };

  const handleAddToWatchlist = (item) => {
    const token = localStorage.getItem("token");
    // console.log("WatchList", item?.ticker)
    if (page == "watchlist") {
      addToWatchlist(item);
    } else {
      addWatchlist(token,item?.ticker)
      navigate(`/watchlist`)
      
    }
  };

  const handleClose = () => {
    onClose();
  };

  const filteredData = tickerInfo?.filter((item) =>
    item?.ticker?.toLowerCase()?.includes(inputValue?.toLowerCase())
  );

  const handleSuggestion = (item) => {
    setSelectedVal(item);
  };

  const getTruncateText = (val) => {
    let maxLength = 15;
    if (screenWidth > 500) {
      if (val?.length > maxLength && screenWidth < 1151) {
        return val?.substring(0, maxLength) + "..";
      } else {
        return val;
      }
    }
  };

  return (
    <div className="sm:w-[50vw]  h-[80vh] pb-[2rem]">
      <div className="flex w-full h-full text-[#000000] p-4">
        <div className="w-full space-y-5">
          {/* header  */}
          {/* <div className="flex justify-between  items-center">
                <p className="font-[500] dark:text-[#F6F6F6] text-[24px]">     
                Search Symbols
                </p>
                <div
                onClick={handleClose}
            className="p-2 rounded-full  block bg-[#f0f3f5] cursor-pointer hover:bg-[#e4e3e3] dark:hover:bg-[#232323] dark:bg-[#FFFFFF1A]"
          >
            <div  className="dark:text-[#B0B0B0]">
              <svg
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12 4L4 12"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M4 4L12 12"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>
            </div> */}

          {/* search bar  */}
          <div className="flex items-center w-full dark:text-white space-x-3 px-2 py-2 cursor-pointer text-sm dark:bg-[#0f172a] dark:border-[#334155] bg-[#F8FAFC] border border-[#D1D1D1] rounded-[10px]">
            <svg
              width="20"
              height="20"
              className="dark:text-[#94A3B8]"
              viewBox="0 0 20 20"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.16667 15.8333C12.8486 15.8333 15.8333 12.8486 15.8333 9.16667C15.8333 5.48477 12.8486 2.5 9.16667 2.5C5.48477 2.5 2.5 5.48477 2.5 9.16667C2.5 12.8486 5.48477 15.8333 9.16667 15.8333Z"
                stroke="currentColor"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M17.5 17.5L13.875 13.875"
                stroke="currentColor"
                stroke-width="1.7"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>

            <input
              value={inputValue}
              onChange={(e) => {
                setInputValue(e.target.value);
                fetchData(e.target.value);
              }}
              className="bg-transparent text-[#181818] dark:text-[white] placeholder:text-[#6D6D6D] placeholder:dark:text-[#94A3B8] placeholder:text-[14px] text-[14px] focus:outline-none w-full "
              placeholder="Search stock or company name"
            />
            <div className="flex items-center space-x-3">
              <p
                onClick={() => {
                  setInputValue("");
                  setTickerInfo([]);
                }}
                className="text-[black] cursor-pointer dark:text-white text-[14px]"
              >
                Clear
              </p>
              <svg
                width="2"
                className="text-[#E7E7E7] dark:text-[#334155]"
                height="28"
                viewBox="0 0 2 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect width="2" height="28" fill="currentColor" />
              </svg>

              <svg
                onClick={handleClose}
                width="24"
                className="dark:text-white"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M18 6L6 18"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6 6L18 18"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          </div>

          <div className="w-full h-[70vh] dark:border-[#334155] dark:bg-[#0f172a] bg-[#F8FAFC] border rounded-[10px] p-[1.5rem] overflow-hidden">
            {/* suggestion     */}
            <div className="w-full flex items-center space-x-3 pt-2 px-2">
              {suggestion?.map((item, i) => (
                <div
                  key={i}
                  className="flex items-center text-[14px] suggestionText  font-[400] cursor-pointer"
                  onClick={() => handleSuggestion(item)}
                >
                  <div
                    className={`flex p-1.5 w-fit px-4 rounded-3xl  ${
                      selectedVal?.key === item.key
                        ? "text-[#F6F6F6] bg-[#292929] dark:text-[#020617] font-[500] dark:bg-[#F8FAFC]"
                        : "bg-[#E2E8F0] hover:bg-[#ced8e5]  dark:bg-[#141D31] dark:text-[#94A3B8] text-[#292929]"
                    }`}
                  >
                    {item.value}
                  </div>
                </div>
              ))}
            </div>

            {/* results  */}
            <div className="w-full h-[60vh] mt-[1rem] overflow-x-hidden overflow-y-auto pr-2">
              <div className="w-full h-full">
                <div className="w-full sm:text-[16px]  text-[12px]">
                  {tickerInfo?.map((item, i) => (
                    <div
                      key={i}
                      className="flex items-start hover:bg-[#f2f2f2] dark:hover:bg-[#141d31] justify-between py-5 border-b dark:border-b-[#1F2A37] border-b-[#E7E7E7]"
                    >
                      <div className="flex items-start sm:space-x-4 space-x-1">
                        <img
                          style={{
                            height: "50px",
                            width: "50px",
                            borderRadius: "50%",
                          }}
                          src={item?.logo}
                          className={screenWidth < 521 ? "w-6 h-6" : ""}
                        />
                        <div className="dark:text-[#F6F6F6]">
                          <p
                            onClick={() => handleQuote(item?.ticker)}
                            className="font-[400] text-[1rem] companyText cursor-pointer hover:underline"
                            title={item?.companyName}
                          >
                            <span
                              className={`font-[600] mr-2 ${
                                screenWidth < 361 ? "text-[12px]" : ""
                              }`}
                            >
                              {item.ticker}
                            </span>
                            {getTruncateText(item?.companyName)}
                          </p>
                          <p className="text-[#BDBDBD] sm:text-[14px]">
                            {item.companyName}
                          </p>
                        </div>
                      </div>

                      <div className="flex sm:items-center sm:space-x-5">
                        <div>
                          <p className="font-[600] priceText">
                            <span className="dark:text-[#F8FAFC]">
                              ${item?.currentPrices}
                            </span>
                            <span class="text-[12px] changeText ml-1 dark:text-[#10B981]  text-[#037950]">
                              {item?.differenceInCurrency?.toFixed(2)} (
                              {item?.differenceInPercentage?.toFixed(2)})
                            </span>
                          </p>
                          <div>
                            <p
                              className={`text-[#BDBDBD] dark:text-[#94A3B8] sm:text-right font-[600] text-[9px] ${
                                screenWidth < 361 ? "text-[7px]" : ""
                              }`}
                            >
                              Last updated: Yesterday
                            </p>
                          </div>
                        </div>

                        <div>
                          {screenWidth > 1499 ? (
                            <div
                              onClick={() => handleAddToWatchlist(item)}
                              className="py-1.5 pl-2.5 pr-3 w-fit bg-[#E2E8F0] hover:bg-[#ced8e5] cursor-pointer   dark:hover:bg-[#b7b5b5] dark:bg-[#F6F6F6] flex items-center space-x-1 rounded-2xl"
                            >
                              <div class="">
                                <svg
                                  className={`sm:h-4 sm:w-4 h-3 w-3 text-[#4F4F4F] dark:text-[#0F172A] ${
                                    screenWidth < 361 ? "w-2 h-2" : ""
                                  }`}
                                  viewBox="0 0 16 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M7.99967 1.33334L10.0597 5.50668L14.6663 6.18001L11.333 9.42668L12.1197 14.0133L7.99967 11.8467L3.87967 14.0133L4.66634 9.42668L1.33301 6.18001L5.93967 5.50668L7.99967 1.33334Z"
                                    stroke="currentColor"
                                    stroke-width="1.2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </div>
                              <p
                                className={`text-[#4F4F4F] dark:text-[#0F172A] font-bold sm:text-[11px] text-[9px] addToWatchlist`}
                              >
                                Add to watchlist
                              </p>
                            </div>
                          ) : (
                            <div
                              onClick={() => handleAddToWatchlist(item)}
                              className="mx-2"
                              title="Add to watchlist"
                            >
                              <svg
                                className="cursor-pointer"
                                width="18"
                                height="18"
                                viewBox="0 0 18 18"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M8.99951 1.70833V16.2917"
                                  stroke="#F88F00"
                                  stroke-width="1.875"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                                <path
                                  d="M1.70801 9H16.2913"
                                  stroke="#F88F00"
                                  stroke-width="1.875"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SymbolSearch;
