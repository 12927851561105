import React from 'react'

function HeadWidthLine({title}) {
  return (
    <div className='flex items-center space-x-4 w-full'>
            <span className='dark:text-[#F8FAFC] whitespace-nowrap text-[18px] font-normal'>
                {title}
            </span>
            <span className='w-full h-[2px] dark:bg-[#0F172A] bg-[#E2E8F0]' />
        </div>
  )
}

export default HeadWidthLine