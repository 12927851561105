import React,{useState,useEffect} from 'react'
import ModalContainer from './ModalContainer'
import ModalBtn from '../Button/ModalBtn'
import infoIcon from '../../assets/info.svg'
import {toggleTwoFaModal,toggleSettingModal} from '../../store/slices/themeSlice'
import { useDispatch } from 'react-redux'

function TwoFaModal() {
    const dispatch = useDispatch()
    const [email,setEmail] = useState(false)
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    useEffect(() => {
      const handleResize = () => setScreenWidth(window.innerWidth);
  
      window.addEventListener('resize', handleResize);
      
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    const handleModalClose = ()=>{
        localStorage.setItem("currentSetting","Security")
        dispatch(toggleTwoFaModal());
        dispatch(toggleSettingModal());
    }
    const handleSave = ()=>{
       
        handleModalClose()
    }

    const toggleSwitch = ()=>{
        setEmail(!email);
    }

  return (
    <div className={`${screenWidth < 362 ? 'w-full' : screenWidth < 525 ? 'w-[20rem]' : screenWidth < 525 ? 'w-[25rem]' : 'w-[30rem]'} h-[15rem]`}>
        <ModalContainer title='Two-Factor Authentication' onClose={handleModalClose} customClass='w-full h-full relative'>
        <div className='w-full flex items-center px-4 dark:border-[#1E293B] pb-4 pt-[1.5rem] border-[#cecece70] border-b justify-between'>
        <div className='flex items-center space-x-2'>
          <span className='label'>Email</span>
          <span className='pt-1'>
            <img src={infoIcon} />
          </span>
        </div>

        <div>
        <button
                      onClick={toggleSwitch}
                      className={`relative w-9 h-5 flex items-center ${email ? 'bg-[#F88F00]':'bg-[#d1d1d1] dark:bg-[#1E293B]'} rounded-full cursor-pointer`}
                    >
                      <div
                          className={`w-5 h-5 rounded-full shadow-md transform transition-transform ${
                            email ? 'translate-x-4 bg-white' : 'translate-x-0 bg-[#b2b2b2] dark:bg-[#475569]'
                          }`}
                        />
                  </button>
        </div>

       
      
      </div>
            

        <div className='absolute bottom-6 flex items-center justify-end space-x-3 right-4'>
           <div>
              <ModalBtn onPress={handleModalClose} title="Go Back" customClass={`pointer-events-auto cursor-pointer !font-medium dark:bg-[#080e20] bg-[#d8d8d8] text-[#383838] dark:text-[#F1F5F9]`} />
            </div>               
           <div>
              <ModalBtn title={screenWidth < 525 ? 'Save' : "Save Changes"} onPress={handleSave} customClass={`pointer-events-auto cursor-pointer !text-white'} !font-semibold !bg-[#FFac39] text-black`} />
            </div>               
            </div>
        </ModalContainer>
    </div>
  )
}

export default TwoFaModal